import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const NewsletterForm = ({ width }) => {
  const [email,setEmail] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!email){
      toast.error('Enter your email.');
    return;
    }
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/submit-newsletter`,
        { email: email },
        {
          headers: {
            'Content-Type': 'application/json', 
          },
        }
      );
      setEmail('')
      toast.success(resp.data.message); 
    } catch (error) {
      toast.error(error.message); 
    }
  };
  
  return (
    <Wrapper id="newsletter-section" style={{ width: width }}>
      <form>
        <div>
        < h5>SIGN UP TO OUR NEWSLETTER</h5>
         <div className='line'>Subscribe to our email list to enjoy exclusive discounts on all the plants and gardening products you love!.</div>
       </div>
       <div className='mail-btn'>
         <input type="email" placeholder="Your email" onChange={(e)=>setEmail(e.target.value)}/>
        <button type="submit" onClick={handleSubmit}>Subscribe</button>
       </div>
    </form>
    </Wrapper>
  );
};

export default NewsletterForm;

const Wrapper = styled.form`
 width:95%;
 margin:0 auto;
 background-color: rgba(35, 57, 142, 0.2);
 margin-bottom:2rem;

 h5{
  text-transform:uppercase;
  font-weight:600;
  letter-spacing:0;
  margin-bottom:0.2rem;
 }
 .line {
  padding: 0;
  margin: 0;
  text-align: justify;
  line-height: 1.6; 
  height: auto;
  overflow: visible; 
  max-width:700px;
}
 form{
  display:flex;
  justify-content:space-between;
  padding:2rem 2.5rem;
  gap:1.5rem;
 }
 .mail-btn{
  display:flex;
  flex-direction:row;
  gap:1rem;
  align-items:center;
  justify-content:center;
  input{
    height:3rem;
    width:250px;
    border:none;
    border-radius:5px;
    padding-left:0.5rem;
  }
  button{
  padding:1rem 2rem;
  border:none;
  background:#23398E;
  color:#fff;
  text-transform:uppercase;
  font-weight:700;
  border-radius:5px;
  }
 }
 input[type="email"] {
  font-family: "Poppins";
}
 @media(max-width:898px){
  form{
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:1rem;
    h5{
      text-align:center;
    }
  }
 }
 @media(max-width:556px){
  font-size:14px;
  .line {
    margin-top:0.35rem;
    line-height: 1.4;

  }
  h5{
    font-size:15px;
  }
  input{
    height:1.75rem !important;
    max-width:200px !important;
    padding-left:0.5rem;
  }
  form{
    padding:1rem 1.25rem;
  }
  button{
  font-weight:600 !important;
  font-size:13px;
  padding:0.45rem 0.85rem !important;
  border-radius:5px;
  }
 }
`;