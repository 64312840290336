import React from 'react'
import styled from 'styled-components'
import FormRow from './support/FormRow'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { forgotPassword } from '../feature/user/userSlice'

const ForgotPasswordPage = () => {
  
  const [email,setEmail] = useState('')
  const dispatch = useDispatch()

  const handleChange = (e)=>{
    const value = e.target.value
    setEmail(value)
  }
  const handleSubmit = () => {
   dispatch(forgotPassword(email))
  };
  return (
    <Wrapper>
      <div className='form small-form'>
       <h5> Forgot Your Password ?</h5>
      <FormRow type="email"  name="email" value={email} handleChange={handleChange}/>
      <button type='button' className="btn-block btn" onClick={handleSubmit} >submit</button>
      <div className='already-member'>
      Already a member?
      <Link to='/user/login' className="member-btn"  >sign in</Link>
      </div>
      </div>
    </Wrapper>
  )
}

export default ForgotPasswordPage

const Wrapper = styled.div`
  width:95%;
  margin:0 auto;
  min-height:42vh;
  input{
  
  }
.forgot-div{
  max-width: 400px;
  margin:0 auto;
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  gap:1rem;
  margin-top:1rem;
}
h5{
  margin-top:0.25rem;
  margin-bottom:0.25rem;
  font-weight:600;
}
.btn-block{
  font-size:17px;
  font-weight:500;
  text-transform:uppercase;
  height:40px;
  margin-top:0;
  background:var(--green-300);

}
.member-btn {
  background: transparent;
  border: transparent;
  color: var(--primary-500);
  cursor: pointer;
  letter-spacing: var(--letterSpacing);
  margin-top:0.25rem;
  margin-left:0.5rem;
}
.already-member{
  width:100%;
  display:flex;
  flex-direction:row;
  margin-top:0.5em;
  justify-content:flex-start;
  align-items:left;
}
`