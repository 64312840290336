import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getSingleBlog } from '../feature/blogs/blogsSlice';
import styled from 'styled-components';
import moment from 'moment';
import BlogsContainer from '../components/BlogsContainer'
import { useRef } from 'react';
import Loading from './Loading';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const SingleBlogPage = () => {
  const {blog,blogs,isSingleBlogLoading} = useSelector((store)=>store.blog);
  const {user,myUser} = useSelector((store)=>store.user) ;
  const navigate = useNavigate()
  const {id} = useParams()
  const dispatch = useDispatch()
  const myRef = useRef(null);


  useEffect(()=>{
  dispatch(getSingleBlog(`${id}`))
  if (myRef.current) {
    myRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  },[id,dispatch])

  const newBlogs = blogs.filter(blog => blog._id !== id);


  const handleButton=(prop)=>{
    navigate(`/blogs/edit-post/${prop._id}`)
  }

const dateAndTime = (prop)=>{
  const formattedDateTime = moment(prop).format("YYYY-MM-DD HH:mm:ss");
  return formattedDateTime
}

const removeHtmlTags = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
};


const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],
  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction
  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['clean']                                         // remove formatting button
];



let showUpdate = false
 if (myUser && blog.author) {
    if (blog.author._id === user?.id) { // Ensure user is not null
      showUpdate = true;
    }
  }
  if(isSingleBlogLoading){
    return(
      <Wrapper>
        <Loading />
      </Wrapper>
    )
  }
    return (
      <>
      <Wrapper ref={myRef}>
        <div className='image'>
        <img src={blog.photo?.url} alt="blog" className='blog-img' draggable="false"/>
        </div>
        <div className='details-div'>
          <h4>{blog.title}</h4>
          <div><span className='title'>{blog.author?.name||''}</span><span className='date'>{dateAndTime(blog.createdAt)}</span></div>
          <div className='tags' style={{ display: 'flex', flexDirection: 'row', gap: '0.75rem' }}>
            {blog.tags && blog.tags.map((tag, idx) => (
            <div key={idx} className='tag' style={{ background: 'var(--green-500)', padding: '0.1rem 0.5rem', fontSize: '14px', color: '#fff', borderRadius: '5px', textTransform: 'capitalize' }}>
            {tag}
            </div>
             ))}
          </div>
          <div className='summary'>{blog.summary}</div>
          <div className='summary'>
          <ReactQuill
      theme="snow"
      value={blog.content}
      readOnly={true} 
      modules={{ toolbar: false }}
      
    />
          </div>
        </div>
      </Wrapper>
       <BlogsContainer  content={'Other Recent Blogs'} newBlogs={newBlogs}/>
       </>
    )
}

export default SingleBlogPage

const Wrapper = styled.div`

 width:70%;
 margin:0 auto;
 display:flex;
 flex-direction:row;
 flex-wrap:wrap;
 margin-top:2rem;
 margin-bottom:3rem;
 gap:2rem;
 min-height:60vh;

 .image {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.blog-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tags{
  margin-top:0.5rem;
}
.quill{
  border:none;
}
.ql-container{
  border:none;
  font-size:16px;
  font-family: var(--bodyFont);
  line-height: 1.75;
  color: var(--textColor);
  a{
    color: var(--textColor);
  }
  
}

 p{
  margin:0;
  padding:0;
  line-height:30px;
  text-align: justify;
 }
 li{
  line-height:30px;
  text-align: justify;
 }
 .summary{
  font-weight:400;
  text-align: justify;
  margin:0.75rem 0rem;
 }

 .details-div{
  display:flex;
  flex-direction:column;
  width:100%;

  div{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
   
    .title{
      font-size:15px;
    }
    .date{
      font-size:13px;
      color:var(--grey-200);
    }
  }
 }
.content{
  width:100%;
  text-align: justify;
  margin-bottom:0.75rem;
  
}
.btn-update{
  width:100px;
  height:30px;
  text-transform:uppercase;
  font-weight:500;
}




@media(max-width:1140px){
  .image {
    width: 100%;
    height: auto;
  }
  
  .blog-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media(max-width:800px){
  p{
    margin:0;
    padding:0;
    line-height:30px;
    font-size:16px !important;
   }
}
@media(max-width:560px){
  width:95%;
  gap:1rem;
  margin-bottom:0.5rem;
  h4{
    font-size:16px;
    font-weight:600;
  }
  .content{
    font-size:14px;
  }
}
@media(max-width:580px){
  p{
    height: auto;
  }
  p{
    line-height:25px;
    font-size:13px !important;
   }
   li{
    line-height:25px;
    font-size:13px ;

   }
   .ql-indent-1{
    padding-left:0 !important;
    margin-left:0 !important;
   }
   ol{
    padding-left:0 !important;
    margin-left:0 !important;
   }
   .ql-editor{
    padding:0 !important;
   }
}
`