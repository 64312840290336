import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { closeCart } from '../../feature/modal/modalSlice'
import { getCart } from '../../feature/user/userSlice'
import axios from 'axios'
import { RiDeleteBin6Line } from "react-icons/ri";
import { checkTime } from '../../utils/helpers'
import { GrFormClose } from "react-icons/gr";
import Loading from '../Loading'

const CartContent = ( props) => {
  const {cartItems,cartLoading,myUser} = useSelector((store)=>store.user)
  const navigate = useNavigate()
  const [cartUpdated, setCartUpdated] = useState(false);
  let renderFromCart =true;
  let total=0;
  const toggle = ()=>{
    navigate('/user-cart')
    dispatch(closeCart());
    props.enableOverflow();
  }
  const toggleHome = ()=>{
    navigate('/')
    dispatch(closeCart());
    props.enableOverflow();
  }

  
  const removeItem = async (userid, itemid,dimensionId) => {
    
    if(myUser){
      try {
        const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}/cart/${userid}/${itemid}`);
        setCartUpdated(true);
        dispatch(getCart())
        return resp.data;
      } catch (error) {
        return error;
      }
    }else{
      try {
        const existingWishlistItems = JSON.parse(localStorage.getItem('cart')) || [];

    //  const itemIndexToRemove = existingWishlistItems.findIndex((item) => item.productId  === itemid);
     const itemIndexToRemove = existingWishlistItems.findIndex((item) =>  item.productId  === itemid &&  item.dimension._id === dimensionId);

     if (itemIndexToRemove !== -1) {
  
     existingWishlistItems.splice(itemIndexToRemove, 1);


     localStorage.setItem('cart', JSON.stringify(existingWishlistItems));
     setCartUpdated(true);
     dispatch(getCart())
  }
    } catch (error) {
        
    }
    }
  }
  const dispatch =useDispatch()
  useEffect(()=>{
    dispatch(getCart())
    setCartUpdated(false);
  },[cartUpdated,dispatch])

useEffect(()=>{
  dispatch(getCart())
},[])
  if(cartItems && cartItems.length===0){
    return (
      <Wrapper>
        <h4>Your cart is empty</h4>
        <button onClick={toggleHome} className='btn'>Add now</button>
      </Wrapper>
    )
  }
  if(cartLoading){
    return(
      <WrapperNext>
      <Loading />
    </WrapperNext>
    )
  }
  return (
 <WrapperNext>
      <div className='scroll'>
      {cartItems &&
        cartItems.map((cart, id) => {
          const { image, totalPrice, productQuantity, potName, size, title ,userId,_id,Status,flashSalePrice,flashSaleStartDate,flashSaleStartTime,
            flashSaleEndTime,flashSaleDiscount,productId,dimension          } = cart;
         
            let flashSaleActive =  false
            
            if(Status && Status === "Unlimited"){
              flashSaleActive= true
            }else{
              flashSaleActive= flashSaleStartDate ? checkTime(flashSaleStartDate, flashSaleEndTime,flashSaleStartTime,) : false;
            }
          total+=(flashSaleActive ? flashSalePrice : totalPrice)
       
          return (
            <>
            <div className='cart-container' key={id}>
            <Link to={`/products/${productId}`} key={id} className="cart-link" onClick={toggle}>
            
              <img src={image} alt='cart item'/>
              <div className='details'>
                <p className='title'>{title}</p>
                <p className='title-sub'>
               {Object.keys(dimension)
                     .filter(key => key.startsWith("Value"))
                     .map(key => dimension[key])
                     .filter(value => value !== undefined && value !== null && value !== '')
                     .join(" / ")}
              </p>
                <div className='inline-div'><span className='qty'>{productQuantity}</span> <GrFormClose />{flashSaleActive ? <p className='price'>{parseFloat(flashSalePrice?.toFixed(2))} <span>AED</span></p> : <p className='price'>{parseFloat(totalPrice?.toFixed(2))} <span>AED</span></p> }</div>
              </div>
            
            </Link>
            <button  type='button' className='delete-btn' onClick={()=>removeItem(userId,productId,dimension?._id)}><RiDeleteBin6Line /></button>
            </div>
            </>
          );
        })}
      </div>
        <div className='checkout-btn'>
          <div className='price-div'><p className='price-sub'>Subtotal:</p><p className='price'>{parseFloat(total).toFixed(2)}AED</p></div>
          <button className="btn btn-Link" onClick={toggle} >Visit cart</button>
        </div>
    </WrapperNext>
  );
};

export default CartContent

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 50vh;
height: auto; /* Allow the container to expand based on content */
padding: 20px;
text-align: center;
h4{
  font-size:2rem;
  text-decoration:none;
}
`
const WrapperNext = styled.div`
  
  img {
    padding-top:1rem;
    width: 80px;
    height: 100px;
  }
  .cart-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction:row; 
    width:100%;
  }
  .scroll {
    max-height: calc(100vh - 200px) !important;
    overflow-y: auto  !important;
    position:relative;
    padding-bottom:5rem;
  }
  .cart-container {
    width:95%;
    display: flex;
    margin-left: 1rem;
    padding-bottom:0.75rem;
    border-bottom:1px solid var(--grey-100);
    position:relative;
  }

  .cart-details {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    gap: 1rem;
    
  }

  p {
    font-weight: 400;
    font-size: 14px !important;
    padding: 0 !important;
    margin: 0 !important;
    padding-top:0.25rem !important;
    
  }
  .qty{
    color:var(--grey-500) !important;
  }
  .checkout-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction:column;
    align-items: center;
    border-top:1px solid var(--grey-100);
    background-color:var(--white);
    z-index:100;
    a{
      width:100%;
      height:50px;
      display:flex;
      justify-content:center;
      align-items:center;
      font-weight:600;
      font-size:19px;
      text-transform:uppercase;
   
    }
  }
  .inline-div{
    display:flex;
    flex-direction:row;
    margin-top:0.45rem;
    align-items:center;
    color:black;
    svg{
      margin:0.25rem;

    }
  }
  .price-div{
    width:100%;
    display:flex;
    justify-content: space-between;
    flex-direction:row;
    align-items: center;
    margin-bottom:0.5rem;
    p{
      font-size:1.25rem !important;
    }
  }
  .price{
    color:var(--green-500);
    font-weight:600;
    margin:0 !important;
    padding:0 !important;
    span{
      color:var(--green-500);
      
    }
  }
  
  .title {
    font-weight: 600;
    text-align:left;
  }
  .title-sub{
    font-size:12px !important;
    font-weight:500;
  }
  span {
    color:var(--grey-500);
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: left; 

    padding-top:1rem;
    padding-left:0.75rem;
    width:70%;
    text-align:left !important;
    p{

    }
  }
  .price-sub{
    font-size:15px;
    font-weight:500;
  }
  .delete-btn{
    align-self: flex-end;
    color:var(--green-300);
    position:absolute;
    bottom:5%;
    right:4%;
    color:black;
    z-index:99;
  }
  .delete-btn:hover{
    color:red;
  }
  button{
    background:transparent;
    border:none;
    font-size:20px;
  }
  .btn-Link{
    background:#228f47;
    width:100%;
    padding:0.5rem 0rem;
  }
  button:hover{
    transition:color 0.2s;
  }
  @media(max-width:580px){
    .title-sub{
      color:var(--grey-500);
      font-size:5px !important;
    }
    .delete-btn{
      font-size:14px;
    }
    .details{
      gap:0.15rem;
      p{
        font-size:13px !important;
        padding:0 !important;
        margin:0 !important;
      }
    }
    .cart-container{
      img{
        width:68px;
        height:85px;
      }
    }
    .price-sub{
      font-size:13px !important;
    }
    .checkout-btn{
      padding-top:0.25;
      padding-bottom:.5rem;
      a{
        height:30px;
        font-size:14px !important;
      }
      button{
        height:30px;
        font-size:14px !important;
        width:100%;
        margin-bottom:auto;
      }
    }
    
  }
  @media(max-width:480px){
    .cart-container{
      margin-left:0.75rem;
      width:95%;
    }
    .qty{
      font-size:13px !important;
    }
    .inline-div{
      svg{
        font-size:11px;
      }
    }
    .title-sub{
      color:var(--grey-500);
      font-size:11px !important;
    }import Loading from './../Loading';

    .cart-link {
      width:100%;
    }
  }
`;