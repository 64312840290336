import moment from "moment";

export const formatPrice = (number) => {
    return Intl.NumberFormat('en-US',{
        style:'currency',
        currency:'AED'
    }).format(number / 10)
}

//checking valid flash sale 
// export const checkTime = (inputDate, endHours,startTime) => {
//     let isFlashSaleActive =false
//     const flashSaleStartTime = new Date(inputDate);
//     const flashSaleEndTime = new Date(flashSaleStartTime);
//     flashSaleEndTime.setHours(flashSaleEndTime.getHours() + endHours);
//     const startTimeParts = startTime.split(':');
//     flashSaleEndTime.setHours(parseInt(startTimeParts[0], 10));
//     flashSaleEndTime.setMinutes(parseInt(startTimeParts[1], 10));

//     const currentTime = new Date();
//     isFlashSaleActive = currentTime >= flashSaleStartTime && currentTime <= flashSaleEndTime;
//     return isFlashSaleActive

//   };

 export const checkTime = (startDate, timeInHours, startTime) => {

    const startDateMoment = moment.tz(startDate, 'YYYY-MM-DD', 'Asia/Dubai');
    const startTimeMoment = moment.tz(startTime, 'HH:mm', 'Asia/Dubai');
  
    const startDateTime = startDateMoment.set({
      hour: startTimeMoment.get('hour'),
      minute: startTimeMoment.get('minute'),
    });
    const endDateTime = startDateTime.clone().add(timeInHours, 'hours');

    const currentTimeDubai = moment().tz('Asia/Dubai');
    const isFlashSaleActive = currentTimeDubai >= startDateTime && currentTimeDubai <= endDateTime;
   

    return  isFlashSaleActive
  };