import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import FormRow from '../components/support/FormRow'
// import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { getCart, loginUser, registerUser, setIsActionFalse, setMyUser, verifyToken } from '../feature/user/userSlice'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import facebook from '../assets/images/facebook.png'
import google from '../assets/images/google_2702602.png'
import { FcGoogle } from "react-icons/fc";
import { toast } from 'react-toastify'
import { closeScrollbar } from '../feature/modal/modalSlice'
import Icon from 'react-icons-kit';
import { basic_eye } from 'react-icons-kit/linea/basic_eye'
import { basic_eye_closed } from 'react-icons-kit/linea/basic_eye_closed'
import { arrows_exclamation } from 'react-icons-kit/linea/arrows_exclamation'
import { arrows_circle_check } from 'react-icons-kit/linea/arrows_circle_check'
import axios from 'axios'
import { Helmet } from 'react-helmet';

const Login = () => {
  const { user, myUser, isLoading, registerMsg, isActionSuccess, cartItems, localStoreCart } = useSelector(store => store.user);

  // validated states
  const [lowerValidated, setLowerValidated] = useState();
  const [upperValidated, setUpperValidated] = useState();
  const [numberValidated, setNumberValidated] = useState();
  const [specialValidated, setSpecialValidated] = useState();
  const [lengthValidated, setLengthValidated] = useState();
  const [showDialog, setShowDialog] = useState(false);

  const [showResendMessage, setShowResendMessage] = useState(false);

  // Reset the resend message state after 5 seconds
  useEffect(() => {
    let timer;
    if (showResendMessage) {
      timer = setTimeout(() => {
        setShowResendMessage(false);
      }, 9000);
    }
    return () => clearTimeout(timer);
  }, [showResendMessage]);



  const isMemberRef = useRef(true);
  const initialState = {
    name: '',
    email: '',
    password: '',
    isMember: isMemberRef.current,
  }
  const defaultValues = {
    ...initialState,
  };
  const [values, setValues] = useState(defaultValues);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);

  const location = useLocation();
  const { newCartItems, redeem, checkoutData, total, GLocations, deliveryCharge, deliveryLocation } = location?.state || {};
  const googleWindow = (e) => {
    e.preventDefault();
    window.location.href = ('https://myplantstore.me/auth/google')
    localStorage.setItem('googleLoginButton', 'True');
  };

  const handleResendVerificationEmail = () => {
    setShowDialog(false); // Activate the dialog
    setValues(defaultValues); // Reset the form values to default
  };

  const checkVerificationStatus = async (email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/checkVerificationByEmail?email=${email}`);
      const data = await response.json();

      if (response.status === 200) {
        // User is verified
        return true;
      } else {
        // Status code is not 200 (e.g., 404, 400, etc.)
        return false;
      }
    } catch (error) {
      console.error("Error checking verification status:", error);
      // Handle error if necessary
      throw error; // Re-throw the error to be caught separately
    }
  };



  const resendVerificationEmail = async (name, email) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/customer/resend-email`, { name, email });
      // Handle success response
      console.log(response.data.message); // Log success message
      // Optionally, you can show a success message to the user
    } catch (error) {
      // Handle error
      setShowResendMessage(true);
      console.error('Error resending verification email:', error);
      // Optionally, you can show an error message to the user
    }
  };



  const facebookWindow = (e) => {
    e.preventDefault();
    window.location.href = ('https://myplantstore.me/auth/facebook')
  };

  useEffect(() => {
    dispatch(verifyToken())
    dispatch(closeScrollbar());

  }, [])
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });

    // Perform password validation checks
    if (name === 'password' && !values.isMember) {
      const lower = new RegExp('(?=.*[a-z])');
      const upper = new RegExp('(?=.*[A-Z])');
      const number = new RegExp('(?=.*[0-9])');
      const special = new RegExp('(?=.*[!@#\$%\^&\*])');
      const length = new RegExp('(?=.{8,})');

      setLowerValidated(lower.test(value));
      setUpperValidated(upper.test(value));
      setNumberValidated(number.test(value));
      setSpecialValidated(special.test(value));
      setLengthValidated(length.test(value));
    }
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password } = values;

    if (!email || !password || (!values.isMember && !name)) {
      toast.warning('Please fill out all fields');
      return;
    }
    if (!values.isMember) {
      if (!lowerValidated) {
        setLowerValidated(false)
        toast.warning("Add one lowercase letter in password")
        setIsRegistrationSuccessful(true);
        return;
      }
      if (!upperValidated) {
        setUpperValidated(false);
        toast.warning("Add one uppercase letter in password")
        return;
      }
      if (!numberValidated) {
        setNumberValidated(false);
        toast.warning("Add one number in password")
        return;
      }
      if (!numberValidated) {
        setNumberValidated(false);
        toast.warning("Add one number in password")
        return;
      }
      if (!specialValidated) {
        setSpecialValidated(false);
        toast.warning("Add one special character in password")
        return;
      }
      if (!lengthValidated) {
        setLengthValidated(false);
        toast.warning("At least 8 characters in password")
        return;
      }
    }
    try {
      if (values.isMember) {
        await dispatch(loginUser({ email, password }));
        setValues((prevValues) => ({ ...prevValues, password: '' }));

      } else {
        const response = await dispatch(registerUser({ email, name, password }));


        checkVerificationStatus(email)
          .then((isVerified) => {
            if (isVerified) {
            } else {
              setShowDialog(true); // Open the dialog upon successful registration
              localStorage.setItem('UserPass', password);
              localStorage.setItem('UserEmail', email);
              setIsRegistrationSuccessful(true);
              toast.success(response.data.message); // Display the verification message

            }
          })
          .catch((error) => {
            console.error("Error checking verification status:", error);
            // Handle other types of errors here
          });


      }
    } catch (error) {
      setIsRegistrationSuccessful(false);

    }
  };

  useEffect(() => {
    if (isRegistrationSuccessful) {
      setValues((prevValues) => ({ ...prevValues, isMember: false }));
    }
  }, [isRegistrationSuccessful]);
  useEffect(() => {
    dispatch(getCart())
    localStorage.setItem('fromCheckoutLogin', 'False');
  }, [])
  //add to cart
  const formatAndDispatchCartData = async (cartData) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const id = userData.id || userData._id;

    try {
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/cart/login-cart`, {
        userId: id,
        products: Array.isArray(cartData) ? cartData : [cartData],
      });
      return resp.data; // Assuming you want to return some data from the response
    } catch (error) {
      return null; // Handle error or return an appropriate value
    }
  };



  useEffect(() => {
    if ((myUser && isRegistrationSuccessful) || isActionSuccess) {
      dispatch(setIsActionFalse())
      setIsRegistrationSuccessful(false)
      let user_id
      if (myUser) {
        const userData = JSON.parse(localStorage.getItem('user'));
        user_id = userData.id || userData._id
        formatAndDispatchCartData(localStoreCart);
        localStorage.removeItem('cart');

      }
      if (newCartItems && checkoutData && total && GLocations && deliveryCharge && deliveryLocation) {
        const updatedCartItems = newCartItems.map(item => ({
          ...item,
          userId: user_id,
        }));
        navigate(`/checkout/order-product`, {
          state: {
            newCartItems: updatedCartItems,
            redeem,
            checkoutData,
            total,
            deliveryCharge,
            GLocations,
            deliveryLocation
          }
        });
      } else {
        setTimeout(() => {
          // window.location.href=('https://myplantstore.me')
          navigate('/');
        }, 2000);
      }
    }
  }, [myUser, isRegistrationSuccessful, navigate, isActionSuccess, dispatch]);


  const toggleMember = () => {
    // Always toggle isMember, but navigate to login only if registration was successful
    setValues((prevValues) => ({ ...prevValues, isMember: !prevValues.isMember }));
    navigate('/user/login', { replace: true });
  };

  return (

    <Wrapper>

      <div>
        <img className="navlogo" src="{navlogo}" alt="" draggable="false" />
      </div>
      <div className="page">
        <div className="form small-form" >
          <h5>{values.isMember ? 'Sign in to continue' : 'Create your account'}</h5>
          {!values.isMember && <FormRow type="text" name="name" value={values.name} handleChange={handleChange} />}
          <FormRow type="email" name="email" value={values.email} handleChange={handleChange} />
          <FormRow type="password" name="password" value={values.password} handleChange={handleChange} />
          {!values.isMember && <main className='tracker-box'>
            <div className={lowerValidated ? 'validated' : lowerValidated === false ? 'not-validated' : 'validate-text'}>
              {lowerValidated ? (
                <span className='list-icon green'>
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className='list-icon'>
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least one lowercase letter
            </div>
            {/* <div className={upperValidated?'validated':'not-validated'}> */}
            <div className={upperValidated ? 'validated' : upperValidated === false ? 'not-validated' : 'validate-text'}>
              {upperValidated ? (
                <span className='list-icon green'>
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className='list-icon'>
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least one uppercase letter
            </div>
            <div className={numberValidated ? 'validated' : numberValidated === false ? 'not-validated' : 'validate-text'}>
              {numberValidated ? (
                <span className='list-icon green'>
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className='list-icon'>
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least one number
            </div>
            <div className={specialValidated ? 'validated' : specialValidated === false ? 'not-validated' : 'validate-text'}>
              {specialValidated ? (
                <span className='list-icon green'>
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className='list-icon'>
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least one special character
            </div>
            <div className={lengthValidated ? 'validated' : lengthValidated === false ? 'not-validated' : 'validate-text'}>
              {lengthValidated ? (
                <span className='list-icon green'>
                  <Icon icon={arrows_circle_check} />
                </span>
              ) : (
                <span className='list-icon'>
                  <Icon icon={arrows_exclamation} />
                </span>
              )}
              At least 8 characters
            </div>
          </main>}
          {(values.isMember && registerMsg === "") ? <Link to="/user/login/forgot_password" className='member-btn' >Forgot password</Link> : " "}
          {/* {(registerMsg) ? <p className='reg-msg' onClick="">{registerMsg}</p> : " "} */}
          <button type='submit' className="btn btn-block" disabled={isLoading} onClick={onSubmit}>{isLoading ? 'Loading...' : (values.isMember ? 'Sign in' : 'Sign up')}</button>
          <div className='auth-btn'>
            {/* <button type='submit' className="btn facebook" disabled={isLoading} onClick={(facebookWindow)}><img src={facebook} alt='logo' draggable="false"/>facebook</button> */}
            <button type='submit' className="btn google" disabled={isLoading} onClick={googleWindow}>< FcGoogle />google</button>
          </div>
          <div className="member-container">
            <p>{values.isMember ? 'Not a member yet?' : 'Already a member'}</p>
            <button type='button' className="member-btn final" onClick={toggleMember}>
              {values.isMember ? 'Register' : 'Login'}
            </button>
          </div>

        </div>

        {showDialog && (
          <div className="overlay">
            <dialog open className="dialog-box">

              <div className="content-container">

                <p className="verify-email-text">Verify Your Email</p>
                <p className="email-address-text">Hi {values.name}, please verify your email address by clicking the link sent to {values.email}.</p>
                <button
                  className="resend-button"
                  onClick={() => resendVerificationEmail(values.name, values.email)}
                  disabled={showResendMessage}
                >
                  {showResendMessage ? "Email sent!" : "Resend Verification Email"}
                </button>

                <button className="close-button" onClick={handleResendVerificationEmail}>Cancel</button>
                <p className="additional-text">Questions? Email us at reach@myplantstore.com</p>
              </div>
            </dialog>
          </div>
        )}





      </div>
    </Wrapper>
  )
}


const Wrapper = styled.section`
    margin-top:7rem;
   .page{
    width:100%
    position:fixed;
    min-height:70vh;
   }
  .navlogo{
       position: relative;
       left: 50%;
       right: 50%;
       margin-top:-7vw;
       margin-left: -50vw;
       min-width: 100vw;
   }
  .auth-btn{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin:0 auto;
    button{
    display:flex;
    font-size:17px;
    font-weight:600;
    gap:0.2rem;
    text-transform:uppercase;
    background:var(--primary-900);
    width: 320px;
    height: 40px;
    justify-content:center;
    align-items:center;
    }
   
    img{
      width:18px;
      background:white;
      border-radius:10px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Add padding */
  }

  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px; /* Adjust font size as needed */
    color: #000; /* Adjust color as needed */
    padding: 10px;
  }
  .member-container {
    display: flex;
    
  }
  
  .member-container p {
    margin-right: 2px; /* Adjust margin as needed */
  }
  
  .email-address-text span {
    font-weight: bold;
  }
  
  
  .verify-email-text {
    font-weight: bold;
    font-size: 34px;
    text-align: center;
  }
  
  .email-address-text {
    text-align: center;
  }
  
  .email-address-text,
  .additional-text {
    font-size: smaller;
    text-align: center;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
    z-index: 999; /* Ensure the overlay is on top of other content */
  }

  .dialog-box {
    border-top: 9px solid lightgreen; /* Thick border on the top side */
    border-right: none; /* Remove right border */
    border-bottom: none; /* Remove bottom border */
    border-left: none; /* Remove left border */
    height: 350px;
    top:33%;
    width:500px;
    border-radius: 15px;
  }
  
  .resend-button {
  display: block;
  margin: 0 auto; /* Center the buttons */
  cursor: pointer;
  background-color: var(--green-500); /* Your button color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px; /* Add padding */
  font-size: 16px; /* Adjust font size */
  margin-top: 20px; /* Add margin top */
}



.verify-email-text {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

.email-address-text {
  text-align: center;
}

.email-address-text,
.additional-text {
  font-size: smaller;
  text-align: center;
}
  

  
  
  
  .facebook:hover{
    background:var(--primary-700) !important;
  }
  .google:hover{
    background:red !important;
  }
  .reg-msg{
    color:red;
    background: transparent;
   border: transparent;
  cursor: pointer;
  letter-spacing: var(--letterSpacing);
  font-size:15px;
  text-align:left;
  }
  .icon-span{
    cursor: pointer;
  }
  
  .tracker-box{
    font-size: 12px;
    letter-spacing: 0.09em;
    border-radius: 5px;
    margin-bottom:1rem;
    border:1px solid var(--green-500);
    padding-left:0.25rem;
    // background:#befad3;
  }
  
  .tracker-box div{
    margin: 5px 0;
  }
  
  .tracker-box .validated{
    color: var(--green-300);
    font-weight:500;
  }
  .tracker-box .not-validated{
    color: red;
  }
  
  .list-icon{
    margin-right: 8px;
  }
  
  .list-icon.green{
    color: var(--green-300);
  }
  p{
    margin:0;
    padding:0;
  }
   .page{
    display:flex;
   align-items:center;
   gap:0rem;
   }
  .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 1.38rem;
  }
  .form {
    max-width: 400px;
   padding-top:0;
  }
   h1 {
    font-weight: 700;
    span {
      color: var(--primary-500);
    }
  }
  h5{
    margin-top:0.25rem;
    margin-bottom:0.5rem;
    font-weight:600;
    text-align: center;
  }
  h4 {
    text-align: center;
  }
  p {
    margin: 0;
    margin-top: 1rem;
    text-align: center;
  }
  .btn {
    margin-top: 1rem;
    background:var(--green-500);
  }
  .btn:hover{
    background:var(--green-300);
  }
  .btn-block{
    font-size:17px;
    font-weight:600;
    text-transform:uppercase;
    height:40px;
    margin-top:0;
    background:var(--green-300);
  }
  .member-btn {
    background: transparent;
    border: transparent;
    color: var(--primary-500);
    cursor: pointer;
    letter-spacing: var(--letterSpacing);
  }
  .final{
    margin-top:0.85rem;
  }
   @media (min-width: 992px) {
       img {
      display: block;
    }
   
  }
   @media (max-width: 992px) {
    margin-top:0rem;
   
  }
  @media (max-width: 1300px) {
    .page{
      margin-top:2rem;
    }
    
  }

  @media(max-width:500px){

    .dialog-box{

      height: 380px;
    top:25%;
    width:300px;

    }
    .verify-email-text {
      font-weight: bold;
      font-size:22px;
      text-align: center;
    }
    .resend-button{
      font-size: 14px;
    }
    .content-container {
      font-size: 14px; /* Adjust the font size as needed */
    }
    
    .additional-text {
      font-size: 12px; /* Adjust the font size as needed */
    }
    .member-container{
      font-size: 14px;
    }
  }
  @media(max-width:310px){

    
    .btn{
      img,svg{
        display:none;
      }
      

    }
    .facebook,.google{
      font-size:14px !important;
      padding:0rem;
      max-width:7rem !important;
    }
    .auth-btn{
      width:100%;
    display:flex;
      width:100px;
    }

   
  }
  

  
`

export default Login