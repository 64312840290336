// LocationPickerMap.js
import React, { useState, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setUserGLocaton } from '../../feature/user/userSlice';
import { useEffect } from 'react';


//google map in checkout page
const GLocation = ({ onLocationSelect }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPlaceName, setSelectedPlaceName] = useState('');
  const autocompleteRef = useRef(null);
  const { userGLocaton } = useSelector((store) => store.user);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (typeof window.google !== 'undefined' && !isMapLoaded) {
      setIsMapLoaded(true);
    }
  }, [isMapLoaded]);

  const mapStyles = {
    height: windowWidth < 580 ? '200px' : '400px',
    width: windowWidth < 580 ? '100%' : '100%',
  };

  const defaultCenter = {
    lat: 25.2048, // Default latitude for UAE
    lng: 55.2708, // Default longitude for UAE
  };

  const onMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setSelectedLocation({ lat, lng });
    onLocationSelect({ lat, lng });
    updatePlaceName(lat, lng);
  };

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    const location = place.geometry.location;

    setSelectedLocation({ lat: location.lat(), lng: location.lng() });
    onLocationSelect({ lat: location.lat(), lng: location.lng() });
    updatePlaceName(location.lat(), location.lng());
  };

  const updatePlaceName = (lat, lng) => {
    // Using Geocoding API to get place name
    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY} `;

    fetch(geocodingApiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'OK') {
          const placeName = data.results[0].formatted_address;
          setSelectedPlaceName(placeName);
        } else {
          // console.error('Error getting place name:', data.status);
        }
      })
      .catch((error) => {
        // console.error('Error fetching geocoding data:', error);
      });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserGLocaton(selectedPlaceName));
  }, [selectedPlaceName]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        setSelectedLocation({ lat, lng });
        onLocationSelect({ lat, lng });

        // Use the browser's geolocation API to get the address information
        const geocoder = new window.google.maps.Geocoder();
        const latLng = new window.google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            const placeName = results[0].formatted_address;
            setSelectedPlaceName(placeName);
          } else {
            // console.error('Error getting place name from geolocation:', status);
          }
        });
      });
    } else {
      // console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <Wrapper>
      <p style={{color:'#303B8E'}}>Add location where you want to receive the product:</p>
      <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)} onPlaceChanged={onPlaceChanged}>
        <input type="text" placeholder="Search location" className='location-input-place' style={{ width: '100%', height: '40px' }} />
      </Autocomplete>
      <button onClick={getCurrentLocation} className='btn-location'>Get Current Location</button>
      {isMapLoaded && (
        <GoogleMap mapContainerStyle={mapStyles} zoom={10} center={selectedLocation || defaultCenter} onClick={onMapClick}>
          {selectedLocation && <Marker position={selectedLocation} />}
        </GoogleMap>
      )}
      
      <p className="place">
        <span>Selected place:</span> {selectedPlaceName}
      </p>
      {/* <button className='btn btn-proceed'>Proceed</button> */}
    </Wrapper>
  );
};

export default GLocation;


const Wrapper = styled.div`
margin-top:1rem;
  p{
    padding:0;
    margin:0;
  }
  input{
    margin-top:0.5rem;
    margin-bottom:0.5rem;
  }
  span{
    font-weight:600;
  }
  .place{
    background:var(--grey-10);
    
  }
  .btn-location {
    border-radius: 5px;
    border: none;
    background: var(--green-300); /* Change background color to green */
    color: #fff; /* Change text color to white */
    padding: 0.5rem 1rem; /* Add padding for better spacing */
    margin: 0.5rem 0;
    cursor: pointer; /* Add pointer cursor to indicate interactivity */
    font-size: 1rem; /* Adjust font size */
    font-weight: bold; /* Make the text bold */
    transition: background 0.3s ease-in-out; /* Smooth transition for background color */
    width: 100%; /* Set button width to 100% */
  }
  
  
  .btn-location:hover {
    background: var(--green-500); /* Darker green on hover */
  }
  
  .btn-proceed{
    background:var(--green-300);
    color:#fff;
    font-weight:600;
    text-align:center;
    padding:0.5rem 1.25rem;
    border-radius:5px;
    display:flex;
    justify-content:center;
    align-items:center;
    max-width:100px;
    margin-left:auto;
    margin-top:1rem;
  }
  .location-input-place {
    font-family: inherit;
    height: 44px;
    padding: 5px;
    border: 1px solid var(--grey-100);
    border-radius: 5px;
    color: var(--grey-500);
    font-size: 16px;
    font-family:'Poppins';
  }
  @media(max-width: 580px) {
    p{
      padding:0;
      margin:0 !important;
      font-size:13px;
    }
  }
`

