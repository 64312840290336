import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getOrderedData, getRedeemedData, getUser, getUserAddress, verifyToken } from '../feature/user/userSlice';
import coin from '../assets/images/coin.png';
import { Loading } from './index';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useStepContext } from '@mui/material';
import { VscClose } from 'react-icons/vsc';
import StripeCheckoutUserForm from './support/StripCheckoutUserForm';
import useHashScroll from './support/useHashScroll';
import { HiGift } from 'react-icons/hi2';
import { toast } from 'react-toastify';
import axios from 'axios';
import OrderStepper from './support/OrderStepper';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

const OrdersAndRefundForms = () => {
  useHashScroll();
  const { orderedData, orderedDataLoading, user, myUser, coins, redeemedCoupon, totalCoins } = useSelector((store) => store.user);
  const { checkoutData: addressData } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buttonRefs = useRef([]);
  const btnsHideRefs = useRef([]);
  const [showStates, setShowStates] = useState([]);
  const [openedIndex, setOpenedIndex] = useState(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [fromRefund, setFromRefund] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [submited, isSubmited] = useState(false);
  const [minimizedItems, setMinimizedItems] = useState(new Array(orderedData?.length || 0).fill(true));
  const [isContentMinimized, setIsContentMinimized] = useState(false);


  const [checkoutData, setCheckoutData] = useState({
    _id:'',
    name: '',
    paymentId: '',
    country: 'uae',
    state: '',
    city: '',
    houseNumber: '',
    landmark: '',
    phone: '',
    phone2: '',
    note: '',
    userId:user?.id
  });
  
  const handleChecked = ()=>{
    setIsChecked(!isChecked)
   }


   const isOverlayfalse = () => {
    setIsOverlayOpen(false);
    navigate('/terms-and-conditions');
    document.body.style.overflowY = 'auto';
  };

  
  const updateCheckoutData = (field, value) => {
    setCheckoutData({ ...checkoutData, [field]: value });
  };

  const toggleContent = () => {
    setIsContentMinimized(!isContentMinimized);
  };

  const updateMinimizedItems = (index) => {
    const newMinimizedItems = [...minimizedItems];
    newMinimizedItems[index] = !newMinimizedItems[index];
    setMinimizedItems(newMinimizedItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(verifyToken());
      await dispatch(getOrderedData());
      await dispatch(getRedeemedData());
      await dispatch(getUserAddress());
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (orderedData && orderedData.length > 0) {
      setShowStates(new Array(orderedData.length).fill(false));
    }
  }, [orderedData]);

  // Function to close the currently opened btns hide
  const closeOpenedBtns = () => {
    if (openedIndex !== null) {
      const newShowStates = [...showStates];
      newShowStates[openedIndex] = false;
      setShowStates(newShowStates);
    }
  };
  const toggleOverlay = (prop,_id,orderId) => {
    setCheckoutData({
      _id:'',
      paymentId: '',
      name: addressData?.name,
      country: 'uae',
      state: addressData?.state,
      city: addressData?.city,
      houseNumber: addressData?. houseNumber,
      landmark: addressData?.landmark,
      phone: addressData?.phone,
      phone2: addressData?.phone2,
      note: '',
      userId:user?.id || user?._id
    })
    setFromRefund(prop?.amount ? true : false)
    const updateId = (newId,orderid) => {
      setCheckoutData((prevData) => ({
        ...prevData,
        _id: newId,
        orderId:orderid
      }));
    };
    updateId(_id,orderId)
    setIsOverlayOpen(!isOverlayOpen);
    window.scrollTo(0, 0);
    // document.body.style.overflowY = isOverlayOpen ? 'auto' : 'hidden';
  };

 
 


  const handleRefundSubmit = async () => {

    if (myUser) {
      try {
        const {
          _id,
          orderId,
          name,
          country,
          state,
          city,
          landmark,
          houseNumber,
          phone,
          phone2,
          note,
          paymentId,
        } = checkoutData;
        if (
          _id &&
          orderId &&
          name &&
          country &&
          state &&
          city &&
          landmark &&
          houseNumber &&
          phone &&
          (!fromRefund || (fromRefund && paymentId))
        ) {
          if (isChecked) {
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/refund-form`, checkoutData);
            isSubmited(true)
            
            toast.success('Refund Requested...');
          } else {
            toast.error('Check terms & conditions');
          }
        } else {
          toast.error('Fill all the fields...');
        }
      } catch (error) {
        toast.error('Oops something went wrong...')
      }
    }
  };
  
  // Add event listener to close the opened btns hide when clicking outside
  useEffect(() => {
    const closeBtnsOnOutsideClick = (e) => {
      if (openedIndex !== null && !btnsHideRefs.current[openedIndex].contains(e.target)) {
        closeOpenedBtns();
      }
    };

    document.addEventListener('mousedown', closeBtnsOnOutsideClick);

    return () => {
      document.removeEventListener('mousedown', closeBtnsOnOutsideClick);
    };
  }, [openedIndex]);

  useEffect(() => {
    document.body.style.overflowY = isOverlayOpen ? 'hidden' : 'auto';

    return () => {
      // Cleanup function to reset the overflow when the component unmounts or when the overlay is closed
      document.body.style.overflowY = 'auto';
    };
  }, [isOverlayOpen]);

  const handleShow = (index) => {
    // Close the currently opened btns hide if a new one is clicked
    if (openedIndex !== null && openedIndex !== index) {
      closeOpenedBtns();
    }

    // Toggle the state for the specific item
    const newShowStates = [...showStates];
    newShowStates[index] = !newShowStates[index];
    setShowStates(newShowStates);

    // Update the currently opened index
    setOpenedIndex(newShowStates[index] ? index : null);
  };


  const count=1
  useEffect(() => {
    if (orderedData && orderedData.length > 0) {
      const newMinimizedItems = new Array(orderedData.length).fill(true);
      for (let i = 0; i < Math.min(count, orderedData.length); i++) {
        newMinimizedItems[i] = false;
      }
      setMinimizedItems(newMinimizedItems);
    }
  }, [orderedData]);

  if (orderedDataLoading) {
    return (
      <OrderedPlant>
        <Loading />
      </OrderedPlant>
    );
  }

  return (
    <OrderedPlant className='overlay-container'>

    {/* Check if overlay is open, if yes, render the overlay */}
    {isOverlayOpen && (
      <>
      <div className="overlay-background" onClick={toggleOverlay} />
      <div className="overlay" >
        <div className='header'>
          <h5>Fill the form</h5>
        <button type="button" onClick={toggleOverlay} className="close-button">
          <VscClose />
        </button>
        </div>
        <div className='form-div'>
        <StripeCheckoutUserForm checkoutData={checkoutData} updateCheckoutData={updateCheckoutData} fromRefund={fromRefund}/>
        <label><input type="checkbox" checked={isChecked} onChange={handleChecked}/> Read and agreed terms & conditions </label><br/>
        <div className='req-btn-container'><button className='request-btn' onClick={handleRefundSubmit}>Submit</button></div>
        
        </div>
        <div className='terms-btns'>
          <button  onClick={isOverlayfalse} className='link-terms'> terms and conditions</button>
        </div>
      </div>
    </>
    )}

      <div className='orders' style={{ display: !orderedData || orderedData.length === 0 ? 'none' : 'flex' }} id='orders'>
        {orderedData &&
          orderedData.map((data, idx) => {
            const { Orderstatus, paymentData, updatedCartItems, createdAt, total, _id, orderId, refundStatus } = data;
            const inputDate = new Date(createdAt);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            const formattedDate = inputDate.toLocaleDateString('en-US', options);

            const coinsDataForOrder = coins?.find((coin) => coin.orderId === data.orderId);
            // Calculate count based on coins data
            const count = coinsDataForOrder ? coinsDataForOrder.coins : 0;
            const pending = coinsDataForOrder ? coinsDataForOrder.valid : false;

            const isMinimized = minimizedItems[idx];

            return (
              <div key={idx} className='ordered-item'>
                <div className='order-header'>
                  <div className='order-header-child'>
                    <p>Order Id : </p>
                    <span>{orderId}</span>
                  </div>
                  <div className='order-header-parent'>
                    <div className='order-header-child'>
                      <p>Date of order : </p>
                      <span>{formattedDate}</span>
                    </div>
                    <div className='order-header-child'>
                      <button type='button' onClick={() => updateMinimizedItems(idx)}>
                        {isMinimized ? <HiChevronDown /> : <HiChevronUp />}
                      </button>
                    </div>
                    <div className='order-header-child'>
                      <button type='button' onClick={() => handleShow(idx)} ref={(el) => (buttonRefs.current[idx] = el)}>
                        <HiDotsHorizontal />
                      </button>
                    </div>
                  </div>
                </div>

                <div className='status'>
                  <div className='options'>
                    <div className={showStates[idx] ? 'btns' : 'btns hide'} ref={(el) => (btnsHideRefs.current[idx] = el)}>
                      <button type='button' onClick={() => toggleOverlay(paymentData, _id, orderId)}>request return</button>
                    </div>
                  </div>
                </div>

                {!isMinimized && (
                  <>
                    {updatedCartItems?.map((item, itemIdx) => (
                      <div key={itemIdx} className='image-item'>
                        <img src={item.image} alt='ordered Item' draggable='false' />
                        <div>
                          <p>{item.title}</p>
                          <p>
                            Qty : <span>{item.amount}</span>
                          </p>
                          <p>{item.dimension.Price} AED</p>
                          <div className='inline-gift-wrap'>
                            <p>
                              {Object.keys(item.dimension)
                                .filter((key) => key.startsWith('Value'))
                                .map((key) => item.dimension[key])
                                .join(' / ')}
                            </p>
                            {item.GiftWrap && <p><HiGift /></p>}
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className='order-status-coin-div'>
                      {count && count > 0 ? (
                        <div className='coins order-header-coins'>
                          <div className='coins order-header-coins-row'>
                            <div>
                              <p>You Earned </p>
                              <span>
                                {count} <img src={coin} alt='coin' className='' draggable='false' />
                                coins
                              </span>
                            </div>
                            <div>
                              <p>Status : </p>
                              <span>{!pending ? 'Pending*' : 'Remaining*'}</span>
                            </div>
                          </div>
                          <div className='coins-terms'>{!pending ? '*Coins credited after 10 days from order.' : '*You can redeem the remaining coins.'}</div>
                        </div>
                      ) : null}
                      <div className='order-status-div'>
                        <div>
                          <p className='order-status-item'>{refundStatus !== ' ' ? `Refund Status : ` : `Order Status : `}</p>
                          <p className='form-status'>{refundStatus !== ' ' ? `${refundStatus}` : `${Orderstatus}`}</p>
                        </div>
                        <div>
                          <p className='order-status-item'>Total : </p>
                          <p className='order-status-p'>{total} AED</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
      </div>

      <div className='rewards'>
      <div className='rewards-header'>
      <p>Your earnings</p>
      <h4>{totalCoins}<span>coins</span><img src={coin} alt="coin" className='rotate-img' draggable="false"/></h4>
      </div>
      <section className='offer-card'>
      <div className='redeem-1 '>
        <div className='main-redeem green'>
        <p>Entire Order</p>
        <div className='heading'><h3>5%</h3><h4>off</h4></div>
        </div>
        <footer>
          <button>Redeem</button>
          <p>250<span> coins</span></p>
        </footer>
      </div>
      <div className='redeem-1'>
        <div className='main-redeem green-1'>
        <p>Entire Order</p>
        <div className='heading'><h3>10%</h3><h4>off</h4></div>
        </div>
        <footer>
          <button>Redeem</button>
          <p>500<span> coins</span></p>
        </footer>
      </div>
      </section>
    </div>
      {/* Your existing code */}
    </OrderedPlant>
  );
};

export default OrdersAndRefundForms;


const Wrapper = styled.section`
    min-height: calc(55vh - 10px); 
    padding: 20px;
    max-width:95vw;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin:0 auto;
    
    h4{
       
        text-align:center;
    }
`

const OrderedPlant = styled.section`
min-height: calc(50vh - 10px); 
max-width:95%;
margin:0 auto;
display:flex;
flex-direction:row;
gap:2rem;
margin-bottom:3rem;
margin-top:2rem;
flex-wrap:wrap;
.orders{
  display:flex;
flex-direction:column;
gap:1rem;
flex: 1;
margin-right: auto;


}
.link-terms{
  color:#0000EE;
}
.rewards{
  border:2px solid var(--grey-50);
  border-radius:10px;
  height:fit-content;
  margin-right: auto;
  letter-spacing:1px;
  img{
    width:20px;
    height:20px;
    margin-left:0.75rem;
  }
  .offer-card{
    display:flex;
    flex-direction:row;
    justify-content:center;
    gap:3rem;
    margin:1rem;
  }
}
.options{
  margin-left:auto;
  margin-bottom:0;
  cursor:pointer;
}
.options:hover{
  color:var(--green-300);
}
button{
  background:transparent;
  border:none;
}
.req-btn-container{
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}
.request-btn{
  background:#228f47;
  color:#fff;
  padding:0.5rem 1.5rem;
  margin-top:1rem;
  border-radius:5px;
}
.inline-gift-wrap{
  display:flex;
  flex-direction:row;
  gap:1rem;
  svg{
    color:var(--green-300);
  }
}
.btns{
  position:absolute;
  display:block;
  right:0%;
  top:15%;
  padding:1rem 0rem;
  justify-content:space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius:10px;
  z-index:99;
  background:#fff;
}
.overlay-container{
  position:relative;
}
.hide{
  display:none;
}
.rewards-header{
height:100px;
padding:0.5rem 1rem;
background:var(--grey-10);
}
.rotate-img {
  animation: rotateHorizontal 4s linear infinite;
  transform-origin: center;
}
.redeem-1{
  width:250px;

}
.green{
  // background: rgb(2,0,36);
  // background: linear-gradient(97deg, rgba(2,0,36,1) 0%, rgba(135,222,174,1) 0%, rgba(207,146,143,1) 100%, rgba(0,212,255,1) 100%);
  background: rgb(2,0,36);
  background: linear-gradient(263deg, rgba(2,0,36,1) 0%, rgba(135,222,174,1) 0%, rgba(5,165,148,1) 100%, rgba(0,212,255,1) 100%);
}
.green-1{
  background: rgb(2,0,36);
background: linear-gradient(263deg, rgba(2,0,36,1) 0%, rgba(135,222,174,1) 0%, rgba(5,165,148,1) 100%, rgba(0,212,255,1) 100%);
}
.main-redeem{
color:white;
padding:1.25rem 1.5rem;
border-radius:10px 10px 0px 0;
h3{
  font-weight:bold !important;
}
}
footer{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius:0px 0px 10px 10px;
  padding:.75rem 1.5rem;
}
footer..no-top-shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0), 0 -5px 10px rgba(0, 0, 0, 0); /* Remove the box shadow from the top */
}
.heading{
  display:flex;
  flex-direction:row;
  h4{
    margin:0.2rem 0.3rem;
  }
}
@keyframes rotateHorizontal {
  0%, 100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}

.overlay {
  position: absolute;
  background: white;
  height: 93%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 10px;
  padding: 0rem 1rem 1rem 1rem;
}


.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
 
  height: 100%;
  background: rgba(169, 169, 169, 0.5); 
  z-index: 998; 
}
input,label{
  cursor:pointer;
}
.close-button {
  position: absolute;
  font-size:1.25rem;
  font-weight:bold;
  color:var(--green-300);
  top: 10px;
  right: 10px;
}
.form-div{
  margin-top:2rem;
}
.header{
  height:50px;
  margin-bottom:auto;
  padding:0;
  margin:0;
  padding-top:3rem;
  padding-bottom:2rem;
  
  h5{
    padding:0;
    margin:0;
    font-weight:500;
  }
}
.terms-btns{
  margin-top:auto;
}
p{
  margin:0;
  padding:0;
}
 img{
  width:70px;
  height:100px;
 }
 .order-status-div{
   display:flex;
   flex-direction:column;
   justify-content:space-between;
   align-items:flex-end;
   padding:0 1rem;
   padding-bottom:0.5rem;
   margin-left:auto;
   p{
    font-size:13px !important;
    font-weight:bold !important;
   }
   .order-status-item{
    color:var(--grey-400)!important;
   }
   .order-status-p{
    color:var(--grey-600);
   }
   div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.25rem;
    width: 250px;


    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;

      button {
        margin-left: 2rem;
        background: #fff;
        border-radius: 5px;
        height: 15px;
      }
    }

    &:last-child {
      padding-top: 0.25rem;
    }
    &:first-child {
      padding-bottom: 0.5rem;
    }
  }
   .form-status{
    background:var(--green-300);
    padding: 0 0.25rem ;
    border-radius:5px;
    color:#fff;
   }
 }
 .conditions-coins {
  text-align: end;
  margin-right: 0.5rem;
  font-style: italic;
  font-size: smaller;
}
.order-status-coin-div{
  display:flex;
  flex-direction:row;
  
}
.order-status-coin-div > div {
  flex: 1;
}

.order-status-coin-div > div:nth-child(2) {
  max-width: 285px; 
}

 .order-header{
  background:var(--grey-10);
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  padding:0.75rem 1rem;
  gap:3rem;
  .order-header-child{
    display:flex;
    flex-direction:row;
    gap:0.25rem;

    button{
      margin-left:2rem;
      background:#fff;
      border-radius:5px;
      height:15px;
    }
    button:hover{
      background:var(--green-300);
      color:#fff;
      transition:all 0.3s;
    }
  }
.order-header-parent{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
}
  p{
    font-size:13px !important;
    font-weight:bold !important;
    color:var(--grey-400)!important;
  }
  span{
    color:var(--grey-600);
    font-size:13px !important;
    font-weight:bold !important;
  }
  img{
    width:15px;
    height:15px;
  }
 }
 .coins-terms {
  font-size: 12px;
  font-style: italic; 
}

 .order-header-coins{
  background:var(--grey-10);
  display:flex;
  justify-content:space-between;
  flex-direction:column;
  padding:0.1rem 1rem ;
  background:var(--grey-10);
  .order-header-coins-row{
    display:flex;
    gap:0.25rem;
    flex-direction:row;
    justify-content:space-between;
    div{
      display:flex;
      gap:0.25rem;
      flex-direction:row;
      justify-content:space-between;
    
    }
  }

  p{
    font-size:13px !important;
    font-weight:bold !important;
    color:var(--grey-400)!important;
  }
  span{
    color:var(--grey-600);
    font-size:13px !important;
    font-weight:bold !important;
  }
}
.ordered-item{
  border:2px solid var(--grey-50);
  border-radius:10px;
  height:fit-content;
  position:relative;

}
.status{
  padding:0rem 1rem;
  padding-top:0.5rem;
  color:var(--grey-600);
  display:flex;
  flex-direction:row;
  
  p{
    background:var(--grey-50);
    display: flex;
    justify-content:center;
    align-items:center;
    padding:0rem 0.5rem;
    border-radius:10px;
    font-weight:600;
    font-size:14px;
  }
}
.image-item{
  display:flex;
  padding:0.75rem 1rem;
  gap:1.5rem;
  p{
    font-size:14px;
  }
}
.coins{
  img{
    width:15px;
    height:15px;
  }
  span{
    display:flex;
    align-items:center;
    gap:0.5rem;
  }
}
@media (max-width: 1140px) {
  max-width: 60vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .orders,
  .rewards {
    width: 100%; /* Set full width for both sections on smaller screens */
    margin-right: 0; /* Remove margin on smaller screens */
  }
}
@media(max-width:1080px){
.overlay{

  min-width:65vw  !important;
}
}
@media (max-width: 868px) {
  max-width: 80vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .overlay{
    width:70%;
  }
  .orders,
  .rewards {
    width: 100%; /* Set full width for both sections on smaller screens */
    margin-right: 0; /* Remove margin on smaller screens */
  }
}
@media(max-width:920px){
  .offer-card{
    flex-direction:column !important;
    justify-content:center;import OrderStepper from './support/OrderStepper';

    align-items:center;
  }
}
@media(max-width:668px){
  .overlay{
    width:90%;
  }
}
@media(max-width:698px){
  .order-status-coin-div {
    flex-direction: column;
    gap:0.5rem;
  }
  
  .order-status-coin-div > div:nth-child(2) {
    width:100%;
    max-width:700px;
  }
  .order-status-div {
    div{
      width:100%;
    }
  }
}


@media (max-width: 598px) {
  max-width:95%;
  .form-div{
    margin-top:0.25rem;
  }
  .order-header{
    padding:.25rem;
  }
  .status{
    p{
      padding:0.1rem .25rem;
      height:fit-content;
    }
  }

  .orders,
  .rewards {
    align-self: flex-start;
  }
   label{
    font-size:14px;
  }
}
@media(max-width:480px){
  p{
    font-size:12px;
  }
  .order-header{
    .order-header-child{
      flex-direction:column;
      gap:0;
    }
    p{
      font-size:12px !important;
    }
  }
.order-header-coins{
  padding:0.1rem .1rem;
  p{
    font-size:12px !important;
  }
}
.order-status-div{
  p{
    font-size:12px !important;
  }
}
}
`