import React, { useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { closeBanner, openSearchTopSearchbar } from '../../feature/modal/modalSlice';
import { handleChange,openSearchResults  } from '../../feature/plants/plantsSlice';
import { useNavigate } from 'react-router-dom';


//searchbar in the top
const SearchBar = () => {
  const navigate = useNavigate()
  const {isOpenTopSearchbar} = useSelector((store)=>store.modal);
  const {categoryList,search,mainCategory} = useSelector((store)=>store.plants)
  const dispatch = useDispatch();
  const options = [
    'one', 'two', 'three'
  ];
  const [inputValue, setInputValue] = useState('');

  const handleSearch = (e) => {
    setInputValue(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Get the form and its elements
    const form = e.currentTarget;
    const searchInput = form.elements.search;
    const mainCategorySelect = form.elements.mainCategory;
    const name = e.target.name;
    const value = e.target.value;
    // Check if the search input has a value, if not set it to null
   if(inputValue){
    dispatch(handleChange({ name:'search', value: inputValue }));
   navigate(`/products/myplantstore/search/${inputValue}`)
   }
  }
  

 const [isOpen, setIsOpen] = useState(false);
  const handleSearchIconClick = () => {
    dispatch(openSearchTopSearchbar());
    dispatch(closeBanner())
    
  };
  return (
    <Wrapper>
    <form onSubmit={(e) => handleSubmit(e)}>
        <div className="form-control">
          <input type="text" placeholder="Search here" className="search-input" name="search"  value={inputValue}  onChange={handleSearch}/>
          <button type="submit" className='button-search' ><CiSearch/></button>
        </div>
      </form>
      {/* <div className='button-hide'>
      <button onClick={()=>handleSearchIconClick()}><CiSearch/></button>
      </div> */}
    </Wrapper>
  )
}
const Wrapper = styled.section`
cursor:pointer;
max-width:50rem;
border:1.5px solid var(--grey-100);
border-radius:10px;
padding-right:0.5rem;

.search-bar {
  position: fixed;
  top: 0;
  left: -10%;
  float: left !important;
  width: 100% !important;
  transition: height 0.3s ease-in-out;
  // display:none;
  z-index:999;
}

.search-bar.open {
  height: 70px;
  display:flex;
}
input[type="text"] {
  font-family:  "Poppins";
}

.form-control{
  display: flex;
  justify-content:space-between;
  padding-left:1rem;
  gap:2rem;
  height:3rem;
  border-radius:10px;
}

input{
border:0;
}
.search-input{
  font-size:15px;
}
textarea:focus, input:focus,search-input,select:focus{
  outline: none;
  
  }
.opt{
  border:0;
  cursor:pointer;
  background:var(--grey-50);
  border-radius:10px 0px 0px 10px; 
  max-width:13rem;
}
select.opt{
  // background: var(--green-500);
  // color: #fff;
  padding-left:10px;
  font-size: 1rem;
  
}
select.opt option{
  // background: var(--green-500);
  // color: #000;
  padding: 0 10px;
  cursor:pointer;
}
select.opt option:hover{
  background: var(--green-500);
}
.display{
  width:100%;
  background:red;
  
}
.button-hide{
  display:none;
}
.button-search{
  cursor:pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  width:3rem;
  font-size:2.25rem;
  border:none;
  color:var(--grey-500);
  background:transparent;
}
.button-search:hover{
  color:var(--grey-800);
}
  .colors {
    display: grid;
    grid-template-columns: 125px 1fr;
    align-items: center;
    margin-bottom: 1rem;
    span {
      text-transform: capitalize;
      font-weight: 700;
    }
    div {
      display: flex;
    }
  }
  .color-btn {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.75rem;
      color: var(--clr-white);
    }
  }
  .active {
    opacity: 1;
  }
  .btn-container {
    margin-top: 2rem;
  }
 
  .btn {
    margin-top: 1rem;
    width: 140px;
  }
  @media (min-width: 1240px){
    width:55rem;
  }
  @media (max-width: 998px) {
     border:none;
    .form-control{
      display:none;
    }
    .button-hide{
      display:flex;
      justify-content:right;
      align-items:center;

      margin-right:1rem;
    }
    .button-hide button{
      background:transparent;
      border:none;
      font-size:3rem;
    }
    .button-hide button:hover{
      font-weight:bold;
      color:var(--green-300);
      
    }
    @media (max-width: 868px) {
      .form-control{
        gap:0rem;

      }
    }

    ::placeholder {
      opacity:0;
    }
    
    .opt{
      display:none;
    }
    .button-search{
      background:white;
      color:black;
      font-size:3rem;
    }
  }
  @media (max-width: 480px) {
    .button-hide button{
      margin-top:3.5rem;
      font-size:2rem;
      position:absolute;
      right:20%;
      color:white;
    }
    .button-hide button:hover{
      color:var(--grey-700);
    }
  }
`

export default SearchBar



// .select-box {
//   display: inline-block;
//   position: relative;
// }

// .select-box select {
//   display: inline-block;
//   padding-right: 25px; /* Space for the arrow */
// }

// .select-box::after {
//   content: '▼'; /* Arrow symbol */
//   position: absolute;
//   right: 10px;
//   top: 50%;
//   transform: translateY(-50%);
// }
