import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link, useNavigate } from 'react-router-dom';
import { openCart } from '../feature/modal/modalSlice';
import { addToCart, getCart } from '../feature/user/userSlice';
import { getFlashSaleProducts } from '../feature/plants/plantsSlice';
import CountDownTimer from './support/CountDownTimer';
import LazyImage from './support/LazyImage';

const FlashSaleHome = () => {
  const { plants, flashSalesProducts } = useSelector((store) => store.plants);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    dispatch(getFlashSaleProducts());

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [showButtons, setShowButtons] = useState(false);

  const handleMouseEnter = () => {
    setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setShowButtons(false);
  };

  const addCartItem = (product, dimension, propPrice, propNewPrice) => {
    const { ProductName, maincategory, subcategory, category, price, photos, _id, ProductId } = product;
    dispatch(openCart('cart'));
    dispatch(addToCart({
      title: ProductName,
      productId: ProductId,
      maincategory,
      subcategory,
      category,
      totalPrice: propPrice,
      price,
      dimension,
      productQuantity: 1,
      image: photos[0].url,
      flashSalePrice: propNewPrice,
      flashSaleStartDate: product.StartDate,
      flashSaleStartTime: product.StartTime,
      flashSaleEndTime: product.TimeInHours,
      flashSaleDiscount: product.OfferPercentage,
    }));
    dispatch(getCart());
  };

  const responsive = {
    0: { items: 1 },
    600: { items: 1 },
    900: { items: 2 },
    1200: { items: 3 },
    1720: { items: 4 },
  };

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <AliceCarousel 
        mouseTracking
        autoPlay
        infinite
        autoPlayInterval={3000}
        animationDuration={1000}
        disableButtonsControls={!showButtons}
        items={flashSalesProducts.map((product, idx) => {
          const { dimensions } = product;
          const sortedDimensions = dimensions ? dimensions.slice().sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price)) : [];

          const oldPrice = Number(sortedDimensions[0]?.Price);
          const offerPercentage = product ? product.OfferPercentage : 0;
          const newPrice = oldPrice - (offerPercentage / 100) * oldPrice;

          return (
            <div key={idx} className="items">
              <Link to={`products/${product.ProductId}`} draggable="false">
                <LazyImage src={product.photos[0].url} alt="offer" draggable="false" />
              </Link>
              <div className='items-details'>
                <p className='percentages'>{product.OfferPercentage}<span>%</span><span className='space'>off</span> </p>
                <CountDownTimer startDate={product.StartDate} startTime={product.StartTime} timeInHours={product.TimeInHours} />
                <Link to={`products/${product.ProductId}`}>
                  <div className='name'>
                    <h6>{product.ProductName && screenWidth < 480 ? (product.ProductName.substring(0, 35) + '..') : product.ProductName}</h6>
                    <p>{sortedDimensions[0] && Object.keys(sortedDimensions[0])
                      .filter(key => key.startsWith("Value"))
                      .map(key => sortedDimensions[0][key])
                      .filter(value => value !== undefined && value !== null && value !== '')
                      .join(" / ")}</p>
                  </div>
                </Link>
                <div className='price'>
                  <h5>AED {Number.isInteger(newPrice) ? newPrice : newPrice.toFixed(2)}</h5>
                  <h6 className='oldprice'>AED {oldPrice}</h6>
                </div>
                <button className='cart-btn btn' onClick={() => addCartItem(product, sortedDimensions[0], oldPrice, newPrice)}>Add to Cart</button>
              </div>
            </div>
          );
        })}
        responsive={responsive}
      />
    </Wrapper>
  );
};

export default FlashSaleHome;

const Wrapper = styled.section`
  width: 95%;
  margin: 0 auto;
  padding-top: 1rem;
  position: relative;
  z-index: 1;

  .items {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    max-width: 300px;

    img {
      width: 205px;
      height: 260px;
    }
  }

  .react-multiple-carousel__arrow { 
    background:transparent; 
    color:red;
  }
  .react-multiple-carousel__arrow--left::before{
    color:var(--green-300);
    font-weight:bold;

  }
  .react-multiple-carousel__arrow--right::before{
    color:var(--green-300);
    font-weight:bold;
    
  }
  .gd-carousel {
    position:unset;

    .react-multiple-carousel__arrow {
        position:absolute;
    }

    .react-multiple-carousel__arrow--left {
        left: calc(-3% + 1px)
    }

    .react-multiple-carousel__arrow--right {
        right: calc(-3% + 1px)
    }
}
  p{
    padding:0;
    margin:0;
  }
  .items-details{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    margin-bottom:0.2rem;
  }
  .percentages{
    font-weight:600;
    text-transform:uppercase;
    background:yellow;
    width:87px;
    text-align:center;
    padding:0.25rem !important;
    font-size:14px !important;
    color:black;
    span{
      font-size:14px !important;
        margin:0 !important;
        padding:0 !important;
    }
    .space{
      padding-left:0.25rem !important;
    }
  }
  .alice-carousel__dots{
    display: none;
}
  .alice-carousel__next-btn,
.alice-carousel__prev-btn {
  width:20px;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: var(--green-500);
  cursor: pointer;
}
.alice-carousel{
}
.alice-carousel__prev-btn {
  left: -2.25rem;
  
}

.alice-carousel__next-btn {
  right: -1.25rem;
}
.alice-carousel__prev-btn-item::after,
.alice-carousel__next-btn-item::after {
    content: "➔"; /* New icon */
    color: var(--green-500); /* Icon color */
    font-size: 30px; /* Icon size */
    // display: none; /* Hide the icon by default */
}


.alice-carousel__prev-btn-item::after{
    transform: rotate(180deg);
    display: inline-block; /* Show the rotated icon */
}
.alice-carousel__next-btn-item::after {
  display: inline-block;
}

.alice-carousel__prev-btn-item span[data-area="<"]::after,
.alice-carousel__next-btn-item span[data-area=">"]::after {
    content: ""; 
}


  
  .name{
    display:flex;
    flex-direction:column;
    gap:0.55rem;
    width:100%;
    h6{
        padding:0;
        margin:0;
        font-size:16px;
        font-weight:600 !important;
    }
   
    p{
        font-size:14px;
        font-weight:500;
    }
  }
  .price{
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:0.5rem;
    margin-top:0.75rem;
    h5,h6{
        padding:0;
        margin:0;
    }
    h5{
        font-weight:600;
        color:var(--green-300);
        font-size:17px;
    }
    h6{
        font-size:14px;
        text-decoration: line-through;
  }
}
.oldprice{
  font-weight:500 !important;
}
a {
  color: inherit;
  text-decoration: none; 
}
.cart-btn{
 width:150px;
 background:var(--green-300);
 padding:0.55rem;
 font-weight:500;
 border-radius:5px;
}
@media(min-width:1240px){
  .react-multi-carousel-track{
    

  }
}

@media(max-width:1040px){
  .alice-carousel__next-btn-item{
    display:none;
  }
  .alice-carousel__prev-btn-item{
    display:none;
  }
  .alice-carousel__prev-btn {
    left: 0;
  }
  .alice-carousel__next-btn {
    right: 0;
  }
}

@media(max-width:900px){

  .react-multiple-carousel__arrow--left{
    display:none;
  }
  .react-multiple-carousel__arrow--right{
    display:none;
  }

  
}

@media(max-width:560px){
  .percentage{
    width:80px !important;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    padding:0.25rem;
    font-size:13px !important;
    span{
      margin:0 !important;
  }
  .space{
    padding-left:0.25rem !important;
  }
}
@media(max-width:490px){
  padding-top:0.5rem;

  .percentages{
    padding:0 !important;
    font-size:13px !important;
  }
  .items {

    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
   
    img {
      width: 150px !important;
      height: 180px;
    }
  }
  .name{
   margin:0;
   h6{
     font-size:14px;
   }
  }
  .price{
    margin:0;
    h5{
      font-size:14px;
    }
    h6{
      font-size:12.5px;
    }
  }
  .cart-btn{
    font-size:13px !important;
    width:120px;
    padding:0.35rem;
  }
  .timing{
    margin-top:0.5rem;
  }
  .react-multiple-carousel-item{
    background:red !important;
  }
  .react-multiple-carousel__arrow {
    display:none;
}
}
@media(max-width:380px){
  .items {
    gap:0.25rem;
    img {
      width: 140px !important;
      height: 170px;
    }
  }
  h5{
    font-size:13px;
  }
}
`;
