import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import animationData from './Animation - 1708415690246.json'; // Import your Lottie animation JSON file

const GoogleLoading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, 
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Wrapper className="Loading-section">
      <Lottie
        options={defaultOptions}
        height={70} 
        width={200} 
      />
      <Text>You are logging in...</Text>
    </Wrapper>
  );
};

export default GoogleLoading;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  @media (min-width: 768px) {
    min-height: 70vh;
  }
  @media (max-width: 560px) {
    min-height: 50vh;
  }
`;

const Text = styled.p`
  font-size: 1.3rem;
  margin-top: 0.5rem; /* Adjust the top margin as needed */
  text-align: center;
`;
