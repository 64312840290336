
import './img/commingsoon.css'; // Import CSS file for styling
import logo1 from './img/logo(1).png'
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CommingSoon = () => {

  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://admin.myplantstore.me/api/submit-newsletter`, { email });
      console.log(response.data); // Log the response from the server
      toast.success('Successfully subscribed!'); // Show a success toast
      setEmail('')
    } catch (error) {
      console.error(error); // Log any errors
      toast.success('Already subscribed!');
      setEmail('');
    }
  };

  return (
    <div className="container">
<ToastContainer/>
    <div className="logo">
        {/* Image logo */}
        <img src={logo1} alt="Logo" />
      </div>
      <div className="image-with-icon">
       Coming Soon
      </div>
      
        {/* Text with two paragraphs */}
        <p className="text1">
        to UAE! 🌿 Subscribe now for exclusive updates and offers. 

        </p>
        <p className="text">
       Stay green, stay tuned!
        </p>
    

      <div className="input-container">
        {/* Textfield and Button */}
        <input type="email"

          value={email}
          onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
        <button onClick={handleSubmit}>SUBSCRIBE</button>
      </div>
    </div>
  );
}

export default CommingSoon;
