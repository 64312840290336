import React from 'react'
import styled from 'styled-components'
import { FaPlus, FaMinus } from 'react-icons/fa'

const AmountButtons = ({ increase, decrease, amount }) => {
  return (
    <Wrapper>
      <button type="button" className='amount-btn decrease' onClick={decrease}><FaMinus /></button>
      <h3 className="amount">{amount}</h3>
      <button type="button" className='amount-btn color' onClick={increase}><FaPlus /></button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 1fr 0.75fr;
  align-items: center;
  border: 1.4px solid var(--grey-100);
  border-radius: 5px;
  height:2.6rem;
  h3 {
    grid-column: 2; 
    margin-bottom: 0;
    font-size: 17px;
    padding: 0 1rem;
    height: 2rem;
    margin: 0 auto;
    padding-top: 0.35rem;
    width:50px;
  }

  button {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;
    margin:0;
    width:100%;
  }
  .decrease{
    border-right: 1.4px solid var(--grey-100);
  }
  .amount-btn {
    grid-column: 1; 
  }

  .color {
    height:104%;
    grid-column: 3; 
    border-radius: 0 5px 5px 0;
    background: var(--green-300);
    color: #fff;
    width:103%;
  }

  h2 {
    margin-bottom: 0;
  }
}
`

export default AmountButtons
