import customer from './1000+ products (1).png'
import productsImg1 from './features (1).png'
import productsImg2 from './features (2).png'
import delivery from './features (3).png'
import satisfiedCus from './products (1).png'

const arrayImgs = [
    {
        id:1,
        url:customer 
    },
    {
        id:2,
        url:productsImg2 
    },
    {
        id:3,
        url:productsImg1 
    },
    {
        id:4,
        url:delivery
    },
    {
        id:5,
        url:satisfiedCus
    },

]




export default arrayImgs