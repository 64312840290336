import styled from "styled-components";
import { BsCaretRightFill } from "react-icons/bs";
import { ScrollCategory } from "./support";
import { useDispatch, useSelector } from "react-redux";
import HorizontalCategory from "./support/HorizontalCategory";





const Categories = () => {
  const dispatch =useDispatch();
  const {categoryList} =useSelector((store)=>store.plants)



  return (
    <Wrapper >
      <HorizontalCategory />
    </Wrapper>
  );
};
const Wrapper =styled.nav`
  max-width:100%;
  padding:0.5rem 2.2rem;
  
  text-transform:uppercase;
  border-bottom:2px solid var(--grey-50);
  display:flex;
  justify-content:flex-start;
  align-items:center;
.containers{
  width: 860px;
  margin: 0 auto;
}


@media (max-width: 1240px) {
      ul{
        
      }

    }
    @media (max-width: 992px) {
      border:none;
      .main-nav{
        display:none;
      }
      
    }
@media(max-width:992px){
    display:none;
}
@media(max-width:898px){
  .containers{
    width:500px;
  }
}
`

export default Categories;