import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBlogs } from '../feature/blogs/blogsSlice';
import { Link } from 'react-router-dom';
import {formatISO9075} from "date-fns"
import styled from 'styled-components';
import { BsArrowRightShort } from "react-icons/bs";
import Loading from "../components/Loading"
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';

const BlogsContainer = ({content,newBlogs}) => {
  const {myUser} =useSelector((store)=>store.user)
    const {blogs , isBlogLoading } = useSelector((store)=>store.blog)

    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getBlogs())
    },[])
    const isProp = newBlogs ? newBlogs : blogs


    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return format(date, 'd MMMM yyyy, HH:mm');
    };
    

    const [showNoBlogsMessage, setShowNoBlogsMessage] = useState(false);

    useEffect(() => {
      if (blogs && blogs.length === 0) {
        const timeoutId = setTimeout(() => {
          setShowNoBlogsMessage(true);
        }, 3000);
  
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }, [blogs]);

useEffect(()=>{
  window.scrollTo(0, 0);
},[])

const truncateSummary = (text) => {
  // Split the text into lines
  const lines = text.split('\n');
  
  // If there are more than 4 lines, truncate and join the first 4 lines
  if (lines.length > 4) {
    return lines.slice(0, 4).join('\n');
  }
  
  // Otherwise, return the original text
  return text;
};


const pageTitle = "Blogs | Newsletter | Articles | MyPlantStore.me";
const pageDescription = "Explore our plant blog for expert tips, guides, and inspiration. Dive into our articles to enhance your gardening knowledge and cultivate thriving greenery in your home or garden.";

if (showNoBlogsMessage) {
  return (
    <Wrapper>
      <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
        </Helmet>
      <p>No blog posts have been published yet.</p>
    </Wrapper>
  );
}
   return (
    <Wrapper>
       <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <div className='header-div'>
       {content ? <h4>{content}</h4> :<>
       <h4>Recent Posts</h4></>}
       </div>
       <div className='post-container'>
       {Array.isArray(isProp) && isProp?.map((blogs,idx)=>{
        const {_id,title,summary,cover,content,createdAt,author,photo,tags} =blogs
        return(
        <div className="post" key={idx}>
        <div className="image">
          <Link to= {`/blogs/post/${_id}`}>
          <img src={photo?.url} alt="blog" draggable="false"/>
          </Link>
        </div>
        <div className="texts">
        <Link to= {`/blogs/post/${_id}`}>
          <h5 className='title'>{title}</h5>
          <p className="info"> 
          <p className='author'>{author?.name}</p>
          <p className='time'>{formatDate(createdAt)}</p>
          </p>
          <div className='tags'>
          {tags&&tags.map((tag,idx)=>{
            return(
                <p key={idx} className='tag'>{tag}</p>
            )
          })}
           </div>
           <p className="summary">{truncateSummary(summary)}</p>
          </Link>
          <Link to={`/blogs/post/${_id}`} className='btn-read-more'>Read more...</Link>
        </div>
      </div>
            )
        })}
       </div>
    </Wrapper>
  )
}

export default BlogsContainer

const Wrapper = styled.section`
 width:95%;
 margin: 0 auto;
 min-height:60vh;

 .post-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(355px, 1fr));
  gap: 2rem;
  justify-content: center;
  margin: 0 auto;
  min-height: 60vh;
  margin-bottom:2.5rem;
}

.summary {
  max-height: calc(1.5em * 4); /* 4 lines with line-height of 1.5em */
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: Add ellipsis for truncated text */
}

 .header-div{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    gap:1rem;
    margin:2rem 0;
    h4{
      margin:0;
      font-weight:600;
      color:var(--green-500);
    }
 }
 .tags{
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  gap:0.5rem;
  margin-top:0.5rem;
 }
 .tag{
  background:var(--green-500);
  padding:0.25rem .5rem;
  font-size:14px;
  color:#fff;
  border-radius:5px;
  text-transform:capitalize;
 }
 .header-link{
  display:flex;
  flex-direction:row;
  gap:1rem;
  justify-content:center;
  align-items:center; 
  text-transform:capitalize;
  a:hover{
    color:var(--green-300);
    text-decoration:underline;
    transition:color 0.3s;
  }
 }
 
 a{
  color:black;
 }
 .author{
  font-weight:500;
 }
 .time{
  color:var(--grey-300);
  font-size:13px;
  margin-top:0.25rem;
 }
 .info{
  font-size:13px;
  margin-top:0.45rem;
 }
 .summary{
  text-align: justify;
  font-size:14px;
  margin: 0.75rem 0;
  line-height:1.5;
 }
 .btn-read-more{
  margin-bottom:auto;
  color:var(--green-300);
  font-weight:600;
  padding:0;
  margin:0;
  display:flex;
  align-items:center;
  font-size:14px;
  svg{
    font-size:1rem;
  }
 }
h5{
  font-size:15px;
  font-weight:600;
  text-align: justify;
  padding:0;
  margin-bottom:0.25rem;
}
.post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 495px;
  border: 1px solid var(--grey-10);
  border-radius: 5px;
  padding: 0.25rem;
  box-sizing: border-box;
}
.post:hover{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
p{
  margin:0;
  padding:0;
}
.image {
  width: 100%;
  height: 180px; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}



.texts {
  width:100%; 
  padding: 1rem;
  background-color: var(--grey-10); 
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}
@media(max-width:1280px){

}

@media (max-width: 768px) {
  .post {
    width: 100%;
  }
}
@media(max-width:560px){
  .post{
    min-width:325px;
  }
  .header-div{
    margin:2rem 0rem 0.5rem 0.75rem;
  }
  h4{
    font-size:16px;
  }
  .tag{
    font-size:13px;
  }
  .summary{
    line-height:1.2;
   }
}

`