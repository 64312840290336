import React, { useState } from 'react'
import {PiShoppingCartDuotone} from "react-icons/pi";
import { MdOutlineFavoriteBorder ,MdEmail} from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import {closeBanner, openCart, openSearchTopSearchbar, setActiveTab} from '../../feature/modal/modalSlice'
import { FiUser } from "react-icons/fi";
import { logoutUser, setMyUser } from '../../feature/user/userSlice';
import { removeAuthTokenCookie, removeUserLocalStorage } from '../../utils/localStorage';
import { FaUserMinus,FaUser} from "react-icons/fa";
import { PiPottedPlantFill } from "react-icons/pi";
import { RiSearchLine } from "react-icons/ri";

const CartButtons = () => {
  const {isOpenCart} = useSelector((store)=>store.modal);
  const { cartItems ,wishListItems,user,myUser} = useSelector((store)=>store.user)
  const dispatch =useDispatch();
  const navigate = useNavigate();
  let cartLen =0
  if(cartItems){
    cartLen = cartItems.length
  }
  let wishtLen =0
  if(wishListItems){
    wishtLen = wishListItems.length
  }

  const scrollToNewsletter = () => {
    const newsletterSection = document.getElementById('newsletter-section');
    if (newsletterSection) {
      newsletterSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
 
  const logoutUser = async () => {
    try {
      const response = await fetch('https://myplantstore.me/auth/logout', {
        method: 'GET',
        credentials: 'include', // Include credentials (cookies) in the request
      });
  
      if (response.ok) {
        // Successful logout, redirect or handle accordingly
        window.location.href = '/';
      } else {
  
        // Handle error
        // console.error('Logout failed:', response.status, response.statusText);
      }
    } catch (error) {
      
      // console.error('Logout failed:', error.message);
    }
  };
  
  const logOut=()=>{
   (logoutUser())
    removeUserLocalStorage()
    removeAuthTokenCookie()
    dispatch(setMyUser())
  }

// const logOutGoogle = async (e) => {
//   e.preventDefault();
//   try {
//     const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/logout`);
//     toast.success(resp.data.message);
//   } catch (error) {
//     console.error("Error during logout:", error);
//     toast.error('Something went wrong...');
//   }
// };


const handleSearchIconClick = () => {
  dispatch(openSearchTopSearchbar());
  dispatch(closeBanner())
  
};

const toggle =()=>{
  dispatch(openCart());
  dispatch(setActiveTab('cart'))
  // window.scrollTo({
  //   top: 0,
  //   behavior: 'smooth'
  // });
}
const toggleFav =()=>{
  dispatch(openCart());
  dispatch(setActiveTab('wishlist'))
}

const [show,setShow]  = useState(false);

const onHover = ()=>{
  setShow(true);
}
const onLeave = ()=>{
  setShow(false)
}

  return (
<Wrapper>
    <button className='search-btn hide-search' onClick={()=>handleSearchIconClick()}><RiSearchLine/></button>
    <Link to="" className='cart-btn favorite' onClick={toggleFav}>
    <span className="cart-container">< MdOutlineFavoriteBorder/>
    <span className="cart-value">{wishtLen}</span>
    </span>
    </Link>
    <Link to="" className="cart-btn" onClick={toggle}>
      <span className="cart-container"><PiShoppingCartDuotone  />
      <span className="cart-value">{cartLen}</span>
      </span>
    </Link>
    <button className='user-btn show-user' onMouseEnter={onHover} onMouseLeave={onLeave}><FiUser /></button>
    <div className={`user-options ${show ? 'show' : ''}`} onMouseEnter={onHover} onMouseLeave={onLeave}>
      <div>
       <p>Welcome {myUser ?( (user!== null || user!== undefined) && user?.name) : 'user'}</p>
      
       {myUser ? <Link className='btns' onClick={logOut}><FaUserMinus/>Logout</Link> : <Link to='/user/login' className='btns'><FaUser />Login/sign up</Link>}
       <Link to='/user/order_status' className="btns"><PiPottedPlantFill />orders & rewards</Link>
       <Link to="#" className="btns bottom" onClick={scrollToNewsletter}><MdEmail />Newsletter</Link>
      </div>
</div>



  </Wrapper>
  )}


const Wrapper = styled.div`
  display:flex;
  gap:2.5rem;
  margin-top:0.75rem;
  margin-right:1rem;
  position:relative;
  .cart-btn {
    color: var(--clr-grey-1) !important;
    font-size: 1.5rem;
    letter-spacing: var(--spacing);
    display: flex;
    align-items: center;
  }
  .cart-container {
    display: flex;
    align-items: center;
    position: relative;
    color:var(--green--300);
    svg {
      height: 1.6rem;
      margin-left: 5px;
    }
  }
  .top{
    margin-top:0.75rem;
  }
  .bottom{
    margin-bottom:0.75rem;
  }
  .cart-value {
    position: absolute;
    top: -10px;
    right: -16px;
    background: var(--primary-800);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.75rem;
    color: white;
    padding: 12px;
  }
  .cart-btn:hover .cart-value {
    animation: car 0.7s alternate infinite;
    animation-iteration-count: infinite;
  }
  button{
    background:transparent;
    border:none;
    font-size:1.5rem;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-1);
  }
  button:hover{
    color:var(--green-300);
    transition: color 0.5s;
  }
  .search-btn{
    margin:0;
    padding:0;
  }
 

  .user-options{
    width: 250px;
    background: #fff;
    position: absolute;
    padding:0 !important;
    top: 100%;
    right: -10%; 
    z-index: 9999; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    font-size:15px !important;
    a{
      color:var(--grey-500);
      font-weight:500;
      text-transform:capitalize;
    }
    a:hover{
      background:#e2fee2;
      transition:color 0.3s ease;
    }
    div{
      display:flex;
      flex-direction:column;
      gap:0rem;
      padding:0 !important;
    }
    h6,p{
      padding:0;
      margin:0;
      font-family: 'Poppins';
    }
    h6{
      
    }
    p{
      font-size:14px !important;
      color:var(--grey-900) !important;
      font-weight:700;
      margin:0.75rem 0rem .15rem .75rem;
    }
  }
  
  .show {
    visibility: visible;
    opacity: 1 !important;
  }
  .btns{
    display:flex;
    align-items:center;
    padding:0.2rem 1rem;
    height:2rem;
    font-family: 'Poppins';
   svg{
    padding-top:0.2rem;
    margin-right:0.25rem;
   }
  }
  .active-search{
    display:none;
  }

  @keyframes car {
    from{
        transform: translateY(0px);
    }
    to{
        transform: translateY(5px);
    }
}
  .auth-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--clr-grey-1);
    letter-spacing: var(--spacing);
    svg {
      margin-left: 5px;
    }
  }
  .cart-btn :hover{
    color:var(--green-300);
    transition: color 0.5s;
  }
  .cart-btn :hover .cart-value{
    color:white;
  }
  @media(min-width:992px){
    .search-btn{
      display:none;
    }
  }
  @media(max-width:992px){
    .show,.show-user{
      display:none;
    }
  }
  @media (max-width: 768px) {
    gap:2rem;
    .user-options{
      right:0;
    }
  }
  @media(max-width:560px){
    .cart-btn{
      font-size:1.4rem;
    }
    .cart-value {
      font-size:0.65rem;
    }
    .cart-btn{
      margin-right:0.5rem;
    }
    // .user-btn{
    //   font-size:1.4rem;
    //   margin:0;
    //   padding:0;
    // }
  }
  @media(max-width:520px){
.user-options{
    width: 230px;
    background: #fff;
    position: absolute;
    padding:0 !important;
    top: 100%;
    right: 0%; 
    z-index: 9999; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    font-size:14px !important;
    div{
      display:flex;
      flex-direction:column;
      gap:0rem;
      padding:0 !important;
    }
   
    h6{
      padding:0;
      margin:0;
    }
    h6{
      
    }
    p{
      font-size:14px !important;
      color:var(--grey-900) !important;
      font-weight:700;
    }
  }
  }
  @media (max-width: 480px) {
    margin-right:1rem;
    padding-left:0 !important;
    .cart-value {
    }
  }
 
   @media (max-width: 568px) {
    gap:1.25rem;
    margin-right:0.25rem;
    .cart-value{
      padding: 9px;
      top: -7px;
      right: -9px;
    }
  }
  @media (max-width: 360px) {
    gap:.25rem;
    .cart-btn,.search-btn {
      font-size:1.2rem;
    }
  }
`
export default CartButtons
