import axios from "axios";

//getting categories from backend
export const getCategoriesThunk = async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAllSubCategories`);
     return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }

//getting products from backend
export const getAllProductsThunk =async (_, thunkApi) => {
    const {search, mainCategory,category,subCategory,sort,max_price,min_price, price,page,unique}=thunkApi.getState().plants;
    let url=`${process.env.REACT_APP_BASE_URL}/getAllProducts?maincategory=${encodeURIComponent(mainCategory)}&category=${encodeURIComponent(category)}&subcategory=${encodeURIComponent(subCategory)}&sort=${sort}&FeatureTag=all&page=${page}&min_price=${min_price}&max_price=${max_price}&unique=${unique}`;
    if(search){
      url =url + `&search=${search}`
    }
    try {
       const resp = await axios.get(url,
      );
  
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg)
    }
  }
  // /getSingleProduct/:id'
  export const getSingleProductsThunk =async ( id , thunkApi) =>{

    let url=`${process.env.REACT_APP_BASE_URL}/getSingleProduct/${id}`;

    try {
       const resp = await axios.get(url,);
  
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg)
    }
  }
 
//getting feature products
export const getFeaturedProductsThunk = async(_,thunkApi)=>{
  let url=`${process.env.REACT_APP_BASE_URL}/getFeaturedProducts`;
  try {
    const resp =await axios.get(url,)
    return resp.data
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.msg)
  }
}
//getting feature products
export const getFeaturedTagsThunk = async(_,thunkApi)=>{
  let url=`${process.env.REACT_APP_BASE_URL}/getFeaturedTags`;
  try {
    const resp =await axios.get(url,)
    return resp.data
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.msg)
  }
}

//getting flash sales products 
export const getFlashSaleProductsThunk = async(_,thunkApi)=>{
 let url = `${process.env.REACT_APP_BASE_URL}/getFlashSalesInfo`;
 try {
    const resp =await axios.get(url,)
    return resp.data
 } catch (error) {
  return thunkApi.rejectWithValue(error.response.data.msg)
 }
}