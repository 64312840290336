import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchSection from '../SearchSection';
import { useDispatch, useSelector } from 'react-redux';
import { closeScrollbar, openScrollbar } from '../../feature/modal/modalSlice';
import HorizontalCategory from './HorizontalCategory';

//the searchbar coming while scroll down
const SearchbarScroll = () => {
  const { isOpen, isOpenCart, isScrollbarOpen,isFilterOpen } = useSelector((store) => store.modal);
  const dispatch = useDispatch();

  const [width, setWidth] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true); 


 var lastScrollTop = 0;

 window.addEventListener("scroll", function() {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop){
      setIsScrollingUp(false)
    } else {
      setIsScrollingUp(true)
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
 }, false);
 

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const distanceFromTop = (scrollPosition / window.innerHeight) * 100;

      if (distanceFromTop >= 15) {
        dispatch(openScrollbar());
        
      } else {
        dispatch(closeScrollbar());
  
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper style={{ zIndex: isScrollbarOpen ? 999 : -1 }}>
    <div className={`your-div ${isScrollbarOpen ? 'shown' : 'hidden'}`} style={{ boxShadow: isFilterOpen ? 'none' : 'var(--shadow-4)' }}>
        <SearchSection />
        <div className='hide-down' style={{paddingLeft:'1.5rem'}}>
          <HorizontalCategory />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
position:relative;
  .your-div {
    max-height: 80px;
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    z-index: 2000;
    box-shadow: var(--shadow-4);
    background-color: #FFF !important;
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.3s, opacity 0.3s;
    background: white;
    width:100%;
    div {
      padding-top: 0.2rem;
      padding-left: 0.75rem;
      background: white;
      padding-bottom:0.15rem;
    }
  }
 .hide-down{
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  width:100%;
  margin:0 !important;
 }
  .hidden {
    position:absolute;
    transform: translateY(-100%);
    opacity: 0;
  }
  @media(max-width:1300px){
    .your-div{
      width:100%;
    }
  }
  @media(max-width:992px){
    .hide-down{
      display:none;
    }
  }
  @media (max-width: 768px) {
    .your-div {
      height: 65px;
    }
  }

  @media (max-width: 480px) {
    .your-div {
      height: 58px;
      width:100%;
    }
  }
`;


export default SearchbarScroll