import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { DropdownProduct, PriceMultiRange } from './support'
import { closeSearchResults, getAllProducts, getCategories, handleChange, handlePriceChange, openSearchResults } from '../feature/plants/plantsSlice'
import { BsChevronRight } from "react-icons/bs";
import { MdArrowBack } from "react-icons/md";
import Products from './Products'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { FaHome } from "react-icons/fa";

//the filter horizontal section while opening, clicking on the categories
const Filterbar = () => {
  const { categoryList, page, numOfPages } = useSelector((store) => store.plants);
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const location = useLocation();
  const pathParts = location.pathname.split('/');

  const lastPart = pathParts.pop();
  const previousPart = pathParts.pop();
  const thirdPart = pathParts.pop();

  // Decode the category names
  const subCategoryName = decodeURIComponent(lastPart);
  const categoryName = decodeURIComponent(previousPart);
  const mainCategoryName = decodeURIComponent(thirdPart);

  let mainCategories
  let subCategories
  let categories

  if (mainCategoryName === 'mainCategory' && categoryName === 'category') {
    mainCategories = subCategoryName
    categories = ''
    subCategories = ''
  } else if (mainCategoryName === 'mainCategory' && categoryName !== 'category') {
    mainCategories = categoryName
    categories = subCategoryName
    subCategories = ''
  } else if (mainCategoryName !== 'mainCategory' && categoryName !== 'category') {
    mainCategories = mainCategoryName
    categories = categoryName
    subCategories = subCategoryName
  }



  const [show, setShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 5000);


    return () => clearTimeout(timer);
  }, []);

  let children = []
  const decodedCategoryName = decodeURIComponent(lastPart);
  const foundItem = categoryList.find(item => item.name === decodedCategoryName);
  if (foundItem && foundItem.children) {
    children = foundItem.children;
  }


  const [position, setPosition] = useState(() => {
    const positionFromStorage = localStorage.getItem('currentPageNo');
    return positionFromStorage ? parseInt(positionFromStorage) : null;
  });

  useEffect(() => {
    const positionFromStorage = localStorage.getItem('currentPageNo');
    const newPosition = positionFromStorage ? parseInt(positionFromStorage) : null;

    if (newPosition !== null && newPosition >= 1 && newPosition <= numOfPages) {
      setPageNo(newPosition);
      dispatch(handlePriceChange({ name: 'page', value: newPosition }));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [numOfPages, dispatch]);





  const [pageNo, setPageNo] = useState(page);

  const handleNext = (prop) => {
    let newPageNo = pageNo;

    if (prop === 'next' && page < numOfPages) {
      newPageNo = pageNo + 1;
    } else if (prop === 'prev' && page > 1) {
      newPageNo = pageNo - 1;
    } else if (typeof prop === 'number' && prop >= 1 && prop <= numOfPages) {
      newPageNo = prop;
    }

    // Store the new page number in local storage
    localStorage.setItem('currentPageNo', newPageNo);

    if (newPageNo !== pageNo) {
      setPageNo(newPageNo);
      dispatch(handlePriceChange({ name: 'page', value: newPageNo }));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };


  return (
    <Wrapper>
      <div className='top-indicator'><Link to='/'><FaHome />Home </Link>&nbsp;/&nbsp;{mainCategories}</div>
      <div className="content">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='main'>
            <div className='dropdown-product'>
              <PriceMultiRange />
              <div style={{paddingTop:'17px'}}>
                
                <div class="green-bar"><p class="capitalize" style={{paddingLeft:'16px',fontWeight:'600px'}}>CATEGORIES</p></div>
              </div>


              <DropdownProduct items={children} pageNo={pageNo} />
            </div>
            <div className='products'>
              < Products />
              {((show) && (numOfPages && numOfPages > 1)) && <div className='page-btns'>
                <button className='btn-prev' onClick={() => handleNext('prev')}><GrFormPrevious /> prev</button>
                {[...Array(numOfPages).keys()].map((num) => {
                  if (num === 0 || num === numOfPages - 1 || Math.abs(page - num - 1) <= 2) {
                    return (
                      <button key={num} onClick={() => handleNext(num + 1)} className={`btn-no ${page === num + 1 ? 'highlight' : ''}`}>
                        {num + 1}
                      </button>
                    );
                  } else if (num === 1 || num === numOfPages - 2) {
                    return <span key={num}>…</span>;
                  } else {
                    return null;
                  }
                })}
                <button onClick={() => handleNext('next')} className='btn-next'>next<GrFormNext /></button></div>}
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  // margin-top:1.75rem;
  .inline{
    display:flex;
    gap:18rem;
    align-items:top;
  }

  
  .capitalize {
    text-transform: capitalize;
    margin-right: 10px; /* Add margin between elements */
   
    font-weight:600;
    
    
  }
  
    .green-bar {
      width: 0.45rem;
      height: 1.5rem;
      background: var(--green-300);
      border-radius: 3.25px;
      margin-right: 0.5rem;
    }
  
  
  .top-indicator{
    display:flex;
    margin-top:0.5rem;
    margin-bottom:0.7rem;
    align-items:center;
    text-transform:capitalize;
    font-size:14px;
    color:var(--grey-500);
 
    a{
      display:flex;
      justify-content:center;
      align-items:center;
      color:var(--grey-500);
    }
    svg{
    padding-bottom:0.12rem;
    }
    a:hover {
      color: var(--green-300);
      text-decoration: underline;
      transition: color 0.2s, text-decoration 0.2s;
    }
    
  }
  .main{
    display:flex;
  flex-direction: row;
  gap:2rem;
  margin-bottom:2rem;
  }
  .inline button{
    font-size:20px;
  }
  .inline button:hover{
    color:var(--green-300);
    transition:color 0.2s;
  }
  .dropdown-product{
    border-radius:0.75rem;
    white-space: nowrap;
    min-width:250px;
    align-self: flex-start;
    display:flex;
    flex-direction:column;
    p{
      padding:0;
      margin:0;
      font-size:15px;
      text-transform:capitalize;
    
    }
  }
  .btn-no{
    min-width:10px;
  }
  .highlight {
    color: var(--green-300);
    font-weight:bold;
    border-bottom:2px solid var(--green-300);
  }
  
  .page-btns{
    margin-top:2.5rem;
    display:flex;
    flex-direction:row;
    justify-content:center;
    gap:1rem;
    width:100%;
    margin-bottom:2rem;
    
  }
  .btn-prev,.btn-next{
      background:var(--grey-100);
      width:100px;
      padding:0.35rem;
      border-radius:10px;
      font-weight:600;
      display:flex;
      justify-content:center;
      align-items:center;
      svg{
        font-size:1rem;
      }
  }
  .products{
    margin:0rem auto;
    width:100%;
    align-self: flex-end;
    margin-bottom:auto;
    min-height:80vh;

    
  }
 
  .inline-child{
    display:flex;
    align-items:center;
    height:40px;
    gap:1rem;
  }
  h5{
    padding-top:1rem;
  }
  .form-control {
    margin-bottom: 1.25rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
  .search-input {
    padding: 0.5rem;
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    letter-spacing: var(--spacing);
  }
  .search-input::placeholder {
    text-transform: capitalize;
  }

  button {
    display: block;
    margin: 0.25em 0;
    padding: 0.25rem 0;
    text-transform: capitalize;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
    cursor: pointer;
  }
  .active {
    border-color: var(--clr-grey-5);
  }
  .company {
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    padding: 0.25rem;
  }
  .colors {
    display: flex;
    align-items: center;
  }
  .color-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.5rem;
      color: var(--clr-white);
    }
  }
  .all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
  .all-btn .active {
    text-decoration: underline;
  }
  .price {
    margin-bottom: 0.25rem;
  }
  .shipping {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    text-transform: capitalize;
    column-gap: 0.5rem;
    font-size: 1rem;
    max-width: 200px;
  }
  .clear-btn {
    background: var(--clr-red-dark);
    color: var(--clr-white);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
  }
  @media (min-width: 768px) {
    .content {
      position: sticky;
      top: 1rem;
    }
    
  }

  @media(max-width:868px){
    margin-top:1rem;
    .content {
      margin-bottom:6rem;
    }
    .products{
      margin-left:0;
    }
    .dropdown-product{
      display:none;
    }
    .inline{
      width:50%;
      justify-content:space-between;
    }
  }

  @media(max-width:460px){
    .inline{
     display:none;
    }
  }
  @media(max-width:580px){
    margin-top:1.75rem;
    .page-btns{
      padding-bottom:4rem;
    }
    .btn-prev,.btn-next{
      width:70px;
    }
    .main{
      margin-bottom:5rem;
    }
    .top-indicator{
      margin-top:0rem;
      margin-bottom:1rem;
      font-size:14px;
      margin-left:0.5rem;  
    }
  }
`

export default Filterbar