import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { handlePriceChange} from '../../feature/plants/plantsSlice';
import DropdownProduct from './DropdownProduct';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { closeFilter, openFilter } from '../../feature/modal/modalSlice';
import Select from 'react-select';
import SearchHeirarchy from './SearchHeirarchy';



//filter page mobile view
const SortbarSmallScreen = () => {
  const { categoryList,isTopParent,totalPlants} = useSelector((store) => store.plants);
  const {isScrollbarOpen,isFilterOpen} = useSelector((store)=>store.modal)


  const dispatch = useDispatch();

  

  const toggle = () => {
      dispatch(openFilter());
  };

  const [formSelect, setFormSelect] = useState({name:'',value:''});
  const [formCheck, setFormCheck] = useState({name:'',value:''});
  const [formMaxPrice, setFormMaxPrice] = useState({name:'',value:''});
  const [formMinPrice, setFormMinPrice] = useState({name:'',value:''});

  const handleSort =(value)=>{
   setFormSelect({name:'sort',value:value})
  }

  const handleSubmit = () => {
    dispatch(handlePriceChange(formSelect))
    dispatch(handlePriceChange(formCheck))
    dispatch(handlePriceChange(formMaxPrice))
    dispatch(handlePriceChange(formMinPrice))
    dispatch( closeFilter())
  };
  

//passing to DropdownProduct
  const handleCheckboxChange = (name, value) => {
    // Update the formCheck state when a checkbox value changes
    setFormCheck({ name, value });
  };

  const location = useLocation();

  
  //fetching the current category while refresh the screen
 let children =[]
  const pathParts = location.pathname.split('/');
   const lastPart = pathParts.pop();
   const decodedCategoryName = decodeURIComponent(lastPart);

   const foundItem = categoryList.find(item => item.name === decodedCategoryName);
   if (foundItem && foundItem.children) {
     children = foundItem.children;

     // Now you can use the children array
   }
  




const options = [
  { value: 'latest', label: 'Latest' },
  { value: 'price-lowest', label: 'Price (Lowest)' },
  { value: 'price-highest', label: 'Price (Highest)' },
  { value: 'a-z', label: 'Name (A-Z)' },
  { value: 'z-a', label: 'Name (Z-A)' },
  { value: 'oldest', label: 'Oldest' },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    minWidth: '200px',
    maxWidth: '100%',
    height: 40,
    borderRadius: 5,
    borderColor: 'var(--grey-100)',
    boxShadow: state.isFocused ? '0 0 0 1px var(--grey-100)' : null,
    "&:hover": {
      borderColor: 'var(--grey-100)',
    },
    marginLeft: '0',
    marginRight: '12px',
  }),
  input: (provided) => ({
    ...provided,
    readOnly: 'true',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
    textAlign: 'left',
    padding: '8px',
    backgroundColor: state.isDisabled
      ? null
      : state.isSelected
      ? 'var(--green-500)'
      : state.isFocused
      ? '#e2fee2'
      : null,
    "&:active": {
      backgroundColor: 'var(--green-500)',
    },
    outline: state.isFocused ? 'none' : null,
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    maxWidth: '100%', 
    minWidth: '200px',
  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: 'left',
  }),
  singleValue: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontSize: 13,
  }),
};

  
  //manage the height of the filter component 
  useEffect(() => {
    if (isFilterOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isFilterOpen]);
  

  //manage the price of filter
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(5000);
  const [priceGap] = useState(0);

  const handleRangeMinChange = (e) => {
    const newMinVal = parseInt(e.target.value);
    const newMaxVal = parseInt(maxPrice);
    setFormMinPrice({name:'min_price',value:e.target.value})
    if (newMaxVal - newMinVal >= priceGap) {
      setMinPrice(newMinVal);
      setMaxPrice(newMaxVal);
    }
  };

  const handleRangeMaxChange = (e) => {
    const newMinVal = parseInt(minPrice);
    const newMaxVal = parseInt(e.target.value);
    setFormMaxPrice({name:'max_price',value:e.target.value})
    if (newMaxVal - newMinVal >= priceGap) {
      setMinPrice(newMinVal);
      setMaxPrice(newMaxVal);
    }
  };


  //body overflow disabling
  const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
   
  }
  @media(max-width:560px){
   body{
    overflow: hidden;
    padding-right:0px !important;
    scroll-y:disabled;
   }
  }
`;


  return (
    <Wrapper>
      <div className='plants-length'>
        <p className='product-count'>
      
        </p>
      </div>
      <div>
        <button className='btn-toggle' type="submit" onClick={toggle}>
          Filter
        </button>
      </div> 

      {/* filter select box */}
      <div className={`filter-panel ${isFilterOpen ? 'open' : ''}`} >

      {isFilterOpen && <GlobalStyle />}
      <div className='contents'>
      <div className='form-elements'>
      <form className='select-container'>
      <label htmlFor="sort">sort by:</label>
      <div className='select-wrapper'>
      <Select options={options} className="sort-input" styles={customStyles} isSearchable={false} defaultValue={options[0]}
      onChange={(selectedOption) => handleSort(selectedOption.value)} />
    </div>
    </form>

    {/* price range input */}
    <div className="wrapper">
        <p className='header'>Price Range</p>
      <div className="slider">
        <div className="progress" style={{ left: `${(minPrice / 5000) * 100}%`, right: `${100 - (maxPrice / 5000) * 100}%` }}></div>
      </div>
      <div className="range-input"> 
      <input type="range" className="range-min" min="0" max="5000" value={minPrice} step="10" name='minPrice' onChange={handleRangeMinChange}/>
      <input type="range" className="range-max" min="0"  max="5000" value={maxPrice} step="10" name='maxPrice' onChange={handleRangeMaxChange}/>
      </div>
      <div  className='price'>
      <p>{minPrice} <span>AED</span></p>
      <p className='right'>{maxPrice} <span>AED{maxPrice === 5000 ?  '+' : null}</span></p>
      </div>
    </div>

    {/*drop down categories*/}
    <div className='scroll-div'>
      <SearchHeirarchy/>
      <DropdownProduct formCheck={formCheck} onCheckboxChange={handleCheckboxChange} />
    </div>
      </div>
      <div className='links'>
        {categoryList && categoryList.slice(0, 6).map((categories)=>{
          const {name,id} = categories;
          return(
            <div key={id}>

              <Link to={`/products/mainCategory/category/${name}`} className={`${isTopParent === name ? 'link-btn btn-color' : 'link-btn'}`}>{name}</Link>
            </div>
          )
        })}
      </div>
      <button className='btn-apply' onClick={handleSubmit}>apply</button>
      </div>
      </div>  
    </Wrapper>
  );
};

export default SortbarSmallScreen;
;

const Wrapper = styled.div`
  display: none;
  z-index: 222;
   height:65px;
  .plants-length{
    flex:.90;
    justify-content:center;
    align-items:center;
    margin:0 auto;
  }
  .product-count{
    color:#fff;
    font-weight:bold;
    height:100%;
    text-align:center;
    display:flex;
    width:100%;
    justify-content:center;
    margin:0 auto;
    align-items:center;
  }
  .btn-toggle{
    width:150px;
    background:#fff;
    margin-right:0.25rem;
    height:88%;
    text-transform:uppercase;
    font-weight:bold;
    font-size:16px;
  }
  .contents{
    position:relative;
    width:100%;
    height:100%;

  }
  .links{
    width:90%;
    position:absolute;
    bottom:20%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    gap:0.75rem;
    justify-content:left;
    align-items:center;
    margin-left:1rem;
  }
  .link-btn{
    font-size:14px;
    width:300px;
    background:var(--grey-100);
    color:black;
    padding:0.25rem 0.5rem;;
    border-radius:10px;
    text-transform:capitalize;
  }
  .scroll-div{
    max-height:250px;
    overflow:auto;
  }
  .form-elements{
    width:90%;
    margin:0 auto;
  }

  .flex-container{
    width:90%;
    margin-left:0.5rem;
    margin-bottom:0.25rem;
    
  }
  .btn-color {
    color:white;
   background:var(--green-300);

  }
  form{
    margin-bottom:0.5rem;
    display: flex;
    align-items: center;
    select {
      border-color: transparent;
      font-size: 0.9rem;
      text-transform: capitalize;
      padding: 0.25rem 0.5rem;
      border: 0;
      cursor: pointer;
      width: 75%;
      // background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat; /* Add custom dropdown arrow */
      // background-position-x: right; 
    }
    
    input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }
    label {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .select-wrapper {
    flex: 1; 
    margin-left:0.75rem;
  }
  p{
    margin:0 !important;
  }
  .category{
    text-transform:capitalize;
    
  }
  .btn-apply{
   position:absolute;
   bottom:11%;
   background-color:  #23398E;
   width:100%;
   height:50px;
   display:flex;
   justify-content:center;
   align-items:center;
   color:#fff;
   font-weight:bold;
   text-transform:uppercase;
  }
  .no-scroll {
    overflow: hidden;
  }
  input,label{
    cursor:pointer;
  }
  .filter-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    top:0;
    width: 100%;
    background: #fff;
    z-index: 9999;
    transform: translateY(100%); 
    transition: transform 0.3s ease;
    overflow-y:auto;
    padding-top:1rem;
    &.open {
      transform: translateY(12%); 
      top:-15%;
    }

  }
  @media (max-width: 868px) {
    display: flex;
    flex-direction:row; 
    justify-content:space-between;
    position: fixed; 
    bottom: 0;
    left: 0;
    width: 100%;
    background-color:  #23398E;
    z-index: 1;
  }
  @media (max-width: 580px) {
    display: flex;
    flex-direction:row; 
    justify-content:space-between;
    position: fixed; 
    bottom: 0;
    left: 0;
    width: 100%;
    background-color:  #23398E;
    z-index: 1;
    
  }
  .sort-input{
    padding:0rem 0.75 !important;
  }
  .wrapper{
    ::selection{
      color: #fff;
      background: #17A2B8;
    }
    .wrapper{
      width: 400px;
      background: #fff;
      border-radius: 10px;
      padding: 20px 25px 40px;
      box-shadow: 0 12px 35px rgba(0,0,0,0.1);
    }
    .header{
      font-size: 16px;
      margin-bottom:1rem !important;
    }
    .price-input{
      width: 100%;
      display: flex;
      margin: 30px 0 35px;
    }
    .price-input .field{
      display: flex;
      width: 100%;
      height: 45px;
      align-items: center;
    
    }
    .field input{
      width: 100%;
      height: 100%;
      outline: none;
      font-size: 19px;
      margin-left: 12px;
      border-radius: 5px;
      text-align: center;
      border: 1px solid #999;
      -moz-appearance: textfield;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .price{
      display:flex;
      flex-direction:row;
      justify-content:space-between !important;
      margin-top:0.75rem;
      margin-bottom:0.75rem !important;
    }
    .right{
      text-align:right;
    }
    
    .price-input .separator{
      width: 130px;
      display: flex;
      font-size: 19px;
      align-items: center;
      justify-content: center;
    }
    .slider{
      height: 5px;
      position: relative;
      background: var(--grey-200);
      border-radius: 5px;
    }
    .slider .progress{
      height: 100%;
      left: 25%;
      right: 25%;
      position: absolute;
      border-radius: 5px;
      background: var(--green-300);
    }
    .range-input{
      position: relative;
    }
    .range-input input{
      position: absolute;
      width: 100%;
      height: 5px;
      top: -7px;
      background: none;
      pointer-events: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    input[type="range"]::-webkit-slider-thumb{
      height: 17px;
      width: 17px;
      border-radius: 50%;
      background: #fff;
      cursor:pointer;
      pointer-events: auto;
      -webkit-appearance: none;
      box-shadow: 0 0 6px rgba(0,0,0,0.5);
    }
    input[type="range"]::-moz-range-thumb{
      height: 17px;
      width: 17px;
      border: none;
      border-radius: 50%;
      background: #17A2B8;
      pointer-events: auto;
      -moz-appearance: none;
      box-shadow: 0 0 6px rgba(0,0,0,0.05);
    }
  }
`;
