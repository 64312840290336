import React from 'react'
//render while error occur
const Error = () => {
  return( 
  <div className='Loading-section section-center text-center'>
    <h2>There was an error...</h2>
  </div>)
}

export default Error
