import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LiaHandPointRightSolid } from 'react-icons/lia';
import { PiShoppingCartDuotone } from 'react-icons/pi';
import { MdFavorite,MdFavoriteBorder } from "react-icons/md";
import { showSingleProduct } from '../../feature/plants/plantsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addToCart, addTowishList, getCart, getWishList } from '../../feature/user/userSlice';
import { openCart } from '../../feature/modal/modalSlice';
import LazyImage from './LazyImage';

//list view of products
const ListView = ({ products }) => {
  const {user,myUser,wishListItems} = useSelector((store)=>store.user)

  const dispatch = useDispatch();
  const [hoveredProduct, setHoveredProduct] = useState(null);

  const singleProduct = () => {
    dispatch(showSingleProduct());
  };
 
  const addWishlist=(product,e)=>{
    const wishListObject = JSON.parse(product);
    dispatch(addTowishList(wishListObject[0]))
    dispatch(getWishList())
    }

    const addCart=(product,e)=>{
      const wishListObject = JSON.parse(product);
      dispatch(addToCart(wishListObject[0]));
      dispatch(getCart())
      dispatch(openCart('cart'))
      }
  

    const isProductInWishlist = (Id) => {
      return (wishListItems || []).some(item => item.productId === Id);
  }



  

  return (
    <Wrapper>
      {products.map((product) => {
        const { _id, photos, title, price,scienticName, caredes ,description,dimensions,flashSaleInfo,category,maincategory,subcategory} = product;

        let sortedDimensions = dimensions ? dimensions.slice().sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price)) : [];

              
      const oldPrice = (Number(sortedDimensions[0].Price))
      const newPrice =flashSaleInfo &&  (oldPrice - ( (flashSaleInfo.OfferPercentage/100)*oldPrice))
         
          // title,_id,mainCategory,subCategory,category,totalPrice:(flashSaleInfo ? newPrice : total),price:price, potName:itemPot,size:itemLength,productQuantity:amount,image:activeImageName.url
          const wishListItem = [
            {
              dimension:sortedDimensions[0],
              image: photos[0].url,
              price:price,
              totalPrice: oldPrice,
              title: title,
              maincategory: maincategory,
              category: category,
              subcategory: subcategory,
              productQuantity:1,
              productId:_id,
            },
          ];    
          wishListItem.forEach((item) => {
            if (flashSaleInfo) {
              if (newPrice !== 0) {
                item.flashSalePrice = newPrice;
              }
              if(flashSaleInfo.Status === "Unlimited"){
            
                if (flashSaleInfo.OfferPercentage) {
                  item.flashSaleDiscount = flashSaleInfo.OfferPercentage;
                }
                if (flashSaleInfo.Status) {
                  item.Status = flashSaleInfo.Status;
                }
              }else{
                if (flashSaleInfo.StartDate) {
                  item.flashSaleStartDate = flashSaleInfo.StartDate;
                }
                if (flashSaleInfo.StartTime) {
                  item.flashSaleStartTime = flashSaleInfo.StartTime;
                }
                if (flashSaleInfo.TimeInHours) {
                  item.flashSaleEndTime = flashSaleInfo.TimeInHours;
                }
                if (flashSaleInfo.OfferPercentage) {
                  item.flashSaleDiscount = flashSaleInfo.OfferPercentage;
                }
              }
            }
          });

          const addedFav = isProductInWishlist(_id);

        return (
          
          <article key={_id}>
            
            <div
              className={`image-container ${photos.length > 1 ? 'hoverable' : ''}`}
              onMouseEnter={() => setHoveredProduct(_id)}
              onMouseLeave={() => setHoveredProduct(null)}
            >
              
              <Link to={`/products/${_id}`}>
              {flashSaleInfo &&<p className='percentages'> {flashSaleInfo.OfferPercentage}<span>%</span><span className='space'>off</span> </p>}
              {photos && (
                <>
                  {/* <img
                    src={photos[0].url}
                    alt={title}
                    className={`image ${hoveredProduct === _id ? 'hidden' : ''}`} draggable="false"
                  /> */}
                 <LazyImage src={photos[0]?.url} alt={title} className={`image ${hoveredProduct === _id ? 'hidden' : ''}`}  draggable="false"/>
                  
                  {photos[1] && (
                    <img
                      src={photos[1].url}
                      alt={title}
                      className={`image second-image ${hoveredProduct === _id ? '' : 'hidden'}`} draggable="false"
                    />
                  )}
                </>
              )}
              </Link>
            </div>
            
            <div className='des'>
            <Link to={`/products/${_id}`}>
            <div className='footer-big'>
                  <div className='item-header'>
                  <p className='paragraph1'> {title}</p>
                  <p className='paragraph2'> {scienticName? scienticName : '' }</p>
                  </div>
                  <div className='item-price'>
                 
                  
                  {flashSaleInfo ?<h5>{newPrice} <span>AED</span></h5> : <h5 className='old-prices-line'>{oldPrice} <span>AED</span></h5>}
                  {newPrice && <h5 className='old-prices'>{oldPrice} <span>AED</span></h5>}
                  </div>
                </div>
              {/* <p>{description}</p> */}
              {/* <p className='paragraph'>{description && description.substring(0, 300)+'...'}</p> */}
              <p className='paragraph'>{description ? description?.substring(0, 300) + '...' : caredes?.substring(0, 300)}</p>

              </Link>
              <div className="footer-div">
                {/* <Link to={`/products/${_id}`} className="btn-button">
                  <LiaHandPointRightSolid />
                </Link> */}
      
                <button className='cart-btn' onClick={(e) => addCart(JSON.stringify(wishListItem))}> <PiShoppingCartDuotone /> add to Cart</button>
                <button className='cart-btn' onClick={(e) => addWishlist(JSON.stringify(wishListItem))}>  {addedFav ? <MdFavorite /> : <MdFavoriteBorder />}Wishlist</button>
                
              </div>
            </div>
          </article>
        
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  row-gap: 1rem;
  max-width:;
  margin:0 auto;
  
  article{
   
    display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 0rem;
      align-items: center;
      padding-bottom:0.5rem;
  
  }
  
  .des{
    margin-bottom:auto;
    height:95%;
    display: flex;
    flex-direction: column;
    p{
      padding:0;
      margin:0;
    }
  }
  a{
    color:var(--grey-800);
  }
  .item-header{
    display:flex;
    flex-direction:column;
    gap:0.75rem;
    .paragraph1{
      font-weight:700;
    }
  }
  .percentages{
    position:absolute;
    top:-3%;
    left:2%;
    font-weight:600;
    text-transform:uppercase;
    background:yellow;
    width:87px;
    text-align:center;
    padding:0.25rem !important;
    font-size:14px !important;
    z-index: 99;
    color:black;
    span{
      font-size:14px !important;
        margin:0 !important;
        padding:0 !important;
    }
    .space{
      padding-left:0.25rem !important;
    }
  }
  .item-price{
    display:flex;
    flex-direction:row;
   gap:1rem;
    align-items:center;
    .old-prices{
      text-decoration: line-through;
      font-size:14px;
      font-weight:500;
    }
  }
  h5 {
    font-size:18px;
    font-weight:bold;
    margin: 0.5rem 0rem !important;
    padding: 0 !important;
    span{
      margin-left:0.5rem !important;
    }
  }
  h4 {
    font-size:18px;
    margin-bottom: 0.5rem;
    font-weight:bold;
  }
  .price {
    color: var(--clr-primary-6);
    margin-bottom: 0.75rem;
  }
  p {
    max-width: 55em;
    margin-bottom: 1rem;
  }
  .btn {
    font-size: 0.5rem;
    padding: 0.25rem 0.5rem;
  }
  .footer-div{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    gap:1rem;
    margin-top:auto;

  }
  .cart-btn{
      padding-left:0 !important;
      margin-left:0 !important;
      margin-right:1rem;
      display:flex;
      font-size:1rem;
      color:var(--green-300);
      padding:0.5rem;
      border-radius:1rem;
      font-weight:500;
      svg{
        font-size:1.15rem;
        margin-right:0.25rem;
      }
  }
  // .cart-btn:hover{
  //     background:var(--grey-100);
  //   }
  .buy-btn{
    font-size: 0.85rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    background: var(--green-500);
    display: flex;
    color: var(--white);
    font-weight:600;
    width:50%;
    justify-content:center;
    border:none;
    max-width:100px;
    align-items:center;
  }
 .paragraph{
  line-height: 1.6 !important;
 }

  .buy-btn:hover {
    background: var(--green-300);
    color: white;
  }
  img{
    object-fit: cover;
  }
  .image-container {
    position: relative;
    width:250px;
    img {
      // width: 100%;
      display: block;
      width: 216px;
      height: 260px;
      border-radius: var(--radius);
      margin-bottom: 1rem;
    }

    .second-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 216px;
      height: 260px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &.hoverable:hover .second-image {
      opacity: 1;
    }
  }

 @media(max-width:1084px){
  .description{
    display:none;
  }
 }
@media(max-width:798px){
  article{
      column-gap: 0.5rem;
      height:230px;
  }
  .des{
    column-gap: 0.5rem;
    height:220px;
    padding-top:0;
  }
  img{
    width:200px !important;
    height:220px !important;
  }
  .image-container{
    width:210px
  }
  h5 {
    font-size:15px;
  }
  .footer-div{
    margin-top:0.75rem;
    gap:0.25;
    button{
      margin:0;
      padding:0;
      margin-bottom:0.5rem;
    }
  }
}

  @media(max-width:398px){
    max-width:300px;
    margin-left:0.5rem;
    article {
      height:200px;
      h5, h4{
        font-size:15px;
      }
      p{
        font-size:14px;
      }
      img{
        width:160px !important;
        height:180px !important;
      }
      .image-container{
        width:170px
      }
      .des{
        height:190px;
      }
    .footer-div{
      gap:0;
      button,.btn-button{
        padding:0;
      }
    }
  }
`

export default ListView
