export const deliveryCharges = [
    { _id: '65b6c1cd073976ae2219bdb2', location: 'Ajman', days: '2 - 4' ,charge:14,minPurchase:100},
    { _id: '65b6c1f6073976ae2219bdba', location: 'Sharjah', days: '2 - 4' ,charge:14,minPurchase:100 },
    { _id: '65b6c21d073976ae2219bdc2', location: 'Dubai', days: '2 - 4' ,charge:14,minPurchase:100},
    { _id: '65b6c2a4073976ae2219bdcb', location: 'Abu Dhabi', days: '3 - 5' ,charge:14,minPurchase:200},
    { _id: '65b6c2b8073976ae2219bdd2', location: 'Fujairah', days: '5 - 7' ,charge:38,minPurchase:400},
    { _id: '65b6c2e4073976ae2219bddb', location: 'Al Ain', days: '5 - 7' ,charge:38,minPurchase:400},
    { _id: '65b91b37073976ae2219d0a6', location: 'Ras Al Khaimah', days: '4 - 5',charge:18,minPurchase:250 },
    { _id: '65b9ee4b4476717c30366291', location: 'Umm Al Quwain', days: '3 - 5' ,charge:18,minPurchase:250},
    { _id: '65b9ee694476717c30366295', location: 'Hatta', days: '5-7' ,charge:40,minPurchase:400},
    { _id: '65b9eeb34476717c30366298', location: 'Khor Fakkan', days: '5-7' ,charge:40,minPurchase:400},
    { _id: '65b9eed54476717c3036629c', location: 'Dibba', days: '5-7' ,charge:40,minPurchase:400},
    { _id: '65b9ef094476717c303662a1', location: 'Al Dhaid', days: '5-7' ,charge:40,minPurchase:400},
    { _id: '65b9ef284476717c303662a5', location: 'Masafi', days: '5-7',charge:40,minPurchase:400 },
    { _id: '65b9ef464476717c303662a8', location: 'Al Manama', days: '5-7' ,charge:40,minPurchase:400},
    { _id: '65b9ef644476717c303662ac', location: 'Ruwais', days: '15 - 20',charge:510,minPurchase:4000 },
  ];
  
  