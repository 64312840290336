import React, { useEffect, useRef, useState } from 'react'
import lazyImage from '../../assets/images/lazyImage.png'

const LazyImage = (props) => {
    const ref = useRef();
    const [inView,setInView] = useState(false);
    let callback = (entries, observer) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            setInView(true);
          }
        });
      }
    
      useEffect(()=>{
        let observer = new IntersectionObserver(callback);
        if(ref?.current){
          observer.observe(ref.current);
        }
        return ()=>{
        //   obServer.unobserve(ref.current);
        observer.disconnect();
        }
      },[])


      return  inView ? (
        <img {...props}/>
       ) : (
       <img ref={ref} src={lazyImage} className="image" alt="placeholder">
       </img>
  )
}

export default LazyImage;
