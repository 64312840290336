import { createSlice,createAsyncThunk  } from "@reduxjs/toolkit";
import axios from 'axios';
import { getAllProductsThunk,getCategoriesThunk, getSingleProductsThunk ,getFeaturedProductsThunk, getFlashSaleProductsThunk, getFeaturedTagsThunk} from "./plantsThunk";

const initialFiltersState = {
    search: '',
    mainCategory:'all',
    category:[],
    subCategory:[],
    sort: 'latest',
    sortOptions: ['latest', 'oldest', 'a-z', 'z-a','price-lowest','price-highest'],
    min_price:0,
    max_price:5000,
    price:0,
    unique:'multi',
};

const initialState = {
  showSearchResults:false,
  isShowSingleProducts:false,
  isSingleProductLoading:false,
  isSingleProductError:false,
  singleProduct:{},
  isLoading:false,
  categoryList:[],
  isActiveCategory:[],
  isProductLoading: true,
  plants: [],
  totalPlants: 0,
  numOfPages: 1,
  page: 1,
  featuredProducts: [],
  featuredTagsLevel: [],
  featuredProductsLoading:false,
  featuredCategories:[],
  mostSellingProducts: [],
  isGridView :true,
  flashSalesProducts:[],
  isHandleChangeLoading:false,
  urlName:[],
  isTopParent:'',
  setParent:[],
  ...initialFiltersState,
};

//fetching products
export const getAllProducts = createAsyncThunk('products/fetchAll',getAllProductsThunk);
//fetching categories
export const getCategories = createAsyncThunk('categories/fetchAll',getCategoriesThunk);
//fetching single product
export const getProduct = createAsyncThunk('singleProduct/fetch' ,getSingleProductsThunk);
//fetching featured products
export const getFeaturedProducts = createAsyncThunk('featuredProducts/fetchAll',getFeaturedProductsThunk);
//getting flash sale products
export const getFlashSaleProducts = createAsyncThunk('flashSaleProducts/fetchAll' ,getFlashSaleProductsThunk);
//fetching feature tags
export const getFeaturedTags = createAsyncThunk('featuredTags/fetchAll',getFeaturedTagsThunk)

const PlantsSlice = createSlice({
    name:'plants',
    initialState,
    reducers:{
      openSearchResults:(state,action)=>{
        state.isActiveCategory = action.payload;
        state.showSearchResults = true
      },
      closeSearchResults:(state)=>{
        
        state.showSearchResults = false
      },
      setUrlName :(state,action)=>{
        state.urlName = action.payload;
      },
      setTopParent :(state,action)=>{
        state.isTopParent = action.payload;
      },
      setGridView:(state)=>{
        state.isGridView =true
      },
      setListView:(state)=>{
        state.isGridView =false
      },
      ShowSingleProduct:(state)=>{
        state.isShowSingleProducts = true
      },
      closeSingleProduct:(state)=>{
        state.isShowSingleProducts = false
      },
      setCheckParent:(state,{payload})=>{
        state.setParent=payload?.value
      },
      handleMultiCategory:(state,{payload})=>{
        state.search=''
        const levelNames = {
          0: 'mainCategory',
          1: 'category',
          2: 'subCategory',
        }
        if(payload.length===0){
          const findLevelForCategory = (categories, categoryName) => {
            const findLevel = (items, currentLevel) => {
              for (let item of items) {
                if (item.name === categoryName) {
                  return currentLevel;
                }
          
                if (item.children) {
                  const childLevel = findLevel(item.children, currentLevel + 1);
                  if (childLevel !== -1) {
                    return childLevel;
                  }
                }
              }
              return -1;
            };
          
            return findLevel(categories, 0);
          };
          const level = findLevelForCategory(state.categoryList,state.urlName);
          const categoryName = levelNames[level]
          // if(categoryName === 'mainCategory'){
          //   state.mainCategory = state.urlName
          //   state.subCategory=[]
          //   state.category=[]
          // }
          if(categoryName === 'category'){
            state.category = state.urlName
            state.subCategory=[]
            state.mainCategory=state.setParent
          }
          if(categoryName === 'subCategory'){
            state.subCategory = state.urlName
            state.category=[]
            state.mainCategory=state.setParent
          }
        }else{
          

        let newState = {...state}
        state.page = 1;
        state.min_price=0;
        state.max_price=5000;
        newState.category = [];
        newState.subCategory = [];
        payload.forEach(item => {
        if (item.name === 'category') {
        newState.category.push(item.value);
       } else if (item.name === 'subCategory') {
       newState.subCategory.push(item.value);
       }
       });

       return newState;
        }


      },
      handlePriceChange:(state,{payload:{name, value,priceChange}})=>{
          state.page = 1;
          state[name] = value;
      },
      handleChange:(state,{payload:{name, value,priceChange}})=>{

        state.search=''
         state.page = 1;
         state.min_price=0;
         state.max_price=5000;
         state.category=[]
         state.subCategory=[]
        if(name==='search'){
          state.category ="all"
          state.subCategory ="all"
          state.mainCategory ='all'
        }
        
        if(name==='mainCategory'){
          state.category ="all"
          state.subCategory ="all"
          state.search=''
        }
        if(name==='category'){
          state.subCategory ="all"
          state.mainCategory ='all'
          state.search=''
        }
        if(name==='mainCategory' && value ==='all'){
          
          state.category ='all'
          state.subCategory ='all'
          state.search=''
        }
        if(name === 'subCategory'){
          state.mainCategory ='all'
          state.category ="all"
          state.search=''
        }
        state[name] = value;

      },
      handleMainChange:(state,{payload:{mainCategories, categories,subCategories}})=>{

        state.page = 1;
        state.min_price=0;
        state.max_price=5000;
        state.search=''
        state.category =categories || 'all'
        state.subCategory =subCategories|| 'all'
        state.mainCategory =mainCategories|| 'all'
      },
      setPageDefault:(state)=>{
        state.page = 1;
      },
      clearFilters:(state)=>{
        return{...state,...initialFiltersState}
      },
      changePage:(state,{payload})=>{
        state.page = payload;
      },
      setUnique:(state,{payload})=>{
        state.unique = payload;
      }
    },
    extraReducers:(builder)=>{
      builder
      //for category
      .addCase(getCategories.pending,(state)=>{
        state.isLoading= true;
      })
      .addCase(getCategories.fulfilled,(state,action)=>{
        state.isLoading =false;
        state.categoryList=action.payload;
      })
      .addCase(getCategories.rejected,(state,action)=>{
        state.isLoading =false;
      })
        //for products
      .addCase(getAllProducts.pending,(state)=>{
        state.isProductLoading = true;
      })
      .addCase(getAllProducts.fulfilled,(state,action)=>{
        state.isProductLoading = false;
        state.plants = action.payload.products;
        state.totalPlants = action.payload.totalProducts
        state.numOfPages = action.payload.numOfPages
      })
      .addCase(getAllProducts.rejected,(state)=>{
        state.isProductLoading = false;
      })
      //for single product
      .addCase(getProduct.pending,(state)=>{
        state.isSingleProductLoading = true;
      })
      .addCase(getProduct.fulfilled,(state,action)=>{
        state.isSingleProductLoading =false
        state.isSingleProductError =false
        state.singleProduct = action.payload.product
      })
      .addCase(getProduct.rejected,(state)=>{
        state.isSingleProductLoading =false
        state.isSingleProductError =true
      })
      //for featured product
      .addCase(getFeaturedProducts.pending,(state)=>{
        state.featuredProductsLoading=true
      })
      .addCase(getFeaturedProducts.fulfilled, (state, action) => {
        state.featuredProductsLoading = false;
        state.featuredProducts = action.payload;
      
        // Set of featuredTag
        const uniqueComponentsSet = new Set();
      
        state.featuredProducts?.forEach(product => {
          const { FeatureTag } = product;
      
          if (FeatureTag && Array.isArray(FeatureTag)) {
            FeatureTag
              .map(tag => tag.trim())
              .filter(tag => tag !== '') 
              .forEach(tag => uniqueComponentsSet.add(tag));
          }
        });
      
        const uniqueComponentsArray = [...uniqueComponentsSet];
  
        state.featuredCategories = uniqueComponentsArray;
      })
      
      .addCase(getFeaturedProducts.rejected,(state)=>{
        state.featuredProductsLoading=false;
      })
      .addCase(getFeaturedTags.pending,(state)=>{

      })
      .addCase(getFeaturedTags.fulfilled,(state,action)=>{
        state.featuredTagsLevel =action.payload;
      })
      .addCase(getFeaturedTags.rejected,(state,action)=>{

      })

      .addCase(getFlashSaleProducts.pending,(state,action)=>{

      })
      .addCase(getFlashSaleProducts.fulfilled,(state,action)=>{
        state.flashSalesProducts = action.payload
      })
      .addCase(getFlashSaleProducts.rejected,(state,action)=>{
       
      })
    },
})

export const {openSearchResults,closeSearchResults,setGridView,setListView,showSingleProduct,closeSingleProduct,
handleChange, setUnique,setCheckParent,handleMainChange,setFeaturedCategories, setMaxPrice,handleMultiCategory,handlePriceChange,setPageDefault,setUrlName,setTopParent} = PlantsSlice.actions;

export default PlantsSlice.reducer;