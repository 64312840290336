import { createSlice,createAsyncThunk  } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import axios from 'axios';
import { addUserLocalStorage, getAuthTokenCookie, getUserFromLocalStorage, getWishlistItemsLocalStorage, setAuthTokenCookie } from "../../utils/localStorage";
import { toast } from "react-toastify";
import { addToCartThunk, addWishlistItemThunk, couponsThunk, forgotPasswordThunk, getCartThunk, getOrderedDataThunk, getRedeemedDataThunk, getUserAddressThunk, getUserGoogleThunk, getUserThunk, getWishlistItemsLocalStorageThunk, loginUserThunk, registerUserThunk, removeWishlistItemLocalStorageThunk, updatePasswordThunk, verifyTokenThunk } from "./userThunk";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


const initialState = {
    isLoading: false,
    user:getUserFromLocalStorage(),
    myUser:false,
    cartItems:[],
    wishListItems:[],
    registerMsg:'',
    totalAmount:'',
    clearCart:'',
    cartLoading:false,
    purchasingProduct:[],
    purchasingPrice:0,
    googleRecaptcha:false,
    orderedData:[],
    userGLocaton:'',
    orderedDataLoading:false,
    isForgotPassWord:false,
    isActionSuccess:false,
    verifyLoading :true,
    verifyGoogleUserLoading :false,
    checkoutData:{
        name:'',
        country:'uae',
        state:'',
        city:'',
        houseNumber:'',
        landmark:'',
        phone:'',
        phone2:'',
        note:'',
    },
    isCouponLoading :false,
    coupons:[],
    welcomeMessageDisplayed:false,
    coins:[],
    redeemedCoupon:[],
    totalCoins:0,
    deliveryCharge:0,
    deliveryLocation:'',
    localStoreCart:[],getCartThunk
    
};

//getting user details when user login by facebook or google
export const getUser = createAsyncThunk('user/getUser',getUserThunk);
//register user
export  const registerUser = createAsyncThunk('user/registerUser',registerUserThunk)
//login user
export const loginUser = createAsyncThunk('user/login',loginUserThunk)
//login by token 
export const verifyToken = createAsyncThunk('user/token',verifyTokenThunk)
//add to cart 
export const addToCart = createAsyncThunk('user/cart',addToCartThunk) 
//get cart items from db
export const getCart = createAsyncThunk('user/getCart',getCartThunk)
//add to local storage
export const addTowishList = createAsyncThunk('user/wishlist',addWishlistItemThunk)
//get wishlist items
export const getWishList = createAsyncThunk('user/getWishlist',getWishlistItemsLocalStorageThunk)
//remove wishlist item
export const removeWishList = createAsyncThunk('user/removeWishlist',removeWishlistItemLocalStorageThunk)
//get ordered data 
export const getOrderedData = createAsyncThunk('user/ordered' ,getOrderedDataThunk)
//forgot password
export const forgotPassword = createAsyncThunk('user/reset_password',forgotPasswordThunk)
//updating password
export const updatePassword =  createAsyncThunk('user/update_password' ,updatePasswordThunk)
//get coupons
export const GetAllCoupons = createAsyncThunk('user/coupons',couponsThunk)
//redeemed coins and coupons
export const getRedeemedData =  createAsyncThunk('user/redeemed',getRedeemedDataThunk)
//getUser address
export const getUserAddress = createAsyncThunk('user/address',getUserAddressThunk)



//logout user
export const logoutUser =()=>{
        window.open(`${process.env.REACT_APP_BASE_URL}/auth/logout`, "_self");
        toast.success('Logging out...')      
}
//opening google window
export const googleWindow = () => {
      window.open("https://my-plant-store.onrender.com/auth/google", "_self");
    };
//opening facebook window
export const facebookWindow = () => {
      window.open("https://my-plant-store.onrender.com/auth/facebook", "_self");

    };

    const formatAndDispatchCartData = async (cartData) => {
        const userData = JSON.parse(localStorage.getItem('user'));
        const id = userData.id || userData._id;
      
        try {
          const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/cart/login-cart`, {
            userId: id,
            products: Array.isArray(cartData) ? cartData : [cartData],
          });
          localStorage.removeItem('cart');
          return resp.data; // Assuming you want to return some data from the response
        } catch (error) {
          return null; // Handle error or return an appropriate value
        }
      };

const UserSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        setUser: (state, { payload }) => {
            state.user = payload;
            state.myUser = true;
            addUserLocalStorage(payload);
        },
        setMyUser:(state)=>{
           state.myUser =false;
        },
        setPurchasingProduct:(state,{payload})=>{
            state.purchasingProduct = payload
        },
        setPurchasingPrice:(state,{payload})=>{
            state.purchasingPrice = payload
        },
        setRecaptcha:(state)=>{
            state.googleRecaptcha =true
        },
        setRecaptchaFalse:(state=>{
            state.googleRecaptcha=false
        }),
        updateCheckoutData: (state, action) => {
            state.checkoutData = { ...state.checkoutData, ...action.payload };
        },
        setForgotPassword :(state,action)=>{
            state.isForgotPassWord = true
        },
        setIsActionFalse:(state)=>{
            state.isActionSuccess = false
        },
        setUserGLocaton:(state,action)=>{
            state.userGLocation = action.payload 
        },
        setDeliveryLocation:(state,action)=>{
            state.deliveryLocation = action.payload.location
        },
        setDeliveryCharge:(state,action)=>{
            state.deliveryCharge = action.payload
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getUser.pending,(state)=>{
            state.isLoading =false;
            state.verifyGoogleUserLoading=true
        })
        .addCase(getUser.fulfilled,(state , {payload})=>{
            
            const user = payload;
            if(user && (user !== null || user !== undefined)){
            state.isLoading = false;
            state.user = user;
            state.myUser =true
            state.verifyGoogleUserLoading=false
            // setAuthTokenCookie(user)
            addUserLocalStorage(user);
            
            if (payload && user && user.name && !state.welcomeMessageDisplayed) {
                
                toast.success(`Welcome back ${user.name}`);
                state.welcomeMessageDisplayed = true;
                formatAndDispatchCartData(state.localStoreCart);
                
            }
            

            }
        })
        .addCase(getUser.rejected,(state)=>{
            state.isLoading =false
            state.verifyGoogleUserLoading=false
        })
        .addCase(registerUser.pending,(state)=>{
            state.isLoading =true;
        })
        .addCase(registerUser.fulfilled,(state,{payload})=>{
            state.isLoading =false;
            state.registerMsg = payload.message
            toast.success(payload.message)
        })
        .addCase(registerUser.rejected,(state)=>{
            state.isLoading = false
        })
        .addCase(loginUser.pending,(state)=>{
            state.isLoading =true
        })
        .addCase(loginUser.fulfilled,(state,{payload})=>{
            state.isLoading = false;
            
            state.myUser = true
            if (payload.token) {
            setAuthTokenCookie(payload.token);
            }
  
            if (payload.name) {
            state.user = { name: payload.name, id: payload.id };
            addUserLocalStorage(state.user);
            toast.success(`Welcome , ${payload.name}`);
            
            }
            state.isActionSuccess =true;
        })
        .addCase(loginUser.rejected,(state,{payload})=>{
            state.isLoading =false
            toast.error('Invalid Credentials...')
        })
        .addCase(verifyToken.pending,(state)=>{
            state.verifyLoading = true
        })
        .addCase(verifyToken.fulfilled,(state,{ payload })=>{
            state.verifyLoading = false
            if(payload?.message==="Token is valid"){
            state.myUser =true
        }
        })
        .addCase(verifyToken.rejected,(state)=>{
            state.verifyLoading = false
            state.myUser = false;
        })
        .addCase(addToCart.pending,(state)=>{

        })
        .addCase(addToCart.fulfilled,(state,{payload})=>{
            toast.success(payload.message)
        })
        .addCase(addToCart.rejected,(state)=>{

        })
        .addCase(getCart.pending,(state)=>{
            
            state.cartLoading = true
        })
        .addCase(getCart.fulfilled,(state,{payload})=>{
            state.cartLoading = false
            state.cartItems = payload
            if(!state.myUser){
                state.localStoreCart = payload
            }
        })
        .addCase(getCart.rejected,(state,{payload})=>{
            state.isLoading = false
           
        })
        .addCase(addTowishList.pending,(state)=>{
            
        })
        .addCase(addTowishList.fulfilled,(state,actions)=>{
        })
        .addCase(addTowishList.rejected,(state)=>{
            
        })
        .addCase(getWishList.pending,(state)=>{
            
        })
        .addCase(getWishList.fulfilled,(state,action)=>{
           state.wishListItems = action.payload
        })
        .addCase(getWishList.rejected,(state)=>{
            
        })
        .addCase(getOrderedData.pending,(state)=>{
            state.orderedDataLoading =true;
        })
        .addCase(getOrderedData.fulfilled,(state,{payload})=>{
            state.orderedDataLoading =false;
            state.orderedData = payload;
        })
        .addCase(getOrderedData.rejected,(state)=>{
            state.orderedDataLoading=false;
        })
        .addCase(updatePassword.pending,(state)=>{
            state.isActionSuccess=false
            state.isLoading=true
        })
        .addCase(updatePassword.fulfilled,(state)=>{
            state.isActionSuccess=true
            state.isLoading=false
        })
        .addCase(updatePassword.rejected,(state)=>{
            state.isActionSuccess=false
            state.isLoading=false
            
        })
        .addCase(GetAllCoupons.pending,(state)=>{
            state.isCouponLoading = true

        })
        .addCase(GetAllCoupons.fulfilled,(state,{payload})=>{
            state.isCouponLoading = false
            state.coupons =payload
            
        })
        .addCase(GetAllCoupons.rejected,(state)=>{
            state.isCouponLoading = false
            
        })
        .addCase(getRedeemedData.pending,(state)=>{

        })
        .addCase(getRedeemedData.fulfilled,(state,{payload})=>{
            state.coins=payload?.coins
            state.redeemedCoupon=payload?.redeemCoupon
            state.totalCoins=payload?.totalCoins
        })
        .addCase(getRedeemedData.rejected,(state)=>{
            
        })
        .addCase(getUserAddress.pending,(state)=>{

        })
        .addCase(getUserAddress.fulfilled,(state,action)=>{
            const { address } = action.payload || {};
        state.checkoutData = {
          ...initialState.checkoutData,
          ...address,
        };
        })
        .addCase(getUserAddress.rejected,(state)=>{
            
        })

    }
})

export const{setUser,setMyUser,setPurchasingPrice,setPurchasingProduct, setRecaptcha,updateCheckoutData,setRecaptchaFalse, setForgotPassword, 
    setIsActionFalse,setUserGLocaton,setDeliveryLocation,setDeliveryCharge} = UserSlice.actions

export default UserSlice.reducer