import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Loading from '../Loading';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

const ImageBanner2 = () => {
  const { banners, isBannerLoading } = useSelector((store) => store.banner);
  const [activeIndex, setActiveIndex] = useState(0);
  const [images, setImages] = useState([]);
  const imageRefs = useRef([]);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const len = images.length - 1;

  useEffect(() => {
    const filteredBanners = banners.filter((b) => b.typeName === 'banner2');
    setImages(filteredBanners);

    // Preload images
    const promises = filteredBanners.map((banner, index) => {
      const imageUrl = banner.photos[0]?.url;

      const image = new Image();
      image.src = imageUrl;

      // Store reference to image element
      imageRefs.current[index] = image;

      return new Promise((resolve) => {
        image.onload = resolve;
      });
    });

    Promise.all(promises).then(() => {
      // Images are loaded, you can update the state or perform other actions here
    });

    // Cleanup: remove event listeners and clear image references
    return () => {
      imageRefs.current.forEach((image) => {
        if (image) {
          image.onload = null;
        }
      });
      imageRefs.current = [];
    };
  }, [banners]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === len ? 0 : prevIndex + 1));
    }, 2000);
    return () => clearInterval(interval);
  }, [len]);

  if (images.length === 0) {
    return <Loading />;
  }

  return (
    <Wrapper>
      {images.map((banner, idx) => {
        if (isDesktopOrLaptop) {
          if (idx !== activeIndex && idx !== ((activeIndex + 1) % images.length)) {
            return null;
          }
        } else {
          if (idx !== activeIndex) {
            return null;
          }
        }
        if (banner.navigate) {
          return (
            <img
              src={banner.photos[0]?.url}
              alt='offers'
              loading='lazy'
              key={idx}
              style={{ cursor: 'pointer' }}
              draggable='false'
              onClick={() => {
                if (banner.navigate) {
                  window.location.href = banner.navigate;
                }
              }}
            />
          );
        }
        return <img src={banner.photos[0]?.url} alt='offers' draggable='false' key={idx} />;
      })}
    </Wrapper>
  );
};

export default ImageBanner2;

const Wrapper = styled.div`
 width:95%;
 margin:0 auto;
 display:flex;
 flex-direction:row;
 gap:1rem;
 margin-top:0.75rem;
 margin-bottom:0.75rem;
 img{
    width:50%;
    max-height:80px;
    object-fit:cover;
    
}
@media(max-width:992px){
  img{
    width:100%;
    margin-bottom:1rem;
}
}

`