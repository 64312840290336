import Cookies from 'js-cookie';
import axios from 'axios';


export const addUserLocalStorage = (user) =>{
    localStorage.setItem('user' , JSON.stringify(user));
};


export const removeUserLocalStorage = () =>{
    localStorage.removeItem('user');
}

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem('user');
  // Check if the result is not undefined and not null
  if (result !== undefined && result !== null) {
    try {
      const user = JSON.parse(result);
      return user;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null; // Handle the error by returning null or another default value
    }
  } else {
    return null; // Return null or another default value when result is undefined or null
  }
};




// add this while production httpOnly: true,
export const setAuthTokenCookie = (token) => {
  Cookies.set('token', token, {  secure: true, sameSite: 'strict', expires: 7 });

};

  
export  const removeAuthTokenCookie = () => {
    Cookies.remove('token');
  };

  export const getAuthTokenCookie = async () => {
    const token = await Cookies.get('token');
    // console.log('Token retrieved:', token);
    return token;
  };
  
  export const isAuthenticated = async () => {
    const token = await getAuthTokenCookie();
    // console.log(token);
    return !!token;
  }

