import React from 'react'
import Sortbar from './support/Sortbar'
import ProductList from './support/ProductList'
import {SortbarSmallScreen } from './support'


const Products = () => {
  return (
    <div>
      <Sortbar />
      <ProductList />
      <SortbarSmallScreen />
    </div>
  )
}

export default Products