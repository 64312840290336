import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {openSearchResults } from '../../feature/plants/plantsSlice';
import { Link, useNavigate } from 'react-router-dom';


const CategoryList = ({ categories }) => {
  const [categoryChildrenVisible, setCategoryChildrenVisible] = useState(false); 
  const {isScrollbarOpen,categoryList} = useSelector((store)=>store.plants)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adjustment, setAdjustment] = useState(false);
  const [adjustmentValue, setAdjustmentValue] = useState(0);

  function findParentById(data, targetId, parent = null) {
    for (const item of data) {
      if (item.id === targetId) {
        // If the current item has the target ID, return its immediate parent
        return parent;
      }
  
      if (item.children && item.children.length > 0) {
        // If the current item has children, recursively search in its children
        const foundInChild = findParentById(item.children, targetId, item);
        if (foundInChild) {
          // If found in the child, return the result
          return foundInChild;
        }
      }
    }
  
    // If the target ID is not found, return null
    return null;
  }

  const mouseClickHandler = (category, event, name) => {
    localStorage.setItem('currentPageNo', 1);
    setCategoryChildrenVisible(false);
    const propertyValues = Object.values(category);
    const topParent = findParentById(categoryList, propertyValues && propertyValues[1]); 
    const outerParent = findParentById(categoryList, topParent && topParent?.id); 
    const url = propertyValues[0];
    dispatch(openSearchResults(propertyValues));
    window.scrollTo(0, 0);
    const firstParam =outerParent?.name ? outerParent?.name :'mainCategory'
    const secondParam =topParent?.name ? topParent?.name :'category'
    const thirdParam =propertyValues[0] ? propertyValues[0] :url
    navigate(`/products/${firstParam}/${secondParam}/${thirdParam}`);
  };
  const handleMouseEnter = () => {
    setCategoryChildrenVisible(true)
  };

  const handleMouseLeave = () => {
    setCategoryChildrenVisible(false)
  };
  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position
      const currentScrollY = window.scrollY;
      if(currentScrollY > 0 && currentScrollY<107){
        setAdjustment(true);
      }else{
        setAdjustment(false);
      }
      // Check if scrolling down and category-children is open
      if (currentScrollY > 0 && categoryChildrenVisible) {
        setCategoryChildrenVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [categoryChildrenVisible]);


  useEffect(() => {
    // Function to update adjustmentValue with the current scrollY
    const handleScroll = () => {
      setAdjustmentValue(window.scrollY);
    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);
    // Detach the event listener on component unmount to avoid memory leaks
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, []);
  useEffect(()=>{
    if(isScrollbarOpen){
      setAdjustment(false)
    }
  },[isScrollbarOpen])

  return (
    <div className="category" style={{ zIndex: isScrollbarOpen ? 0 : 0 }}>
      {categories.map((category) => (
        <div key={category.id} className="category-item"  onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}>
          <div className="category-name">{category.Link ? (
          <Link to={category.Link}>{category.name}</Link>
        ) :
            <button
              type="button"
              className="main-btn"
              onClick={(event) => {
                mouseClickHandler(category, event, 'mainCategory');
                setCategoryChildrenVisible(false);
              }}
            >
              {category.name}
            </button>}
          </div>
          {categoryChildrenVisible && category.children && (
          <div className="category-children" style={(adjustment) ? { marginTop: `-${(adjustmentValue)/16}rem` } : {marginTop:0}}>

              {category.image && <div className='img-container'>  <img src={category.image?.url} alt="category" draggable="false"/></div>}
              {category.children.map((child) => (
                <div key={child.id} className="child-item">
                  <div className="child-name">
                    <button
                      type='button'
                      className='child-btn'
                      onClick={(event) => {
                        mouseClickHandler(child, event, 'category');
                        setCategoryChildrenVisible(false);
                      }}
                    >
                      {child.name}
                    </button>
                  </div>
                  {child.children && (
                    <ul className="grandchildren-list">
                      {child.children.map((grandChild) => (
                        <li key={grandChild.id}>
                          <button
                            type='button'
                            className='grandChild-btn'
                            onClick={(event) => {
                              mouseClickHandler(grandChild, event, 'subCategory');
                              setCategoryChildrenVisible(false); 
                            }}
                          >
                            {grandChild.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const HorizontalCategory = () => {
  const { categoryList } = useSelector((store) => store.plants);
  //adding blogs and about
  const arr = [
    {
      name: 'Blogs',
      Link: '/blogs',
    },
    {
      name: 'About us',
      Link: '/about',
    },
  ];
  const mergedCategoryList = [...categoryList, ...arr];
 
  return <Wrapper><CategoryList categories={mergedCategoryList} /></Wrapper>;
};

export default HorizontalCategory;


const Wrapper = styled.section`
  // overflow-x: auto;
  // white-space: nowrap;
  // position: relative;

  // /* Simple scrollbar styles */
  // &::-webkit-scrollbar {
  //   width: 6px;
  //   height: 4px;
  // }

  // &::-webkit-scrollbar-track {
  //   border-radius: 10px;
  //   background: rgba(0, 0, 0, 0.1);
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   background: var(--green-500);
  // }

  // &::-webkit-scrollbar-thumb:hover {
  //   background: var(--green-300);
  // }

  // &::-webkit-scrollbar-thumb:active {
  //   background:var(--green-300);
  // }
.category {
  display: flex;
  margin-left:0.5rem;
  text-transform:capitalize;
  align-items:flex-start;
  justify-content:flex-start ;
  
}
.child-name{
  font-weight:bold;
  margin-top:0.75rem ;
  height:30px;
  padding-left:0 !important;
}
.child-name:hover{
  color:var(--green-500);
  transition:color 0.3s;
}
.category-name{
  display: inline-block;
  height:30px;
  display: inline-block !important;
  text-align:left !important;
  margin:0 !important;
  padding:0 !important;
  font-family: "Poppins";
}
a{
  font-size:15px;
  color:black !important;
  margin-right:0.75rem;
  font-weight:500;
  letter-spacing:0;
  padding-bottom:0.20rem;
}
a:hover{
  border-bottom:3px solid var(--green-500);
}
button{
  background:transparent;
  border:0;
  padding:0;

  font-family: "Poppins";
  text-transform:capitalize;
}
.img-container{
  width:14rem !important;
  display:flex;
  border-right: 2px solid var(--grey-10);
  padding-top:2rem;
  justify-content:center;
  align-items:center;
  margin:0 !important;
  padding-left:0 !important;
  img{
    width:90%;
    height:220px;
  }

}

.main-btn{
  font-weight:500;
  font-size:15px !important;
  padding-bottom:0.25rem;
  display:inline-block;
  margin-right:1rem;
}
.child-btn{
  margin:0;
  padding:0;
  font-weight:bold !important;
  color:black !important;
  height:30px;
  border-bottom: 2px solid var(--grey-10);
}

.grandChild-btn:hover{
 border-bottom:2px solid var(--grey-50);
}
.category-item {
  position: relative;
  z-index: 1;
  cursor:pointer;
  display: inline-block !important;
  text-align:left !important;
  margin:0 !important;
  padding:0 !important;
}
.category-item:hover .category-name button.main-btn {
  border-bottom:3px solid var(--green-500);
}

.category-children {
  display:none;
  position: fixed;
  width: 95%;
  left: 50% ;
  margin-top:0.1rem;
  transform: translateX(-50%);
  z-index: 1000; 
  background: white;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

  min-height: 18rem;
  padding-top: 10px; /* Add some padding to create space */
  transition:0.2s;
  padding-left:0 !important;
}

.category-item:hover .category-children {
  display: flex; /* Show the dropdown on hover */
  pointer-events: auto;
  transition:0.2s;
}
.category-children.open {
  display: flex;
  pointer-events: auto;
  transition: 0.2s;
}

.child-item {
  border-right:2px solid var(--grey-10);
  width:14rem;
  display:flex;
  flex-direction:column;
  align-items:left;
  justify-content:flex-start;
  padding-left:20px;
  font-size:15px !important;
}

.grandchildren-list {
  list-style-type: none;
  width:100%;
  text-align:left;
}
@media(max-width:992px){
  display:none;
}
@media(max-width:1040px){
  margin-left:0rem;
  .category {
    margin-left:0rem;
    padding:0;
    
  }
}
`


