import React from 'react'
import styled from 'styled-components'
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs'

//showing star rating
const StarsReviews = ({stars ,reviewRate,show}) => {
  let NotEmpty = stars >1 ? true : false;
     const tempStars = Array.from({length:5},(_,index)=>{
      const number = index + 0.5;
      return (
        <span key={index}>
         {stars >= index + 1 ? <BsStarFill /> : stars >= number ? <BsStarHalf /> : <BsStar/>}
        </span>
      )
     })
  //showing star rating below the name of plant
  return( 
  <Wrapper>
    <div className="stars">
    {tempStars}
    </div>
    {(!show && NotEmpty) && <p className="reviews">{`${stars} ( ${reviewRate} )`}</p>}
  </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap:0.5rem;
  justify-content:flex-start;
  height:12px;
  .stars{
    display: flex;
    align-items: center;
    flex-direction:row !important;
  }
  span {
    color: var(--green-300);
    font-size: 0.75rem;
    margin-right: 0.25rem;
    
    
  }
  p {
    margin:0 !important;
    padding:0 !important;
    text-align:left;
  }
`
export default StarsReviews
