import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link, useNavigate } from 'react-router-dom';
import { PiShoppingCartDuotone } from 'react-icons/pi';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { addTowishList, getWishList } from '../../feature/user/userSlice';
import { addToCart, getCart } from '../../feature/user/userSlice';
import { openCart } from '../../feature/modal/modalSlice';
import LazyImage from './LazyImage';

const SingleFeaturedRow = ({ category, mCategory }) => {
  const { featuredProducts, plants } = useSelector((store) => store.plants);
  const { wishListItems } = useSelector((store) => store.user);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const ref = useRef();

  const screenWidthThreshold = 600; // Set threshold for screen width 

  // Check if the screen width is less than the threshold
  const isScreenSmall = screenWidth < screenWidthThreshold;

  const handleMouseEnter = () => {
    setShow(true); // Show the additional div on mouse enter
  };


  const handleMouseLeave = () => {
    setShow(false); // Hide the additional div on mouse leave
  };



  const addCartItem = (product, e) => {
    const wishListObject = JSON.parse(product);
    dispatch(openCart('cart'));
    dispatch(addToCart(wishListObject[0]));
    dispatch(getCart());
  };


  useEffect(() => {
    // Function to update the screen width in the state

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);



  let selectedProducts = [];

  if (category && category.length) {
    selectedProducts = featuredProducts?.filter((product) => {
      return product.FeatureTag.includes(category);
    });
  } else {
    // for the suggested plants in the single product page
    if (mCategory) {
      selectedProducts = featuredProducts?.filter((product) =>
        product.maincategory.some((category) => mCategory[0].includes(category.trim()))
      );
    }
  }

  const addWishlist = (product, e) => {
    const wishListObject = JSON.parse(product);

    dispatch(addTowishList(wishListObject[0]));
    dispatch(getWishList());
  };

  // checking product is in wishlist or not
  const isProductInWishlist = (Id) => {
    return (wishListItems || []).some((item) => item.productId === Id);
  };


  const handleViewAllClick = () => {
    // Navigate to the desired URL with the category as a parameter
    navigate(`/Feature/myplantstore/search/${category}`);
    localStorage.setItem('FeatureCategory', category);
    localStorage.setItem('currentPageNo', 1);
  };
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter1 = () => {
    setHovered(true);
  };

  const handleMouseLeave1 = () => {
    setHovered(false);
  };


  const aliceCarouselRef = useRef(null);

  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const handleSlideChange = (e) => {
    setIsPrevDisabled(e.isPrevSlideDisabled);
    setIsNextDisabled(e.isNextSlideDisabled);
    // Your custom slide change logic here
  };
  const moveMultipleItems = (value) => {
    const width = window.innerWidth
    let numItems;

    switch (true) {
      case width <= 959:
        numItems = 2;
        break;
      case width <= 1279:
        numItems = 3;
        break;
      case width <= 1919:
        numItems = 4;
        break;
      default:
        numItems = 5;
    }
    numItems *= value

    const currentSlide = aliceCarouselRef.current.state.activeIndex;
    const targetSlide = currentSlide + numItems;
    aliceCarouselRef.current.slideTo(targetSlide);
  };

  const currentZoomLevel = window.innerWidth / window.screen.availWidth;

  // Determine the number of items based on the current zoom level
  let itemsToShow;
  if (currentZoomLevel <= 0.8) {
    itemsToShow = 7;
  } else if (currentZoomLevel >= 0.9) {
    itemsToShow = 6;
  } else {
    itemsToShow = Math.ceil((currentZoomLevel * 10) + 1); // Adjust as needed
  }

 

  return (
    <Wrapper className='featured-row' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="category-line">
        {category ? (
          <>
            <div className="category-content" style={{ paddingTop: '10px' }}>
              <div className="green-bar"></div>
              <h5>{category}</h5>

            </div>
            <div className="category-content">
              <p style={{ cursor: 'pointer' }} onClick={handleViewAllClick} onMouseEnter={handleMouseEnter1}
                onMouseLeave={handleMouseLeave1}>View More</p>
              <svg
                onClick={handleViewAllClick}
                onMouseEnter={handleMouseEnter1}
                onMouseLeave={handleMouseLeave1}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="20"
                height="20"
                style={{ cursor: 'pointer' }} // Add cursor style for better usability
              >
                {/* Background circle */}
                {/* <circle cx="8" cy="8" r="7" fill="#00FF00" opacity={hovered ? 0.2 : 0} /> */}

                {/* Icon */}
                <path
                  d="m6.627 3.749 5 5-5 5"
                  stroke="#FFFFFF" // Change the stroke color to white
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </>
        ) : (
          <div className='also-like'>
            <h5>you may also like</h5>
            <AiOutlineArrowRight />
          </div>
        )}
      </div>

      {/* Starting alice carousel*/}




      <AliceCarousel
        // infinite
        responsive={{
          0: { items: 2 },   // 0-599px - show 2 items
          600: { items: 2 }, // 600-799px - show 2 items
          800: { items: 3 }, // 800-959px - show 3 items
          960: { items: 4 }, // 960-1199px - show 4 items
          1200: { items: 5 }, // 1200-1439px - show 5 items
          1470: { items: 6 }, // 1440px and above - show 6 items
          1800: { items: 7 },
          2000: { items: 8 },
          2600: { items: 11 },

        }}

        mouseTracking
        autoPlayInterval={1000}
        animationType="fadeout"
        animationDuration={2000}
        autoWidth={isScreenSmall}
        controlsStrategy={"responsive"}
        buttonsDisabled={selectedProducts.length <= 5}
        touchTrackingEnabled
        swipeDelta={4}
        touchMoveDefaultEvents={['onTouchMove']}
        duration={1}
        autoPlayStrategy='all'

        onSlideChanged={handleSlideChange}
        mouseDragEnabled
        ref={aliceCarouselRef}
        renderPrevButton={() => (
          show && (
            <div className={`alice-carousel__prev-btn-item ${isPrevDisabled ? 'disabled' : ''}`} onClick={() => moveMultipleItems(-1)} ></div>
          )
        )}
        renderNextButton={() => (show &&
          (<div className={`alice-carousel__next-btn-item ${isNextDisabled ? 'disabled' : ''}`} onClick={() => moveMultipleItems(1)} ></div>)
        )}
      >
        {selectedProducts.map((product, idx) => {
          const { title, _id, photos, price, pots, allLengths, flashSaleInfo, stock, scienticName, category, maincategory, subcategory, dimensions } = product;

          let sortedDimensions = dimensions ? dimensions.slice().sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price)) : [];

          const oldPrice = Number(sortedDimensions[0]?.Price);
          const newPrice = flashSaleInfo && parseFloat((oldPrice - (flashSaleInfo.OfferPercentage / 100) * oldPrice).toFixed(2));

          const wishListItems = [
            {
              dimension: sortedDimensions[0],
              image: photos[0]?.url,
              price: price,
              totalPrice: oldPrice,
              title: title,
              maincategory: maincategory,
              category: category,
              subcategory: subcategory,
              productQuantity: 1,
              productId: _id,
            },
          ];

          wishListItems.forEach((item) => {
            if (flashSaleInfo) {
              if (newPrice !== 0) {
                item.flashSalePrice = newPrice;
              }
              if (flashSaleInfo.Status === 'Unlimited') {
                if (flashSaleInfo.OfferPercentage) {
                  item.flashSaleDiscount = flashSaleInfo.OfferPercentage;
                }
                if (flashSaleInfo.Status) {
                  item.Status = flashSaleInfo.Status;
                }
              } else {
                if (flashSaleInfo.StartDate) {
                  item.flashSaleStartDate = flashSaleInfo.StartDate;
                }
                if (flashSaleInfo.StartTime) {
                  item.flashSaleStartTime = flashSaleInfo.StartTime;
                }
                if (flashSaleInfo.TimeInHours) {
                  item.flashSaleEndTime = flashSaleInfo.TimeInHours;
                }
                if (flashSaleInfo.OfferPercentage) {
                  item.flashSaleDiscount = flashSaleInfo.OfferPercentage;
                }
              }
            }
          });

          const addedFav = isProductInWishlist(_id);

          return (
            <div className="button-wrapper" key={idx}>
            
              <div className="card" key={_id}>
              
                <div className='cardContent'>
                  <button className="card-top-btns" onClick={(e) => addWishlist(JSON.stringify(wishListItems))}>
                    {addedFav ? <MdFavorite /> : <MdFavoriteBorder />}
                  </button>
                  <Link to={`/products/${_id}`} name="mainCategory" style={{ textDecoration: 'none', color: 'inherit' }}  draggable="false">
                  {photos && (
                    <div className="image-container">
                      {flashSaleInfo && (
                        <p className="percentage">
                          {' '}
                          {flashSaleInfo.OfferPercentage}
                          <span>%</span>
                          <span className="space">off</span>{' '}
                        </p>
                      )}

                      <LazyImage src={photos[0]?.url} alt={title} className="image " draggable="false" />
                      {photos.length > 1 && <img src={photos[1]?.url} alt={title} className="image second-image" draggable="false" />}
                    </div>
                  )}

                  <div class="footer-big">
                    <div class="item-1">
                      <div className="title">
                        <p className="title-text">
                          {title && (screenWidth < 480
                            ? (title.length > 40
                              ? title.substring(0, 28) + '..'
                              : title)
                            : title.length > 29
                              ? title.substring(0, 30) + '...'
                              : title)}
                        </p>
                        <span className="scientific-name" >
                          {scienticName
                            ? (screenWidth < 480
                              ? (scienticName.length > 19
                                ? scienticName.substring(0, 22) + '..'
                                : scienticName)
                              : scienticName.length > 20
                                ? scienticName.substring(0, 22) + '...'
                                : scienticName)
                            : '\u00A0'}
                        </span>
                      </div>

                    </div>
                    <div class="item-2">
                      <Link to={`/products/${_id}`} name="mainCategory" draggable="false">
                        <button class="add-to-cart-btn" >View</button>
                      </Link>

                      {newPrice && <span className="price2"><span span class="price2"> AED&nbsp;</span>{oldPrice} </span>}
                      <span class="price1"><span class="currency"> AED&nbsp;</span>{flashSaleInfo ? newPrice : oldPrice}</span>

                    </div>
                  </div>





                  <div className="footer-mobile">
                    <div className="title">
                      <p className="first"> {title && title.substring(0, 12) + '...'} </p>
                      <p className="second"> {scienticName && scienticName.substring(0, 13) + '...'} </p>
                    </div>
                    <div className="price">
                      {newPrice ? (
                        <>
                          <h5 className="first">{newPrice} <span>AED</span></h5>
                          <h5 className="second line">{oldPrice} <span>AED</span></h5>{' '}
                        </>
                      ) : (
                        <h5 className="first">{oldPrice} <span>AED</span></h5>
                      )}
                    </div>
                  </div>

                  <div className="hide-div">
                    <button className="cart" type="button">
                      <PiShoppingCartDuotone />
                      Add To Bag
                    </button>
                  </div>
                  </Link>
                </div>
              
              </div>
              
            </div>

          );
        })}


      </AliceCarousel>

    </Wrapper>
  );
};

export default SingleFeaturedRow;

const Wrapper = styled.section`
.footer-big {
  display: flex;


}

.title {
  white-space: nowrap; /* Prevent wrapping */
}

.title-text {
  margin: 0;
}

.scientific-name {
  margin: 0;
  font-size: 10px; /* Adjust font size */
  text-align: left; /* Ensures text in title-text and scientific-name starts from the left */
  padding: 0; /* Remove any default padding */
  color:black;
  display: block; /* Ensures each item starts on a new line */
  font-weight:400px;

}

.item-2 {
  display: flex;
  align-items: center;
}

.add-to-cart-btn {
  background-color: #558D4C;
  color: white;
  font-size: 12px; /* Adjust font size */
  padding: 5px 4px; /* Adjust padding */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width:70px;
}

.price1 {

  font-size: 18px;
  font-weight: 900; /* semi bold */
}

.price2 {
  font-size: 12px;
  font-weight: 600; /* semi bold */
  text-decoration: line-through; /* adds strikethrough effect */
}

.currency {
  font-size: 14px;
  font-weight: 900; /* semi bold */
}



.alice-carousel__stage-item.__cloned{
  
}

  .alice-carousel__stage-item{
   max-width:270px;
  }

width:95% ;
margin:0 auto;
position: relative;

.card {
  width: 230px;
  position: relative;

  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
  border-radius: 8px; /* Increased border radius */
  overflow: hidden; /* Ensures content stays within the border */
  padding: 8px;
  // transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds transition for transform and box-shadow */
  cursor: pointer;
}


.card:hover {
  
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Increases box shadow on hover */
}



.cardContent {
  display: flex;
  flex-direction: column;
  gap: 8px; 
}


.alice-carousel__dots{
      display: none;
}
.alice-carousel__next-btn,
.alice-carousel__prev-btn {
  width:20px;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: var(--green-500);
  cursor: pointer;
}
.alice-carousel{
  margin-left:1rem;
}
.alice-carousel__prev-btn {
  left: -3rem;
  
}

.alice-carousel__next-btn {

  right: 2rem;
}
.also-like{
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:0.35rem;
}
/* Media query to hide buttons on small screens */
@media (max-width: 800px) {
  .alice-carousel__next-btn,
  .alice-carousel__prev-btn {
    display: none;
  }
}

  img{
    width:100% !important;
   height:260px !important;
   position:relative;
  }

.image-container {
  position: relative;
  width: 100% !important;
}
.hide-div{
  display:none;
}
img{
  object-fit:cover;
}
.cart{
  display:flex;
  font-size:1rem;
  color:var(--grey-300) !important;
  font-weight: 500; 
  border-radius:1rem;
  border:none;
  background:transparent;
  
}
.cart:hover{
  color:var(--green-300)!important;
  transition: color 0.2s;
}

.button-wrapper {
  position: relative;
  display: flex;
  justify-content:left;
  align-items: center;
  width:fit-content;
  padding:10px;
}

.card-top-btns {
  position: absolute;
  top: 2.5%;
  right: 1.5%;
  font-size: 1.7rem;
  z-index: 222;
  border: none;
  background: transparent;
  display: none;
  svg {
    height:1.7rem;
    width:1.7rem;
    background: white;
    color: red;
    border-radius: 2rem;
    padding: 0.25rem;
    padding-bottom: 0.2rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  }
}

.button-wrapper:hover .card-top-btns {
  display: block !important;
}



.footer-mobile{
  display:none;
}
.second-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:260px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

}

.card:hover .second-image  {
  opacity: 1;
  /* Show the second image on hover */
}
.card:hover  .cart{
display:block;
}
.percentage{
  position:absolute;
  z-index:99;
    top:2%;
    left:1%;
    font-weight:600;
    text-transform:uppercase;
    background:yellow;
    width:87px !important;
    text-align:center;
    padding:0.25rem !important;
    font-size:14px !important;
    span{
      font-size:14px !important;
        margin:0 !important;
        padding:0 !important;
    }
    .space{
      padding-left:0.25rem !important;
    }
}


  p{
    width:100%;
    margin:0;
    text-align:left;
    margin-left:0.2rem;
    font-size:15px;
    color:black;
    letter-spacing:0;
  
  }
  .green-bar {
    width: 0.75rem;
    height: 2rem;
    background: var(--green-300);
    border-radius: 3.75px;
    margin-right: 0.5rem;
  }
  
  h5 {
    text-transform: uppercase;
    font-weight: 700 !important;
    margin: 0;
    font-size: 15px;
    color: black;
  }
  
  p {
    
    font-weight: 600 !important;
    margin: 0;
    font-size: 15px;
    color: black;
  }
  
  svg {
    font-size: 1.25rem;
    // color: var(--green-500);
  }
  .category-content {
    display: flex;
    align-items: center;
  }
  .category-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;/* Aligns items to the start and end of the container */
    margin: 2rem 0 1.25rem 0;
  }
  
  .view-more-btn {
    font-size: 1.5rem;
    color: var(--green-500);
    cursor: pointer;
  }
  

  .item-1,.item-2{
   display:flex;
   flex-direction: row;
   justify-content: space-between;
   p{
    width:70%;
   
   }
   h5{
    width:30%;
    text-align:right;
   }
   
  }
  .item-1{
    p{
      font-weight:600;
    }
    span{
      font-weight:400;
     }
  }

  .item-2{
    p{
      font-size:14px !important;
    }
   h5{
    font-size:36px !important;
   }
   h5{
    width:50%;
   }

  }
  
  .category-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0 1.25rem 0;
    gap: 0.75rem;


  }
  .old-price{
    text-decoration: line-through;
  }
  h5 {
    text-transform: uppercase;
    font-weight: 500;
    padding: 0;
    margin: 0;
    font-size:15px;
    color:black;
    letter-spacing:0;
  }

  .footer-big {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    p{
      margin-left:0 !important;
    }
  }
  .paragraph{
    width:100%;
    margin:0;
    text-align:left;
    margin-left:0.2rem;
    font-size:15px;
    color:black;
    letter-spacing:0;
  }
  .item-1,.item-2{
    margin-top:0.5rem;
   display:flex;
   flex-direction: row;
   justify-content: space-between;
   p{
    width:70%;
    
   }
   h5{
    width:30%;
    padding:0 !important;
    margin:0 !important;
    text-align:right;
    font-size:15px;
    color:black;
    letter-spacing:0;
   }
  }
  .item-1{
    h5{
      display:flex;
      gap:0.25rem;
      align-items:flex-end;
      justify-content:flex-end;
    }
    p,h5{
      font-weight:500 !important;
    }
    p{
      font-size:13px;
    }
    
  }
  
  .item-2{
   p{
    font-size:14px !important;
   }
   h5{
    font-size:36px !important;
   }
   h5{
    width:50%;
   }
  }

  .alice-carousel__prev-btn-item::after,
  .alice-carousel__next-btn-item::after {
      content: "➔"; /* New icon */
      color: var(--green-500); /* Icon color */
      font-size: 30px; /* Icon size */
      // display: none; /* Hide the icon by default */
  }
  
  
  .alice-carousel__prev-btn-item::after{
      transform: rotate(180deg);
      display: inline-block; /* Show the rotated icon */
  }
 .alice-carousel__next-btn-item::after {
    display: inline-block;
  }
  
  .alice-carousel__prev-btn-item span[data-area="<"]::after,
  .alice-carousel__next-btn-item span[data-area=">"]::after {
      content: ""; 
  }
  

  .alice-carousel__prev-btn-item.disabled::after,
  .alice-carousel__next-btn-item.disabled::after {
      opacity: 0.3;
  }

  
  @media(max-width:1200px){
    .card{
      width:210px !important;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
      border-radius: 8px; /* Increased border radius */
      overflow: hidden; /* Ensures content stays within the border */
      padding: 8px;
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds transition for transform and box-shadow */
      cursor: pointer;
    }
    img{
      width:100% !important;
     height:205px !important;
    }
    .react-multiple-carousel__arrow--left{
      display:none;
    }
    .react-multiple-carousel__arrow--right{
      display:none;
    }
  }
  @media(max-width:770px){
    .card{
      width:200px !important;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
      border-radius: 8px; /* Increased border radius */
      overflow: hidden; /* Ensures content stays within the border */
      padding: 8px;
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds transition for transform and box-shadow */
      cursor: pointer;
    }
    img{
      width:100% !important;
     height:200px !important;
    }
    .alice-carousel{
      margin-left:0;
    }
  }
  @media(max-width:992px){
    width:93% ;
  }
  @media(max-width:600px){
    .react-multi-carousel-list{
      padding-bottom:2rem;
    }
    .react-multi-carousel-track{
      padding-bottom:2rem;
    }
    .card{
      width:150px !important;
      border: 1px solid #ccc; /* Reduced border thickness */
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
      border-radius: 8px; /* Increased border radius */
      overflow: hidden; /* Ensures content stays within the border */
      padding: 8px;
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds transition for transform and box-shadow */
      cursor: pointer;
      
    }
    .button-wrapper{
      width:200px;
      margin-right:1rem;
    }
    .image-container{
      width: 140px !important;
      height: 170px !important;
    }
    img {
      width: 140px !important;
      height: 170px !important;
    }
    .footer-big{
      display: none;
      flex-direction: column;
      gap: 0.25rem;
    }
    .footer-mobile{
      display: flex;
      flex-direction: column;
      gap: 0.35rem;
      
      h5,p{
        margin:0;
        padding:0;
  
      }
  
      .title{
        margin-top:0.25rem;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        gap:0.35rem;
        
      }
      .price{
        gap:0.25rem !important;
        min-height:20px;
        align-item:center;

        h5{
          font-size:13px;
          height:20px;
          padding:0 !important;
          margin:0 !important;
          
        }
       display:flex;
       flex-direction:row;
      }
      .first{
        font-weight:600;
        color:black;
        h5{
          padding:0 !important;
          margin:0 !important;
        }
      }
      .second{
        color:var(--grey-900);
        font-size:12px !important;
      }
      .line{
        text-decoration: line-through;
      }
      .button-wrapper{
        justify-content:left;
      }
    }

    .percentage{
      width:80px !important;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    padding:0.25rem;
    font-size:13px !important;
    span{
      margin:0 !important;
  }
  .space{
    padding-left:0.25rem !important;
  }
  }
  @media(max-width:530px){
    height:325px !important;
    .button-wrapper{
      justify-content:space-between;
      max-width:165px;
    }
    
     .card{
      border: 1px solid #ccc; /* Reduced border thickness */
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
      border-radius: 8px; /* Increased border radius */
      overflow: hidden; /* Ensures content stays within the border */
      padding: 8px;
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds transition for transform and box-shadow */
      cursor: pointer;
      
     }
     .card-top-btns{
      font-size: 1.25rem;
      svg {
        padding:0.15rem;
      }
     }
     h5{
      font-size:13px;
     }
     p{
      font-size:13px;
     }
     .item-2{
      p,h5{
       font-size:12px !important;
      }
     }

  
     
  }
  @media(max-width:460px){
    .card{
      max-width:160px !important;
      border: 1px solid #ccc; /* Reduced border thickness */
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
      border-radius: 8px; /* Increased border radius */
      overflow: hidden; /* Ensures content stays within the border */
      padding: 8px;
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds transition for transform and box-shadow */
      cursor: pointer;
      
     }
     .item-1{
      h5{
        font-size:10px;
        font-weight:bold;
       }
       p{
        font-size:10px;
       }
     }
     .item-2{
      h5{
        font-size:10px !important;
      }
      p{
       font-size:11px !important;
      }
     }
     .react-multiple-carousel__arrow--left::before{
      color:transparent;
    }
    .react-multiple-carousel__arrow--right::before{
      color:transparent;
    }
  
  }
  @media(max-width:398px){
    .card {
      .image-container{
        width: 140px !important;
        height: 170px !important;
        border: 1px solid #ccc; /* Reduced border thickness */
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
        border-radius: 8px; /* Increased border radius */
        overflow: hidden; /* Ensures content stays within the border */
        padding: 8px;
        transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds transition for transform and box-shadow */
        cursor: pointer;
      }
      img {
        width: 140px !important;
        height: 170px !important;
      }import SingleFeaturedRow from './SingleFeaturedRow';

    }
    .category-line{
      margin:0rem 0 1.25rem 0;
    }
  }
  .carousel-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .view-more-btn {
    font-size: 1.5rem;
    color: var(--green-500);
    cursor: pointer;
    margin-left: 1rem; /* Adjust the margin as needed */
  }
`