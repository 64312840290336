import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    isOpen:false,
    isOpenTopSearchbar:false,
    isOpenCart:false,
    isActiveTab:(''),
    isScrollbarOpen:false,
    isBannerOpen:true,
    isFilterOpen:false,
};

const modalSlice = createSlice({
    name:'modal',
    initialState,
    reducers:{
        //opening sidebar
        openModal:(state ,action) =>{
            state.isScrollbarOpen=false;
            state.isOpen = true;
            
    
        },
        //closing sidebar
        closeModal:(state ,action) =>{
            state.isOpen = false;
    
        },
        //opening top searchbar 
        openSearchTopSearchbar:(state,action) =>{
            state.isOpenTopSearchbar = true;

        },
         //closing top searchbar 
        closeSearchTopSearchbar:(state,action) =>{
            state.isOpenTopSearchbar = false;

        },
        //opening cart/wishlist
        openCart:(state,action) =>{
            state.isOpenCart=true
            state.isActiveTab ='cart'
            state.isScrollbarOpen=false;
        },
         //closing cart/wishlist
        closeCart:(state,action) =>{
            state.isOpenCart=false;
            state.isActiveTab =''
        },
        setActiveTab:(state,action)=>{
            state.isActiveTab=action.payload;
        },
        openScrollbar:(state)=>{
            state.isScrollbarOpen=true;
        },
        closeScrollbar:(state)=>{
            state.isScrollbarOpen=false;
        },
        openBanner :(state)=>{
            state.isBannerOpen = true
        },
        closeBanner :(state)=>{
            state.isBannerOpen = false
        },
          //opening sidebar
          openFilter:(state) =>{
            state.isFilterOpen = true;
            
    
        },
        //closing sidebar
        closeFilter:(state ) =>{
            state.isFilterOpen = false;
    
        },
    },
    extraReducers:{

    },
})

export const {openModal ,closeModal,openSearchTopSearchbar, closeSearchTopSearchbar,openCart, closeCart,setActiveTab,
    openScrollbar,closeScrollbar,openBanner,closeBanner,  openFilter, closeFilter } = modalSlice.actions;

export default modalSlice.reducer;