import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { BsChevronRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleChange, openSearchResults } from '../../feature/plants/plantsSlice';
import { closeModal } from '../../feature/modal/modalSlice';

const Dropdown = () => {
  const [activeMenu, setActiveMenu] = useState([]);
  const [openChild, setOpenChild] = useState(null); 
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { categoryList } = useSelector((store) => store.plants);

  const dropdownRef = useRef();

  useEffect(() => {
    const handleTouchMove = (e) => {
      e.stopPropagation();
    };

    const dropdownElement = dropdownRef.current;

    if (dropdownElement) {
      dropdownElement.addEventListener('touchmove', handleTouchMove);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  
  const newCategoryList = [
    ...categoryList,
    {
      name: 'Blogs',
      Link: '/blogs',
    },
    {
      name: 'About us',
      Link: '/about',
    },
    
  ];

  const [rotate, setRotate] = useState({});
  
  const handleClick = (category, level) => {
    localStorage.setItem('currentPageNo', 1);
    if (activeMenu.includes(category.id)) {
      setActiveMenu(activeMenu.filter((id) => id !== category.id)); 
      setRotate({ ...rotate, [category.id]: false }); 
      if (!category.children) {
        setOpenChild(null); 
      }
    } else {
      if (level === 0) {
        setActiveMenu([category.id]);
        setRotate({ [category.id]: true }); 
      } else {
        setActiveMenu([...activeMenu, category.id]); 
        setRotate({ ...rotate, [category.id]: true }); 
      }
      if (category.children) {
        setOpenChild(category.id); 
      }
    }
  };
  
  

  const levelNames = {
    0: 'mainCategory',
    1: 'category',
    2: 'subCategory',
  };
  function findParentById(data, targetId, parent = null) {
    for (const item of data) {
      if (item.id === targetId) {
        // If the current item has the target ID, return its immediate parent
        return parent;
      }
  
      if (item.children && item.children.length > 0) {
        // If the current item has children, recursively search in its children
        const foundInChild = findParentById(item.children, targetId, item);
        if (foundInChild) {
          // If found in the child, return the result
          return foundInChild;
        }
      }
    }
  
    // If the target ID is not found, return null
    return null;
  }
  const mouseClickHandler = (category, event, name) => {
    localStorage.setItem('currentPageNo', 1);
    if (category.Link) {
      navigate(category.Link);
      dispatch(closeModal());
    } else {
      const propertyValues = Object.values(category);
      const url = propertyValues[0];
      // dispatch(handleChange({ name: name, value: url }));
      // dispatch(openSearchResults(propertyValues));
      // navigate(`/products/category/${url}`);
      // navigate(`/products/mainCategory/category/${url}`);
      // dispatch(closeModal());
    const topParent = findParentById(categoryList, propertyValues && propertyValues[1]); 
    const outerParent = findParentById(categoryList, topParent && topParent?.id); 
    dispatch(openSearchResults(propertyValues));
    window.scrollTo(0, 0);
    const firstParam =outerParent?.name ? outerParent?.name :'mainCategory'
    const secondParam =topParent?.name ? topParent?.name :'category'
    const thirdParam =propertyValues[0] ? propertyValues[0] :url
    navigate(`/products/${firstParam}/${secondParam}/${thirdParam}`);
    dispatch(closeModal());
    }
  };
  

  const renderCategories = (categories, level = 0) => { 
    return categories.map((category) => (
      <div key={category.id} className="item">
        <div className={`main-level main-level-${level}`}>
        <button name={`level-${level}`}
          className={`menu-button level-${level}`}  onClick={(e)=>  mouseClickHandler(category, e, levelNames[level])}
       
        >
          <p>{category.name}</p>
        </button>
        <span onClick={(event) => { if (category.children) { handleClick(category, level); 
           } else {
                   mouseClickHandler(category, event, levelNames[level]);
                  }
             }} style={{ transform: rotate[category.id] ? 'rotate(90deg)' : 'none' }} >{category.children && <BsChevronRight />}</span>
        </div>
        {category.children && (
          <div className={`submenus ${activeMenu.includes(category.id) ? 'open' : ''}`}>{renderCategories(category.children, level + 1)}</div>
        )}
      </div>
    ));
  };
  
  return <Wrapper openChild={openChild}>{renderCategories(newCategoryList)}</Wrapper>;
  
  
}  

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  position: fixed;
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  overflow: auto;
  position: relative;
  padding-top: 1rem;
  margin-left: 0.5rem;
  button {
    display: flex;
    justify-content: space-between;
    background: transparent;
    border: none;
    margin-right: 1rem;
  }
  span {
    transition: transform 0.3s ease;
    color:var(--grey-400);
    cursor:pointer;
  }
  span:hover{
    color:var(--green-300);
  }
  .main-level-1{

  }
.main-level{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  width:90%;
}
  
  p,
  button {
    color: var(--grey-900);
    font-family: inherit;
  }
  .menu-button {
    display: flex;
    justify-content: space-between;
    background: transparent;
    border: none;
    margin-right: 1rem;
    p {
      font-weight: 15px !important;
    }
    span {
      transition: transform 0.3s ease;
      
      transform: ${(props) => (props.openChild === props.categoryId ? 'rotate(90deg)' : 'rotate(0)')}; // Rotate based on the openChild state
    }
  }
  .level-0 {
    margin-bottom:1rem;
    font-weight:500;
  }
  .level-1 {
    width: 80%;
    margin-left: 1rem;
 
  }
  .level-2 {
    margin-left: 1.85rem;
    margin-bottom:0.75rem;
    margin-top:0.75rem;
    p{
      color:var(--grey-600);
    }
  
  }
  .item {
    display: flex;
    flex-direction: column;

  }
  p {
    padding: 0;
    margin: 0;
    font-size: 17px;
    float: left;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
  p:hover {
    color: var(--green-300);
    transition: color 0.3s;
  }

  .submenus {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    margin-bottom:0.5rem;
    padding: 1rem;
  }
  
  .submenus.open {
    max-height: 500px; /* you can adjust this value */
  }
  
  .back-button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
    p {
      margin: 0;
    }
  }
  @media screen and (max-width: 992px) {
    margin-left: 0.25rem;
  
    p {
      font-size: 14.5px !important;
    }
    .submenus {
      padding: 0;
      gap: 0.1rem;
    }
    .back-button {
      font-size: 15px;
    }
    .back {
      width: 80px;
    }
  }
`;

export default Dropdown;
