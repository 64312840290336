import axios from 'axios';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveryLocation } from '../../feature/user/userSlice';

const DeliveryLocation = ({ cartPage }) => {
  const { isOpenCart } = useSelector((store) => store.modal);
  const { deliveryLocation } = useSelector((store) => store.user);
  const [locationsData, setLocationsData] = useState([]);
  const [locations, setLocations] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(true); // State to track if input is empty
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const openSidebar = () => {
    setIsOpen(true);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputEmpty(inputValue.trim() === ''); // Check if input value is empty
    setLocations(inputValue); // Update locations state
  };

  const getLocations = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-locations`);
      setLocationsData(resp.data);
      setLoading(false);
      return resp.data;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const data = await getLocations();
    };
    fetchData();
  }, []);

  return (
    <>
      {isOpen && <GlobalStyle />}
      <Wrapper className='location-check-div'>
        <div className='paragraph'>Delivers to:</div>
        <div className='location-check-container'>
          <input
            type='text'
            className={`location-text ${(!deliveryLocation && inputEmpty) ? 'empty' : ''}`}
            placeholder='Delivery Location'
            value={
              locations && locations.location
                ? `To ${locations.location} in ${locations.days} days`
                : deliveryLocation
                  ? `To ${deliveryLocation}`
                  : ''
            }
            onChange={handleInputChange}
            style={{
              fontFamily: 'inherit',
              height: '44px',
              padding: '5px',
              border: `1px solid ${(!deliveryLocation && inputEmpty) ? 'red' : 'var(--grey-100)'}`,
              borderRadius: '5px',
              color: 'var(--grey-500)',
              fontSize: '16px',
              fontFamily: "'Poppins'"
            }}
          />




          <button type='button' className='location-btn' onClick={openSidebar}>
            {cartPage ? 'Choose' : 'Check'}
          </button>
        </div>
      </Wrapper>
      <SidebarWrapper>
        <aside className={`${isOpen ? 'sidebar show-sidebar' : 'sidebar'}`}>
          <div className='content-div'>
            <div className="sidebar-header">
              <p>Select Locations</p>
              <button className="close-btn" type="submit" onClick={closeSidebar}>
                <VscClose />
              </button>
            </div>
            <div className='full-height'>
              {locationsData && locationsData.map((loc, idx) => {
                return (
                  <div key={idx} className='locations' onClick={() => { setLocations(loc); dispatch(setDeliveryLocation(loc)); setIsOpen(false); }}>
                    <p>{loc.location}</p>
                  </div>
                )
              })}

            </div>
          </div>
        </aside>
      </SidebarWrapper>
    </>
  );
};

export default DeliveryLocation;

const SidebarWrapper = styled.aside`
text-align: center;
overflow: hidden;
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem;
  background:var(--green-300);
  letter-spacing: 1px;
  padding-left:1rem;
}
.sidebar-header {
  display:flex;
  justify-content:space-between;
  p{
    color:white;
    font-weight:bold;
    font-family:inherit;
    line-spacing:1px;
    text-align:left;
    margin:0.45rem 0 !important;
  }
}
.locations{
  width:90%;
  margin:0 auto;
  padding:0.5rem 0rem;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  border-bottom:1.4px solid var(--grey-100);
  cursor:pointer;
  p{
      padding:0;
      margin:0;
      text-align:left;
      text-transform:capitalize;
      color:var(--grey-600);
      font-size:15px;
     
  }
}
.locations:hover{
  p{
      transition:color 0.3s;
      color:black;
  }
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  transition: var(--transition);
  cursor: pointer;
  color: var(--clr-red-dark);
  
}
.sidebar-login{
  border-bottom:2px solid var(--green-500);
}
.close-btn:hover {
  color:white;
}
.btn-login{
  font-size:14.5px;
  background:transparent;
  border:none;
  color:var(--grey-500);
  padding-left:0;
  svg{
    padding-top:0.25rem;
  }
}
.btn-login:hover{
  color:var(--green-300);
  transition:color 0.3s;
}

.logo {
  justify-self: center;
  height: 45px;
}
.first-link{
  display:flex;
  justify-content:space-between;
}
.links {
  margin-bottom: 2rem;
}
.links a {
  display: block;
  text-align: left;
  font-size: 1rem;
  text-transform: capitalize;
  padding:1rem 0.5rem;
  color: var(--clr-grey-3);
  transition: var(--transition);
  letter-spacing: var(--spacing);
}

.links a:hover {
  padding-left:.5rem;
  padding-left: 2rem;
  background: var(--green-500);
  color: var(--clr-grey-2);
  .arrow{
    color:white;
    rotate: 90deg;
  }
}
.second-div li{
  width:80%;
}
.second-child{
  margin-left:1.5rem;
}
.second-child a{
  display:flex;
  justify-content:space-between;
}
.show{
  display:flex;

}
.full-height{
  // background-color: #FFF !important;
  overflow:auto;
  max-height:90%;
  scrollbar-width: auto;
  scrollbar-color: auto; scrollbar-width: auto;
  
}
show-99{
  display:flex;
}
.sidebar {
  position:fixed;
  top: 0;
  right: 0;
  bottom:0;
  width: 100%;
  height: 100%;
  box-shadow:var(--shadow-4);
  background-color: red;
  background-color: rgba(0, 0, 0, 0.5);
  transition: var(--transition);
  transform: translate(100%);
  z-index: 0;
  overflow-y:hidden;
}
.show-sidebar {
  transform: translate(0);
  z-index: 999;
}
.content-div{
  margin-left:auto;
  max-width:350px;
  background-color: #FFF !important;
  height:100%;
}

.cart-btn-wrapper {
  margin: 2rem auto;
}
@media screen and (max-width: 580px) {
  .sidebar {
    width:75%;
  }
  .sidebar-header p{
    font-size:14px;
  }
  .close-btn{
    padding-right:0;
    font-size:21px;
    margin-right:0;
  }
  
}

.first{
  
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 175px;
    margin-left: -15px;
  }
}
.nav-links {
  display:flex;
  justify-content:space-between;
  margin:0.5rem;

  position:relative;
}
.first{
  order:1;
}
.scroll-event{

}
.flag-div{
  max-width:9rem;
  flex-direction: row;
  position:absolute;
  z-index:1;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px grey;
  display:none;
  right:0rem;
}
a:hover{
  color:var(--green-300);
  transition: color 0.5s;
}
.dropdown-main:hover + .flag-div,
.flag-div:hover {
display: block;
z-index: 999;
}
.dropdown-main{
  display:flex;
  justify-content:center;
  align-items:center;
  gap:1.5rem;
}
.dropdown{
  width:10rem;
  background:white;
  display:flex;
  align-items:center;
  padding:0rem 0rem 0rem  0.25rem;
  
}
.dropdown:hover{
  background-color:var(--green-300);
  
}
a{
  color:var(--grey-700);
}
.dropdown:hover .country-name {
  font-weight: bold;
}
.flag-div button{
  width:100%;
  background:white;
  border:none;
  cursor:pointer;
}
.country{
  display:flex;
  padding-left:1rem;
  align-items:center;
  gap:1rem;
}
.country :hover{
  font-weight:1rem;
}
img{
  width:1.9rem;
}
.down-sidebar{
position: fixed;
bottom: 0;
text-align: center;
right: 0;
left:0;
width:100%;
display:flex;
justify-content:space-between;
border-top:2px solid var(--green-500);
padding:0.5rem;
z-index:999;

background:white;
padding-bottom:0.75rem;
a{
  color:var(--grey-500);
  font-size:14.5px;
}
}
`

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden !important;
    padding-right: 14.75px;
  }
  @media(max-width:560px){
   body{
    overflow: hidden;
    padding-right:0px !important;
    scroll-y:disabled;
   }
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  .paragraph {
    text-align: left;
  }

  .location-check-container {
    margin: 0.15rem 0;
    display: flex;
    justify-content: space-between;
    height: 2.5rem;
    gap: 0.5rem;

    input {
      border: 1.4px solid ${props => props.empty ? 'red' : 'var(--grey-100)'};
      padding-left: 0.5rem;
      flex: 1;
      border-radius: 5px;
      @media(max-width:580px) {
        width:80%;
      }
    }
    }
  }

  .location-btn {
    border: none;
    border-radius: 5px;
    background: #23398e;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }

  @media(max-width:580px) {
    .paragraph {
      font-size: 14px;
    }
    .location-check-container {
      height: 34px;
    }
    .location-text {
      font-size: 13px;
      
    }
    .location-btn {
      font-size: 13px;
    }
  }
`;
