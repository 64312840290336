import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, handleChange,handleMultiCategory, setCheckParent, setUnique} from "../../feature/plants/plantsSlice";
import { useLocation } from 'react-router-dom';


//the check boxes for filter (bigger screen)
const DropdownProduct = ({ formCheck, onCheckboxChange,pageNo, }) => {
  const {sort,min_price,max_price,categoryList} =useSelector((store)=>store.plants)

  const location = useLocation();
 const pathParts = location.pathname.split('/');
 const lastPart = pathParts.pop();
 const decodedCategoryName = decodeURIComponent(lastPart);
 const [initialRender, setInitialRender] = useState(true);

const dispatch = useDispatch()
const navigate =useNavigate()

const [checkedItems, setCheckedItems] = useState([]);
let level =0;


  const previousPart = pathParts.pop();
  const thirdPart = pathParts.pop();

  // Decode the category names
  const subCategoryName = decodeURIComponent(lastPart);
  const categoryName = decodeURIComponent(previousPart);
  const mainCategoryName = decodeURIComponent(thirdPart);



  let mainCategories 
  let subCategories
  let categories
  let subArray =[]
  if(mainCategoryName ==='mainCategory' && categoryName ==='category'){
    mainCategories=subCategoryName
    categories=''
    subCategories=''
  }else if(mainCategoryName ==='mainCategory' && categoryName !=='category' ){
    mainCategories=categoryName
    categories=subCategoryName
    subCategories=''
  }else if(mainCategoryName !=='mainCategory' && categoryName !=='category' ){
    mainCategories=mainCategoryName
    categories=categoryName
    subCategories=subCategoryName
  }
  

const findLevelAndParentForCategory = (categories, categoryName) => {
  const findLevelAndParent = (items, currentLevel, parentName) => {
    for (let item of items) {
      if (item.id === categoryName) {
        return { level: currentLevel, parentName };
      }
      if (item.children) {
        const childResult = findLevelAndParent(item.children, currentLevel + 1, item.name);
        if (childResult.level !== -1) {
          return childResult;
        }
      }
    }
    return { level: -1, parentName: null };
  };
  return findLevelAndParent(categories, 0, null);
};


const findSubArray =(arr, searchString) => {
  for (const item of arr) {
    if (item.name === searchString) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const result = findSubArray(item.children, searchString);
      if (result) {
        return item; 
      }
    }
  }
  return null;
}



function getChildren(name, list) {
  for (let item of list) {
    if (item.name === name && item.children) {
      return item.children;
    } else if (item.children) {
      let result = getChildren(name, item.children);
      if (result) return result;
    }
  }
  return null;
}

const [selecteClicked,setSelecteClicked]=useState(false)

const handleSelect = (categoryLevel, event, name) => {
  const propertyValues = Object.values(categoryLevel);
  const url = propertyValues[0];
  const categoryType = event.target.name;
  let categoryTypeName;
  
  if (categoryType === 'level-0') {
    categoryTypeName = 'category';
  } else {
    categoryTypeName = 'subCategory';
  }

  const topParent =findLevelAndParentForCategory(categoryList, categoryLevel.id)
  
   const seedsChildren = getChildren(topParent.parentName, categoryList);
  
  if (event.target.checked) {
    setCheckedItems(prevItems => {
    // Filter out the parent and children of categoryLevel.name
    const filteredItems = prevItems.filter(item => {
      if (item.name === 'category' && item.value === categoryLevel.name) {
        return false; // Remove the parent
      }
      if (item.name === 'subCategory' && categoryLevel.children) {
        return !categoryLevel.children.some(child => child.name === item.value);
      }
      return true;
    });


    // Add the new item
    let newItems = [...filteredItems, { name: categoryTypeName, value: categoryLevel.name }];
    let seedsChildrenValues = seedsChildren.map(child => child.name);

     // Check if newItems contains an item with value equal to parent
     let containsParent = newItems.some(item => item.value === topParent.parentName);

     // Check if newItems contains any item in seedsChildren
     let containsChild = newItems.some(item => seedsChildrenValues.includes(item.value));
     // If both conditions are met, remove the item with value equal to parent
     if (containsParent && containsChild) {
     newItems = newItems.filter(item => item.value !== topParent.parentName);
     
    }
    // Check if checkedItems.length becomes 0 and run the action
    if (newItems.length === 0) {
      dispatch(setUnique('multi'));
      dispatch(handleChange({ name: 'mainCategory', value: mainCategories  }));
    }
    dispatch(setUnique('multi'));
    dispatch(handleMultiCategory(newItems));

    return newItems;
  });
  } else {
    setCheckedItems(prevItems => {
      const newItems = prevItems.filter((i) => i.value !== categoryLevel.name);
      dispatch(setUnique('multi'));
      dispatch(handleMultiCategory(newItems));

      // Check if checkedItems.length becomes 0 and run the action
      if (newItems.length === 0) {
        dispatch(setUnique('multi'));
        dispatch(handleChange({ name: 'mainCategory', value: mainCategories }));
      }

      return newItems;
    });
  }
  setSelecteClicked(!selecteClicked)
};



//for initial checking of checkbox 
const findLevelForCategory = (categories, categoryName) => {
  const findLevel = (items, currentLevel) => {
    for (let item of items) {
      if (item.name.trim() === categoryName.trim()) {
        return currentLevel;
      }

      if (item.children) {
        const childLevel = findLevel(item.children, currentLevel + 1);
        if (childLevel !== -1) {
          return childLevel;
        }
      }
    }
    return -1;
  };

  return findLevel(categories, 0);
};




subArray = findSubArray(categoryList,  mainCategories );
const [isOpen, setIsOpen] = useState(false);
const toggleOpen = () => setIsOpen(!isOpen);

const [expandedItems, setExpandedItems] = useState([]);
const toggleItem = (itemName) => {
  if (expandedItems.includes(itemName)) {
    setExpandedItems(expandedItems.filter((item) => item !== itemName));
  } else {
    setExpandedItems([...expandedItems, itemName]);

  }
};

useEffect(() => {
  if(!initialRender){
  dispatch(getAllProducts());
   }
   setInitialRender(false);
}, [dispatch,pageNo,min_price,max_price,sort,selecteClicked]);


useEffect(() => {
  const levelOfItem = findLevelForCategory(categoryList, decodedCategoryName);
  let catName
  if (levelOfItem===1) {
    setExpandedItems([])
    catName='category'
    setExpandedItems([categories])
  }
  if (levelOfItem===2) {
    setExpandedItems([])
    catName='subCategory'
    setExpandedItems([categories])
  }
  if (levelOfItem !== -1) {
    setCheckedItems([{ name: catName || `level-${levelOfItem}`, value: decodedCategoryName }]);
  }
  dispatch(setCheckParent({ name: 'mainCategory', value: mainCategories }));
}, [decodedCategoryName,mainCategories]);




const renderSubList = (children, level) => {
  return (
    <ul className="sub-list">
      {children.map((child) => (
        <li key={child.id}>
          <div className="list-item">
          <div className='checkbox-div'>
            <input
              type="checkbox"
              id={child.name}
              value={child.name}
              checked={checkedItems.some(item => item.value === child.name)}
              name={`level-${level}`} onChange={(event) => handleSelect(child, event, 1)} style={{ marginLeft: `${level * 20}px` }} className={`level:${level}`} />
            <label htmlFor={child.name}>
              {child.name}
            </label>
            </div>
            {child.children && child.children.length > 0 && (
              <span className={`chevron ${expandedItems.includes(child.name) ? 'rotate' : ''}`} onClick={() => toggleItem(child.name)}  >
                <BsChevronRight />
              </span>
            )}
          </div>
          {expandedItems.includes(child.name) &&
            child.children &&
            renderSubList(child.children, level + 1)}
        </li>
      ))}
    </ul>
  );
};

return (
  <Wrapper>
    {subArray && subArray.children ? (
      <ul className="main-list">
        {subArray.children.map((item) => (
          <li key={item.id}>
            <div className="list-item">
              <div className='checkbox-div'>
              <input
                type="checkbox"
                id={item.name}
                value={item.name}
                name={`level-${level}`}
                checked={checkedItems.some(it => it.value === item.name)}
                onChange={(event) => handleSelect(item, event, 1)} className={`level-${level}`}
                onClick={() => toggleItem(item.name)}
              />
              <label htmlFor={item.name}>{item.name}</label>
              </div>
              {item.children && item.children.length > 0 && (
                <span
                  className={`chevron ${expandedItems.includes(item.name) ? 'rotates' : ''}`}
                  onClick={() => toggleItem(item.name)}
                >
                  <BsChevronRight onClick={toggleOpen} style={{ transform: `${expandedItems.includes(item.name) ? 'rotate(90deg)' : 'none'}` }} />
                </span>
              )}
            </div>
            
            {expandedItems.includes(item.name) && item.children && renderSubList(item.children, 1)} </li> ))}
          </ul>) : (
      <div></div>
    )}
  </Wrapper>
);

}



export default DropdownProduct;


const Wrapper = styled.div`

.chevron{
  rotate(90deg);
}
label,input{
  text-transform:capitalize;
}
.list-item {
  width:95%;
  display:flex;
  justify-content:space-between;
  padding-bottom:0.25rem;

}
checkbox,label{
  cursor:pointer;
}
input{
  cursor:pointer;
  accent-color:green;
}
.chevron{
  display:flex;
  justify-content:center;
  align-items:center;
}
.level-3 {
  padding-left: 2rem;
}
.checkbox-div{
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding-bottom:0.25rem;
}
.main-list{
  font-size:16px;
  font-weight:500;
  color:black;
}
.sub-list{
  font-size:15px;
  font-weight:400;
  color:black;
}

@media(max-width:768px){
  font-size:14px;
}
@media(max-width:580px){
  li{
    margin-bottom:0.5rem;
  }
  .list-item{
    margin-bottom:0.5rem;
  }
}
`;
