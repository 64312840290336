import React, { useEffect, useState } from 'react'
import EmptyWishlist from '../../assets/images/notFoundImg.png'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { closeCart, openCart } from '../../feature/modal/modalSlice'
import { Link, useNavigate } from 'react-router-dom'
import { IoClose } from "react-icons/io5";
import { getWishlistItemsLocalStorage, removeWishlistItemLocalStorage } from '../../utils/localStorage'
import { addToCart, getWishList, removeWishList } from '../../feature/user/userSlice'
import {PiShoppingCartDuotone} from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { checkTime } from '../../utils/helpers'
import { GrFormClose } from "react-icons/gr";

//wishlist container
const WishListContent = () => {
  const {wishListItems} = useSelector((store)=>store.user)
  
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const toggle =()=>{
    dispatch(closeCart());
    navigate('/');
  }
  const toggleClose = ()=>{
    dispatch(closeCart());
  }
  const removeItem = async (id,dimensionId) => {
    try {
       dispatch(removeWishList({id,dimensionId}))
       dispatch(getWishList())
    } catch (error) {
      // console.error("Error removing item:", error);
    }
  }

  const addCart = async (data) => {
    // Extract the required properties from the wishlist object
    const {wishlist} = data;

  // Destructure the wishlist object
  const {
    title,
    productId,
    maincategory,
    subcategory,
    category,
    totalPrice,
    dimension,
    image,
    Status,
    productQuantity,
    flashSalePrice,
    flashSaleStartDate,
    flashSaleStartTime,
    flashSaleEndTime,
    flashSaleDiscount,
  } = wishlist;

  // Create a new object with only the properties that are not undefined, null, or empty strings
  const cartItem = {
    title,
    productId,
    maincategory,
    subcategory,
    category,
    totalPrice,
    dimension,
    image,
    productQuantity,
    
  };

  if(Status === "Unlimited"){
    if (flashSaleDiscount) {
      cartItem.flashSaleDiscount = flashSaleDiscount;
    }
    if (flashSalePrice) {
      cartItem.flashSalePrice = flashSalePrice;
    }
    if (flashSaleDiscount) {
      cartItem.Status = Status;
    }
  }else{
  if (flashSalePrice) {
    cartItem.flashSalePrice = flashSalePrice;
  }

  if (flashSaleStartDate) {
    cartItem.flashSaleStartDate = flashSaleStartDate;
  }

  if (flashSaleStartTime) {
    cartItem.flashSaleStartTime = flashSaleStartTime;
  }

  if (flashSaleEndTime) {
    cartItem.flashSaleEndTime = flashSaleEndTime;
  }

  if (flashSaleDiscount) {
    cartItem.flashSaleDiscount = flashSaleDiscount;
  }
}

  dispatch(addToCart(cartItem));
  dispatch(openCart('cart'));
  await removeItem(productId);
  dispatch(getWishList());
  };


  if(wishListItems && wishListItems.length===0){
    return(
       <Wrapper>
        <h4>Your wishlist is empty</h4>
        <button className='btn' onClick={toggle}>Add now</button>
       </Wrapper>
    )
  }
  return (
    
    <WrapperNext>
      <div className='scroll-wishlist'>
       {wishListItems &&
        wishListItems.map((wishlist, id) => {
          const { image, totalPrice, productQuantity, potName, size, title ,Status,userId,flashSalePrice,productId,flashSaleStartDate,flashSaleStartTime,
            flashSaleEndTime,flashSaleDiscount,dimension}= wishlist;
            let flashSaleActive =  false
            
            if(Status && Status === "Unlimited"){
              flashSaleActive= true
            }else{
              flashSaleActive= flashSaleStartDate ? checkTime(flashSaleStartDate, flashSaleEndTime,flashSaleStartTime,) : false;
            }

          return (
            <>
            <div className='cart-container' key={id}>
            <Link to={`/products/${productId}`} key={id} className="cart-link" onClick={toggleClose}>
              <img src={image} alt='wishlist item' draggable="false"/>
              <div className='details'>
                <p className='title'>{title}</p>
                <p className='title-sub'>
               {Object.keys(dimension)
                     .filter(key => key.startsWith("Value"))
                     .map(key => dimension[key])
                     .filter(value => value !== undefined && value !== null && value !== '')
                     .join(" / ")}
              </p>
                {/* <p><span className='qty'>Qty:</span> </p> */}
                <div className='inline-divs'><span className='qty'>{productQuantity}</span> <GrFormClose />{flashSaleActive ? <p className='price'>{parseFloat(flashSalePrice?.toFixed(2))}<span>AED</span></p> : <p className='price'>{parseFloat(totalPrice?.toFixed(2))} <span>AED</span></p> }</div>
                </div>
             </Link>
             <div className='align-right'>
             <button type='button' onClick={()=>addCart({wishlist})} className='cart-btn'><PiShoppingCartDuotone/></button>
             <button  type='button' className='delete-btn ' onClick={()=>removeItem(productId,dimension?._id)}><RiDeleteBin6Line /></button>
            </div>
            </div>
            </>
          );
        })}
        </div>
    </WrapperNext>
  )
}

export default WishListContent

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 50vh;
height: auto; /* Allow the container to expand based on content */
padding: 20px;
text-align: center;
h4{
  font-size:2rem;
  text-decoration:none;
}
`


const WrapperNext = styled.div`

img {
  padding-top:1rem;
  width: 80px;
  height: 100px;
}

.cart-container {
  width:95%;
  display: flex;
  margin-left: 1rem;
  gap: 1rem;
  padding-bottom:0.75rem;
  border-bottom:1px solid var(--grey-100);
  position:relative;
}
.cart-link{
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction:row; 
  width:100%;
}
.cart-details {
  display: flex;
  flex-direction: column; 
  align-items: left !important; 
  gap: 1rem;
  
}
span {
  color:var(--grey-500);
}

.scroll-wishlist {
  max-height: calc(100vh - 100px) !important;
  overflow-y: auto  !important;
  overflow-x:hidden;
  position:relative;
}
p {
  font-weight: 400;
  font-size: 14px !important;
  padding: 0 !important;
  margin: 0 !important;
  padding-top:0.25rem !important;
}
.price{
  color:var(--green-500);
  font-weight:600;
  span{
    color:var(--green-500) !important;
    
  }
}
.inline-divs{
  display:flex;
  flex-direction:row;
  margin-top:0.45rem !important;
  align-items:center;
  width:100%;
  color:black;
  justify-content:left;
  svg{
    margin:0.25rem;

  }
}

.price-btn{
  width:95%;
  display:flex;
  justify-content:space-between;
}

.title {
  font-weight: 600;
  text-align:left;
}
.title-sub{
  font-size:12px !important;
  font-weight:500;
}
span {
  color:var(--grey-500)!important;
}


.details {
  display: flex;
  flex-direction: column;
  align-items: left; 

  padding-top:1rem;
  padding-left:0.75rem;
  width:70%;
  text-align:left !important;
  div{
  
    margin-left: auto;
    margin-top: auto;
    align-self: flex-end;
  }
  button{
    color:var(--green-300);
    margin-left:1rem;
  }
  
  .cart-btn:hover{
  color:blue;
  }
  
}
.details-btn{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position:absolute;
  right:5%;
}
button{
  background:transparent;
  border:none;
  font-size:20px;
}
button:hover{
  color:var(--green-300);
  transition:color 0.2s;
}
.align-right{
  position:absolute;
  width:70px !important;
  bottom:0;
  right:2%;
  .delete-btn:hover{
    color:red;
  }
  }
@media(max-width:580px){
  .title-sub{
    color:var(--grey-500);
    font-size:5px !important;
  }
  .delete-btn,.cart-btn{
    font-size:14px;
  }
  .details{
    gap:0.15rem;
    p{
      font-size:13px !important;
      padding:0 !important;
      margin:0 !important;
    }
  }
  .cart-container{
    img{
      width:68px;
      height:85px;
    }
  }
  .checkout-btn{
    background-color:var(--white);
    z-index:100;
    .title-sub{
      color:var(--grey-500);
      font-size:5px !important;
    }
    .details{
      gap:0.15rem;
      p{
        font-size:13px !important;
        padding:0 !important;
        margin:0 !important;
      }
    }
    padding-top:0.25;
    padding-bottom:.5rem;
    .price-div{
      justify-content:center;
      height:20px;
      gap:1rem;
      p{
        padding:0;
        margin:0;
        font-size:14px !important;
      }
    }
    a{
      height:30px;
      font-size:14px !important;
      width:100px;
    }
    button{
      height:30px;
      font-size:14px !important;
      width:100px;
      margin-bottom:auto;
    }
  }
}
@media(max-width:480px){
  .cart-container{
    margin-left:0.75rem;
    width:95%;
  }
  .qty{
    font-size:13px !important;
  }
  .inline-divs{
    svg{
      font-size:11px;
    }
  }
  .title-sub{
    color:var(--grey-500);
    font-size:11px !important;
  }
  .cart-link {
    width:100%;
  }
}
`;