import axios from "axios"


export const addUserReviewThunk = async (review,thunkApi)=>{
    try {
        const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/reviews/${review.id}`,review)
       
        return resp.data
    } catch (error) {
        if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
        ) {
            // setError(error.response.data.message);
            return thunkApi.rejectedWithValue(error.response.data.msg)
        }
    }
}