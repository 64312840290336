import { createSlice,createAsyncThunk  } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addBlogThunk, getBlogThunk, getSingleBlogThunk, updateBlogThunk } from "./blogsThunk";

const initialState = {
    isBlogLoading:false,
    isLoading:false,
    isSingleBlogLoading:false,
    user_Id:'',
    product_id:'',
    blogs:[],
    blog:[]

}

//getting blogs
export const getBlogs = createAsyncThunk('user/blog' ,getBlogThunk);
//post blog
export const addBlog = createAsyncThunk('user/postBlog',addBlogThunk)
//single blog
export const getSingleBlog =  createAsyncThunk('user/singleBlog',getSingleBlogThunk)
//update blog
export const updateBlog = createAsyncThunk('user/editBlog' ,updateBlogThunk)


const BlogSlice = createSlice({
    name:'blog',
    initialState,
    reducers:{

    },
    extraReducers:(builder)=>{
        builder
        .addCase(getBlogs.pending,(state)=>{
            state.isBlogLoading = true;
        })
        .addCase(getBlogs.fulfilled,(state,{payload})=>{
            state.isBlogLoading = false
            if(payload !==undefined || payload !==null){
                state.blogs = payload
            }
        })
        .addCase(getBlogs.rejected,(state)=>{
            state.isBlogLoading = false;
        })
        .addCase(addBlog.pending,(state)=>{
            state.isLoading = true;
        })
        .addCase(addBlog.fulfilled,(state,{payload})=>{
            state.isLoading = false
            toast.success('Blog added successfully...')
        })
        .addCase(addBlog.rejected,(state)=>{
            state.isLoading = false;
        })
        .addCase(getSingleBlog.pending,(state)=>{
            state.isSingleBlogLoading = true
        })
        .addCase(getSingleBlog.fulfilled,(state,{payload})=>{
            state.isSingleBlogLoading = false
            state.blog = payload
        })
        .addCase(getSingleBlog.rejected,(state)=>{
            state.isSingleBlogLoading = false;
        })
        .addCase(updateBlog.pending,(state)=>{
            state.isLoading = true
        })
        .addCase(updateBlog.fulfilled,(state,{payload})=>{
            state.isLoading = false
           
        })
        .addCase(updateBlog.rejected,(state)=>{
            state.isLoading = false;
        })
    }
})


export const{} = BlogSlice.actions
export default BlogSlice.reducer