import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import icon from './icon.jpeg';

export const CashOnDeliverysuccessStatus = () => {
    const { id } = useParams();
    const [showMessage, setShowMessage] = useState(true);
    const [emailSent, setEmailSent] = useState(false); // Track if email has been sent
    const [total, setTotal] = useState(null);

    useEffect(() => {
        const fetchTotal = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/total/${id}`);
            setTotal(response.data.total);
          } catch (error) {
            console.error('Error fetching total:', error);
          } finally {
           
          }
        };
    
        fetchTotal();
      }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!emailSent) { // Check if email has not been sent
                    const userData = JSON.parse(localStorage.getItem('user'));
                    let additionalTo = ''; // Additional 'to' address from another endpoint
                    const userId = userData.id;
                    const to = userData.email;
                    const userName=userData.name
                    // Check if user email is available in the response
                    if (!to) {
                        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/customer/userEmail/${userId}`);
                        additionalTo = response.data.email; // Additional 'to' address
                    }
              
                    // Combine the 'to' addresses
                    const allTo = to ? [to, additionalTo] : additionalTo;

                    await axios.post(`${process.env.REACT_APP_BASE_URL}/send-email-with-attachment-confirm`, {
                        to: allTo,
                        OrderId: id,
                        name:userName
                    });

                    setEmailSent(true); // Set emailSent to true once email is sent
                }

                // Redirect to the specified URL after updating the payment status
                setTimeout(() => {

                    if (window.gtag) {
                      
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-16486222648/MGePCLyo1J8ZELiWn7U9',
                            'value': total, 
                            'currency': 'AED',
                            'transaction_id': id 
                        });
                    }

                    window.location.href = 'https://myplantstore.me/'; // Change to your desired URL
                }, 5000);
            } catch (error) {
                console.error('Error updating payment status:', error);
            }
        };

        fetchData(); // Call the function when the component mounts
    }, [id]); // Execute the effect when orderId changes

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <header style={{ textAlign: 'center', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', height: '90px', position: 'relative' }}>
                {/* Image icon */}
            </header>
            <img src={icon} alt="Icon" style={{ position: 'absolute', top: 120, left: '50%', transform: 'translateX(-50%)', width: '50px', height: '50px' }} />

            <div style={{ textAlign: 'center', position: 'relative' }}>
                <h4 style={{ marginTop: '100px' }}>HIFAA FLOWERS & ORNAMENTAL PLANTS TRADING CO. L.L.C</h4>
                {showMessage ? (
                    <div>
                        <div className="loader"></div>
                        <p>Updating Purchase status...</p>
                    </div>
                ) : (
                    <p>Purchase Made successful. Redirecting to the home page shortly...</p>
                )}
            </div>
        </div>
    );
};
