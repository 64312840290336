import React, { useEffect, useRef, useState } from 'react'
import { ImFontSize } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getCart, getUser, verifyToken } from '../../feature/user/userSlice';
import { checkTime } from '../../utils/helpers';
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from 'axios';


const CheckoutProducts = ({ updateTotal, updateCartItems,redeem }) => {

  const location = useLocation();
  const { renderFromCart } = location.state || { renderFromCart: false };

  let total=0;
  const dispatch =useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(verifyToken());
      await dispatch(getCart());
    };
    fetchData();
  }, [dispatch]);


  const removeItem = async (userid, itemid) => {
      try {
        const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}/cart/${userid}/${itemid}`);
        dispatch(getCart())
        return resp.data;
      } catch (error) {
        return error;
      }
    }

    const {cartItems,} = useSelector((store)=>store.user);

    const {id} = useParams()
    let updatedCartItems
    if(renderFromCart){
      updatedCartItems = cartItems ;
    }else{
      updatedCartItems=cartItems && cartItems.filter((item) => item._id === id) 
    }
    useEffect(() => {
      if (updatedCartItems) {
        let total = 0; 
  
        updatedCartItems.forEach((item) => {
          total += item.totalPrice;
        });
  
        updateTotal(total);
        updateCartItems(updatedCartItems);
      }
    }, [updatedCartItems, updateTotal, updateCartItems]);
  

    let totals=0;
    // useEffect(() => {
    //   if (updatedCartItems) {
    //     let calculatedTotal = 0; // Initialize the total
  
    //     // Calculate the total amount
    //     updatedCartItems.forEach((item) => {
    //       calculatedTotal += item.totalPrice;
    //     });
  
    //     updateTotal(calculatedTotal); // Update the total in StripeCheckout
    //   }
    // }, [updateCartItems, updateTotal]);
    let redeemTotal
    const redeemFun = (prop)=>{
      let result
      if(redeem){
        if(redeem.type==='Percentage'){
          result =((prop * Number(redeem.value)/100))
          return( prop-result)
        }else{
          return prop-Number(redeem.value)
        }
      }
    }

  return (
    <Wrapper>
     {updatedCartItems && Object.keys(updatedCartItems).map((key, id) => {
      const { image, activePot, activeImageName, title,photos,totalPrice,size,userId,_id,potName,productQuantity,flashSaleStartDate, flashSaleEndTime
        ,flashSaleStartTime,flashSalePrice,OtherProductDetails,productId} = updatedCartItems[key];
    
      const flashSaleActive =  flashSaleStartDate ? checkTime(flashSaleStartDate, flashSaleEndTime,flashSaleStartTime) : false;
      totals+=(flashSaleActive ? flashSalePrice : totalPrice)
      redeemTotal =  redeemFun(totals)

      return (
      <div key={id} className='product'>
      <div className='img-name'>
      <div className='img-div'><img src={image} alt="plant" /></div>
      <div className='img-div-details'>
      <p>{title}</p>
      {(size && potName) ? <div className='inline'><p>{size?.length}<span>Cm</span></p><span className='bar'>/</span> <p>{potName?.name}<span>Pot</span></p></div>
      :<div className='inline'><p>{OtherProductDetails?.Value}<span>Kg/Ltr</span></p></div>}
      <p className='inline'>Qty <span>{productQuantity}</span></p>
      </div>
      </div>
      <div className='img-div-details price flex-div'>
      <p>{flashSaleActive ? flashSalePrice : totalPrice }<span> AED </span></p>
      <button onClick={()=>removeItem(userId,productId)}><RiDeleteBin6Line /></button>
      </div>
    </div>
    );
   })}

   <div className='total-amt'>
          <p >Total:</p><p className='price'>{redeem ? redeemTotal :totals} AED</p>
    </div>

    </Wrapper>
  )
}

export default CheckoutProducts

const Wrapper = styled.div`
   background:var(--grey-10);
   height:fit-content;
   padding-top:0.5rem;
   margin-top:2rem;
   border-radius:10px;
  .product{
    display:flex;
    gap:1rem;
    margin:1.5rem 2rem;
    justify-content:space-between;
  }
  .flex-div{
    display:flex;
    flex-direction:column;
    gap:1rem;
    button{
      background:transparent;
      border:none;
      box-shadow:none;
      color:var(--green-300);
    }
    button:hover{
      transition:color 0.3s;
      color:red;
    }
  }
  img{
    width:60px;
    height:90px;
    padding-top:0rem !important;
    margin:0.3rem;
  }
  .img-name{
    display:flex;
    gap:1rem;
  }
  .price{
    margin-right:2rem;
    p{
      color:var(--green-500) !important;
      font-weight:600;
    }
  }
  .img-div-details{
    p{
      padding:0 !important;
      margin:0 !important;
      color:var(--grey-500);
      font-size:14px !important;
    }
    span{
      margin-left:0.25rem;
    }
  }
  .inline{
    display:flex;
    p{
      padding:0 !important;
      margin:0 !important;
      color:var(--grey-500);
    }
    span{
      margin-left:0.25rem;
    }
  }
  .bar{
    margin-right:0.25rem;
  }
  .total-amt{
    display:flex;
    gap:1rem;
    margin:0rem 2rem;
    border-top:2px solid var(--green-500);
    justify-content:flex-end;
    p{
      font-weight:bold !important;
      font-size:18px !important;
      color:var(--green-300) !important;
    }
    .price{
      margin-left:1.75rem;
    }
  
  }
  @media only screen and (max-width: 600px) {
    .product{
      margin:0;
      padding:0;
      border-bottom:1px solid var(--grey-100);
      gap:0.25rem;
    }
    .img-name{
      gap:0.25rem;
    }
    .img-div-details{
      p{
        font-size:12px !important;
      }
    }
    .product > div:nth-child(2) {
      width: 60px;
     margin:0;
     padding:0;
  }
  }

`