import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import{ SearchBarScroll} from './support'
import { BsChevronDown} from "react-icons/bs";
import { removeAuthTokenCookie, removeUserLocalStorage } from '../utils/localStorage'
import { logoutUser, setMyUser } from '../feature/user/userSlice'
import { FaUserMinus} from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { FiShoppingBag } from "react-icons/fi";

const Navbar = () => {
  const [country ,setCountry] = useState(false);
  const {myUser} = useSelector((store)=>store.user)
  const {isBannerOpen} = useSelector((store)=>store.modal)

  const dispatch = useDispatch()
  const navigate =useNavigate()
  const countryChange=({text})=>{
    
  }
//remove all necessary data while logout 

const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch initial content when component mounts
        fetchContent();
    }, []);

    const fetchContent = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/getEditable`);
            if (!response.ok) {
                throw new Error('Failed to fetch content');
            }
            const data = await response.json();
            setContent(data.content); // Set content directly as it's a string
        } catch (error) {
            console.error(error);
            console.error('Failed to fetch content');
        }
    };

  const logOut=()=>{
    dispatch(logoutUser)
    removeUserLocalStorage()
    removeAuthTokenCookie()
    dispatch(setMyUser())
  }
  return (
    <NavContainer>
     <div className='searchbar-scroll'> <SearchBarScroll /></div>
        <div className='nav-header'></div>
        {isBannerOpen && <div className='banner'>
       <p className="icon" ><FiShoppingBag  /></p> <p >{content}</p>
      </div>}
    </NavContainer>
  )
}

const NavContainer = styled.nav`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background:var(--green-300);
  margin-bottom:1rem;
  text-transform:capitalize;
  width:100%;
 
  p{
    margin:0;
    padding:0;
    color: white;
    font-family: "Poppins";
  }
  .headFirst {
    font-size: 28px;
  }
  .banner{
    display:flex;
    justify-content:center !Important;
    align-items:center !Important;
    gap:0.5rem;
    height:35px;
    font-weight:600;
    width:100%;
    margin:0 auto !important;

  }
  .icon {

    text-align:right;
   
  }

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 175px;
      margin-left: -15px;
    }
  }
  .nav-links {
    display: none;
  }
  .scroll-event{
 
  }
  .flag-div{
    max-width:10rem;
    flex-direction: row;
    position:absolute;
   
    z-index:1;
    -webkit-box-shadow: 0 0 10px #fff;
    box-shadow: 0 0 10px grey;
    display:none;
    left:-10%;
    top:75%;

  }
  li:hover{
    color:var(--green-300);
    transition: color 0.5s;
  }
  .dropdown-main:hover + .flag-div,
.flag-div:hover {
  display: block;
  z-index: 999;
}
  .dropdown-main{
    display:flex;
    justify-content:center;
    align-items:center;
    gap:1.5rem;
  }
  .dropdown-main span{
    svg{
      margin-top:1rem;
    }
  }
  .logout{
    background:transparent;
    border:none;
    padding-top:0;
    font-size: 1rem;
    text-transform: capitalize;
    letter-spacing: 1px;
    color: var(--grey-500);
    span{
      margin-left:0.5rem;
    }
  }
  .logout:hover{
    color:var(--green-300);
    transition: color 0.5s;
  }
  .dropdown{
    width:10rem;
    background:white;
    display:flex;
    align-items:center;
    padding:0rem 0rem 0rem  0.25rem;
    
  }
  .dropdown:hover{
    background-color:var(--green-300);
    
  }
 
  .country-name{
    color:var(--grey-600);
  }
  .dropdown:hover .country-name {
    font-weight: bold;
  }
  .flag-div button{
    width:100%;
    background:white;
    border:none;
    cursor:pointer;
  }
  .country{
    display:flex;
    padding-left:1rem;
    align-items:center;
    gap:1rem;
  }
  .country :hover{
    font-weight:1rem;
  }
  img{
    width:1.9rem;
  }
  @media(max-width:992px){
    margin-bottom:0.25rem;
     p {
  font-size: 11px; /* Set the font size to 16 pixels */
}
  }
  @media (min-width: 768px) {
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
        p {
  font-size: 11px; /* Set the font size to 16 pixels */
}
    ul.nav-links > *:not(:first-child) {
      margin-top: 0.5rem;
    }
    .nav-links {
      position:relative;
      display: flex;
      justify-content: center;
      margin:0;
      li {
        margin: 0 0.75rem;
      }
      .icon{
        margin-right:0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: 1rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid var(--clr-primary-7);
        }
      }
    }
    .cart-btn-wrapper {
      display: grid;
    }
  }
  .nav-links li:hover{
   
  }
  @media (max-width: 450px) {
        p {
  font-size: 11px; /* Set the font size to 16 pixels */
}
    .searchbar-scroll{
      display:flex;
      padding-bottom:0;
    }
    .nav-header{
      width:90%:
    }
  }
@media(min-width:1240px){
  .banner{
    width:100% !important;
  }
      p {
  font-size: 18px; /* Set the font size to 16 pixels */
}
}
`

export default Navbar