import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { GetAllCoupons, getCart, getUserAddress } from '../feature/user/userSlice'
import { checkTime } from '../utils/helpers'
import Loading from './Loading'
import axios from 'axios'
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus, FaMinus } from 'react-icons/fa'
import { toast } from 'react-toastify'
import coin from '../assets/images/coin.png'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { DeliveryLocation, GLocation } from './support'
import StripeCheckoutUserForm from './support/StripCheckoutUserForm'
import {Link, useNavigate } from 'react-router-dom'
import DeliveryChargeRange from './support/DeliveryChargeRange'

//main cart page
const CartAndCoupons = () => {
  const {cartItems,myUser,deliveryLocation,coupons,cartLoading,coins,redeemedCoupon,totalCoins,userGLocation,deliveryCharge} = useSelector((store)=>store.user)
  const { checkoutData : addressData } = useSelector((store) => store.user);
  const dispatch = useDispatch()

let total=0;


const [checkedItems, setCheckedItems] = useState({});

const handleCheckChange = (event, id) => {

  setCheckedItems({...checkedItems, [id]: event.target.checked});
    handleRemoveButtonClick();
}


const [cartUpdated, setCartUpdated] = useState(false);
const [loginWarning,setLoginWarning] = useState(false);
const [addCoupon, setAddCoupon] = useState(false);
//remove item from cart
const removeItem = async (userid, itemid) => {
 
  if(myUser){
    try {
      const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}/cart/${userid}/${itemid}`);
      setCartUpdated(true);

      dispatch(getCart())
      handleRemoveButtonClick();
      return resp.data;
    } catch (error) {
      return error;
    }
  }else{
    try {
      const existingWishlistItems = JSON.parse(localStorage.getItem('cart')) || [];

   const itemIndexToRemove = existingWishlistItems.findIndex((item) => item.productId  === itemid);

   if (itemIndexToRemove !== -1) {

   existingWishlistItems.splice(itemIndexToRemove, 1);


   localStorage.setItem('cart', JSON.stringify(existingWishlistItems));
   setCartUpdated(true);
   dispatch(getCart())
   handleRemoveButtonClick();
}

  } catch (error) {
      
  }
  }
}

const [amounts, setAmounts] = useState({});

// Update amounts state after cartItems have been fetched
useEffect(() => {
  if (cartItems) {
    setAmounts(
      cartItems.reduce((acc, item) => ({ ...acc, [item._id]: item.productQuantity }), {})
    );
  }
}, [cartItems]);

const increase = (cart) => {
 
  //increase amount
  setAmounts((oldAmounts) => {
    let tempAmount = oldAmounts[cart._id] + 1;
    if (tempAmount > cart.stock) {
      tempAmount = cart.stock;
    }
    return { ...oldAmounts, [cart._id]: tempAmount };
  });
};

  //increase amount
const decrease = (cart) => {
  if (redeem) {
      handleAddButtonClick();  
    }
  setAmounts((oldAmounts) => {
    let tempAmount = oldAmounts[cart._id] - 1;
    if (tempAmount < 1) {
      tempAmount = 1;
      
    }
    
    return { ...oldAmounts, [cart._id]: tempAmount };
    
  });
};



const [showCoupons, setShowCoupons] = useState(false);
let user_coins=totalCoins

const coinsOffer =[
  {
    "_id": "65627504ca1d3faa16936dc",
    "code": 250,
    "coins":true,
    "type": "Percentage",
    "value":5,
    "usage": "Single",
    "startDate": "2023-11-25T00:00:00.000Z",
    "endDate": "2023-11-30T00:00:00.000Z",
    "__v": 0
},
{
  "_id": "65627504ca1d3faa16905l9dc",
  "coins":true,
  "code": 500,
  "type": "Percentage",
  "value": 10,
  "usage": "Single",
  "startDate": "2023-11-25T00:00:00.000Z",
  "endDate": "2023-11-30T00:00:00.000Z",
  "__v": 0
},
]


useEffect(()=>{

  dispatch(GetAllCoupons())
},[dispatch,showCoupons])
const [showAnotherOption, setShowAnotherOption] = useState(false);
const[redeem,setRedeem] =useState('')

const handleRedeemClick = () => {
 setShowCoupons(!showCoupons);
 setShowAnotherOption(false);
};

const handleAnotherOptionClick = () => {
 setShowAnotherOption(!showAnotherOption);
 setShowCoupons(false);
};
const handleInputChange = (event) => {
  setInputValue(event.target.value);
};
const handleDivClick = (code) => {
 
  const selectedCouponData =coinsOffer&& coinsOffer.find((coupon) => coupon.code === code);

  if (selectedCouponData && selectedCouponData?.code > user_coins) {
    toast.warning('Not enough coins ....')
    return;
  }

  setInputValue(code);
  setSelectedCoupon(code);
};






const handleAddButtonClick = () => {
  const matchingCoupon =coupons&& coupons.find((coupon) => coupon.code === inputValue || coupon.value === inputValue);
  const matchingCoin = coinsOffer && coinsOffer.find((coin)=>coin.code === inputValue || coin.value === inputValue)
  let enable = false

  
  const checkMatchingIds =()=> {
    if(myUser){
      for (const key in matchingCoupon) {
        if (redeemedCoupon.some((rcoupon) => rcoupon.couponId === matchingCoupon._id)) {
          return false; 
        }
      }
      return true;
    }
    
    toast.warning("Login to add coupons...")
    return true;
  }
  enable=checkMatchingIds()

   if ((matchingCoupon && (finalTotal >= matchingCoupon.minvalue)  && enable)|| matchingCoin) {
    setAddCoupon(true);
   setRedeem(matchingCoupon || matchingCoin)
  } else {
    toast.warning("Coupon not available...")
    handleRemoveButtonClick();
 }
};

const handleRemoveButtonClick = () => {
  setRedeem(null); // Remove the selected coupon or coin
  setSelectedCoupon('');
  setInputValue('')
  setAddCoupon(false);
};

let redeemTotal=0
let redeemedAmt=0
//find out redeem price
    const redeemFun = (prop)=>{
      let result
      if(redeem){
        if(redeem.type==='Percentage'){
          result =((prop * Number(redeem.value)/100))
          redeemedAmt=result
          return( prop-result)
        }else{
          redeemedAmt=Number(redeem.value)
          return prop-Number(redeem.value)
        }
      }
    }

    

const [selectedCoupon, setSelectedCoupon] = useState('');
const [inputValue, setInputValue] = useState('');


const [checkoutData, setCheckoutData] = useState({
  name: addressData?.name,
  country: 'uae',
  state: addressData?.state,
  city: addressData?.city,
  houseNumber: addressData?. houseNumber,
  landmark: addressData?.landmark,
  phone: addressData?.phone,
  phone2: addressData?.phone2,
  note: addressData?.note,
});

useEffect(() => {
  setCheckoutData(addressData);
}, [addressData]);

const updateCheckoutData = (field, value) => {
  setCheckoutData({ ...checkoutData, [field]: value });
};

//google location
const [GLocations ,setGlocations] =useState([])
 
const locationName = userGLocation

const handleLocationSelect = (location) => {
  // Assuming location is an object with lat and lng properties
  
  setGlocations({
    location: location,
    name: locationName, // Assuming userGLocation is the name property you want to set
  });
};

useEffect(() => {
  setGlocations((prevGLocations) => ({
    ...prevGLocations,
    name: locationName,
  }));
}, [userGLocation]);

useEffect(()=>{
    dispatch(getUserAddress());
},[])



const [isOpen, setIsOpen] = useState(false);

const toggleForm = () => {
  myUser ? setLoginWarning(false) : setLoginWarning(true);
  setIsOpen(!isOpen);
};

// useEffect(() => {
//   if (loginWarning) {
//     const timeoutId = setTimeout(() => {
//       setLoginWarning(true);
//     }, 10000);
//     return () => clearTimeout(timeoutId);
//   }
// }, [dispatch, myUser, loginWarning]);


const navigate = useNavigate()


const newCartItems = cartItems.map((cart) => {

  const amount = amounts[cart._id];
  const isChecked = checkedItems[cart._id];

  
  return { ...cart, amount, GiftWrap:isChecked };
});


let finalTotal=0
let wraptotal =0

const finalData = []
const onClickCheckOut = ()=>{
 
  if (
    checkoutData.name &&
    checkoutData.country === 'uae' &&
    checkoutData.state &&
    checkoutData.city &&
    checkoutData.houseNumber &&
    checkoutData.landmark &&
    checkoutData.phone &&
    deliveryLocation
  ) {

      if(!myUser){
        navigate('/user/login', { 
          state: { 
            newCartItems,
            redeem,
            checkoutData,
            total: (redeemTotal ? redeemTotal : finalTotal),
            deliveryCharge,
            GLocations,
            deliveryLocation
          } 
        });
      }else{
        navigate(`/checkout/${cartItems[0].productId}`, { 
          state: { 
            newCartItems,
            redeem,
            checkoutData,
            total: (redeemTotal ? redeemTotal : finalTotal),
            deliveryCharge,
            GLocations,
            deliveryLocation
          } 
        });
      }
}else{
  toast.error('Please fill all required fields')
}
  
}


const [showMap ,setShowMap] =useState(true)


let cartPage=true;
let prop;

const handleNoteInputChange = (field, value) => {

  updateCheckoutData(field, value);
};
const handleLoginClick = () => {
  localStorage.setItem('fromCheckout', 'True');
  localStorage.setItem('fromCheckoutLogin', 'True');
};



if(cartLoading){
  return(
    <Wrapper>
     <Loading/>
  </Wrapper>
  )
}
if((!cartLoading) && ( cartItems && cartItems.length===0)){
  return (
    <Wrapper>
      <h4>Your cart is empty</h4>
      {/* <button onClick={toggle} className='btn'>Add now</button> */}
    </Wrapper>
  )
}

  return (
    <Wrapper>
    <h5>My cart</h5>
    <section className='section-cart'>
    <div className='column-div'>
      {cartItems?.map((cart,id)=>{
        const { image, totalPrice, productQuantity, potName, size,Status, title ,userId,_id,flashSalePrice,flashSaleStartDate,flashSaleStartTime,
          flashSaleEndTime,flashSaleDiscount,productId ,OtherProductDetails,dimension } = cart;
          let flashSaleActive =  false
            
          if(Status && Status === "Unlimited"){
            flashSaleActive= true
          }else{
            flashSaleActive= flashSaleStartDate ? checkTime(flashSaleStartDate, flashSaleEndTime,flashSaleStartTime,) : false;
          }
        total+=(flashSaleActive ? flashSalePrice : totalPrice)
      
        const quantity = amounts[_id];
   
       // Calculate the new total price
       let newTotalPrice =(Number(( totalPrice /productQuantity)* quantity));
       let newFlashSalePrice 
       if(flashSaleActive){
        newFlashSalePrice = (Number((flashSalePrice /productQuantity)*quantity))
        if (checkedItems[_id]) {
          newFlashSalePrice += (20*quantity);
          wraptotal+=(20*quantity)
        }
       }
       if (!flashSaleActive && checkedItems[_id]) {
        newTotalPrice += (20 *quantity);
        wraptotal+=(20 *quantity)
      }
    
     
      if (!newFlashSalePrice) {
        finalTotal += newTotalPrice;
      }else{
        finalTotal += newFlashSalePrice;
      }
      
      redeemTotal =  redeemFun(finalTotal )
      
        return(
          <div className='items-column-div'>
          <div className='row-div'>
          <img src={image} alt='cart item' draggable="false"/>
          <div className='item-detail'>
            <p className='main-title'>{title}</p>
           {/* {OtherProductDetails ? <p className='length-p'>{OtherProductDetails?.Value} Kg</p> : 
           <p className='length-p'>{size?.length} cm / <span>{potName?.name}</span></p>} */}
          <p className='length-p'>
          {Object.keys(dimension)
                     .filter(key => key.startsWith("Value"))
                     .map(key => dimension[key])
                     .filter(value => value !== undefined && value !== null && value !== '')
                     .join(" / ")}
          </p>
          

            {flashSaleActive ? <div className='price'><p className='price-p'>{parseFloat(newFlashSalePrice).toFixed(2) } <span>AED</span></p>
             <div className='inline-p'>
              <p className='strike-p'>{parseFloat(newTotalPrice).toFixed(2)} <span>AED</span></p><p className='bg-color'>{flashSaleDiscount}%</p></div></div> 
              : <p className='price-p'>{parseFloat(newTotalPrice.toFixed(2))} <span>AED</span></p> }
          </div>
          <div className='inline-div'>
            <AmountButtons>
             <button type="button" className='amount-btn decrease' onClick={() => decrease(cart)}><FaMinus /></button>
             <h3 className="amount">{amounts[cart._id]}</h3>
             <button type="button" className='amount-btn color' onClick={() => increase(cart)}><FaPlus /></button>
           </AmountButtons>
          <button  type='button' className='delete-btn' onClick={()=>removeItem(userId,productId)}><RiDeleteBin6Line /></button>
          </div>
          </div>
          <div className='color-div'>
        <label htmlFor={`giftWrap-${_id}`}>
          <span className="custom-span-main">Want to make your gift stand out?</span>
          <input 
            type='checkbox' 
            id={`giftWrap-${_id}`} 
            checked={checkedItems[_id] || false}
            onChange={(event) => handleCheckChange(event,_id)}
          /><br/>
          <p className="custom-span">
          For an additional AED 20 per plant, you can choose our Premium Plant Gift Wrap for your indoor plants.
           </p>
        </label>
      </div>
          </div>
        )
      })}
    </div>
    <div className='main-cart-div'>
    <div>
    <DeliveryChargeRange  prop={(finalTotal - wraptotal).toFixed(1)}/>
    <div className='location-choose'>
    <DeliveryLocation cartPage={cartPage}/> 
    </div>
    </div>
    <div className={`cart-user-form  ${isOpen ? 'open border-form' : ' border-form'}`}>
    <p onClick={toggleForm} className='paragraph-btn'>
      Shipping address {isOpen ? <FaAngleUp /> : <FaAngleDown />}
    </p>
    {isOpen &&<> 
    {loginWarning &&<p className='Login-warning-p'><a onClick={handleLoginClick} href="/">Login,</a>Before Adding Credentials </p>}
    <StripeCheckoutUserForm checkoutData={checkoutData} updateCheckoutData={updateCheckoutData} />
    <GLocation key={isOpen} onLocationSelect={handleLocationSelect} /></>}
    </div>
    <textarea type="text" placeholder="Leave a note" className="note-textarea"   onChange={(e) => handleNoteInputChange('note', e.target.value)}/>
    <div className='coupon'>
      <div className='redeem-options'>
        <p onClick={handleRedeemClick} className={showCoupons ? 'active' : ''}>
          Redeem Coupons <FaAngleDown />
        </p>
        <p  onClick={handleAnotherOptionClick} className={showAnotherOption ? 'active' : ''}>
          Redeem coins  <FaAngleDown />
        </p>
      </div>
      <div className='coupon-btn'>
        {showCoupons && (
          <div className={`${showCoupons || showAnotherOption ? 'coupon-div set-height' : 'coupon-div'}`}>
          {coupons?.map((coupon) => (
          !coupon.Exclusive && (
          <div key={coupon._id}
          className={`check-box ${selectedCoupon === coupon.code ? 'selected' : ''}`} onClick={() => handleDivClick(coupon.code)} >
          <div className='redeem-content'>
         <span>{coupon.code}</span>
          <div className='redeem-content-offer'>
          {coupon.type === "Percentage" ? <div className='off-percentage'>{coupon.value}%</div> : <div className='off-percentage'>{coupon.value} AED</div>}
          {coupon.type === "Percentage" ? <div>off</div> : <div>flat</div>}
         </div>
         </div>
         <button className='redeem-btn'>{selectedCoupon === coupon.code ? 'Selected' : ' Redeem'}</button>
        <div className='coupon-desc'><span>For {coupon.minvalue} AED or more {coupon.usage} Use.</span></div>
      </div>
      )
     ))}
          </div>
        )}
        {showAnotherOption && (
          <>
          <div className='coins-count'><p>Your coins</p><p>{user_coins}  coins<img src={coin} alt="coin" className='coin-img' draggable="false"/></p></div>
            <div className='coins-div'>
            {coinsOffer.map((coupon) => (
            <div key={coupon._id}
              className={`check-box ${selectedCoupon === coupon.code ? 'selected' : ''}`} onClick={() => handleDivClick(coupon.code)} 
              style={{ cursor: coupon.code > user_coins ? 'not-allowed' : 'pointer' }}>
              <div className='redeem-content'>
              <span>{coupon.code} Coins<img src={coin} alt="coin" className='coin-img' draggable="false"/></span>
              <div className='redeem-content-offer'>
              {coupon.type==="Percentage" ? <div className='off-percentage'>{coupon.value}%</div> : <div  className='off-percentage'>{coupon.value} AED</div>}
              {coupon.type==="Percentage" ? <div>off</div> : <div>flat</div>}
              </div></div>
              <button className='redeem-btn'>{selectedCoupon === coupon.code ? 'Selected' : ' Redeem'}</button>
            </div>
          ))}
            </div>
          </>
        )}
        
        <div className='redeem-content-input'>
          <input type='text' placeholder='Selected Coupon' value={inputValue} className='select-place' onChange={handleInputChange}/>
          {addCoupon ? (  <button onClick={handleRemoveButtonClick} className='add-btn'>  Remove </button> )
           : (<button onClick={handleAddButtonClick} className='add-btn'> Add </button>)}
        </div>
        <div className='subtotal'>
        <p className='header-p'>Order summery</p>
     
        <div><p>Subtotal</p><p>{ (finalTotal - wraptotal).toFixed(1) }</p></div>
        <div><p>Gift Wrap</p> <p>{wraptotal}</p></div>
        {redeem && <div><p>Cashback</p> <p>{redeemedAmt}</p></div>}
        <div className=''><p>Shipping Fee<span></span></p> <p>{deliveryCharge}</p></div>
        <div className='total-p'><p>Total</p> <p>{ redeem ? parseFloat(redeemTotal+deliveryCharge).toFixed(2) :parseFloat(finalTotal +deliveryCharge).toFixed(2)} AED</p> </div>
        </div>
      </div>
    </div>
    <div className='checkout-btn'>
          {(cartItems && cartItems.length > 0) && <button onClick={onClickCheckOut} className="btn "state={{ renderFromCart: true }}>
          checkout
          </button>}
        </div>
    </div>
    </section>
    </Wrapper>
  )
}

export default CartAndCoupons

const Wrapper = styled.section`
min-height:50vh;
 width:95%;
 margin:0 auto;
 padding:1.5rem 0.75rem;
 h5{
  font-weight:600;
 }
 img{
  width:100px;
  height:110px;

 }
 .cart-user-form {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  
}

.cart-user-form.open {
  height: auto; /* or specify a maximum height based on your design */
}
.location-choose{
  border: 1px solid rgba(50, 50, 93, 0.1);
  border-radius:5px;
  padding:0rem 0.5rem;
  margin-bottom:1rem;
}
.border-form{
  border: 1px solid rgba(50, 50, 93, 0.1);
  border-radius:5px;
  padding:0.5rem;
  margin-bottom:1rem;
}
textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--grey-100);
  border-radius: 4px;
  outline: none;
  resize: vertical; 
  font-family: inherit;
  font-family:'Poppins';
}
.select-place {
  font-family: inherit;
  height: 44px;
  padding: 5px;
  border: 1px solid var(--grey-100);
  border-radius: 5px;
  color: var(--grey-500);
  font-size: 16px;
  font-family:'Poppins';
}
.note-textarea {
  margin-bottom: 0.5rem;
  background-color: #bdfad3; /* Background color */
 
}
.note-textarea::placeholder {
  color: rgba(0, 0, 0, 1); /* Adjust the opacity value (0.7 in this example) to make it brighter */
}


 input{
  cursor:pointer;
 }
 .column-div{
  display:flex;
  flex-direction:column;
  
  
 }
 .item-detail{
  display:flex;
  flex-direction:column;
  p{
    margin:0;
  }

 }
.total-p{
  font-weight:600;
  margin-top:0.5rem;
  p{
    font-size:17px !important;
  color:var(--green-300) !important;
  }
}
.paragraph-btn{
  cursor:pointer;
}
 .items-column-div{
  display:flex;
  flex-direction:column;
  gap:0.75rem;
  border: 1px solid rgba(50, 50, 93, 0.1);
  border-radius:5px;
  border-top:none;
  padding:1rem;
 }
 .coupon-desc{
  display:flex;
  flex-direction:row;
  gap:0.25rem;
  span{
    font-size:11px;
  }
 }
 .row-div{
  display:flex;
  flex-direction:row;
  gap:1rem;
  
 }
 .price-p{
  font-size:15px;
 }
 .length-p{
  font-size:14px;
 }
 .main-title{
  font-size:14px;
  font-weight:600;
 }

 .delete-btn{
  align-self: flex-end;
  color:var(--green-300);
  color:black;
  font-size:15px;
  background:transparent;
  border:none;
}

.section-cart {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.section-cart > div:first-child {
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 1024px) {
  .section-cart {
    flex-direction: row;
  }

  .section-cart > div:first-child {
    flex: 1 0 60%;
    box-sizing: border-box;
    margin: 10px; /* Adjust this value as needed */
  }

  .section-cart > div:last-child {
    flex: 1 0 30%;
    box-sizing: border-box;
    margin: 10px; /* Adjust this value as needed */
  }
}

@media(max-width:992px){
  .section-cart {
    max-width:600px;
    margin:0 auto;
  }
}
.delete-btn:hover{
  color:red;
}
 .bg-color{
  display:flex;
  justify-content:center;
  align-items:center;
  height:1.5rem;
  background:yellow;
  padding:0rem 1.5rem;
  margin:0;
  font-size:13px;
 }
 .color-div{
  background:#befad3;
  padding:0.5rem;
  span,input,p{
    cursor:pointer;
  }
  input{
    margin-left:1rem;
  }

 }
 .checkout-btn {
  margin-top:1rem;
  display: flex;
  justify-content: space-between;
  flex-direction:column;
  align-items: center;
  button{
    width:100%;
    height:50px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight:600;
    font-size:19px;
    text-transform:uppercase;
 
  }
}

 .custom-span {
  font-size: 13px;
  line-height: 1.3 !important;
  padding: 0;
  margin: 0;
}
.custom-span-main{
  font-size: 14px;
  font-weight:500;
}
.strike-p{
  text-decoration: line-through;
  font-size:12px;
}
 .items-column-div:first-child {
  border-top: 1.4px solid var(--grey-50);
}
 .inline-div{
  margin-left:auto;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
 }
 .inline-p{
  display:flex;
  flex-direction:row;
  gap:1rem;
 }
 .coupon{
  border: 1px solid rgba(50, 50, 93, 0.1);
  border-radius:5px;
  p{
   margin-bottom:0.5rem;
  }
 }
 .coin-img{
  width:15px;
  padding:0;
  margin-left:0.25rem;
  height:15px;
 }
 .redeem-content{
   display:flex;
   flex-direction:column;
   img{
     width:15px;
     padding:0;
     margin-left:0.25rem;
   }
 }
 .redeem-content-offer{
   display:flex;
   flex-direction:row;
   gap:0.25rem;
 }
 .off-percentage{
   font-weight:600;
 }
 .add-btn{
  background:#23398E;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
 }
 .remove-btn{
    background:#EF4040;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
 }
 .coupon-btn{
   width:100%;
 
   padding-bottom:0.5rem;
   display:flex;
   flex-direction:column;
   padding-left:0.5rem;
   padding-right:0.5rem;
   input[type="text"] {
     border-radius: 5px 0 0 5px;
     outline: none;
     max-width: 50%;
   }    
   button{
     width:130px;
     max-height:44px;
     border-radius:0px 5px 5px 0px;
   }
 }
 p{
   svg{
     padding-top:0.1rem;
    }
 }
 .icon-p{
   
 }
 .location-btn{
  width:100px !important;
 }
 .redeem-content-input {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  
  input, button {
     box-sizing: border-box;
  }

  input {
    max-width:70% !important;
     border: 2px solid var(--grey-50);
     flex: 0 0 70%; 
  }

  button {
     flex: 0 0 30%; 
  }
}



 .coupon-div{
   display:flex;
   flex-wrap:wrap;
   justify-content:space-between;
   gap:0.5rem;
   max-height: 0;
   overflow: hidden;
   transition: max-height 0.5s ease-out;
}

.coupon-div.set-height {
   max-height: 500px; 
   transition: max-height 0.5s ease-in;
}
.subtotal{
  margin-top:1rem;
  max-width:90%;
  display:flex;
  flex-direction:column;
  .header-p{
    font-weight:600;
  }
  p{
    margin:0;
  }
  div{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    p{
      margin:0;
      font-size:13.5px;
      color:var(--grey-500);
    }
  }
}
.line-p{
  border-bottom:1px solid rgba(50, 50, 93, 0.1);
  padding-bottom:0.5rem;
}
.coins-div{
 display:flex;
 flex-wrap:wrap;
 justify-content:space-between;
 gap:0.5rem;
}
 .available-coupons{
   display:flex;
   flex-direction:column;
   gap:0.5rem;
   p{
     margin-top:0.5rem;
     margin-bottom:0.5rem;
   }
 }
 .redeem-options{
  width:90%;
   display:flex;
   flex-direction:row;
   justify-content:space-between;
   gap:1.5rem;
  
 
   border-radius:5px;
   padding-left:0.5rem;
   padding-right:0.5rem;
   p{
     cursor:pointer;
     margin-top:0.5rem;
   }
 }
 .coin-div{
   display:flex;
     flex-wrap:wrap;
     justify-content:space-between;
     gap:0.5rem;
     transition: max-height 0.5s ease-out;
 }
 .coins-count{
   display:flex;
   flex-direction:row;
   gap:1rem;
   p{
     margin-top:0.5rem;
   }
   img{
    width:15px;
    padding:0;
    margin-left:0.5rem;
   }
 }
 .check-box{
   width:48%;
   display:flex;
   background: rgb(2,0,36);
   background: linear-gradient(263deg, rgba(2,0,36,1) 0%, rgba(135,222,174,1) 0%, rgba(5,165,148,1) 100%, rgba(0,212,255,1) 100%);
   color:#fff;
   max-width:300px;
   cursor:pointer;
   
   flex-direction:row;
   justify-content:space-between;
   align-items:center;
   height:90px;
   padding:0.5rem;
   border-radius:5px;
   min-width:250px;
   flex-wrap:wrap;
   gap:0rem;
   input{
     width:30px;
   }
   
 }
 .active{
   border-bottom:2px solid var(--green-300);
 }
 .Login-warning-p {
  background-color: #FDFFAB; /* Soft yellow background for visibility */
  padding: 0.5rem; /* Increased padding for better spacing */
  font-size: 1rem; /* Larger font size for readability */
  font-weight: bold; /* Bold font for emphasis */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  
  margin: 1rem 0; /* Add space above and below the paragraph */
}

.Login-warning-p a {
  color: var(--green-300); /* Soft green color for the link */
  text-decoration: none; /* No underline for a clean look */
  transition: color 0.3s ease-in-out; /* Smooth color transition for hover effect */
  font-weight: bold; /* Bold font for the link */
  padding: 0.25rem 0.5rem; /* Padding for the link for a button-like appearance */
 
  border-radius: 4px; /* Rounded corners for the link/button */
}

.Login-warning-p a:hover {
  // color: var(--green-00);
}

.Login-warning-p a:active {
  color: var(--green-700); /* Even darker green for the active state */
  
}



 .redeem-btn{
   width:70px !important;
   background:var(--grey-10);
   color:#228f47;
   font-size:12px;
   border-radius:5px;
   display:flex;
   justify-content:center;
   align-items:center;
   padding:0.25rem 0;
 }
 .add-coupons{
   display:flex;
   flex-direction:row;
 }

@media(max-width:580px){
  margin:0 auto;
  padding:0;
  img{
    width:70px;
    height:80px;
  
   }
   h5{
    font-size:15px;
   }
   .items-column-div{
    gap:0.25rem;
   }
   h5{
    margin-bottom:1rem;
    margin-top:1rem;
   }
   .row-div{
    gap:0.25rem;
   }
   .item-detail{
    p{
      font-size:12px !important;
    }
   }
   .color-div{
    padding:0.25rem;
    span{
      font-size:13px;
    }
    .custom-span{
      font-size:11px;
    }
   }
   .cart-user-form{
    p{
      margin:0;
      font-size:13px;
      padding-bottom:0.25rem;
    }
   }
   .redeem-options{
    p{
      margin:0;
      font-size:13px;
      padding-top:0.25rem;
    }
   }
   .redeem-content-input{
    margin-top:0.25rem;
    input,button{
      height:34px;
      font-size:13px;
    }
    button{
      font-size:13px;
      padding:0;
      margin:0;
      
    }
   }
   .checkout-btn{
    margin-bottom:1rem;
    button{
      font-size:15px;
      padding:0;
      height:34px;
    }
   }
}
  

`

const AmountButtons = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 1fr 0.75fr;
  align-items: center;
  border: 1.4px solid var(--grey-100);
  border-radius: 5px;
  height:2.6rem;
  h3 {
    grid-column: 2; 
    margin-bottom: 0;
    font-size: 17px;
    padding: 0 0.5rem;
    height: 2rem;
    margin: 0 auto;
    padding-top: 0.35rem;
    text-align:center;
    width:50px;
  }

  button {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;
    margin:0;
    
  }
  .decrease{
    border-right: 1.4px solid var(--grey-100);
  }
  .amount-btn {
    grid-column: 1; 
  }

  .color {
    height:104%;
    grid-column: 3; 
    border-radius: 0 5px 5px 0;
    background: var(--green-300);
    color: #fff;
    width:103%;
  }

  h2 {
    margin-bottom: 0;
  }
  @media (max-width: 580px) {
    grid-template-columns: 0.5fr .5fr 0.5fr;
    height:2rem;
    h3{
      padding:0;
      margin:0;
      text-align:center;
    }
    .amount{
      width:30px;
      font-size:13px !important;
      padding-top:0.35rem;
      height:99%;
      margin-bottom:auto;
    }
    .color {
      // height:97%;
    }
    .amount-btn{
      width:30px;
      padding:0 !important;
      height:99%;import DeliveryChargeRange from './support/DeliveryChargeRange';

      margin-bottom:auto;
    }
  }
}

`