import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { addTowishList, getWishList } from '../../feature/user/userSlice';
import { toast } from 'react-toastify';
import LazyImage from './LazyImage';


//showing products which is triggered click on the grid view
const Product = ({ photos, title, scienticName, price, _id, flashSaleInfo, category, maincategory, subcategory, dimensions }) => {
  const [show, setShow] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { wishListItems } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  const handleContainerHover = () => {
    if (photos.length > 1) {
      setHovered(true);
    }
  };

  const handleContainerLeave = () => {
    setHovered(false);
  };

  const addWishlist = (product, e) => {
    const wishListObject = JSON.parse(product);
    dispatch(addTowishList(wishListObject[0]))
    dispatch(getWishList())
  }

  let sortedDimensions = dimensions ? dimensions.slice().sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price)) : [];

  const oldPrice = (Number(sortedDimensions[0]?.Price))
  const newPrice = flashSaleInfo && (oldPrice - ((flashSaleInfo.OfferPercentage / 100) * oldPrice))




  // title,_id,mainCategory,subCategory,category,totalPrice:(flashSaleInfo ? newPrice : total),price:price, potName:itemPot,size:itemLength,productQuantity:amount,image:activeImageName.url
  const wishListItem = [
    {
      dimension: sortedDimensions[0],
      image: photos[0]?.url,
      price: price,
      totalPrice: oldPrice,
      title: title,
      maincategory: maincategory,
      category: category,
      subcategory: subcategory,
      productQuantity: 1,
      productId: _id,
    },
  ];
  wishListItem.forEach((item) => {
    if (flashSaleInfo) {
      if (newPrice !== 0) {
        item.flashSalePrice = newPrice;
      }
      if (flashSaleInfo.Status === "Unlimited") {

        if (flashSaleInfo.OfferPercentage) {
          item.flashSaleDiscount = flashSaleInfo.OfferPercentage;
        }
        if (flashSaleInfo.Status) {
          item.Status = flashSaleInfo.Status;
        }
      } else {
        if (flashSaleInfo.StartDate) {
          item.flashSaleStartDate = flashSaleInfo.StartDate;
        }
        if (flashSaleInfo.StartTime) {
          item.flashSaleStartTime = flashSaleInfo.StartTime;
        }
        if (flashSaleInfo.TimeInHours) {
          item.flashSaleEndTime = flashSaleInfo.TimeInHours;
        }
        if (flashSaleInfo.OfferPercentage) {
          item.flashSaleDiscount = flashSaleInfo.OfferPercentage;
        }
      }
    }
  });
  //
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the screen width in the state
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const truncateTitle = (title, length) => {
    return title.length > length ? title.substring(0, length) + '...' : title;
  };

  const isProductInWishlist = (Id) => {
    return (wishListItems || []).some(item => item.productId === Id);
  }
  const addedFav = isProductInWishlist(_id);

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className='card'>
      <div className='cardContent'>
        <Link to={`/products/${_id}`}>

          <div
            className={`containers ${hovered ? 'hovered' : ''}`}
            onMouseEnter={handleContainerHover}
            onMouseLeave={handleContainerLeave}
          >
            {flashSaleInfo && <p className='percentage'> {flashSaleInfo.OfferPercentage}<span>%</span><span className='space'>off</span> </p>}
            {photos && (
              <>
                <LazyImage src={photos[0]?.url} alt={title} className={`image ${hovered ? 'hidden' : ''}`} draggable="false" />
                {photos.length > 1 && (
                  <img
                    src={photos[1].url}
                    alt={title}
                    className={`image ${hovered ? '' : 'hidden'}`} draggable="false"
                  />
                )}
              </>
            )}

          </div>

          <div class="footer-big">
            <div class="item-1">
              <div className="title">
                <p className="title-text">
                  {title && (screenWidth < 480
                    ? (title.length > 24
                      ? title.substring(0, 24) + '..'
                      : title)
                    : title.length > 24
                      ? title.substring(0, 24) + '...'
                      : title)}
                </p>
                <span className="scientific-name">
                  {scienticName
                    ? (screenWidth < 480
                      ? (scienticName.length > 19
                        ? scienticName.substring(0, 21) + '..'
                        : scienticName)
                      : scienticName.length > 20
                        ? scienticName.substring(0, 21) + '...'
                        : scienticName)
                    : '\u00A0'}
                </span>
              </div>

            </div>
            <div class="item-2">
              <Link to={`/products/${_id}`} name="mainCategory" draggable="false">
                <button class="add-to-cart-btn" >View</button>
              </Link>

              {newPrice &&
                <span className="price2"><span class="price2">AED&nbsp;</span>{oldPrice} </span>}
              <span class="price1"><span class="currency">AED&nbsp;</span>{flashSaleInfo ? newPrice : oldPrice}</span>

            </div>
          </div>

          <div className='footer-mobile'>
            <div className='title'>
            <p className="first" style={{ fontSize: '13px', fontWeight: '500px' }}> {title && title.length > 35 ? title.substring(0, 35) + '...' : title}</p>
           
              <p className="second"> {scienticName
                            ? (screenWidth < 480
                              ? (scienticName.length > 10
                                ? scienticName.substring(0, 10) + '..'
                                : scienticName)
                              : scienticName.length > 10
                                ? scienticName.substring(0, 10) + '...'
                                : scienticName)
                            : '\u00A0'}
                            {newPrice ? (
                              <span style={{paddingLeft:'20px'}}><span>AED&nbsp;</span>{parseFloat(oldPrice?.toFixed(2))} </span>
                            ):(
                              '\u00A0'
                            )
                            } </p>



            </div>
            <div className='price'>
              <Link to={`/products/${_id}`} name="mainCategory" draggable="false">
                <button class="add-to-cart-btn2" >View</button>
              </Link>
              {newPrice ? <><h5 className='first'><span>AED</span>{parseFloat(newPrice?.toFixed(2))} </h5>
              </> : <h5 className='first'><span>AED&nbsp;</span>{parseFloat(oldPrice?.toFixed(2))}</h5>}
            </div>
          </div>

        </Link>
        <button className={`card-top-btn ${show ? 'visible' : ''}`} onClick={(e) => { setShow(!show); addWishlist(JSON.stringify(wishListItem)); }}>
          {addedFav ? <MdFavorite /> : <MdFavoriteBorder />}
        </button>
        </div>
      </div>

    </Wrapper>
  );
};
const Wrapper = styled.article`
  position: relative;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  transition: box-shadow 0.3s ease;
  border-radius: 0.5rem;
  text-transform:capitalize;
  user-select: none; /* Prevent text selection on touch/drag */
  -webkit-tap-highlight-color: transparent; /* Remove blue highlight on mobile */
  outline: none; /* Remove outline on focus */

  .second {
    display: flex;
    
    justify-content: space-between;
    white-space: nowrap;
    font-size:11px;
  }

  .second h5 {
    margin: 0;
    font-weight: normal;
  }

  .second span {
    margin-right: 0.2em;
    text-decoration: line-through;
    text-align:right;
  }

  .card-top-btns {
    position: absolute;
    top: 7%;
    right: 2.5%;
    font-size: 1.7rem;
    z-index: 222;
    border: none;
    background: transparent;
    display: none;
    svg {
      background: white;
      color: red;
      border-radius: 2rem;
      padding: 0.25rem;
      padding-bottom: 0.2rem;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    }
  }
  
  .button-wrapper:hover .card-top-btns {
    display: block !important;
  }

  .card {
    max-width: 270px;
    position: relative;
    user-select: none;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
    border-radius: 8px; /* Increased border radius */
    overflow: hidden; /* Ensures content stays within the border */
    padding: 8px;
    user-select: none; /* Prevent text selection on touch/drag */
  -webkit-tap-highlight-color: transparent; /* Remove blue highlight on mobile */
  outline: none; /* Remove outline on focus */
  }
  // .card:hover .second-image  {
  //   opacity: 1;
  //   /* Show the second image on hover */
  // }
  
  .card:hover {
    
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Increases box shadow on hover */
  }

  @media (max-width: 600px) {
  .card:hover {
 
    box-shadow: none;
    
}



}
  
  .currency {
    font-size: 13px;
    font-weight: 900; /* semi bold */
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    gap: 8px; 
  }
  .containers {
    position: relative;
    background: var(--clr-black);
    border-radius: var(--radius);
    max-width: 224px;
    display: flex;
    flex-direction: column;
    margin:0 auto;
    
    span {
      color: var(--grey-900);
      margin-left: 0.2rem;
      margin-right: 0.4rem;
      height: 1rem;
      font-size: 15px;
      letter-spacing: var(--letterSpacing);
      display: inline-block;
      margin-top:0.5rem;
    }
  }
  .title {
    display: flex; /* Use flexbox to control alignment */
    flex-direction: column; /* Stack elements vertically */
  }
  .title p {
    margin: 0; /* Remove default margin */
  }
  
  .title-text {
    margin: 0;
  }
  .scientific-name {
    margin: 0;
    font-size: 12px; /* Adjust font size */
  }
  .title-text,
.scientific-name {
  text-align: left; /* Ensures text in title-text and scientific-name starts from the left */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  color:black;
  display: block; /* Ensures each item starts on a new line */
  font-size: 10px;
  font-weight:400px;
}


  .add-to-cart-btn {
    background-color: #558D4C;
    color: white;
    font-size: 12px; /* Adjust font size */
    padding: 5px 4px; /* Adjust padding */
    border-radius: 5px;
    border: none;
    cursor: pointer;
    width:70px;
  }

  .add-to-cart-btn2 {
    background-color: #558D4C;
    color: white;
    font-size: 10px; /* Adjust font size */
    padding: 3px 3px; /* Adjust padding */
    border-radius: 5px;
    border: none;
    cursor: pointer;
    width:49px;
    
  }
  
  .price1 {
  
    font-size: 16px;
    font-weight: 900; /* semi bold */
  }
  
  .price2 {
    font-size: 12px;
    font-weight: 600; /* semi bold */
    text-decoration: line-through; /* adds strikethrough effect */
  }

  img {
    object-fit: cover;
    display: block;
    max-width: 216px;
    width: 100%;
    height:270px;
    border-radius: var(--radius);
    transition: var(--transition);
    margin:0 auto;
  }
  .image {
    display: block;
    border-radius: var(--radius);
    transition: var(--transition);
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
    height:270px;
    &.hidden {
      display: none;
    }
  }
}
.percentage{
  position:absolute;
  top:-3%;
  // left:2%;
  font-weight:600;
  text-transform:uppercase;
  background:yellow;
  width:87px;
  text-align:center;
  padding:0.25rem !important;
  font-size:14px !important;
  color:black;
  span{
    font-size:14px !important;
      margin:0 !important;
      padding:0 !important;
  }
  .space{
    padding-left:0.25rem !important;
    padding-right:0.25rem !important;
  }
}

.card-top-btn {
  position: absolute;
  left: 82%;
  top: 0;
  font-size: 1.7rem;
  // z-index: 222;
  border: none;
  background: transparent;
  display: none !important;
  svg {
    background: white;
    color: red;
    border-radius: 2rem;
    padding: 0.25rem;
    padding-bottom: 0.2rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  }
}

.visible {
  display: block !important;
}

@media (max-width: 1570px) {
  .card{
    width: 240px;
  }
}

@media (max-width: 768px) {
   .card-top-btn {
    display: block !important;
    
  }
}



.paragraph{
  width:100%;
  margin:0;
  text-align:left;
  margin-left:0.2rem;
  font-size:15px;
  color:black;
  letter-spacing:0;
}
.old-price{
  text-decoration: line-through;
}

.footer-big {
  display: flex;
  flex-direction: column;
 
  gap: 0.25rem;
  p{
    margin-left:0 !important;
  }
}
.footer-mobile{
  display:none;
}
.item-2 {
  display: flex;
  align-items: center;
  color:black;
}
.item-1,.item-2{
  margin-top:0.5rem;
 display:flex;
 flex-direction: row;
 justify-content: space-between;
 text-align: left;
 p{
  // width:70%;
 
 }
 h5{
  width:30%;
  padding:0 !important;
  margin:0 !important;
  text-align:right;
  font-size:15px;
  color:black;
  letter-spacing:0;
 }
 
}
.item-1{
  h5{
    display:flex;
    gap:0.25rem;
    align-items:flex-end;
    justify-content:flex-end;
  }
  p,h5{
    font-weight:500 !important;
    font-size:13px !important;
  }
  
  
}

.item-2{
 p,h5{
  font-size:14px !important;
 }
 h5{
  width:50%;
 }
}


  .btn-button {
    font-size: 0.85rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    background: var(--green-500);
    display: flex;
    color: var(--white);
    margin-top:1rem;
    font-weight:600;
    width:50%;
    justify-content:center;
  }
  

  .btn-button:hover {
    background: var(--green-300);
    color: white;
  }


  .hide-div {
    position: absolute;
    top: 98%;
    left: 0;
    width: 100%;
    height:2.2rem;
    background: white;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    border-radius: 0rem 0rem 0.5rem 0.5rem ;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    padding-left:0.75rem;
    
  .cart{
    display:flex;
    font-size:1rem;
    color:var(--grey-300) !important;
    font-weight: 500; 
    border-radius:1rem;

  }
  .cart:hover{
    color:var(--green-300)!important;
    transition: color 0.2s;
  }
}



@media (max-width: 772px) {

.item-1{
  p,h5{
    font-size:13px;
  }
}



.item-2{
 p,h5{
  font-size:12px !important;
 }
}
.footer-big{
  gap:0;
  p{
    margin:0 !important;
  }
}
}

@media(max-width:580px){
 
    
  
  .footer-big{
    display: none;
    flex-direction: column;
    gap: 0.25rem;
  }
  .footer-mobile{
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    
    
    h5,p{
      margin:0;
      padding:0;

    }
    .title{
      margin-top:0.25rem;
      display:flex;
      flex-direction:column;
      align-items:flex-start;
      gap:0.35rem;
      
      
    }
    .price{
      gap:0.25rem !important;
    
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

      h5{
        font-size:13px;
        padding:0 !important;
        margin:0 !important;
        font-weight: 900;

        span{
          font-size:11px;
          font-weight: 900;
        }
        
      }
     
    }
    .first{
        
      font-weight:500;
      color:black;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      text-align: left;
      height: 2.4em;
      font-size:13px;
      h5{
        padding:0 !important;
        margin:0 !important;

      }
    }
    .second{
      color:var(--grey-900);
      font-size:11px !important;
    }
  
  
    // .second h5 {
    //   margin: 0;
    //   font-weight: normal;
    // }
  
  
    
    .line{
      text-decoration: line-through;
    }
  }
}

@media(max-width:556px){
  .card {
    width: 210px;
  }
  
  .image{
    // width:200px ;
    // height:210px;
  }
  .percentage{
    width:80px !important;
    top:-5%;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    padding:0.25rem;
    font-size:13px !important;
    span{
      margin:0 !important;
  }
  .space{
    padding-left:0.25rem !important;
  }
  }
  .card-top-btn{
    font-size:1.35rem;
    left:82%;
    top:1.3%;
  svg{
    background:white;
    color:red;
    border-radius:2rem;
    padding:0.1rem;
    padding-bottom:0rem;
  }
 
}
}



@media (max-width: 480px) {
  .image {
    width: 175px !important;
    max-height:210px !important;
  }
  .card {
    width: 185px;
  }
 

  
}

@media (max-width: 460px) {
  .image{
    max-width:175px !important;
    max-height:215px !important;
  }
  .card {
    width: 182px;
  }
  
  .footer-mobile .price h5{
    font-size: 13px;
  }
  
}
@media (max-width: 400px) {
  .image{
    max-width:170px !important;
    max-height:210px !important;
  }
  .card {
    width: 175px;
  }
  
}

@media (max-width: 360px) {
  .image{
    max-width:145px !important;
    max-height:200px !important;
  }
  .card {
    width: 160px;
  }

  .footer-mobile .price h5 span {
    font-size: 9px;
    font-weight:900;
  }
  .card-top-btn{
    left:77%;
  }
  
  
}


@media (max-width: 333px) {
  .image{
    max-width:185px !important;
    max-height:187px !important;
  }
  .card {
    width: 200px;
  }

  .footer-mobile .price h5 span {
    font-size: 12px;
  }
  .footer-mobile .price h5{
    font-size: 14px;
    
  }
  
  
  
}
@media (max-width: 380px) {
  // grid-template-columns: repeat(2, 130px); 
  // grid-auto-rows: 0.75fr;
  // gap: 15%rem; 
  // max-width: 140px;
  // width:140px !important;
  // img{
  //   max-width:140px !important;
  //   max-height:170px !important;
  //   width: 140px !important;
  //   max-height:170px !important;
  // }import LazyImage from './LazyImage';

}
`;

export default Product;