import React, { useEffect, useState } from 'react';
import { Navbar, Footer, SearchSection, Categories, Featured, ImageSlider, SideBar, Cart, CategoriesIcon, NewsletterForm, FlashSaleHome } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { getCart, getUser } from '../feature/user/userSlice';
import styled from 'styled-components';
import ImageBanner2 from '../components/support/ImageBanner2';
import { Helmet } from 'react-helmet';
import { closeScrollbar } from '../feature/modal/modalSlice';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import GoogleLoading from '../components/GoogleLoading';

const Products = () => {
  const { myUser } = useSelector((store) => store.user);
  const { isOpenCart } = useSelector((store) => store.modal);
  const [loading, setLoading] = useState(true);
  const [googleLogin, setGoogleLogin] = useState('');

  const [fromCheckoutLogin, setFromCheckoutLogin] = useState('');
  const [fromCheckout, setFromCheckout] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('currentPageNo', 1);
    dispatch(closeScrollbar());
  }, [dispatch]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        await dispatch(getUser());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCart());
    const googleLogin = localStorage.getItem('googleLoginButton');
    const fromCheckoutLogin = localStorage.getItem('fromCheckoutLogin');
    setFromCheckoutLogin(fromCheckoutLogin);
    setGoogleLogin(googleLogin);
    const fromCheckout = localStorage.getItem('fromCheckout');
    setFromCheckout(fromCheckout);
  }, [myUser, isOpenCart]);

  if (loading) {
    return googleLogin === 'True' ? <GoogleLoading /> : <Loading />;
  }

  if (fromCheckout === 'True') {
    if (fromCheckoutLogin === 'True') {
      navigate('/user/login');
    } else {
      navigate('/user-cart');
      localStorage.setItem('fromCheckout', 'False');
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name="title" content="Home | Shop Indoor & Outdoor Plants Online | UAE | myplantstore.me" />
        <meta name='keywords' content="Indoor Plants, Outdoor plants, Flowering Plants, Fertilizers, Plant care, Seed care, Where to buy plant care products UAE., Plant Maintenance, Plant Store Near Me, Plant Store Online" />
        <meta name='description' content="Discover indoor outdoor plants, plant grow kits, care products, and more at our online store. Enjoy the best delivery in UAE. Find everything for your indoor gardens at our plant store near you." />
      </Helmet>
      <Cart />
      <Navbar />
      <SearchSection />
      <SideBar />
      <Categories />
      <Wrapper>
        <ImageSlider />
        <FlashSaleHome />
        <ImageBanner2 />
        <Seperator>
          <CategoriesIcon />
          <Featured />
        </Seperator>
        <NewsletterForm />
        <Footer />
      </Wrapper>
    </>
  );
};

const Seperator = styled.div`
  width: 100%;
  min-height: 80vh;
`;

const Wrapper = styled.div`
  padding: 0 10px;

  @media (max-width: 992px) {
    padding: 0;
  }
`;

export default Products;
