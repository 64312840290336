import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { PiShoppingCartDuotone } from 'react-icons/pi';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { addTowishList, getWishList } from '../../feature/user/userSlice';
import { toast } from 'react-toastify';
import LazyImage from './LazyImage';

const SingleFeaturedRowMobile = ({ category, mCategory }) => {
  const { featuredProducts } = useSelector((store) => store.plants);
  const { wishListItems } = useSelector((store) => store.user);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const selectedProducts = useMemo(() => {
    if (category && category.length) {
      return featuredProducts.filter((product) => product.FeatureTag.includes(category));
    } else if (mCategory) {
      return featuredProducts.filter((product) => product.maincategory.some((cat) => mCategory[0].includes(cat.trim())));
    } else {
      return [];
    }
  }, [category, mCategory, featuredProducts]);

  const addWishlist = (product) => {
    const wishListObject = JSON.parse(product);
    dispatch(addTowishList(wishListObject[0]));
    dispatch(getWishList());
  };

  const isProductInWishlist = (id) => wishListItems?.some((item) => item.productId === id);
  const handleViewAllClick = () => {
    // Navigate to the desired URL with the category as a parameter
    localStorage.setItem('currentPageNo', 1);
    localStorage.setItem('FeatureCategory', category);
    navigate(`/Feature/myplantstore/search/${category}`);
  };


  const [hovered, setHovered] = useState(false);

  const handleMouseEnter1 = () => {
    setHovered(true);
  };

  const handleMouseLeave1 = () => {
    setHovered(false);
  };



  return (
    <Wrapper className='featured-row'>
      <div className="category-line">
        {category ? (
          <>
            <div className="category-content">
              <div className="green-bar"></div>
              <h5 onClick={handleViewAllClick} style={{ cursor: 'pointer' }}>{category}</h5>

            </div>
            <svg
              onClick={handleViewAllClick}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="20"
              height="20"
              style={{ cursor: 'pointer' }} // Add cursor style for better usability
            >
              {/* Background circle */}
              <circle cx="8" cy="8" r="7" fill="#00FF00" opacity={hovered ? 0.2 : 0} />

              {/* Icon */}
              <path
                d="m6.627 3.749 5 5-5 5"
                stroke="#FFFFFF" // Change the stroke color to white
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        ) : (
          <>
            <h5>you may also like</h5>
            <AiOutlineArrowRight />
          </>
        )}
      </div>
      <MediaScroller>
        {selectedProducts.map((product, idx) => {
          const {
            title,
            _id,
            photos,
            price,
            pots,
            allLengths,
            flashSaleInfo,
            stock,
            scienticName,
            category,
            maincategory,
            subcategory,
            dimensions,
          } = product;

          let sortedDimensions = dimensions ? [...dimensions].sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price)) : [];

          const oldPrice = Number(sortedDimensions[0]?.Price);
          const newPrice = flashSaleInfo && parseFloat((oldPrice - (flashSaleInfo.OfferPercentage / 100) * oldPrice).toFixed(2));

          const wishListItems = [
            {
              dimension: sortedDimensions[0],
              image: photos[0]?.url,
              price: price,
              totalPrice: oldPrice,
              title: title,
              maincategory: maincategory,
              category: category,
              subcategory: subcategory,
              productQuantity: 1,
              productId: _id,
            },
          ];

          wishListItems.forEach((item) => {
            if (flashSaleInfo) {
              if (newPrice !== 0) {
                item.flashSalePrice = newPrice;
              }
              if (flashSaleInfo.Status === 'Unlimited') {
                if (flashSaleInfo.OfferPercentage) {
                  item.flashSaleDiscount = flashSaleInfo.OfferPercentage;
                }
                if (flashSaleInfo.Status) {
                  item.Status = flashSaleInfo.Status;
                }
              } else {
                if (flashSaleInfo.StartDate) {
                  item.flashSaleStartDate = flashSaleInfo.StartDate;
                }
                if (flashSaleInfo.StartTime) {
                  item.flashSaleStartTime = flashSaleInfo.StartTime;
                }
                if (flashSaleInfo.TimeInHours) {
                  item.flashSaleEndTime = flashSaleInfo.TimeInHours;
                }
                if (flashSaleInfo.OfferPercentage) {
                  item.flashSaleDiscount = flashSaleInfo.OfferPercentage;
                }
              }
            }
          });

          const addedFav = isProductInWishlist(_id);

          return (
            <div className="button-wrapper" key={idx}>
              <div key={_id}>
                <div className='cardContent'>

                  <Link to={`/products/${_id}`} name="mainCategory">
                    {photos && (
                      <div className="image-container">
                        {flashSaleInfo && (
                          <p className="percentage">
                            {' '}
                            {flashSaleInfo.OfferPercentage}
                            <span>%</span>
                            <span className="space">off</span>{' '}
                          </p>
                        )}
                        <LazyImage src={photos[0]?.url} alt={title} className="image " draggable="false" />
                        {photos.length > 1 && <img src={photos[1]?.url} alt={title} className="image second-image" draggable="false" />}
                      </div>
                    )}
                    <div className="footer-big">
                      <div className="item-1">
                        <p className="paragraph">
                          {' '}
                          {title && (screenWidth < 480 ? (title && title.length > 9 ? title.substring(0, 9) + '..' : title) : title.length > 16 ? title.substring(0, 14) + '...' : title)}
                        </p>
                        {flashSaleInfo ? <h5>{newPrice} <span>AED</span></h5> : <h5>{oldPrice} <span>AED</span></h5>}
                      </div>
                      <div className="item-2">
                        <p className="paragraph">
                          {' '}
                          {scienticName
                            ? (screenWidth < 480
                              ? (scienticName.length > 9
                                ? scienticName.substring(0, 9) + '..'
                                : scienticName)
                              : scienticName.length > 16
                                ? scienticName.substring(0, 14) + '...'
                                : scienticName)
                            : '\u00A0'}
                        </p>
                        {newPrice && <h5 className="old-price">{oldPrice} <span>AED</span></h5>}
                      </div>
                    </div>
                    <div className="footer-mobile">
                      <div className="title">
                        <p className="first" style={{ fontSize: '13px', fontWeight: '500px' }}> {title && title.length > 35 ? title.substring(0, 35) + '...' : title}</p>
                        <p className="second"> {scienticName
                          ? (screenWidth < 480
                            ? (scienticName.length > 13
                              ? scienticName.substring(0, 13) + '..'
                              : scienticName)
                            : scienticName.length > 13
                              ? scienticName.substring(0, 13) + '...'
                              : scienticName)
                          : '\u00A0'}
                          {newPrice ? (
                            <span><span>AED&nbsp;</span>{parseFloat(oldPrice?.toFixed(2))} </span>
                          ) : (
                            '\u00A0'
                          )
                          } </p>
                      </div>
                      <div className="price">

                        <Link to={`/products/${_id}`} name="mainCategory" draggable="false">
                          <button class="add-to-cart-btn2" >View</button>
                        </Link>
                        {newPrice ? (
                          <>

                            <h5 className="first"><span>AED&nbsp;</span>{newPrice} </h5>

                          </>
                        ) : (
                          <h5 className="first"><span>AED&nbsp;</span>{oldPrice} </h5>
                        )}
                      </div>
                      
                    </div>
                  </Link>
                  <button className="card-top-btns" onClick={(e) => addWishlist(JSON.stringify(wishListItems))}>
                        {addedFav ? <MdFavorite /> : <MdFavoriteBorder />}
                      </button>
                  <div className="hide-div">
                    <button className="cart" type="button">
                      <PiShoppingCartDuotone />
                      Add To Bag
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </MediaScroller>
    </Wrapper>
  );
};

export default React.memo(SingleFeaturedRowMobile);


const MediaScroller = styled.div`
  width: 98%;
  margin: 0 auto;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-bottom: 1rem;
  gap: 0rem;
  align-items: flex-start;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  .second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    font-size:11px;
  }

  .second h5 {
    margin: 0;
    font-weight: normal;
  }

  .second span {
    margin-right: 0.2em;
    text-decoration: line-through;
  }

  .add-to-cart-btn2 {
    background-color: #558D4C;
    color: white;
    font-size: 10px;
    padding: 3px 3px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    width: 49px;
  }

  .prev {
    left: -3%;
  }

  .next {
    right: -3%;
  }

  .media-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 180px;
    font-size: 15px;
    gap: 0.25rem;
    border: 2px solid var(--grey-100);
    border-radius: 12px;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  }

  .category-scroll-img {
    width: 70px;
    height: 88px;
    border-radius: 10px 0px 0px 10px;
  }

  .snaps-inline {
    scroll-snap-type: inline mandatory;
    scroll-padding-inline: var(--size-3);
  }

  .snaps-inline > * {
    scroll-snap-align: start;
  }

  /* Hide scrollbar for WebKit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

 
  
  @media(min-width: 600px) {
    .scroll-buttons {
      position: absolute;
      top: 50%;
      font-size: 1.5rem;
      color: var(--green-500);
      cursor: pointer;
      transition: color 0.3s, transform 0.3s;
      opacity: 0;
    }
    
  }

  @media(max-width: 800px) {
    gap: 0.9rem;

    .category-row {
      max-width: 130px !important;
      height: 70px !important;
      margin-right: 0.25rem;
    }

    // p {
    //   font-size: 13px !important;
    // }

    .category-scroll-img {
      width: 50px !important;
      height: 69px !important;
    }

    .media-element {
      width: 160px;
    }
  }

  @media(max-width: 560px) {
    .category-row {
      height: 51px !important;

      p {
        font-size: 12px !important;
      }
    }

    .category-scroll-img {
      width: 70px;
      height: 50px !important;
      border-radius: 10px 0px 0px 10px;
    }

    .media-element {
      width: 140px;
    }
  }

  @media(max-width: 450px) {
    .media-element {
      width: 140px;
    }
  }
`;


const Wrapper = styled.section`

// .button-wrapper{
//   position:relative;
//   display: flex;
//   justify-content:flex-start;
//   align-items:center;
  
// }
.alice-carousel__stage-item.__cloned{
  
}

  .alice-carousel__stage-item{
   max-width:270px;
  }

width:95% ;
margin:0 auto;
position: relative;
.cardContent {
  display: flex;
  flex-direction: column;
  gap: 8px; 
}

.card{
  width:225px;
  position:relative;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Reduced box shadow */
  border-radius: 8px; /* Increased border radius */
  overflow: hidden; /* Ensures content stays within the border */
  padding: 8px;
  user-select: none; /* Prevent text selection on touch/drag */
  -webkit-tap-highlight-color: transparent; /* Remove blue highlight on mobile */
  outline: none; /* Remove outline on focus */
}
// .card:hover {

//   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Increases box shadow on hover */

// }
.alice-carousel__dots{
  display:none;
}
.alice-carousel__next-btn,
.alice-carousel__prev-btn {
  width:20px;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: var(--green-500);
  cursor: pointer;
}
.alice-carousel{
  margin-left:1rem;
}
.alice-carousel__prev-btn {
  left: -3rem;
  
}
img{
  object-fit:cover;
}
.alice-carousel__next-btn {

  right: 2rem;
}

/* Media query to hide buttons on small screens */
@media (max-width: 800px) {
  .alice-carousel__next-btn,
  .alice-carousel__prev-btn {
    display: none;
  }
}

  img{
    width:100% !important;
   height:220px !important;
   position:relative;
  }

.image-container {
  position: relative;
  width: 100% !important;

  
}
.hide-div{
  display:none;
}

.cart{
  display:flex;
  font-size:1rem;
  color:var(--grey-300) !important;
  font-weight: 500; 
  border-radius:1rem;
  border:none;
  background:transparent;
  
}
.cart:hover{
  color:var(--green-300)!important;
  transition: color 0.2s;
}

.button-wrapper {
  position: relative;
  display: flex;
  justify-content:left;
  align-items: center;
  width:fit-content;
}

// .card-top-btns {
//   position: absolute;
//   top: 0; /* Align to the top */
//   right: 0; /* Align to the right */
//   font-size: 1.7rem;
//   border: none;
//   background: transparent;
//   svg {
//     background: white;
//     color: red;
//     border-radius: 2rem;
//     padding: 0.25rem;
//     padding-bottom: 0.2rem;
//     box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
//   }
// }
.card-top-btns {
  position: absolute;
  top: 0; /* Use viewport units for better consistency */
  right: 1vw; /* Use viewport units for better consistency */
  font-size: 1.7rem;
  border: none;
  background: transparent;
  -webkit-transform: translateZ(0); /* Ensures GPU acceleration in Safari */

  svg {
    background: white;
    color: red;
    border-radius: 2rem;
    padding: 0.25rem;
    padding-bottom: 0.2rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  }
}

/* Specific styles for iOS devices */
@media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .card-top-btns {
    top: 0; /* Use viewport units for better consistency */
    right: 1vw;
  }
}



// .button-wrapper:hover .card-top-btns {
//   display: block !important;
// }


.footer-mobile{
  display:none;
}
.second-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:260px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

}

.card:hover .second-image  {
  opacity: 1;
  /* Show the second image on hover */
}
.card:hover  .cart{
display:block;
}

@media (max-width: 600px) {
  .card:hover {
    transform: none; /* Disables transform on hover */
    
  }
}
.percentage{
  position:absolute;
  z-index:99;
    top:2%;
    left:1%;
    font-weight:600;
    text-transform:uppercase;
    background:yellow;
    width:87px !important;
    text-align:center;
    padding:0.25rem !important;
    font-size:14px !important;
    span{
      font-size:14px !important;
        margin:0 !important;
        padding:0 !important;
    }
    .space{
      padding-left:0.25rem !important;
    }
}

  p{
    width:100%;
    margin:0;
    text-align:left;
    margin-left:0.2rem;
    font-size:13px;
    color:black;
    letter-spacing:0;
  
  }
 
  .category-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;/* Aligns items to the start and end of the container */
    margin: 2rem 0 1.25rem 0;
  }

  .category-content {
    display: flex;
    align-items: center;
  }
  
  .green-bar {
    width: 0.475rem; /* Adjusted thickness */
    height: 2rem;
    background: var(--green-300);
    border-radius: 3.75px;
    margin-right: 0.5rem;
}

  
  h5 {
    text-transform: uppercase;
    font-weight: 700 !important;
    margin: 0;
    font-size: 15px;
    color: black;
  }
 
  
  
  svg {
    font-size: 1.25rem;
    color: var(--green-500);
  }
  .item-1,.item-2{
   display:flex;
   flex-direction: row;
   justify-content: space-between;
   p{
    width:70%;
   
   }
   h5{
    width:30%;
    text-align:right;
   }
  }
  .item-1{
    p{
      font-weight:600;
    }
  }

  .item-2{
   p,h5{
    font-size:14px !important;
   }
   h5{
    width:50%;
   }
  }
  
  .category-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2.75rem 0 1.25rem 0;
    gap: 0.75rem;
    
    // div {
    //   width: 0.75rem;
    //   height: 2rem;
    //   background: var(--green-300);
    //   border-radius: 3.75px;
    // }
    // h5 {
    //   text-transform: uppercase;
    //   font-weight: 700 !important;
    // }
    // svg{
    //   font-size:1.25rem;
    //   color:var(--green-500);
    // }
  }
  .old-price{
    text-decoration: line-through;
  }
  h5 {
    text-transform: uppercase;
    font-weight: 500;
    padding: 0;
    margin: 0;
    font-size:15px;
    color:black;
    letter-spacing:0;
  }

  .footer-big {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    p{
      margin-left:0 !important;
    }
  }
  .paragraph{
    width:100%;
    margin:0;
    text-align:left;
    margin-left:0.2rem;
    font-size:15px;
    color:black;
    letter-spacing:0;
  }
  .item-1,.item-2{
    margin-top:0.5rem;
   display:flex;
   flex-direction: row;
   justify-content: space-between;
   p{
    width:70%;
   
   }
   h5{
    width:30%;
    padding:0 !important;
    margin:0 !important;
    text-align:right;
    font-size:15px;
    color:black;
    letter-spacing:0;
   }
  }
  .item-1{
    h5{
      display:flex;
      gap:0.25rem;
      align-items:flex-end;
      justify-content:flex-end;
    }
    p,h5{
      font-weight:600 !important;
    }
    
  }
  
  .item-2{
   p,h5{
    font-size:14px !important;
   }
   h5{
    width:50%;
   }
  }

  .alice-carousel__prev-btn-item::after,
  .alice-carousel__next-btn-item::after {
      content: "➔"; /* New icon */
      color: var(--green-500); /* Icon color */
      font-size: 30px; /* Icon size */
      // display: none; /* Hide the icon by default */
  }
  
  
  .alice-carousel__prev-btn-item::after{
      transform: rotate(180deg);
      display: inline-block; /* Show the rotated icon */
  }
 .alice-carousel__next-btn-item::after {
    display: inline-block;
  }
  
  .alice-carousel__prev-btn-item span[data-area="<"]::after,
  .alice-carousel__next-btn-item span[data-area=">"]::after {
      content: ""; 
  }
  



  
  @media(max-width:830px){
    .card{
      width:210px !important;
    }
    img{
      width:100% !important;
     height:250px !important;
    }
    .react-multiple-carousel__arrow--left{
      display:none;
    }
    .react-multiple-carousel__arrow--right{
      display:none;
    }
  }
  @media(max-width:770px){
    .card{
      width:220px !important;
    }
    img{
      width:100% !important;
     height:280px !important;
    }
    .alice-carousel{
      margin-left:0;
    }
  }
  @media(max-width:992px){
    width:93% ;
  }
  @media(max-width:600px){
    .react-multi-carousel-list{
      padding-bottom:2rem;
    }
    .react-multi-carousel-track{
      padding-bottom:2rem;
    }
    .card{
      width:168px !important;
      
    }
    .button-wrapper{
   
      margin-right:0.5rem;
    }
    .image-container{
      width: 140px !important;
      height: 160px !important;
    }
    img {
      width: 150px !important;
      height: 175px !important;
    }
    .footer-big{
      display: none;
      flex-direction: column;
      gap: 0.25rem;
    }
    .footer-mobile{
      display: flex;
      flex-direction: column;
      gap: 0.35rem;
      
      h5,p{
        margin:0;
        padding:0;
  
      }
  
      .title{
        margin-top:1rem;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        gap:0.35rem;

        
      }
      .price{
        gap:0.25rem !important;
        min-height:20px;
        text-align:right;
        justify-content: space-between;
        h5{
          font-size:13px;
          height:20px;
          padding:0 !important;
          margin:0 !important;
          
          
        }
        span{
          font-size:11px;
        }
        .second-line{
          font-size:8px;
        }
       display:flex;
       flex-direction:row;
      }
      .first{
        
        font-weight:500;
        color:black;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        height: 2.4em; /*
        font-size:13px;
        h5{
          padding:0 !important;
          margin:0 !important;
          font-size:10px;
        }
      }
      .second{
        color:var(--grey-900);
        font-size:12px !important;
      }
      .line{
        text-decoration: line-through;
      }
      .button-wrapper{
        justify-content:left;
      }
    }
    .percentage{
      width:80px !important;
    height:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    padding:0.25rem;
    font-size:13px !important;
    span{
      margin:0 !important;
  }
  .space{
    padding-left:0.25rem !important;
  }
  }
  

  @media(max-width:530px){
    height:325px !important;
    .button-wrapper{
      justify-content:space-between;
      max-width:165px;
    }
    
     .card{

      
     }
     .card-top-btns{
      font-size: 1.25rem;
      svg {
        padding:0.15rem;
      }
     }
     h5{
      font-size:13px;
     }
    //  p{
    //   font-size:13px;
    //  }
     .item-2{
      p,h5{
       font-size:12px !important;
      }
     }

  
     
  }
  @media(max-width:460px){
    .card{
      max-width:180px !important;
      
     }
     .item-1{
      h5{
        font-size:10px;
        font-weight:bold;
       }
       p{
        font-size:10px;
       }
     }
     .item-2{
      h5{
        font-size:10px !important;
      }
      p{
       font-size:11px !important;
      }
     }
     .react-multiple-carousel__arrow--left::before{
      color:transparent;
    }
    .react-multiple-carousel__arrow--right::before{
      color:transparent;
    }
    .button-wrapper{
        margin-right:0.25rem;
      }
  
  }
  @media(max-width:398px){
   
    
    .card {
        // width: 140px !important;
        // height: 170px !important;
      .image-container{
        width: 140px !important;
        height: 175px !important;
      }
      img {
        width: 150px !important;
        height: 175px !important;
      }
    }
    .category-line{
      margin:0rem 0 1.25rem 0;
    }
  }


  @meadia(max-width:365px){
    // .card-top-btns {
    //   left:78%;
    // }
  }

  @meadia(max-width:345px){
    // .card-top-btns {
    //   left:68%;
    // }
  }
`