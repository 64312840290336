import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import animationData from './Animation - 1708415690246.json'; // Import your Lottie animation JSON file

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // Your animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Wrapper className="Loading-section">
      <Lottie
        options={defaultOptions}
        height={200} // Adjust the height as needed
        width={200} // Adjust the width as needed
      />
    </Wrapper>
  );
};

export default Loading;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: grid;
  place-items: center;
  align-items: center;
  
  @media (min-width: 768px) {
    min-height: 70vh;
  }
  @media (max-width: 560px) {
    min-height: 50vh;
  }
`;
