import React, { useState } from 'react'
import styled from 'styled-components'
import Product from './Product'
import { useSelector } from 'react-redux'

//grid view of products
const GridView = ({products}) => {
  const{wishListItems} = useSelector((store)=>store.user)
 

  return (
  <Wrapper>
    <div className="products-container">
      {products.map((product)=>{
        return <Product key={product.id} {...product}/>
      })}
    </div>
  </Wrapper>)
}

const Wrapper = styled.section`
width:100%;
margin:0 auto;

  img {
    height: 220px;
  }

  .products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
    gap:1.75rem;
   width:100%;
    margin: 0 auto;
  }
  @media (max-width: 1044px) {
    .products-container {
      max-width:500px !important;
    }
  }
  @media (max-width: 772px) {
    .products-container {
      max-width:500px !important;
    }
  }

@media (max-width: 665px) {
  .products-container {
      grid-template-columns: repeat(2, 1fr); 
      max-width:500px !important;
  }
}
@media (max-width: 556px) {
  .products-container {
    grid-template-columns: repeat(2, 200px); /* Set the width of child elements to 200px */
    grid-auto-rows: 1fr; /* Create 2 rows in each row */
    gap: 10%rem; /* Adjust the gap between items */
    justify-content:center;

  }
}
@media (max-width: 480px) {
  width:95%;
  .products-container {
    grid-template-columns: repeat(2, 180px); /* Set the width of child elements to 200px */
    grid-auto-rows: 1fr; /* Create 2 rows in each row */
    gap: 0.6rem; /* Adjust the gap between items */
    justify-content:space-evenly;

  }
}
@media (max-width: 430px) {
  width:95%;
  .products-container {
    grid-template-columns: repeat(2, 188px); /* Set the width of child elements to 200px */
    grid-auto-rows: 1fr; /* Create 2 rows in each row */
    gap: 0.5rem; /* Adjust the gap between items */
    justify-content:space-evenly;

  }
}
@media (max-width: 410px) {
  .products-container {
  grid-template-columns: repeat(2, 190px); 
  grid-auto-rows: 1fr;
  gap: 0.3rem;
  justify-content:space-evenly;
  }
}

@media (max-width: 390px) {
  .products-container {
  grid-template-columns: repeat(2, 185px); 
  grid-auto-rows: 1fr;
  gap: 0.1rem;
  justify-content:space-evenly;
  }
}
@media (max-width: 380px) {
  .products-container {
  grid-template-columns: repeat(2, 183px); 
  grid-auto-rows: 1fr;
  gap: 0.1rem;
  justify-content:space-evenly;
  }
}
// @media (max-width: 370px) {
//   .products-container {
//   grid-template-columns: repeat(2, 183px); 
//   grid-auto-rows: 1fr;
//   gap: 0.1rem;
//   justify-content:space-evenly;
//   }
// }

@media (max-width: 360px) {
  .products-container {
  grid-template-columns: repeat(2, 160px); 
  grid-auto-rows: 1fr;
  gap: 0.1rem;
  justify-content:space-evenly;
  }
 
}

@media (max-width: 335px) {
  .products-container {
  grid-template-columns: repeat(1, 200px); 
  grid-auto-rows: 1fr;
  gap: 1rem;
  justify-content:space-evenly;
  }
 
}
`

export default GridView
