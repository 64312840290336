import React from 'react'
import { FaList } from "react-icons/fa";
import { BsFillGridFill, BsList } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { handlePriceChange, setGridView, setListView } from '../../feature/plants/plantsSlice'

//sortbar on the top
const Sortbar = () => {
  const { isGridView, plants, totalPlants } = useSelector((store) => store.plants);
  let size = plants.length

  const handleSort = (e) => {
    dispatch(handlePriceChange({ name: e.target.name, value: e.target.value }));

  }
  const dispatch = useDispatch();
  const setGridViews = () => {
    dispatch(setGridView())
  }
  const setListViews = () => {
    dispatch(setListView())
  }
  return (
    <Wrapper>
      <div className="btn-container">
        <button type="button" className={`${isGridView ? 'active' : null}`} onClick={setGridViews} >
          <BsFillGridFill />
        </button>
        <button type="button" className={`${!isGridView ? 'active' : null}`} onClick={setListViews} >
          <FaList />
        </button>
      </div>
      <form>
        <label htmlFor="sort">sort by:</label>
        <select name="sort" id="sort" className='sort-input' onChange={(e) => handleSort(e)} >
          <option value="latest">latest</option>
          <option value="price-lowest">price (lowest)</option>
          <option value="price-highest">price (highest)</option>
          <option value="a-z">name (a-z)</option>
          <option value="z-a">name (z-a)</option>

          <option value="oldest">oldest</option>
        </select>
      </form>
      {/* <p><span>{totalPlants}</span> products found</p> */}

    </Wrapper>)
}

const Wrapper = styled.section`
  width:100%;
  display: grid;
  grid-template-columns: 0.15fr 1fr auto;
  margin:0 auto;
  align-items: center;
  margin-bottom: 2rem;
  column-gap: 2rem;
  background:#EBEBEB;
  padding:0.5rem 1rem ;
  border-radius:0.75rem;
  top:0;
  form{
    margin:0 auto;
    background:transparent;
    padding:0.20rem 0.5rem;
    border-radius:1rem;
  }
  select{
    background:transparent;
  }
  input[type="select"] {
    font-family: "Poppins";
  }
  @media (max-width: 576px) {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.75rem;
    .btn-container {
      width: 50px;
    }
    label {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
  @media (min-width: 768px) {
    column-gap: 2rem;
  }
  p {
    text-transform: capitalize;
    margin-bottom: 0;
    margin:0 auto;
    span{
      color:var(--green-300);
      font-weight:bold;
    }
  }

  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    button {
      background: transparent;
      border: 1px solid var(--clr-black);
      color: var(--clr-black);
      width: 1.5rem;
      border-radius: var(--radius);
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        font-size: 1rem;
      }
    }
    .active {
      background: var(--clr-black);
      color: var(--green-500);
    }
  }

  .sort-input {
    border-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 0.25rem 0.5rem;
    border:0;
    cursor:pointer;
  }
  input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
  label {
    font-size: 1rem;
    text-transform: capitalize;
  }
  @media(max-width:580px){
      display:none;
  }
`

export default Sortbar
