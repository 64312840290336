import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FlashSaleBanner, FlashSaleBannerBottom, SingleFeaturedRow } from './support';
import styled from 'styled-components';
import features from '../assets/offerimages/features.png';
import arrayImgs from '../assets/FeatureIcons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SingleFeaturedRowMobile from './support/SingleFeaturedRowMobile';
import { debounce } from 'lodash';

const MemoizedSingleFeaturedRow = React.memo(SingleFeaturedRow);
const MemoizedSingleFeaturedRowMobile = React.memo(SingleFeaturedRowMobile);

const Featured = () => {
  const { featuredCategories, featuredProductsLoading, featuredTagsLevel } = useSelector((store) => store.plants);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  const responsive = {
    desktop: { breakpoint: { max: 1040, min: 700 }, items: 5 },
    tablet: { breakpoint: { max: 700, min: 500 }, items: 3 },
    mobile: { breakpoint: { max: 500, min: 0 }, items: 3 },
  };

  const sortedCategories = useMemo(() => {
    return [...featuredCategories].sort((a, b) => {
      const levelA = featuredTagsLevel.find((tag) => tag.name === a)?.level || 0;
      const levelB = featuredTagsLevel.find((tag) => tag.name === b)?.level || 0;
      return levelA - levelB;
    });
  }, [featuredCategories, featuredTagsLevel]);

  const handleResize = useCallback(
    debounce(() => {
      setIsMobileView(window.innerWidth <= 600);
    }, 300),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (featuredProductsLoading) {
      // Handle loading state as needed
    }
  }, [featuredProductsLoading]);

  return (
    <Wrapper>
      {sortedCategories.map((category, index) => (
        <React.Fragment key={index}>
          {isMobileView ? (
            <MemoizedSingleFeaturedRowMobile category={category} />
          ) : (
            <MemoizedSingleFeaturedRow category={category} />
          )}
          {index === 1 && <FlashSaleBanner />}
          {index === 3 && <FlashSaleBannerBottom />}
        </React.Fragment>
      ))}
      <div className='feature-img'>
        <img src={features} alt='features' className='feature-imgs' draggable='false' loading='lazy' />
      </div>
      <div className='imgs-small'>
        <Carousel
          responsive={responsive}
          swipeable={true}
          autoPlay={true}
          ssr={true}
          infinite
          arrows={false}
          renderButtonGroupOutside={true}
          draggable={true}
          showDots={false}
          className='gd-carousel'
        >
          {arrayImgs.map((img, idx) => (
            <img key={idx} src={img.url} alt='features' className='feature-imgs-small' draggable='false' />
          ))}
        </Carousel>
      </div>
    </Wrapper>
  );
};

export default Featured;


const Wrapper = styled.section`
 .feature-img{
  margin:7rem 0;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  .feature-imgs{
    width:95%;
    height:auto;
    object-fit:contain;
   }
 }
 .imgs-small{
  display:none;
 }
 .feature-imgs-small{
  width:150px;
  height:90px;
 }
 @media(min-width:1450px){
  .feature-imgs{
    width:95%;
    height:auto !important;
   }
 }
 @media(max-width:1150px){
  .feature-imgs{
    width:95%;
    height:110px !important;
  
   }
 }
 
 @media(max-width:992px){
  .imgs-small{
    display:block;
    width:95%;
    margin: 0 auto;
    margin-top:3rem;
    margin-bottom:3rem;
  }
  .feature-img{
    display:none;
  }
}
 @media(max-width:580px){
  .feature-img{
    display:none;
    margin:0;
  }
  .feature-imgs{
    height:70px;
  }
  .feature-imgs-small{
    width:130px;
    height:75px;
    object-fit:contain;
  }
 }
 @media(max-width:400px){
  margin-top:1.5rem;
 }

`