import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ImageSlider/ribbontag.css'; // Import the CSS file for ribbon tag styling

const RibbonTag = ({ sku }) => {
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
       
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getCategorySKU/${sku}`);
        setProductDetails(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [sku]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <><div className="category-wrapper">
          {productDetails.uniqueTags.map((item, index) => (
              <div key={index}>
                  <span className='spantext'>{item}</span>
              </div>
          ))}
      </div>
      
          </>
  
  );
};

export default RibbonTag;
