import React from 'react'
import styled from 'styled-components'

const FormRow = ({type , name,value , handleChange ,labelText}) => {
  return (
    <Wrapper className="form-row">
        <label htmlFor={name} className="form-label">{labelText || name}</label>
        <input type={type} id={name} name={name} value={value} onChange={handleChange} className="form-input" />
    </Wrapper>
  )
}

export default FormRow

const Wrapper = styled.div`


input:focus {
  border: 2.5px solid var(--green-500) !important; 
  outline: none !important;
}
`