import React, { useState, useEffect } from 'react';
import { Country, State, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';


const StripeCheckoutUserForm = ({ checkoutData, updateCheckoutData, fromRefund }) => {
  const [selectedCountry, setSelectedCountry] = useState('AE');
  const [selectedState, setSelectedState] = useState(checkoutData?.state);
  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    updateCheckoutData(field, value);
  };

  const handleCountryChange = (event) => {
    const countryValue = event.target.value;
    setSelectedCountry(countryValue);

    // Clear selected state and city when changing the country
    setSelectedState('');
    // Update checkoutData with the selected country
    handleInputChange('country', countryValue);
  };

  const countryStates = [
    'Ajman Emirate',
    'Abu Dhabi Emirate',
    'Dubai',
    'Fujairah',
    'Ras al-Khaimah',
    'Sharjah Emirate',
    'Umm al-Quwain'
  ];

  const handleStateChange = (event) => {
    const value = event.target.value;
    setSelectedState(value);
    // Set the state name in your checkoutData
    updateCheckoutData('state', value);
  };

  useEffect(() => {
    handleValidation();
  }, [checkoutData]);

  const handleValidation = () => {
    const newErrors = {};
    if (!checkoutData.name || checkoutData.name === "Name") {
      newErrors.name = 'Name is required';
    }
    if ((!checkoutData.paymentId && fromRefund === false) || checkoutData.paymentId === "Payment Id") {
      newErrors.paymentId = 'Payment Id is required';
    }
    if (!checkoutData.city || checkoutData.city === "City") {
      newErrors.city = 'City is required';
    }
    if (!checkoutData.landmark || checkoutData.landmark === "Building / Landmark") {
      newErrors.landmark = 'Building / Landmark is required';
    }
    if (!checkoutData.houseNumber || checkoutData.houseNumber === "Flat / House number") {
      newErrors.houseNumber = 'Flat / House number is required';
    }
    if (!checkoutData.phone || checkoutData.phone === "Phone") {
      newErrors.phone = 'Phone is required';
    }
    if (!checkoutData.state) {
      newErrors.state = 'State is required';
    }
    setErrors(newErrors);
  };


  return (
    <Wrapper>
      <input type="text" id="name" name="name" placeholder="Name" value={checkoutData.name} onChange={(e) => handleInputChange('name', e.target.value)} style={{ border: errors.name && '1px solid red' }} />
      {(fromRefund === false) && <input type="text" id="paymentId" name="paymentId" placeholder="Payment Id" value={checkoutData.paymentId} onChange={(e) => handleInputChange('paymentId', e.target.value)} style={{ border: errors.paymentId && '1px solid red' }} />}
      {(fromRefund !== false) && <select id="country" value={selectedCountry} onChange={handleCountryChange} style={{ border: errors.country && '1px solid red' }}>
        <option value="AE">UAE</option>
      </select>}
      <select id="state" value={selectedState} onChange={handleStateChange} style={{ border: errors.state && '1px solid red' }}>
  <option value="">Select State</option>
  {countryStates.map((state) => (
    <option key={state} value={state}>
      {state}
    </option>
  ))}
</select>

      <input type="text" id="city" name="city" placeholder="City" value={checkoutData.city} onChange={(e) => handleInputChange('city', e.target.value)} style={{ border: errors.city && '1px solid red' }} />
      <input type="text" id="address" name="address" placeholder="Building / Landmark" value={checkoutData.landmark} onChange={(e) => handleInputChange('landmark', e.target.value)} style={{ border: errors.landmark && '1px solid red' }} />
      <input type="text" id="address" name="address" placeholder="Flat / House number" value={checkoutData.houseNumber} onChange={(e) => handleInputChange('houseNumber', e.target.value)} style={{ border: errors.houseNumber && '1px solid red' }} />
      <input type="number" id="phone" name="phone" placeholder="Phone" value={checkoutData.phone} onChange={(e) => handleInputChange('phone', e.target.value)} style={{ border: errors.phone && '1px solid red' }} />
      <input type="number" id="phone2" name="phone2" placeholder="Alternate Phone no" value={checkoutData.phone2} onChange={(e) => handleInputChange('phone2', e.target.value)} />
      {fromRefund !== undefined && <textarea type="text" placeholder={fromRefund !== undefined ? "Explain the issue" : "Leave a note"} value={checkoutData.note} onChange={(e) => handleInputChange('note', e.target.value)} />}
    </Wrapper>
  );
};

export default StripeCheckoutUserForm;


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 

  select {
    height: 44px;
    padding: 5px;
    border: 1px solid var(--grey-100);
    border-radius: 5px;
    color: var(--grey-500);
    font-size: 16px;
    
  }

  .first {
    flex: 1;
  }

  input, select {
    font-family: inherit;
    height: 44px;
    padding: 5px;
    border: 1px solid var(--grey-100);
    border-radius: 5px;
    color: var(--grey-500);
    font-size: 16px;
    font-family: 'Poppins';
  }
  /* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  textarea {
    width: 100%;
    height: 110px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--grey-100);
    border-radius: 4px;
    outline: none;
    resize: vertical; 
    font-family: inherit;
    font-family:'Poppins';
  }

  textarea:focus {
    // border-color: #007bff; 
  }

  select::placeholder {
    color: grey;
  }

  input, select:focus {
    outline: none; 
    box-shadow: none; 
  }

  // #city {
  //   width: 100%; 
  // }

  // #state {
  //   width: 50.25%; 
  // }

  #name, #country{
    width: 100%;
  }
  #state, #city{
    width: 48.5%;
  }

  #address, #phone, #phone2 {
    width: calc(50% - 5px); /* 5px for the gap */
  }

  textarea {
    width: 100%;
  }

  // @media (min-width: 692px) {
  //   input, select {
  //     width: calc(50% - 5px); /* 5px for the gap */
  //   }
  // }

  @media (max-width: 692px) {
    input, select {
      width: 100%;
    }
    #address, #phone, #phone2 {
      width:100%;
    }
  
  }


  @media (max-width: 580px) {
    input, select {
      font-size: 14px;
      height: 34px;
    }

    textarea {
      font-size: 14px;
      height: 80px;
    }
  }
`;
