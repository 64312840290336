import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import success from '../../assets/images/party-popper.png';
import { deliveryCharges } from '../../utils/deliveryCharges';
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveryCharge } from '../../feature/user/userSlice';

const RemainingRangeInput = ({ currentPrice, maximumPrice }) => {



  const calculateRemainingPercentage = () => {
    const percentage = (currentPrice / maximumPrice) * 100;
    return percentage >= 100 ? 100 : percentage;
  };

  const remainingPercentage = calculateRemainingPercentage();

  const rangeStyles = {
    width: '100%',
    height: '7px',
    borderRadius: '5px',
    background: 'var(--grey-100)',
    position: 'relative',
    overflow: 'hidden',
  };

  const fillStyles = {
    height: '100%',
    width: `${remainingPercentage}%`,
    background: 'var(--green-300)',
    position: 'absolute',
    top: '0',
    left: '0',
  };

  return (
    <div style={rangeStyles}>
      <div style={fillStyles}></div>
    </div>
  );
};

// Example usage:
const DeliveryChargeRange = ({ prop }) => {
  const { deliveryLocation } = useSelector((store) => store.user)
  const dispatch = useDispatch()

  const selectedLocation = deliveryCharges.find(loc => loc.location === deliveryLocation);

  const currentPrice = prop
  let shippingPrice = selectedLocation?.charge;
  const maximumPrice = selectedLocation?.minPurchase;
  const state = maximumPrice - currentPrice;
  const [shipping, setShipping] = useState(false);


  useEffect(() => {
    if (state > 0) {
      setShipping(true);
    } else {

      if (isNaN(state)) {
        setShipping(true);
        shippingPrice = 0;
      }
      else {
        setShipping(false);
        shippingPrice = 0;
      }
    }
    dispatch(setDeliveryCharge(shippingPrice))
  }, [shipping, setShipping, state, selectedLocation])
  return (
    <Wrapper>
      <div className='content'>
        {isNaN(state) ?
          "Please Select Delivery Location" :
          shipping ?
            `You're just AED ${Math.ceil(state)} away from free shipping!` :
            <div className='success'>Victory! Enjoy free shipping on your order! <img src={success} alt='success' /> </div>
        }


      </div>
      <RemainingRangeInput currentPrice={currentPrice} maximumPrice={maximumPrice} />
      <div className='price-range-showing'><span>{currentPrice} AED</span> <span>{maximumPrice} AED</span></div>
    </Wrapper>
  );
};

export default DeliveryChargeRange;

const Wrapper = styled.div`
  margin-bottom:1rem;
  border: 1px solid rgba(50, 50, 93, 0.1);
  padding:0rem 0.5rem;
  border-radius:5px;
  .content{
    margin-top:0.7rem;
    margin-bottom:0.5rem;
    font-weight:500;
  }
  .success{
    img{
        width:20px;
        height:20px;
    }
}
.price-range-showing{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:1rem;
    span{
        font-size:0.75rem;
        font-weight:500;
        color:var(--grey-900);
    }
}
`