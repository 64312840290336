import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openSearchResults, handleChange, getAllProducts } from '../../feature/plants/plantsSlice';

const ScrollCategory = () => {
  const { categoryList } = useSelector((store) => store.plants);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState(0);
  const maxSliderValue = categoryList.length - 1;

  const mouseClickHandler = (category) => {
    const propertyValues = Object.values(category);
    const url = propertyValues[0];
    dispatch(openSearchResults(propertyValues));
    // dispatch(handleChange({ name: 'mainCategory', value: url }));
    navigate(`/products/mainCategory/category/${url}`);
    window.scrollTo(0, 0);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  return (
    <Wrapper>
      <MediaScroller>
        {categoryList &&
          categoryList.map((category, idx) => (
            <div
              key={idx}
              className='media-element'
              onClick={() => mouseClickHandler(category)}
            >
              <img src={category.image?.url} alt='icon' className='category-scroll-img' draggable="false" />
              {/* <p className='category-name'>{category.name}</p> */}
              <p className='category-name'>
             {category.name.length > 16 ? `${category.name.substring(0, 16)}...` : category.name}
            </p>
            </div>
          ))}
      </MediaScroller>
      {/* <SliderWrapper>
        <input
          type="range"
          min="0"
          max={maxSliderValue}
          step="1"
          value={sliderValue}
          onChange={(event) => handleSliderChange(parseInt(event.target.value, 10))}
        />
      </SliderWrapper> */}
    </Wrapper>
  );
};

export default ScrollCategory;

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  width: 95%;
  margin: 0 auto;
`;

const MediaScroller = styled.div`
display: grid;
gap: var(--size-3);
grid-auto-flow: column;
padding: 0 var(--size-3) var(--size-3);
overflow-x: auto;
overscroll-behavior-inline: contain;
grid-template-columns: repeat(auto-fill, 180px);

gap: 1rem;
padding-bottom:1rem;
.media-element {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width:180px;
  font-size: 15px;
  gap: 0.25rem;
  border: 2px solid var(--grey-100);
  border-radius: 12px;
  p {
    display:flex;
    justify-content:center;
    align-items:center;
    padding: 0;
    text-transform:capitalize;
    margin: 0;
  }
}

.category-scroll-img {
  width: 75px;
  height: 88px;
  border-radius: 10px 0px 0px 10px;
}
.snaps-inline {
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: var(--size-3);
}

.snaps-inline > * {
  scroll-snap-align: start;
}
&::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
  height:5px;
}

&::-webkit-scrollbar-thumb {
  background-color: var(--green-300); /* Set the color of the thumb */
  border-radius: 10px;
  -webkit-appearance: none;
}

&::-webkit-scrollbar-track {
  background-color: #ddd; /* Set the background color of the scrollbar track */
  border-radius: 10px;
}
@media(max-width:800px){
  grid-template-columns: repeat(auto-fill, 160px);
  gap: 0.5rem;
  .category-row {
    max-width:130px !important;
    height: 70px !important;
    margin-right:0.25rem;
  }
  p{
    font-size:13px !important;
  }
  .category-scroll-img{
    width: 50px !important;
    height: 69px !important;
  }
  .media-element {
    width:160px;
    height:fit-content;
  }
}
@media(max-width:560px){
  grid-template-columns: repeat(auto-fill, 150px);

  gap: 1rem;
  .category-row{
    height: 51px !important;
    p{
      font-size:12px !important;
    }
  }
  .category-scroll-img {
   
    width: 70px;
    height: 50px !important;
    border-radius: 10px 0px 0px 10px;
  }
  .media-element {
    width:140px;
    height:fit-content;

  }
}
@media(max-width:450px){
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 135px);

  .media-element {
    width:140px;
    height:fit-content;

  }
}
`;

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input[type="range"] {
    width: 95%;
    -webkit-appearance: none;
    height: 5px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 10px;
    margin-top: 10px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    max-width: 150px;
    height: 5px;
    background: var(--green-300);
    border-radius: 10px;
  }

  input[type="range"]:hover {
    opacity: 1;
  }
`;
