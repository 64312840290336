import React, { useEffect, useState, useRef } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styled from "styled-components";
import axios from "axios";
import { Loading } from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Slider = (props) => {
  const { banners, isBannerLoading } = useSelector((store) => store.banner);
  const [images, setImages] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true); // Track whether images are still loading
  const navigateTo = useNavigate();
  const imageRefs = useRef([]); // Use a ref to keep track of image elements

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const filteredBanners = banners.filter((b) => b.typeName === 'banner1');
    setImages(filteredBanners);

    // Preload images
    const promises = filteredBanners.map((slide, index) => {
      const imageUrl =
        screenWidth < 580 ? slide.photos[1]?.url : slide.photos[0]?.url;

      const image = new Image();
      image.src = imageUrl;

      // Store reference to image element
      imageRefs.current[index] = image;

      return new Promise((resolve) => {
        image.onload = resolve;
      });
    });

    Promise.all(promises).then(() => {
      setIsLoading(false); // Set loading to false when images are loaded
    });

    // Cleanup: remove event listeners and clear image references
    return () => {
      imageRefs.current.forEach((image) => {
        if (image) {
          image.onload = null;
        }
      });
      imageRefs.current = [];
    };
  }, [banners, screenWidth]);

  useEffect(() => {
    // Preload images when component mounts
    const initialPromises = images.map((slide, index) => {
      const imageUrl =
        screenWidth < 580 ? slide.photos[1]?.url : slide.photos[0]?.url;

      const image = new Image();
      image.src = imageUrl;

      // Store reference to image element
      imageRefs.current[index] = image;

      return new Promise((resolve) => {
        image.onload = resolve;
      });
    });

    Promise.all(initialPromises).then(() => {
      setIsLoading(false); // Set loading to false when images are loaded
    });
  }, [images, screenWidth]);

  const handleSlideChange = (currentIndex) => {
    // Add any custom logic you want to execute when the slide changes
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setAutoPlay(false); // Pause auto-play on hover
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setAutoPlay(true); // Resume auto-play on mouse leave
  };

  if (isBannerLoading || isLoading) {
    return <Loading />; // Replace with your loading component
  }

  return (
    <MainWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Wrapper>
        <div className="wrapper">
          <AliceCarousel
            mouseTracking
            autoPlay={autoPlay}
            infinite
            autoPlayInterval={3000}
            autoPlayStrategy="default"
            disableButtonsControls={!isHovered}
            items={images.map((slide, index) => {
              const imageUrl =
                screenWidth < 580 ? slide.photos[1]?.url : slide.photos[0]?.url;

              return (
                <img
                  key={index}
                  src={imageUrl}
                  alt={`slider-${index}-${index}`}
                  style={{ cursor: slide.navigate ? 'pointer' : 'default' }}
                  loading="eager" // Use eager loading for immediate fetching
                  onClick={() => {
                    if (slide.navigate) {
                      navigateTo(slide.navigate);
                    }
                  }}
                  draggable="false"
                />
              );
            })}
            onSlideChanged={handleSlideChange}
            animationType="slide"
            animationDuration={800}
            buttonsDisabled={true}
            dotsDisabled={false}
            responsive={{
              0: { items: 1 },
              600: { items: 1 },
              1024: { items: 1 },
            }}
          />
        </div>
      </Wrapper>
    </MainWrapper>
  );
};

export default Slider;



const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  .aside-item {
    width: 37%;
  }
  @media (max-width: 1240px) {
    .aside-item {
      display: none;
      width: 0;
    }
  }
  @media (min-width: 1100px) {
    min-height:50vh;
  }
  @media (max-width: 1100px) {
    min-height:20vh;
  }
`;

const Wrapper = styled.div`
  width: 95%;
  margin:0 auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
  
  .wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;

    .alice-carousel {
      position: relative;

      .alice-carousel__wrapper {
        .alice-carousel__stage-item {
          img {
            width: 100%;
            aspect-ratio: 10 / 3.25;
            display:block;
          }
        }
      }
      .alice-carousel__prev-btn-item::after,
      .alice-carousel__next-btn-item::after {
          content: "➔"; /* New icon */
          color: var(--green-500); /* Icon color */
          font-size: 30px; /* Icon size */
          // display: none; /* Hide the icon by default */
      }
      
      
      .alice-carousel__prev-btn-item::after{
          transform: rotate(180deg);
          display: inline-block; /* Show the rotated icon */
      }
     .alice-carousel__next-btn-item::after {
        display: inline-block;
      }
      
      .alice-carousel__prev-btn-item span[data-area="<"]::after,
      .alice-carousel__next-btn-item span[data-area=">"]::after {
          content: ""; 
      }
      .alice-carousel__prev-btn,
      .alice-carousel__next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        color: #fff;
        padding: 8px;
        border-radius: 50%;
        cursor: pointer;
      }


      .alice-carousel__prev-btn {
        width:20px;
        left:0;
      }

      .alice-carousel__next-btn {
        width:20px;
        right:3%;
      }
      @media(max-width:992px){
        .alice-carousel__next-btn {
          width:20px;
          right:6%;
        }
      }
      @media(max-width:700px){
        .alice-carousel__prev-btn-item::after,
        .alice-carousel__next-btn-item::after {
          content: "➔"; /* New icon */
          color: var(--green-500); /* Icon color */
          font-size: 30px; /* Icon size */
          display: none; /* Hide the icon by default */
      }
      }
      @media(max-width:580px){
        img {
          width: 100%;
          display:block;
          height:180px;
        }
      }
      @media(max-width:375px){
        img {
          width: 100%;

          height:160px;
        }
      }
      .alice-carousel__dots {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        .alice-carousel__dots-item {
          margin: 0 5px;
          margin-bottom:1rem;
          button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #000;
            cursor: pointer;
          }

          &.alice-carousel__dots-item-active button {
            background-color: #000;
          }
        }
      }
    }
  }

`;
