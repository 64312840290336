import React, { useEffect, useState } from 'react'
import StarRatingUser from './support/StarRatingUser'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { addReview } from '../feature/Review/reviewSlice'
import { toast } from 'react-toastify'
import { json, useParams } from 'react-router-dom'
import { getProduct } from '../feature/plants/plantsSlice'
import { verifyToken } from '../feature/user/userSlice'


const ReviewForm = (id) => {
 
    const { user, myUser } = useSelector((store) => store.user);
    const {isLoading}=useSelector((store)=>store.review)
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState(''); // State to hold the review text
    const dispatch = useDispatch()
    const {_id} = useParams()
    const setUserRating = (rate) => {
      setRating(rate);
    };
   useEffect(()=>{
  dispatch(verifyToken())
  },[])
    const handleTextareaChange = (event) => {
      setReviewText(event.target.value);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      if(!myUser){
        toast.warning("Login to post review")
        return;
      }
      if (rating === 0) {
         toast.error('Add ratings...')
        return;
      }
      if(reviewText===''){
        toast.error('Add reviews...')
        return;
      }
  
      // Prepare the review object
      const review = {
        product_id: id.id,
        rating: rating,
        content: reviewText,
        userId: user.id
      };
      
      dispatch(addReview(review));
      setRating(0);
      setReviewText('');
      setUserRating(0)
      if(!isLoading){
        dispatch(getProduct(id.id))
      }
    }

    return (
      <Wrapper>
        <h6>Review</h6>
        <StarRatingUser setUserRating={setUserRating} />
        <textarea
          type="text"
          value={reviewText}
          onChange={handleTextareaChange}
        />
        <button className="btn-review" onClick={handleSubmit} >
          Post Review
        </button>
      </Wrapper>
    );
  };

 export default ReviewForm

const Wrapper = styled.div`
width:95%;
margin:0 auto;
margin-top:1rem;
margin-bottom:2rem;
display:flex;
flex-direction:column;
h6 {
  display: inline;
  color: var(--grey-900);
  text-decoration: none; 
  border:none !important;
}

h6::after {
  content: "";
  display: block;
  width: 5%;
  border-bottom: 0.15rem solid var(--green-500); 
  margin-top: 0rem; 
}
 textarea {
    margin-top:0.5rem;
    width: 100%;
    height: 110px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    resize: vertical; 
  }
  textarea:focus  {
    // border-color: #007bff; 
    box-shadow: 0 0 5px var(--grey-100); 
  }
  .btn-review{
    max-width:9rem;
    margin-top:0.5rem;
    margin-right: auto;
    padding:0.5rem .85rem;
    background:#23398E;
    font-weight:600;
    text-transform:capitalize;
    color:white;
    border-radius:5px;
  }

`