import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import AmountButtons from './AmountButtons'
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { useDispatch } from 'react-redux'
import { addToCart, getCart, addTowishList, getWishList } from '../../feature/user/userSlice'
import { openCart, setActiveTab } from '../../feature/modal/modalSlice'


const AddtoCart = ({product ,activeImageName}) => {
    const dispatch= useDispatch()
    const {id:paramId} = useParams()
    const {id,stock,colors,length,Pots,price,Allpots,allLengths,title,_id,maincategory,subcategory,category} = product;

  const sortedLengths =allLengths && allLengths.slice().sort((a, b) => {
    const [aMin, aMax] = a.name.split('-').map(Number);
    const [bMin, bMax] = b.name.split('-').map(Number);
  
    // Compare based on the minimum value (aMin and bMin)
    return aMin - bMin;
  });
  //pot sorting
  const sortedAllpots =Allpots &&( [...Allpots].sort((a, b) => a.price - b.price));

  
 
  //cart count setting
  const [amount , setAmount] = useState(1);
  const [selectedPot,setSelectedPot] = useState(sortedAllpots && sortedAllpots[0]);
  const [selectedLength, setSelectedLength] = useState(sortedLengths && sortedLengths[0] );
  const [total, setTotal] =useState(price)
  const increase = () =>{
    setAmount((oldAmount)=>{
      let tempAmount = oldAmount + 1;
      if(tempAmount > stock){
        tempAmount = stock;
      }
      return tempAmount;
    })
  }
  
  const decrease = () =>{
   setAmount((oldAmount)=>{
    let tempAmount = oldAmount - 1;
    if(tempAmount < 1){
      tempAmount = 1;
    }
    return tempAmount;
   })
  }
 //length selecting
  const [activeLengthButton, setActiveLengthButton] = useState(0); 
    const handleButtonClick = (value) => {
    const lengths = JSON.parse(value);
    setActiveLengthButton(lengths.id);
    setSelectedPot([lengths.name, lengths._id, lengths.price]);
  
  };
  
  //pot selecting
  const [activePot, setActivePot] = useState(0); 
  
  const handlePotButtonClick = (value) => {
    const pots = JSON.parse(value);
    setActivePot(pots.potIndex);
    setSelectedPot({pots})
  };
  // let totals =price+potPrice+lengthPrice
  // let totals = ((selectedLength?.price || 0) + (selectedPot?.price || 0) + price) * amount;
  useEffect(() => {
    const totalPrice = ((selectedLength?.price || 0) + (selectedPot.pots?.price || 0) + price) * amount;

    setTotal(totalPrice);
  }, [selectedLength, selectedPot, amount, price]);

  //add to cart
  const addCart =()=>{
    dispatch(openCart('cart'))
    dispatch(addToCart({title,_id,maincategory,subcategory,category,totalPrice:total,price:price, potName:selectedPot,size:selectedLength,productQuantity:amount,image:activeImageName}))
    dispatch(getCart())
  }
  const addWishlist=()=>{
    // addWishlistItemLocalStorage({title,_id,maincategory,subcategory,category,totalPrice:totals, potName:selectedPot,size:selectedLength,productQuantity:amount,image:activeImageName})
    dispatch(openCart());
  dispatch(setActiveTab('wishlist'))
  dispatch(addTowishList({title,_id,maincategory,subcategory,category,totalPrice:total,price:price, potName:selectedPot,size:selectedLength,productQuantity:amount,image:activeImageName}))
  dispatch(getWishList())
  }
//storing purchasing product

  return (
  <Wrapper>
    <div className='size'>
    <span>Choose height : </span>
    <div className='size-btn-container'>
    <select onChange={(e) => handleButtonClick(e.target.value)} className='select-box'>
    {sortedLengths && sortedLengths.map((len,id) => {
    const { price, name, lengthId} = len;
    const addId = {...len ,id}
     return (
      <option key={id} value={JSON.stringify(addId)}>
        {name} cm
      </option>
    );
  })}
</select>
    <div class="select-arrow"></div>
    </div>
    <div className='size'>
    <span>Choose Pots: </span>
    <div className='size-btn-container'>
  <select onChange={(e) => handlePotButtonClick(e.target.value)} className='select-box'>
    {sortedAllpots && sortedAllpots.map((pot, potIndex) => {
      const {price, name,_id} = pot;
      const addIndex = {...pot,potIndex}
      return (
        <option key={id} value={JSON.stringify(addIndex)}>
          {name}
        </option>
      );
    })}
    </select>
   <div class="select-arrow"></div>
    </div>
    </div>
    </div>
    <div className="btn-container">
      <AmountButtons amount={amount} increase={increase} decrease={decrease}/>
      <Link to="" className="btn-cart" onClick={addCart}>Add to Bag</Link>
      <button className='wishlist-btn' onClick={()=>addWishlist()}> <span><MdOutlineFavoriteBorder/></span></button>
    {/* <Link to={`/checkout/${_id}`} className="btn" onClick={toPurchase}>buy it now</Link> */}
    </div>
    <div className='location-check'>
      <input type='text' placeholder='Delivery Location'/>
      <button type='button' className='location-btn'>Check</button>
    </div>
    <div className='cart-btn-container'>
   
    
    </div>
    <div className='plant-care'>
      
    </div>
  </Wrapper>
  )
}

const Wrapper = styled.section`
.size{
  span{
    display:flex;
  }
}
.size-btn-container{
  position:relative;
  

}
.size-btn-container .select-box {
  width: 100%;
  padding: 0.75rem 2rem 0.75rem 0.75rem; 
  outline: none;
  border-radius: 5px;
  border: 2px solid var(--grey-100);
  position: relative; /* To position the arrow */
  -webkit-appearance: none;
  appearance: none;

  
}
.select-arrow{
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: 15px;
  right: 15px;
}
.btn-container{
  display:flex;
  margin-top:1rem;
  justify-content:space-between;
  gap:0.5rem;
}
.btn-cart{
  background:var(--green-300);
  color:#fff;
  font-weight:600;
  text-align:center;
  padding:0.25rem 1.25rem;
  border-radius:5px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.wishlist-btn{
  font-size:1.5rem;
  border:2px solid var(--green-300);
  border-radius:5px;
  background:transparent;
  color:var(--green-300);
  span{
    display:flex;
    justify-content:center;

  }
}
.location-check{
  margin-top:1rem;
  display:flex;
  justify-content:space-between;
  height:2.5rem;
  gap:0.5rem;
  input{
    border:2px solid var(--grey-100);
    padding-left:0.5rem;
    flex:1;
    border-radius:5px;
  }
}
.location-btn{
  border:none;
  flex:0.5;
  border-radius:5px;
  background:#23398E;
  color:#fff;
  font-weight:bold
}
// margin-top: 0.75rem;
// display:flex;
// flex-direction:column;
// margin-left:1rem;
// margin-left:1rem;
// align-items:flex-start;
// letter-spacing:1px;
// .colors {
//   display: grid;
//   grid-template-columns: 125px 1fr;
//   align-items: center;
//   margin-bottom: 1rem;
//   span {
//     text-transform: capitalize;
//     font-weight: 700;
    
//   }
//   div {
//     display: flex;
//   }
// }
// .pot-selector {
//   width:100%;
//   display: flex;
//   flex-direction: column;
//   align-items:center;
// }
// .pot-selector > div:first-child {
//   align-self: flex-start; /* Align the first div to the left */
// }

// .wishlist{
//   display:flex;
//   align-items:center;
//   justify-content:center;
// }
// .wishlist-btn{
//   background:transparent;
//   border:none;
//   font-size:15px;
//   margin-top:0.75rem;
//   cursor:pointer;
//   span{
//    margin-left:0.25rem;
//    color:var(--grey-500);
//   }
// }
// .wishlist-btn:hover span{
//   color:var(--green-500);
//   transition:color 0.5s;
// }
// .size-btn {
//   margin: 5px;
//   padding: 5px 10px;
//   cursor: pointer;
//   border: none;
//   background-color: #ccc;
//   width:6rem;
// }

// .size-btn.active {
//   background-color: #4caf50;
// }

// .pot-images {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
// }

// .pot-carousel {
//   display: none;
// }

// .pot-carousel.active {
//   display: block;
// }

// .pot-carousel .carousel {
//   max-width: 200px;
// }

// .pot-carousel .carousel .slide img {
//   max-width: 200px;
//   max-height: 200px;
// }
// .price{
//   padding-bottom:0.75rem;
//   font-size:1.7rem;
//   span{
//     padding-left:0.25rem;
    
//   }
// }

// .color-btn {
//   display: inline-block;
//   width: 1.5rem;
//   height: 1.5rem;
//   border-radius: 50%;
//   background: #222;
//   margin-right: 0.5rem;
//   border: none;
//   cursor: pointer;
//   opacity: 0.5;
//   display: flex;
//   align-items: center;
//   justify-content: center;
 
//   svg {
//     font-size: 0.75rem;
//     color: var(--clr-white);
//   }
// }
// .cart-btn-container{
//   display:flex;
//   gap:1rem;
//   justify-content:center;
//   flex-wrap:wrap;
// }
// .cart{
//   background:var(--grey-300);
//   padding:0.65rem;
  
// }
// .cart:hover{
//   background:var(--grey-500);
// }
// .active {
//   opacity: 1;
// }
// .btn-container {
//   margin-top: 1.75rem;
// }
// .size{
//   span{
//       font-weight:bold;
//       width:3.5rem;
//       text-align:left;
//   }
//   display:flex;
//   flex-direction:row;
//   flex-wrap:wrap;
//   margin-bottom:1rem;
//   justify-content:flex-start;
//  button{
//   letter-spacing:1px;
//   background:transparent;
//   border:1px solid var(--grey-300);
//   border-radius:5px;
//   cursor:pointer;
//  }
// }
// .size-btn-container{
//   max-width:15rem;
//   display:flex;
//   flex-wrap:wrap;
// }
// .size-btn {
//   background-color: white;
// }
// .size-btn.active {
//   background-color: var(--green-500);
//   color:white;
// }

// .btn {
//   margin-top: 1rem;
//   width: 140px;
//   display:flex;
//   align-items:center;
//   justify-content:center;
// }
`

export default AddtoCart