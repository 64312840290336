import ReactQuill from "react-quill";
import styled from "styled-components";

export default function Editor({value,onChange}) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };
  return (
    <Wrapper>
    <ReactQuill
      value={value}
      theme={'snow'}
      onChange={onChange}
      modules={modules} />
    </Wrapper>
  );
}


const Wrapper = styled.div`
 .quill{
    font-family: inherit;
    padding:0;
    border: 1px solid var(--grey-100);
    border-radius: 5px;
    color: var(--grey-500);
    font-size:16px;
    width:100%;
 }
 .ql-snow{
    font-family: inherit;
    border:none;
 }
 .ql-toolbar{
    font-family: inherit;
    border-bottom: 1px solid var(--grey-100);
 }
 .ql-container{
    font-family: inherit;
    min-height:100px;
 }
`
