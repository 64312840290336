import React, { useEffect, useState } from 'react'
import GridView from './GridView'
import ListView from './ListView'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../Loading'
import styled from 'styled-components'
import { getCart } from '../../feature/user/userSlice'


const ProductList = () => {
  const { isGridView, plants, isProductLoading } = useSelector((store) => store.plants);
  const dispatch = useDispatch();

  const [showNoProductsMessage, setShowNoProductsMessage] = useState(false);

  useEffect(() => {
    dispatch(getCart());
  }, []);

  useEffect(() => {
    // Use a timeout to show the message after 2 seconds when isProductLoading becomes false
    if (!isProductLoading) {
      const timeoutId = setTimeout(() => {
        setShowNoProductsMessage(true);
      }, 2000);

      return () => {
        clearTimeout(timeoutId); // Clear the timeout if the component unmounts before it triggers
      };
    }
  }, [isProductLoading]);

  if (isProductLoading) {
    return <Wrapper><Loading /></Wrapper>;
  }

  if (plants.length < 1) {
    if (showNoProductsMessage) {
      return (
        <Wrapper>
          <h5 style={{ textTransform: 'none' }}>
            Sorry, no products matched your search....
          </h5>
        </Wrapper>
      );
    }

    return null; // Hide the message until the timeout is reached
  }

  if (isGridView === false) {
    return (
      <ListView products={plants} />
    );
  }

  return <GridView products={plants}>product list</GridView>;
}

export default ProductList;

const Wrapper = styled.div`
max-width: 500px !important;
display: flex;
justify-content: center;
margin: 0 auto;
position: relative; /* Add position relative to establish stacking context */

  @media(max-width:560px){
    height:70vh;

  }
`