import axios from "axios"
import { toast } from "react-toastify"

export const getBanner1Thunk = async(_,thunkApi)=>{
    try {
        const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/getBanners`)
        return resp.data
    } catch (error) {
        return thunkApi.rejectedWithValue(error.response.data)
    }
}
