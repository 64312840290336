import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import modalSlice from "./feature/modal/modalSlice";
import plantsSlice from "./feature/plants/plantsSlice"
import userSlice from "./feature/user/userSlice";
import reviewSlice from "./feature/Review/reviewSlice";
import blogsSlice from "./feature/blogs/blogsSlice";
import bannerSlice from "./feature/BannerImages/bannerSlice";

export const store =configureStore({
    reducer:{
        modal:modalSlice,
        plants:plantsSlice,
        user:userSlice,
        review:reviewSlice,
        blog:blogsSlice,
        banner:bannerSlice
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})