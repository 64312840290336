import { createSlice,createAsyncThunk  } from "@reduxjs/toolkit";
import { getBanner1Thunk } from "./bannerThunk";

const initialState = {
    isBannerLoading:false,
    banners:[],
}

//getting all banners
export const getBanners = createAsyncThunk('/banners' ,getBanner1Thunk)




const BannerSlice = createSlice({
    name:'banner',
    initialState,
    reducers:{

    },
    extraReducers:(builder)=>{
        builder
        .addCase(getBanners.pending , (state,action)=>{
            state.isBannerLoading = true
        })
        .addCase(getBanners.fulfilled , (state,action)=>{
            state.isBannerLoading = false
            state.banners = action.payload
        })
        .addCase(getBanners.rejected , (state,action)=>{
            state.isBannerLoading = false
        })
  
    }
})


export const{} = BannerSlice.actions
export default BannerSlice.reducer