import React from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import FormRow from './support/FormRow';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsActionFalse, updatePassword } from '../feature/user/userSlice';
import { useEffect } from 'react';


//password resetpage
const ResetPasswordPage = () => {
const {myUser,isLoading,isActionSuccess}=useSelector((store)=>store.user)
const navigate = useNavigate();
const {id} = useParams()
const dispatch = useDispatch();

const initialState = {
    email:'',
    password:'',
    token:''
}  
const defaultValues = {
    ...initialState,
  };
 
  const [values ,setValues] = useState(defaultValues);
  const handleChange = (e) =>{
    const name = e.target.name
    const value = e.target.value
    setValues(prevValues => ({...prevValues, token: id, [name]: value}))
}


  const onSubmit = async (e) => {
    e.preventDefault();
    const {email, password } = values;

    if (!email || !password) {
      toast.warning('Please fill out all fields');
      return;
    }
    dispatch(updatePassword(values))
}
useEffect(() => {
  if (isActionSuccess) {
    setTimeout(() => {
      navigate('/user/login');
      dispatch(setIsActionFalse())
    }, 2000);
  }
}, [isActionSuccess,navigate,dispatch]);

  return (
    <Wrapper>
        <div className="form small-form">
        <h5>ResetPasswordPage</h5>
        <FormRow type="email"  name="email" value={values.email} handleChange={handleChange}/>
        <FormRow type="password"  name="password" value={values.password} handleChange={handleChange}/>
        <button type='button' className="btn-block btn" onClick={onSubmit} >{isLoading ? 'loading': 'submit'}</button>
        </div>
    </Wrapper>
  )
}

export default ResetPasswordPage

const Wrapper = styled.div`
  width:95%;
  margin:0 auto;
  min-height:42vh;

.forgot-div{
  max-width: 400px;
  margin:0 auto;
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  gap:1rem;
  margin-top:1rem;
  margin-bottom:3rem;
}
h5{
  margin-top:0.25rem;
  margin-bottom:0.25rem;
  font-weight:600;
}
.btn-block{
  font-size:17px;
  font-weight:500;
  text-transform:uppercase;
  height:40px;
  margin-top:0;
  background:var(--green-300);

}
.member-btn {
  background: transparent;
  border: transparent;
  color: var(--primary-500);
  cursor: pointer;
  letter-spacing: var(--letterSpacing);
  margin-top:0.25rem;
  margin-left:0.5rem;
}
.already-member{
  width:100%;
  display:flex;
  flex-direction:row;
  margin-top:0.5em;
  justify-content:flex-start;
  align-items:left;
}
`