import axios from "axios"
import { toast } from "react-toastify"

export const getBlogThunk = async(_,thunkApi)=>{
    try {
        const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/blogs/post`)
        return resp.data
    } catch (error) {
        return thunkApi.rejectedWithValue(error.response.data)
    }
}

export const addBlogThunk =  async(blog,thunkApi)=>{

    const { myUser } = thunkApi.getState().user;
    if(myUser){
        try {
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/blogs/post`,blog)
            return resp.data
        } catch (error) {
            return thunkApi.rejectedWithValue(error.response.data)
        }
    }else{
        toast.error('Login to continue')
    }
}

export const getSingleBlogThunk = async(id,thunkApi)=>{
    try {
        const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/blogs/post/${id}`)
        return resp.data
    } catch (error) {
        return thunkApi.rejectedWithValue(error.response.data)
    }
}

export const updateBlogThunk = async (blog, thunkApi) => {
    const { myUser } = thunkApi.getState().user;
   
    if (myUser) {
      try {
  
        const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}/blogs/post`, blog);
        return resp.data;
      } catch (error) {
        return thunkApi.rejectedWithValue(error.response.data);
      }
    } else {
      toast.error('Login to continue');
    }
  };
  