import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useHashScroll() {
  const location = useLocation();

  useLayoutEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
      }
    } else {
      window.scrollTo({top: -10, left: 0, behavior: "smooth"})
    }
  }, [location]);
}

export default useHashScroll;
