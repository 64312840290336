import React, { useState ,useRef,useEffect } from 'react'
// import logo from '../assets/logo.svg'
import { Link } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'
// import { links } from '../utils/constants'
import styled from 'styled-components'
import { closeCart, setActiveTab } from '../feature/modal/modalSlice'
import { useDispatch, useSelector } from 'react-redux'
// import CartButtons from './CartButtons'
import { injectGlobal } from 'styled-components';
import {PiShoppingCartDuotone} from "react-icons/pi";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import CartContent from './support/CartContent'
import WishListContent from './support/WishListContent'
import { VscClose } from "react-icons/vsc";
import wishlistIcon from '../assets/images/wishlist.png'
import cartIcon from '../assets/images/carts.png'
import { getCart } from '../feature/user/userSlice'


const Sidebar = () => {
const {isOpenCart,isActiveTab} = useSelector((store)=>store.modal)
const dispatch =useDispatch();
const closeToggle =()=>{
    dispatch(closeCart());
}

const disableOverflow = () => {
  document.body.style.overflow = 'hidden';
};

const enableOverflow = () => {
  document.body.style.overflow = 'auto';
};

//changing wishlist and cart tab while clicking on it
  const handleTabClick = (tab) => {
    dispatch(setActiveTab(tab));
  };

//closing cart sidebar while click outside 
const sidebarRef = useRef();
if(isOpenCart){
  disableOverflow();
}
if(!isOpenCart){
  enableOverflow();
}
useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        dispatch(closeToggle);
        enableOverflow();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
useEffect(()=>{
  dispatch(getCart())
},[])
  return( 
  <SidebarContainer>
     <aside className={`${isOpenCart ? 'sidebar show-sidebar' : 'sidebar'}`} ref={sidebarRef}>
      <div className="sidebar-header">
        <div className='first'>
        {/* <p>Check your bag</p> */}
        <button className="close-btn" type="submit" onClick={closeToggle}>
          <VscClose />
        </button>
        </div>
        <div className="header">
        <div
          className={`tab ${isActiveTab === 'cart' ? 'active' : ''}`}
          onClick={() => handleTabClick('cart')}
        >
          <span>Cart</span><img src={cartIcon} alt="cart" className='cartIcon' draggable="false"/>
        </div>
        <div
          className={`tab ${isActiveTab === 'wishlist' ? 'active' : ''}`}
          onClick={() => handleTabClick('wishlist')}
        >
          <span>Wishlist</span><img src={wishlistIcon} alt="cart" className='wishIcon' draggable="false"/>
        </div>
      </div>
        </div>
      
      <div className="container">
      <div className="content">
        {isActiveTab === 'cart' && <div><CartContent enableOverflow={enableOverflow} /></div>}
        {isActiveTab === 'wishlist' && <div><WishListContent /></div>}
      </div>
    </div>
      <ul className="links">
    

      </ul>
    </aside>
  </SidebarContainer>)
}

const SidebarContainer = styled.div`
letter-spacing:1px;
text-align: center;

.sidebar-header {
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  background:var(--green-300);
}
.cartIcon{
  width:20px;
  margin-left:0.45rem;
}
.wishIcon{
  width:19px;
  height:16px;
  margin-left:0.5rem;
}
.first{
  width:100%;
  display: flex;
  padding-left:0.5rem;
  align-items:center;
  height:25px;
  button{
    margin-top:1rem;
  }
}
.first p{
  margin:0 !important;
  color:white;
  margin-right:auto;
  margin-left:0.5rem;
}
.close-btn {
  font-size: 1.75rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  transition: var(--transition);
  cursor: pointer;
  color: var();
  margin-right:auto;
}
p{
    margin-top:1rem;
    margin-left:4rem;
    font-size:1.2rem;
    font-weight:bold;
}
.close-btn:hover {
  color:white;
  
}
.header{
  margin-top:16px;
  span{
    font-size:14px;
    font-weight:600;
  }
}
.logo {
  justify-self: center;
  height: 45px;
}
.links {
  margin-bottom: 2rem;
}
.links a {
  display: block;
  text-align: left;
  font-size: 1rem;
  text-transform: capitalize;
  padding: 1rem 1.5rem;
  color: var(--clr-grey-3);
  transition: var(--transition);
  letter-spacing: var(--spacing);
}

.links a:hover {
  padding: 1rem 1.5rem;
  padding-left: 2rem;
  background: var(--clr-grey-10);
  color: var(--clr-grey-2);
}

.sidebar {
    position: fixed;
    top: 0;
    right: 0; 
    width:400px;
    height: 100%;
    background: white;
    transition: var(--transition-linear);
    transform: translate(100%); 

    z-index:999;
  }
.show-sidebar {
  transform: translate(0);
  z-index: 999;
}

.cart-btn-wrapper {
  margin: 2rem auto;
}
// @media screen and (min-width: 992px) {
//   .sidebar {
//     display: none;
//   }
// }
@media screen and (max-width: 580px) {
  .sidebar {
    width:75%;
  }
  .sidebar-header {
    background:white;
    p{
      display:none;
    }
    .first{
      margin:0;
      height:27px;
      button{
        font-size:1.5rem;
      }
      button:hover{
        color:var(--green-300);
      }
    }
    p,span{
      color:var(--grey-600) !important;
    }
  }
  .first{
    width:700px;
  }
   p{
    font-size:14px;
  }
  .close-btn {
    font-size:21px;
    margin-top:1rem;
  }
}
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  
}
span{
 color:white;
}

.header {
  display: flex;
  width:100%;
  justify-content:space-between;
  height:35px;
  align-items:center;
  font-size:19px;
}

.tab {
  flex: 1;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  font-family: "Poppins";
  svg{
    height:1.5rem;
    color:#fff;
  }

}

.tab.active {
  // text-decoration: underline;
  border-bottom:3px solid #FFE569;
  font-weight:bold;
}

.content {
  flex: 1;
  width:100%;
}
@media(max-width:992px){
  .first{
     padding:0 0.5rem;
     margin:0;
  }
}
@media(max-width:786px){
.first{
    width:100%;
}
p{
  font-size:16px;
  margin:0 auto;
}
.close-btn{
  font-size:16px;
     }
.header{
  font-size:16px;
     }
}
@media(max-width:580px){
  span{
    font-size:13px;
  }
  .close-btn{
    padding-left:0;
    margin-left:0;
  }
}
`

export default Sidebar
