import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { setTopParent } from '../../feature/plants/plantsSlice'

//showing only on mobile screen in the filter page
const SearchHeirarchy = () => {
  const {categoryList,category,subCategory,mainCategory} = useSelector((store)=>store.plants)
  const location = useLocation();  
  const dispatch = useDispatch()

  let children =[]
   const pathParts = location.pathname.split('/');
    const lastPart = pathParts.pop();
    const decodedCategoryName = decodeURIComponent(lastPart);
    const [loading,setLoading] = useState(false)

   
    function findTopParent(categoryList, decodedCategoryName) {
      for (let i = 0; i < categoryList.length; i++) {
          if (categoryList[i].name === decodedCategoryName) {
              return categoryList[i].name;
          }
          if (categoryList[i].children) {
              let parent = findTopParent(categoryList[i].children, decodedCategoryName);
              if (parent) {
                  return categoryList[i].name;
              }
          }
      }
      return null;
  }
  

  let topParent = findTopParent(categoryList, decodedCategoryName);
  dispatch(setTopParent(topParent))
      
      return(
        <Wrapper >
        {/* {parents.map((parent,idx)=>{
        return(
            <button key={idx}>{parent} /</button>
         )})} */}
         <button>{topParent}</button>
        </Wrapper >
      )
}

export default SearchHeirarchy

const Wrapper = styled.div`
  display:flex;
  flex-direction:row;
  gap:0.25rem;
  // margin-bottom:2rem;
  button{
    font-size:15px;
    margin:0 !important;
    padding:0 !important; 
    border-bottom:1px solid var(--grey-100);
  }
`