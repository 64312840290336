import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { styled, createGlobalStyle } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { SearchBar, CartButtons } from './support';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { closeSearchTopSearchbar, openBanner, openModal } from '../feature/modal/modalSlice';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { RiSearchLine } from 'react-icons/ri';
import { getFeaturedProducts, handleChange } from '../feature/plants/plantsSlice';
import { getBanners } from '../feature/BannerImages/bannerSlice';
import { verifyToken } from '../feature/user/userSlice';

// Preload the logo image
const logoImage = new Image();
logoImage.src = logo;

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    padding-right: 14.75px;
  }
  @media(max-width:560px){
   body{
    overflow: hidden;
    padding-right:0px !important;
    scroll-y:disabled;
   }
  }
`;

const SearchSection = () => {
  const { isOpenTopSearchbar, isOpen, isOpenCart, isScrollbarOpen } = useSelector((store) => store.modal);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // After the logo image is loaded, it will be cached for subsequent use
    logoImage.onload = () => {};
    // Preload other necessary data or dispatch necessary actions here
    dispatch(verifyToken());

    return () => {
      logoImage.onload = null;
      // Clean up any other resources if needed
    };
  }, [dispatch]); // Run this effect when dispatch changes

  const reloadPage = () => {
    if (window.location.pathname === '/') {
      dispatch(verifyToken());
      dispatch(getFeaturedProducts());
      dispatch(getBanners());
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const toggle = () => {
    dispatch(openModal());
  };

  const closeSearchTop = () => {
    dispatch(closeSearchTopSearchbar());
    dispatch(openBanner());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchValue) {
      dispatch(handleChange({ name: 'search', value: searchValue }));
      navigate(`/products/myplantstore/search/${searchValue}`);
      closeSearchTop();
    }
  };

  return (
    <Wrapper>
      {(isOpenCart || isOpen) && <GlobalStyle />}
      <div className={`overlay ${isOpen || isOpenCart ? 'enabled' : ''}`} />
      <div
        className={`search-bar ${isOpenTopSearchbar ? 'open' : ''}`}
        style={{ boxShadow: isScrollbarOpen ? 'none' : 'var(--shadow-4)' }}
      >
        <button className="search-icon" onClick={closeSearchTop}>
          <i className="fa fa-search">
            <AiOutlineArrowLeft />
          </i>
        </button>
        <input
          type="text"
          className="search-input"
          placeholder="Search.."
          name="search"
          value={searchValue}
          onChange={handleSearch}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(e);
            }
          }}
        />
        <button type="submit" className="search-icon" onClick={handleSubmit}>
          <i className="fa fa-search">
            <RiSearchLine />
          </i>
        </button>
      </div>
      <main className="full-width">
        <div className="logo">
          <button type="button" className="nav-toggle" onClick={toggle}>
            <FaBars />
          </button>
          <Link to="/" draggable="false">
            <div className="logo-img" onClick={reloadPage}>
              <img src={logo} alt="logo" draggable="false" />
            </div>
          </Link>
        </div>
        <section className="section-searchbar">
          <SearchBar />
        </section>
        <section className="cart-section">
          <CartButtons />
        </section>
      </main>
    </Wrapper>
  );
};

export default SearchSection;

const Wrapper = styled.section`
  padding:0 1.2rem !important;
  .overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right:0;
    z-index: 1;
    opacity:0.2;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .overlay.enabled {
    display: initial;
  }

  .search-bar {
    position: fixed;
    top: 0;
    left: 0;
    padding-right:0.5rem;
    float: left !important;
    width: 100% !important;
    transition: height 0.3s ease-in-out;
    display:none;
    z-index:999;
    background:#fff;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .search-bar.open {
    animation: slideDown 0.3s ease-out forwards;
    height: 70px;
    display: flex;
    align-items: left;
    justify-content: space-between;
  }

  .search-input {
    width:80%;
    font-size:14px;
  }
  .search-icon {
    flex: 1;
    background:white;
    border:none;
    font-size:2rem;
    color:var(--green-500);
  }
  .search-icon:hover {
    color:var(--green-300);
    transition:0.5s;
  }
  .search-input {
    flex: 8;
    border:none;
  }
  input:focus {
    outline: none;
  }
  transition: top 0.1s;
  margin-top:0.55rem;
  .full-width {
    display:grid;
    grid-template-columns: 2fr 5fr 1fr  !important;
    gap:1rem;
    margin-bottom:0.5rem;
  }
  .section-searchbar {
    max-width:80rem;
  }

  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--dark-green);
    cursor: pointer;
    position:absolute;
    padding-top:0.75rem;
    left:0;
    svg {
      font-size: 1.5rem;
    }
  }
  .logo {
    display:flex;
    justify-content:flex-start;
    padding:0 !important;
  }
  .logo-img {
    margin-left:1rem !important;
    padding:0 !important;
    img {
      margin:0;
      padding-top:0;
      width:225px;
    }
  }
  .form {
    display:flex;
  }
  .form-input,
  .form-select,
  .btn-block {
    height: 35px;
  }
  .form-row {
    margin-bottom: 0;
  }
  .form-center {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    row-gap: 0.5rem;
  }
  .heading {
    display:flex;
    align-items: center;
    gap:1.5rem;
    padding-bottom:.75rem;
  }
  img {
    margin-left:3rem;
    padding-top:1rem;
  }
  h5 {
    font-weight: 700;
  }
  .btn-block {
    align-self: end;
    margin-top: 1rem;
  }
  @media (min-width: 992px) {
    .logo button {
      display:none;
    }
  }
  @media (min-width: 992px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .btn-block {
      margin-top: 0;
    }
  }
  @media (max-width: 992px) {
    padding:0;
    padding-top:0.25rem;
    margin:0 !important;
    .full-width {
      margin-bottom:0rem;
    }
    .logo {
      margin-left:1rem;
    }
  }
  @media (max-width: 558px) {
    padding-left:1.2rem !important;
    padding-right:.2rem !important;
    height:35px !important;
    .logo-img {
      margin-left:0.25rem !important;
      padding-top:0.35rem !important;
    }
    .full-width {
      justify-content:flex-end;
      align-items:center;
    }
    img {
      margin:0 !important;
      padding:0  !important;
    }
    .heading img {
      width:55px;
    }
    .logo-img img {
      margin:0;
      padding-top:0;
      width:180px;
    }
  }
  @media (max-width: 992px) {
    margin-bottom:1rem;
  }
  @media (max-width: 480px) {
    padding:0 ;
    margin-top:0rem;
    .full-width {
      gap:0rem;
    }
    .search-icon {
      width:10%;
      font-size:1.5rem;
    }
    .search-input {
      padding-left:0.5rem;
    }
    .nav-toggle {
      margin-left:0rem !important ;
    }
    .logo {
      padding:0 !important;
      margin-left:1rem !important ;
    }
    .logo-img {
      padding-left:0 !important;
      img {
        margin:0 !important;
        padding-top:0.25rem;
        padding-left:0.75rem;
        width:160px !important;
      }
    }
  }
  @media (max-width: 760px) {
    margin:0;
    height:60px;
    .search-bar {
      width:300px;
    }
  }
`
