

import { PiPottedPlantFill } from "react-icons/pi";
import {FaHome,FaUserPlus,} from "react-icons/fa";

export const links = [
    {
      id: 7,
      text: 'Home',
      icon:<FaHome />,
      url: '/',
    },
      {
        id:6,
        text:'Orders & rewards',
        icon:<PiPottedPlantFill />,
        url:'/user/order_status',
      },
      {
        id: 4,
        text:'Register or sign in',
        icon:<FaUserPlus />,
        url: '/user/login',
      },
  
    ]
  