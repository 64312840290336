import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdNavigateBefore, MdNavigateNext, MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import LazyImage from './support/LazyImage';
import './support/Hoverimage.css'
const ProductImages = ({ images, updateActiveImageName, activePotIndex }) => {
  const [main, setMain] = useState(images && images[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleImageClick = (image, index) => {
    setMain(image);
    setActiveIndex(index);
    updateActiveImageName(image);
    scrollToIndex(index);
  };

  const handlePrevClick = () => {
    const newIndex = activeIndex > 0 ? activeIndex - 1 : images.length - 1;
    setMain(images[newIndex]);
    setActiveIndex(newIndex);
    scrollToIndex(newIndex);
  };

  const handleNextClick = () => {
    const newIndex = activeIndex < images.length - 1 ? activeIndex + 1 : 0;
    setMain(images[newIndex]);
    setActiveIndex(newIndex);
    scrollToIndex(newIndex);
  };

  const handleScrollTop = () => {
    setScrollPosition(Math.max(0, scrollPosition - 1));
  };

  const handleScrollBottom = () => {
    setScrollPosition(Math.min(images.length - 4, scrollPosition + 1));
  };

  const scrollToIndex = (index) => {
    setScrollPosition(Math.max(0, Math.min(images.length - 4, index)));
  };


  const [backgroundPosition, setBackgroundPosition] = useState('center');
  const [showZoom,setShowZoom] = useState(false);

  const handleMouseEnter = () => {
    setBackgroundPosition('center'); 
    setShowZoom(true)
  };


  const handleMouseMove = (e) => {
    const width = e.target.offsetWidth;
    const height = e.target.offsetHeight;
    const mouseX = e.nativeEvent.offsetX;
    const mouseY = e.nativeEvent.offsetY;

    const bgPosX = (mouseX / width) * 100;
    const bgPosY = (mouseY / height) * 100;

    setBackgroundPosition(`${bgPosX}% ${bgPosY}%`);
  };

  const handleMouseLeave = () => {
    setBackgroundPosition('center');
    setShowZoom(false)
  };

  useEffect(() => {
    // Update activeImageName whenever it changes
    if (images && activePotIndex < images.length) {
      setActiveIndex(activePotIndex);
      setMain(images[activePotIndex]);
      scrollToIndex(activePotIndex);
    }
  }, [activePotIndex, images]);

  const [isZoomed, setIsZoomed] = useState(false);

  const handleMouseEnter1 = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave1 = () => {
    setIsZoomed(false);
  };

  const handleMouseMove1 = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const offsetX = (e.clientX - left) / width;
    const offsetY = (e.clientY - top) / height;

    e.target.style.transformOrigin = `${offsetX * 100}% ${offsetY * 100}%`;
  };
  

  return (
    <Wrapper>
      <div className="main-wrapper">
      <div className="image-zoom-container"
      onMouseEnter={handleMouseEnter1}
      onMouseLeave={handleMouseLeave1}
      onMouseMove={handleMouseMove1}>
    <img
  src={images && images.length > 0 && main.url} // Closing parenthesis added here
  alt="Zoomable Image"
  className={`zoomable-image ${isZoomed ? 'zoomed' : ''}`}
/>

    </div>
        <button className="prev" onClick={handlePrevClick}>
          <MdNavigateBefore />
        </button>
        <button className="next" onClick={handleNextClick}>
          <MdNavigateNext />
        </button>
      </div>

      <div className="gallery">
        {images && images.length > 0 && (
          <>
            {(images?.length>4) &&<button className="scroll-top" onClick={handleScrollTop}>
              <MdArrowUpward />
            </button>}
            {images.slice(scrollPosition, scrollPosition + 4).map((image, index) => (
              <LazyImage src={image.url}  alt="images"  key={index}  onClick={() => handleImageClick(image, index + scrollPosition)} 
              className={index + scrollPosition === activeIndex ? 'active gallery-image' : 'gallery-image'}  draggable="false"/>
            ))}
            {(images?.length>4) && <button className="scroll-bottom" onClick={handleScrollBottom}>
              <MdArrowDownward />
            </button>}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default ProductImages

const Wrapper = styled.section`
  max-width:100%;
  height:600px;
  display:flex;
  flex-direction:row;
  gap:1.75rem;

  .main-wrapper {
    position: relative;
    width:85%;
    .main {
      width: 100%;
      height:100%;
      min-width:400px;
      display: block;
      border-radius: var(--radius);
      object-fit:cover;
      background-size: 100%;
      background-repeat: no-repeat;
      &:hover {
       background-size: 175%;
      }
    }
  }
  .scroll-top,
  .scroll-bottom {
    border: none;
    background: #befad3;
    color: var(--green-300);
    transition: background 0.3s ease;
  }
  
  .scroll-top:hover,
  .scroll-bottom:hover {
    background: var(--green-500);
    color: #fff;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color:var(--green-500);
    border: none;
    cursor: pointer;
    z-index:0;
    // display:none;
  }
  
  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
  .gallery {
    display: flex;
    flex-direction:column;
    height:100%;
    gap:1rem;
    .gallery-image {
      object-fit:cover;
      width:100px;
      height:120px;
      cursor: pointer;
    }
  }
  .active {
    box-shadow: 0px 0px 0px 2px var(--green-500);
  }
  @media(max-width:1150px){
      // max-width:700px;
      // margin:0 auto;
  }
  @media(max-width:610px){
    .main-wrapper {
      max-width:400px;
      .main {
       max-width:400px;
       max-height:475px;
       min-width:200px;
      }
    }
    .gallery {
      gap:0.75rem;
      .gallery-image {
        width:100px;
        height:110px;
      }
    }
  }
  @media (max-width: 530px) {
    .main-wrapper {
      max-width:300px;
      .main {
       max-width:300px;
       max-height:400px;
       min-width:200px;
      }
    }
    .gallery {
      gap:0.75rem;
      .gallery-image {
        width:75px;
        height:76px ;
      }
    }
  }
  @media (max-width: 430px) {

    .prev,
    .next {
      top:65%;
    }
    max-height:300px;
    justify-content:flex-start;
  
    gap:0.75rem;
    .main-wrapper {
      max-height:320px;
      max-width:250px;
      .main {
       max-width:320px;
       height:100%;
       min-width:210px;
      }
    }
    .gallery {
      gap:0.75rem;
      .gallery-image {
        width:60px;
        height:58px;

      }
    }
    
  }
  @media (min-width: 992px) {
    .main {
      height: 500px;
    }
    
    }
  }
  @media(min-width:1650px){
    .main-wrapper{
      width:600px !important;
    }
  }
`


