import axios from "axios";
import { getAuthTokenCookie, setAuthTokenCookie } from "../../utils/localStorage";
import { verifyToken,setItem, setUser } from "./userSlice";
import { json } from "react-router-dom";
import { toast } from "react-toastify";


//getting user details after sign in by facebook or google
export const getUserThunk = async (_, thunkApi) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URLS}/auth/login/success`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const user = response.data.user;
    localStorage.setItem('user', JSON.stringify(user));
    thunkApi.dispatch(setUser(user));

    return user;
  } catch (error) {
  
    return thunkApi.rejectWithValue();
  }
};

//get user address
export const getUserAddressThunk= async (_,thunkApi)=>{
  const { myUser } = thunkApi.getState().user;
  try {
    if (myUser) {
      // Retrieve userId from local storage
      const userData = JSON.parse(localStorage.getItem('user'));
      // Add userId to the data object
      const userId = userData.id || userData._id;
      const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-user-previous-data/${userId} `);
      return resp.data;
    }
  } catch (error) {
    return
}
}

//register user by email and password
export const registerUserThunk = async (user,thunkApi)=>{
    try {
        const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/customer`,user)
        return resp.data
    } catch (error) {
      toast.error(error.response.data.message)
        if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
        ) {
            // setError(error.response.data.message);
            return thunkApi.rejectedWithValue(error.response.data.msg)
        }
    }
}

//login user by email and password
export const loginUserThunk = async (user, thunkApi) => {
    try {
        const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/Customerauth`, user);
        return resp.data;
    } catch (error) {
        if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
        ) {
            return thunkApi.rejectWithValue(error.response.data.msg);
        }
    }
}
//reset password 
export const forgotPasswordThunk = async(email,thunkApi)=>{
    try {
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/Customerauth/forgotpassword`, { email: email });
      toast.success(resp.data.message)
      return resp.data;
     } catch (error) {   
    toast.error(error.response.data.message)
    return thunkApi.rejectWithValue(error.response.data.msg);
  }
}

//updating password
export const updatePasswordThunk = async(user,thunkApi)=>{
;
  try {
    const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}/Customerauth/updatepassword`, user);
    toast.success(resp.data.message)
    return resp.data;
   } catch (error) {   
    toast.error(error.response.data.message)
  return thunkApi.rejectWithValue(error.response.data.msg);
}
}


//verifying user with token
export const verifyTokenThunk = async (_,thunkApi) => {
    const token = await getAuthTokenCookie()
    if(token){
      try {
        const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/Customerauth/verify`, 
        { headers: { 'x-auth-token': token } });
        return resp.data;
        
      } catch (error) {
          // return thunkApi.rejectWithValue(error.response.data.msg);
      }
    }
  };

//adding to cart items
export const addToCartThunk = async (data, thunkApi) => {
    const { myUser } = thunkApi.getState().user;
    
    try {
      if (myUser) {
        // Retrieve userId from local storage
        const userData = JSON.parse(localStorage.getItem('user'));
        // Add userId to the data object
        data.userId = userData.id || userData._id;
        
        const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/cart`, data);
        return resp.data;
      } 
      else {
        let cartData = JSON.parse(localStorage.getItem('cart')) || [];
        data._id = data.productId;
        // Check if item already exists in the cart
        const existingItemIndex = cartData.findIndex(item => 
          item.productId=== data.productId &&
        item.dimension._id === data.dimension._id
        );
        
        if (existingItemIndex !== -1) {
          // If item exists, update it
          cartData[existingItemIndex] = data;
        } else {
          // If item does not exist, add it to the cart
          cartData.push(data);
        }
        
        localStorage.setItem('cart', JSON.stringify(cartData));
        
        return data;
        
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  };
  

    //getting cart items
    export const getCartThunk = async(_,thunkApi)=>{
        const {myUser} = thunkApi.getState().user
        if(myUser){
            try {
                const userData = JSON.parse(localStorage.getItem('user'));
                const id = userData.id || userData._id
                const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/cart/${id}`);

            return resp.data;
            } catch (error) {
                return thunkApi.rejectWithValue(error.response.data.msg);
               
            }
        }else {
          // If myUser is false, retrieve the cart from local storage
          const cartFromLocalStorage = JSON.parse(localStorage.getItem('cart')) || [];
          return cartFromLocalStorage;
          
        }
    }



 //push to wishlist items to local storage
 export const addWishlistItemThunk = async (wishlistItem, thunkApi) => {
    
    try {
      const existingWishlistItems = JSON.parse(localStorage.getItem('wishlistItems')) || [];
  
      // const existingItemIndex = existingWishlistItems.findIndex((item) => item.productId === wishlistItem.productId);
      const existingItemIndex = existingWishlistItems.findIndex(item => 
        item.productId=== wishlistItem.productId &&
        item.dimension._id === wishlistItem.dimension._id
      );
  
      if (existingItemIndex !== -1) {
        // Update the existing item
        existingWishlistItems.splice(existingItemIndex, 1);
        localStorage.setItem('wishlistItems', JSON.stringify(existingWishlistItems));
      } else {
        // Add the new item to the wishlist
        existingWishlistItems.push(wishlistItem);
        toast.success("Added successfully...")
      }
  
      // Update the wishlist items in Local Storage
      localStorage.setItem('wishlistItems', JSON.stringify(existingWishlistItems));
  
      // Return the updated wishlist items
      return existingWishlistItems;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  };
  //get wishlist items from  local storage
    export const getWishlistItemsLocalStorageThunk = async (_,thunkApi) => {
    try {
        const result = localStorage.getItem('wishlistItems')
        const resp = JSON.parse(result)
        return resp 
    } catch (error) {
        return thunkApi.rejectWithValue(error.response.data.msg);
    }
}

//remove wishlist item
export const removeWishlistItemLocalStorageThunk = async(itemId,thunkApi) => {
    try {
        const existingWishlistItems = JSON.parse(localStorage.getItem('wishlistItems')) || [];
        
     const itemIndexToRemove = existingWishlistItems.findIndex((item) =>  item.productId  === itemId.id &&  item.dimension._id === itemId.dimensionId);

     if (itemIndexToRemove !== -1) {
  
     existingWishlistItems.splice(itemIndexToRemove, 1);


     localStorage.setItem('wishlistItems', JSON.stringify(existingWishlistItems));
  }
    } catch (error) {
      
        return thunkApi.rejectWithValue(error.response.data.msg); 
    }
 
};


//get ordered data
export const getOrderedDataThunk =  async(_,thunkApi)=>{
  const {myUser } = thunkApi.getState().user
  if(myUser){
    try {
      const userData = JSON.parse(localStorage.getItem('user'));
      const id = userData.id || userData._id
      const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-ordered-items/${id}`);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);

    }
  }
}

export const couponsThunk = async(_,thunkApi)=>{
  const {myUser} = thunkApi.getState().user
  if(myUser || !myUser){
    try {
      const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/GetAllValidCoupons`)
      return resp.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
}


export const getRedeemedDataThunk = async (data, thunkApi) => {
  const { myUser } = thunkApi.getState().user;
  
  try {
    if (myUser) {
      const userData = JSON.parse(localStorage.getItem('user'));
      const id = userData.id || userData._id
      const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/couponsAndCoins/${id}`)
      return resp.data
    }
  }
  catch (error){
    return thunkApi.rejectWithValue(error.response.data.msg);
  }
}