import React, { useState ,useEffect} from 'react'
import styled from 'styled-components';
import {handlePriceChange} from '../../feature/plants/plantsSlice'
import { useDispatch} from 'react-redux';

//price filter multi range in products page 
const PriceMultiRange = () => {
  const dispatch = useDispatch();

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(5000);
  const [priceGap] = useState(0);

  // Flags to indicate if a drag is in progress
  const [draggingMin, setDraggingMin] = useState(false);
  const [draggingMax, setDraggingMax] = useState(false);

  const handleRangeMinChange = (e) => {
    // Mark the start of a drag
    setDraggingMin(true);
    const newMinVal = parseInt(e.target.value);
    if (maxPrice - newMinVal >= priceGap) {
      setMinPrice(newMinVal);
    }
  };

  const handleRangeMaxChange = (e) => {
    // Mark the start of a drag
    setDraggingMax(true);
    const newMaxVal = parseInt(e.target.value);
    if (newMaxVal - minPrice >= priceGap) {
      setMaxPrice(newMaxVal);
    }
  };
  useEffect(() => {
    if (!draggingMin && !draggingMax) {
      // This code will execute only when no drag is in progress
      dispatch(handlePriceChange({ name: 'max_price', value: maxPrice }));
      dispatch(handlePriceChange({ name: 'min_price', value: minPrice}));
    }
  }, [maxPrice, minPrice, draggingMin, draggingMax]);

  // Reset dragging flags when the user releases the range input
  useEffect(() => {
    const handleMouseUp = () => {
      setDraggingMin(false);
      setDraggingMax(false);
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  
  return (
    <Wrapper className="wrapper">

<div style={{paddingBottom:'20px'}}>
                
                <div class="green-bar"><p class="header" style={{paddingLeft:'16px'}}>PRICE RANGE</p></div>
              </div>
        
      <div className="slider">
        <div className="progress" style={{ left: `${(minPrice / 5000) * 100}%`, right: `${100 - (maxPrice / 5000) * 100}%` }}></div>
      </div>
      <div className="range-input">
        <input
          type="range"
          className="range-min"
          min="0"
          max="5000"
          value={minPrice}
          step="10"
          onChange={handleRangeMinChange}
        />
        <input
          type="range"
          className="range-max"
          min="0"
          max="5000"
          value={maxPrice}
          step="10"
          onChange={handleRangeMaxChange}
        />
      </div>
      <div  className='price'>
      <p>{minPrice} <span>AED</span></p>
      <p className='right'>{maxPrice} <span>AED{maxPrice === 5000 ?  '+' : null}</span></p>
      </div>
    </Wrapper>
  );
}

export default PriceMultiRange

const Wrapper = styled.div`
::selection{
  color: #fff;
  background: #17A2B8;
}
.green-bar {
  width: 0.45rem;
  height: 1.5rem;
  background: var(--green-300);
  border-radius: 3.25px;
  margin-right: 0.5rem;
}
.wrapper{
  width: 400px;
  background: #fff;
  border-radius: 10px;
  padding: 20px 25px 40px;
  box-shadow: 0 12px 35px rgba(0,0,0,0.1);
}
.header{
  font-size: 16px;
  margin-bottom:1rem !important;
  font-weight:600;
}
.price-input{
  width: 100%;
  display: flex;
  margin: 30px 0 35px;
}
.price-input .field{
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;

}
.field input{
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 19px;
  margin-left: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.price{
  display:flex;
  flex-direction:row;
  justify-content:space-between !important;
  margin-top:0.75rem;
  margin-bottom:0.75rem !important;
}
.right{
  text-align:right;
}

.price-input .separator{
  width: 130px;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
}
.slider{
  height: 5px;
  position: relative;
  background: var(--grey-200);
  border-radius: 5px;

}
.slider .progress{
  height: 100%;
  left: 25%;
  right: 25%;
  position: absolute;
  border-radius: 5px;
  background: var(--green-300);
}
.range-input{
  position: relative;
}
.range-input input{
  position: absolute;
  width: 100%;
  height: 5px;
  top: -7px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="range"]::-webkit-slider-thumb{
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #fff;
  cursor:pointer;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0,0,0,0.5);
}
input[type="range"]::-moz-range-thumb{
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: #17A2B8;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0,0,0,0.05);
}

`