import React, { useEffect,useState } from 'react';
import icon from './icon.jpeg'
export const PaymentFailed = () => {
    const [showMessage, setShowMessage] = useState(true);
  useEffect(() => {
    const result = localStorage.getItem('CheckoutId');
    const timeout = setTimeout(() => {
  
      window.location.href = `https://myplantstore.me/user-cart`;
    }, 5000); 

    
    return () => clearTimeout(timeout);
  }, []); 

  return (
    <div>
    <header style={{ textAlign: 'center', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', height: '90px', position: 'relative' }}>
        {/* Image icon */}

    </header>
    <img src={icon} alt="Icon" style={{ position: 'absolute', top: 120, left: '50%', transform: 'translateX(-50%)', width: '50px', height: '50px' }} />
       
    <div style={{ textAlign: 'center', position: 'relative' }}>
        <h4 style={{ marginTop: '100px' }}>HIFAA FLOWERS & ORNAMENTAL PLANTS TRADING CO. L.L.C</h4>
        {showMessage ? (
            <div>
                <div className="loader"></div>
                <p>Payment Failed...</p>
            </div>
        ) : (
            <p>Payment Failed...</p>
        )}
    </div>

</div>
  );
};
