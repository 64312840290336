import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { handleChange, openSearchResults } from '../feature/plants/plantsSlice'
import { useNavigate } from 'react-router-dom'
import { ScrollCategory } from './support'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const CategoriesIcon = () => {
const {categoryList} = useSelector((store)=>store.plants)
const dispatch = useDispatch()
const navigate =useNavigate()

const mouseClickHandler = (category, event, name) => {
  const propertyValues = Object.values(category);
  const url = propertyValues[0];
  // dispatch(handleChange({ name: 'mainCategory', value: url }));
  dispatch(openSearchResults(propertyValues))
   window.scrollTo(0, 0);
  navigate(`/products/mainCategory/category/${url}`);
  
}
  return (
    <React.Fragment>
      <Wrapper >
      <div className='head'>
      <div></div>
      <h5>categories</h5>
      </div>
      <AliceCarousel
  mouseTracking
  autoPlay
  infinite
  autoPlayInterval={3000}
  autoPlayStrategy="default"
  items={categoryList?.map((category, idx) => (
    <div
      key={idx}
      className='category-row'
      onClick={(event) => mouseClickHandler(category, event, '')}
    >
      <img src={category.image?.url} alt='icon' draggable="false"/>
      <p className='category-name'>{category.name}</p>
    </div>
  ))}
  animationType="fadeout" 
  animationDuration={1000}
  touchTrackingEnabled
  swipeDelta={5}
  
  touchMoveDefaultEvents={['onTouchMove']}
  buttonsDisabled={true}
  duration={10}
  responsive={{
    0: { items: 2 }, // 0-599px - show 2 items
    600: { items: 2 },
    800: { items: 3 }, // 600-959px - show 3 items
    960: { items: 4 }, // 960-1279px - show 4 items
    1280: { items: 6 }, // 1280-1919px - show 5 items
    1920: { items: 7 }, // 1920px and above - show 6 items
  }}
/>
      </Wrapper>
      <ScrollDiv>
      <ScrollCategory />
      </ScrollDiv>
    </React.Fragment>
  )
}

export default CategoriesIcon

const ScrollDiv = styled.div`
@media(min-width:992px){
  display:none;
}
@media(max-width:490px){
  margin-top:0.5rem;
}
`

const Wrapper = styled.section`
  max-width: 95%;
  margin: 0 auto;
  
  @media (max-width: 992px) {
    display: none;
    
  }
  .alice-carousel__prev-btn,.alice-carousel__next-btn{
    display:none;
  }
  .alice-carousel__dots{
    margin-top:1rem;
  }
  .alice-carousel__stage-item{
    padding:0.5rem;
  }
  .grid-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 0.75rem;
    cursor: pointer;
  }
  
  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0 1.5rem 0;
    gap: 0.75rem;
    
    div {
      width: 0.75rem;
      height: 2rem;
      background: var(--green-300);
      border-radius: 3.75px;
    }
  }
  
  h5 {
    text-transform: uppercase;
    font-weight: 700;
    padding: 0;
    margin: 0;
    font-size:15px;
    letter-spacing:0;
  }
  
  p {
    letter-spacing: 0;
    padding: 0;
    margin: 0;
    padding-right: 0.25rem !important;
    text-transform:capitalize  !important;
  }
  
  .category-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 90px;
    border: 2px solid var(--grey-100);
    border-radius: 10px;
    font-size: 15px;
    p{}
  }
  
  img {
    width: 75px;
    height: 88px;
    border-radius: 10px 0px 0px 10px;
  }
  
  p {
    margin-left: 10px;
  }
}
@media (max-width: 520px) {
 .category-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

`