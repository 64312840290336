import { createSlice,createAsyncThunk  } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addUserReviewThunk } from "./reviewThunk";

const initialState = {
    isLoading:false,
    user_Id:'',
    product_id:'',
}

export const addReview = createAsyncThunk('user/review' ,addUserReviewThunk)

const ReviewSlice = createSlice({
    name:'review',
    initialState,
    reducers:{

    },
    extraReducers:(builder)=>{
        builder
        .addCase(addReview.pending,(state)=>{
            state.isLoading =true
        })
        .addCase(addReview.fulfilled,(state,{payload})=>{
            state.isLoading =false
            toast.success(payload.msg)
        })
        .addCase(addReview.rejected,(state,{payload})=>{
            state.isLoading =false
            toast.error(payload.msg)
        })

    }
})

export const{} = ReviewSlice.actions

export default ReviewSlice.reducer