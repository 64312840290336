import React from 'react'
import styled from 'styled-components'
import facebook from '../assets/images/facebook.png'
import twitter from '../assets/images/twitter.png'
import insta from '../assets/images/insta.png'
import linkedin from '../assets/images/linkedin.png'
import { useSelector } from 'react-redux'
import { PiCopyright } from "react-icons/pi";
import { Link, useNavigate } from 'react-router-dom'
import mastercard from '../assets/images/master.png'
import paypal from '../assets/images/paypal.png'
import visa from '../assets/images/visa.png'
import logo from '../assets/images/logo.png'
const Footer = () => {
  const { categoryList } = useSelector((store) => store.plants)
  const navigate = useNavigate()
  let arr = [];


  if (categoryList.length > 0) {
    arr = categoryList.slice(0, 2);
  }
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const onBtnClick = (prop) => {
    const url = `https://myplantstore.me/products/mainCategory/category/${prop}`;
    window.location.href = url;
    window.scrollTo(0, 0);
  }
  const phoneNumber = '+97145771196';
  const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{4})(\d{3})/, '$1 $2 $3');

  const whatsappNo = '+971527349456';
  const formattedWhatsappno = whatsappNo.replace(/(\d{3})(\d{4})(\d{3})/, '$1 $2 $3');

  const whatsappNo2 = '971527349456';
  return (
    < Wrapper>
      <div className='top'>
        <div className='store-info'>
          <img src={logo} alt="logo" className='logo-img' draggable="false" />
          <h6>HIFAA FLOWERS & ORNAMENTAL PLANTS TRADING CO. L.L.C</h6>
          <p style={{ fontSize: '12px' }}>shop No.20 , Building X-26 ,<br />England Cluster , international city , <br />dubai<span>-UAE</span></p>
          <a href={`tel:${phoneNumber}`} style={{ fontSize: '15px', textDecoration: 'none', }}>
            Hotline: {formattedPhoneNumber}
          </a>
          <a href={`https://wa.me/${whatsappNo2}`} style={{ fontSize: '15px', textDecoration: 'none' ,paddingBottom:'5px'}}>
            Whatsapp: {formattedWhatsappno}
          </a>

          {/* <p style={{ fontSize: '12px' }}>Monday-Saturday: 9am - 6pm</p> */}
        </div>
        <div className='info'>
          <div className='quick-links'>
            <h5>quick links</h5>
            {arr && arr.map((link, idx) => {
              return (
                <div key={idx}>
                  <button type='button' onClick={() => onBtnClick(link.name)} >{link.name}</button>
                </div>
              )
            })}
            <Link to='/blogs' className='padding-a'>blogs</Link >
            <Link to='/about'>about us</Link >
          </div>
          <div className='information'>
            <h5>information</h5>
            <Link to="">account info</Link>
            <Link to="/user/order_status#orders">Track your order</Link>
            <Link to="/terms-and-conditions#shipping-policies">shipping policy</Link>
            <Link to="/terms-and-conditions#Refund-policies">return and refund</Link>
            <Link to="/terms-and-conditions#terms&conditions">terms and conditions</Link>
          </div>
          <div className='social-media show-big'>
            <h5>follow us</h5>
            <div className='images'>
              <a href="https://www.instagram.com/myplantstore.me?igsh=YzFtZnBoMDR0Z3h6" target="_blank" rel="noopener noreferrer">
                <img src={insta} alt='icon' draggable="false" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61556736087843&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt='icon' draggable="false" />
              </a>

              {/* <Link to=""><img src={linkedin} alt='icon' draggable="false" /></Link>
              <Link to=""><img src={twitter} alt='icon' draggable="false" /></Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className='social-media show-small'>
        <h5>follow us</h5>
        <div className='images'>
          <a href="https://www.instagram.com/myplantstore.me?igsh=YzFtZnBoMDR0Z3h6" target="_blank" rel="noopener noreferrer">
            <img src={insta} alt='Instagram' draggable="false" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61556736087843&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt='Facebook' draggable="false" />
          </a>

          {/* <Link to=""><img src={linkedin} alt='icon' draggable="false" /></Link>
          <Link to=""><img src={twitter} alt='icon' draggable="false" /></Link> */}
        </div>
      </div>
      <div className='bottom'>
        <div className='copy-right'>
          <p className='year'>{currentYear} <PiCopyright /> myplantstore.me</p>
          <div className='payment'>
            <img src={visa} className='visa' alt="payment-img" draggable="false" />
            <img src={mastercard} className='master' alt="payment-img" draggable="false" />
            <img src={paypal} className='paypal' alt="payment-img" draggable="false" />
          </div>
        </div>
      </div>
    </ Wrapper>
  )
}

const Wrapper = styled.footer`
bottom: 0;
left: 0;
width: 100%;
background: white;
padding: 0.25px 2rem;
font-family: "Poppins";
.top{
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  margin-bottom:0.5rem;
}
.store-info{
  display:flex;
  flex-direction:column;
  max-width:270px;
}
h6,p{
  margin:0;
  padding:0;
  font-size:14px;
  color:black;
}

p{
  margin-bottom:0.25rem;
  text-transform:capitalize;
}
.year{
  text-transform:none;
  display: flex;
  align-items: center;
  gap:0.1rem;
  
}
.bottom{
  border-top:2px solid var(--grey-50);
}
button{
  background:transparent;
  border:none;
  text-align:left;
  margin:0;
  padding:0;
  font-weight:500;
  text-transform:capitalize;
  
}
h5{
  font-weight:500;
  text-transform:capitalize;
  letter-spacing:0;
  padding:0;
  margin:0;
}
a{
  font-size:13px;
  color:black;
  letter-spacing:0;
  margin:0.45rem 0;
}
a:hover ,button:hover{
  text-decoration:underline;
  color:var(--green-500);
  transition:0.2s ;
}
.quick-links,.information{
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:left;
  text-transform:capitalize;
  font-weight:500;
}
.images{
  display:flex;
  flex-direction:row;
  gap:0.25rem;
}
img{
  width:25px;
}
.info{
  display:flex;
  flex-direction:row;
  gap:3rem;
  
}
.payment {
  display: flex;
  flex-direction: row;
  gap: 0.75rem; 
}
.logo-img{
  width:205px;
  margin-bottom:0.5rem;
}
.payment img {
  width: 40px; 
  height: auto; 
}
.master{
  width: 45px !important;
}
.copy-right{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-top:0.75rem;
  margin-bottom:0.5rem;
}
.show-small{
  display:none;
}
@media(max-width:786px){
  .show-small{
    display:block;
  }
  .quick-links{
    display:none;
  }
  .show-big{
    display:none;
  }
  .payment img {
    width: 30px; 
    height: 30px; 
  }
}
@media(max-width:580px){
  padding: 0.25px 1rem;
  .store-info{
   width:50%;
   gap:0.25rem;
   p{
    display:inline-block;
   }
  }
  .info{
    padding-top:0.7rem;
    h5{
      font-size:16px;
      font-weight:bold;
    }
  }
  .top{
    gap:0rem;
  }
  .social-media{
    
    h5{
      font-size:16px;
      font-weight:600;
      margin-top:5px !Important;
    }
  }
  img{
    object-fit:cover;
  }
  .master{
    width:30px !important;
    height:30px !important;
  }
  .logo-img{
    width:170px;
    margin-bottom:0.45rem;
  }
  .information {
    h5{
      margin-bottom:0.75rem;
    }
    a {
      font-size: 12px !important;
      font-weight: 400;
     
    }
    
  }
  .store-info{
    h6{
      font-weight:400;
      font-size:12px !important;
    }
    p{
      font-size:10px !important;
      font-weight:400;
    }
    a{
      font-size:12px !important;
      font-weight:400;
      text-decoration: underline !important;
    }
  }
}
@media(min-width:1240px){

  .quick-links > * {
    flex: 1; 
    margin: 5px; 
    max-height: 16px;
    
  }
 .padding-a{
  margin-top:0.75rem;
 }
  
 
  .information > * {
    flex: 1; 
    margin: 5px; 
    max-height: 25px;
    
  }
}
`

export default Footer
