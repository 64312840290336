import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Products from './pages/Products';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { lazy, useEffect, useState,Suspense} from 'react';
import { getCart,getUser,setUser, getWishList, verifyToken } from './feature/user/userSlice';
import { useDispatch} from 'react-redux';
import { getCategories, getFeaturedProducts, getFeaturedTags } from './feature/plants/plantsSlice';
import { getBanners } from './feature/BannerImages/bannerSlice';
import { Loading } from './components';
import CommingSoon from './components/commingsoon';
import { PaymentSuccess } from './components/PaymentFail';
import { PaymentFailed } from './components/PaymentSuccess';
import { CashOnDeliverysuccessStatus } from './components/CashOnDeliverysuccessStatus';
import GoogleLoading from './components/GoogleLoading';

// import CommingSoon from './pages/CommingSoon';
const About = lazy(()=>import ('./pages/About'))
const NotFoundPage = lazy(()=>import ('./pages/NotFoundPage'))
const SingleProductPage = lazy(()=>import ('./pages/SingleProductPage'))
const SearchPage = lazy(()=>import ('./pages/SearchPage'))
const FeatureTagSearch=lazy(()=>import ('./pages/FeatureTagSearch'))
const Login  = lazy(()=>import ('./pages/LoginPage'))
const EmailVerificationPage = lazy(()=>import ('./pages/EmailVarificationPage'))
const PrivateRoute  = lazy(()=>import ('./pages/PrivateRoute'))
const CheckoutPage = lazy(()=>import ('./pages/CheckoutPage'))
const OrdersAndRefund  = lazy(()=>import ('./pages/OrdersAndRefund'))
const TermsAndConditions = lazy(()=>import ('./pages/TermsAndContidtions'))
const Blog = lazy(()=>import ('./pages/Blog'))
const SingleBlog  = lazy(()=>import ('./pages/SingleBlog'))
const ForgotPassword = lazy(()=>import ('./pages/ForgotPassword'))
const ResetPassword = lazy(()=>import ('./pages/ResetPassword'))
const CartPage  = lazy(()=>import ('./pages/CartPage'))

function App() {
  const [isTawkMessengerLoaded, setTawkMessengerLoaded] = useState(false);
const dispatch = useDispatch()

useEffect(() => {
  // Check if user data is available in local storage
  const userData = localStorage.getItem('user');
  if (userData) {
    // Parse the user data and dispatch an action to update the Redux state
    const user = JSON.parse(userData);
    dispatch(setUser(user));
  }
}, [dispatch]);

useEffect(() => {
  const fetchData = async () => {
    await dispatch(verifyToken());
  };

  fetchData();
}, [dispatch]);

// Memoize the API calls
const fetchData = async () => {
  dispatch(getCart());
  dispatch(getWishList());
  dispatch(getUser());
  dispatch(getCategories());
};

fetchData();  // Fetch the initial data

const timeoutId = setTimeout(() => {
  // Parallelize API calls
  const fetchDataAsync = async () => {
    try {
      await Promise.all([
        dispatch(getFeaturedTags()),
        dispatch(getFeaturedProducts()),
        dispatch(getBanners()),
      ]);
    } catch (error) {
      // Handle errors
    }
  };

  fetchDataAsync();

  setTimeout(() => {
    setTawkMessengerLoaded(true);
  }, 5000);
}, 0);

  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_API.customStyle = {
    zIndex: window.innerWidth > 992 ? 999 : 10,
    visibility : {
      desktop : {
       
        position : 'bl'
      },
      mobile : {
        xOffset : '-1px',
        yOffset : '65px',
        position : 'br'
      },
    }
  }

  return (
    <Router>
    <Suspense fallback={<Loading />}>
      <Routes>
      <Route path='/CommingSoon' element={<CommingSoon/>} />
        <Route path='/' element={<Products />} />
        <Route path='/user/login' element={<Login />} />
        <Route path='/user/login/forgot_password' element={<ForgotPassword />} />
        <Route path='/resetpassword/:id' element={<ResetPassword />} />
        <Route path='/products/:mainCategory/:category/:subCategory' element={<SearchPage />} />
        <Route path='/Feature/:mainCategory/:category/:subCategory' element={<FeatureTagSearch/>} />
        <Route path='/products/:id' element={<SingleProductPage />} />
        <Route path='/user-cart' element={<CartPage />} />
        <Route path='/about' element={<About />} />
        <Route path='/blogs' element={<Blog />} />
        <Route path='/blogs/post/:id' element={<SingleBlog />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path="/users/:id/verify/:token" element={<EmailVerificationPage />} />
        <Route path='/checkout/:id' element={<PrivateRoute><CheckoutPage /></PrivateRoute>} />
        <Route path='/user/order_status' element={<PrivateRoute><OrdersAndRefund /></PrivateRoute>} />
        <Route path='*' element={<NotFoundPage />} />
        <Route path='/PaySuccess/:id' element={<PaymentSuccess/>} />
        <Route path='/PayFailed' element={<PaymentFailed />} />
        <Route path='/CashOnDeliveryStatus/:id' element={<CashOnDeliverysuccessStatus />} />
        <Route path='/GoogleLoading' element={<GoogleLoading />} />
      </Routes>
    </Suspense>
    {isTawkMessengerLoaded && (
        <TawkMessengerReact
          propertyId={process.env.REACT_APP_CHAT_BOT_PROPERTY_ID}
          widgetId={process.env.REACT_APP_CHAT_BOT_WIDGET_ID}
        />
      )}
    <ToastContainer position="top-center" limit={3} autoClose={1000} />
  </Router>
  );
}

export default App;
