// import React, { useState } from 'react';

// const OrderStepper = () => {
//   const orderStatusPosts = [
//     { id: 1, label: 'Order status' },
//     { id: 2, label: 'Order confirmed' },
//     { id: 3, label: 'Shipping' },
//     { id: 4, label: 'Delivered' }
//   ];

//   const refundPosts = [
//     { id: 1, label: 'Requested' },
//     { id: 2, label: 'Processing' },
//     { id: 3, label: 'Refunded' }
//   ];

//   const [orderStatus, setOrderStatus] = useState(1);
//   const [refundStatus, setRefundStatus] = useState(1);

//   const renderStepper = (statuses, currentStatus, onClickHandler) => {
//     return statuses.map((status) => (
//       <div
//         key={status.id}
//         className={`step ${status.id <= currentStatus ? 'completed' : ''}`}
//         onClick={() => onClickHandler(status.id)}
//       >
//         {status.label}
//       </div>
//     ));
//   };

//   const handleOrderStatusChange = (newStatus) => {
//     setOrderStatus(newStatus);
//   };

//   const handleRefundStatusChange = (newStatus) => {
//     setRefundStatus(newStatus);
//   };

//   return (
//     <div>
//       <p>
//         {refundStatus !== '' && refundPosts[refundStatus - 1]
//           ? `Refund: ${refundPosts[refundStatus - 1].label}`
//           : orderStatusPosts[orderStatus - 1]
//           ? `Order: ${orderStatusPosts[orderStatus - 1].label}`
//           : ''}
//       </p>
//       <div className="stepper">
//         {refundStatus !== '' && renderStepper(refundPosts, refundStatus, handleRefundStatusChange)}
//         {orderStatus !== '' && renderStepper(orderStatusPosts, orderStatus, handleOrderStatusChange)}
//       </div>
//     </div>
//   );
// };

// export default OrderStepper;


import React, { useState } from 'react';
import styled from 'styled-components';


  const orderStatusPosts = [
    { id: 1, label: 'Order status' },
    { id: 2, label: 'Order confirmed' },
    { id: 3, label: 'Shipping' },
    { id: 4, label: 'Delivered' }
  ];

  const refundPosts = [
    { id: 1, label: 'Requested' },
    { id: 2, label: 'Processing' },
    { id: 3, label: 'Refunded' }
  ];


const steps = [
  'Order status',
  'Order confirmed',
  'Shipping',
 ' Delivered',
];

const StepperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .step-row{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
`;

const StepIndicator = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${(props) => (props.active ? '#4CAF50' : '#E0E0E0')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-weight: bold;
`;

const StepLabel = styled.div`
  flex-grow: 1;
`;

const StepDivider = styled.div`
  border-top: 2px solid #E0E0E0;
  width: 100%;
  margin: 0 12px;
`;

const Stepper = ({ activeStep }) => {
  return (
    <StepperContainer>
      {steps.map((label, index) => (
        <Step key={label} >
          <div className='step-row'>
          <StepIndicator active={index <= activeStep}>
            {index + 1}
          </StepIndicator>
          <StepLabel>{label}</StepLabel>
          </div>
          {index !== steps.length - 1 && <StepDivider />}
        </Step>
      ))}
    </StepperContainer>
  );
};

export default function App() {
  const [activeStep, setActiveStep] = useState(1);

  return <Stepper activeStep={activeStep} />;
}
