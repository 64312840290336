import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaShare, FaWhatsapp, FaInstagram, FaGoogle } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';

const Container = styled.div`
  position: relative;
`;

const ShareButton = styled.span`
  height: 40px;
  width: 40px;
  background:var(--green-300);
  font-size: 20px;
  color: #e9fcf8;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #159d82;
  transition: background 0.3s, border-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--green-300);
    border-color: #12876f;
  }
`;

const Popup = styled.div`
  position: absolute;
  bottom: 50px; /* Position the popup above the share button */
  right: 0;
  width: 200px;
  background: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
`;


const OptionButton1 = styled.button`
  height: 40px;
  width: 40px;
  background: #25D366;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  &:hover {
    background: #25D366;
  }
`;
const OptionButton2 = styled.button`
  height: 40px;
  width: 40px;
  background: linear-gradient(135deg, #f9ce34, #ee2a7b, #6228d7);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  &:hover {
    background: linear-gradient(135deg, #f9ce34, #ee2a7b, #6228d7);
  }
`;
const OptionButton3 = styled.button`
  height: 40px;
  width: 40px;
  background: #eb281a;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  &:hover {
    background: #eb281a;
  }
`;
const OptionButton4 = styled.button`
  height: 40px;
  width: 40px;
  background: #3d3b3b;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  &:hover {
    background: #3d3b3b;
  }
`;
const OptionButton5 = styled.button`
  height: 40px;
  width: 40px;
  background:  #3b5998;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  &:hover {
    background: #3b5998;
  }
`;


const AnimatedShareButton = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const handleWhatsAppClick = () => {
    const url = `https://myplantstore.me/products/${id}`;
    const message = encodeURIComponent(`Check out this product: ${url}`);
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };


 

  const handleCopyClick = (prop) => {
    const el = document.createElement('textarea');
    el.value = `https://myplantstore.me/products/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    // Optionally, you can provide some UI feedback to the user that the link has been copied
    alert('Link copied to clipboard!');
  }

  const handleInstaClick = () => {
    alert('Please paste the link manually after opening Instagram Direct.');
    const el = document.createElement('textarea');
    el.value = `https://myplantstore.me/products/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    const url = 'https://www.instagram.com/direct/inbox';
    window.open(url, '_blank');
  };
  

  const handleGmailClick = () => {
    const subject = encodeURIComponent('Check out this product!');
    const body = encodeURIComponent(`Check out this product: https://myplantstore.me/products/${id}`);
    const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
    window.open(mailtoUrl, '_self');
  };
  const handleFacebookClick = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=https://myplantstore.me/products/${id}`;
    window.open(url, '_blank');
  };


  return (
    <Container ref={containerRef}>
      <ShareButton onClick={togglePopup}>
        <FaShare />
      </ShareButton>
      <Popup isOpen={isOpen}>
        <Row>
          <OptionButton1 onClick={handleWhatsAppClick}>
            <FaWhatsapp />
          </OptionButton1>
          <OptionButton2 onClick={handleInstaClick}>
            <FaInstagram />
          </OptionButton2>
          <OptionButton5 onClick={handleFacebookClick}>
            <FaFacebook />
          </OptionButton5>
          <OptionButton3 onClick={handleGmailClick}>
            <MdEmail />
          </OptionButton3>
        </Row>
        <Row>
          <OptionButton4 onClick={handleCopyClick}>
            <FiCopy />
          </OptionButton4>
        </Row>
      </Popup>
    </Container>
  );
};

export default AnimatedShareButton;
