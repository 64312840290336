import React, {useRef,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal} from '../feature/modal/modalSlice';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { links } from '../utils/constants'
import Dropdown from './support/Dropdown';
import { VscClose } from "react-icons/vsc";
import {  setMyUser } from '../feature/user/userSlice';
import { removeAuthTokenCookie, removeUserLocalStorage } from '../utils/localStorage';
import { FaUserMinus,FaUser} from "react-icons/fa";
import { toast } from 'react-toastify';

const SideBar = () => {
    const dispatch =useDispatch();
    const {isOpen} =useSelector((store)=>store.modal);
    const {myUser}=useSelector((store)=>store.user)
   const navigate = useNavigate()
    const toggle = () =>{
        dispatch(closeModal());
      };
   
  const closeModalClick= ()=>{
    dispatch(closeModal());
  }
  
   const loginToggle = ()=>{
    navigate('/user/login')
    dispatch(closeModal());
   }
    //closing while click outside
    const sidebarRef = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          dispatch(closeModal());
        }
      };
    
      const handleTouchMove = (e) => {
        e.stopPropagation();
      };
    
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchmove', handleTouchMove);
      };
    }, []);


    const logoutUser = async () => {
      try {
        const response = await fetch('https://myplantstore.me/auth/logout', {
          method: 'GET',
          credentials: 'include',
        });
    
        if (response.ok) {
          window.location.href = '/';
        } else {
    
        }
      } catch (error) {
        toast.error('Something went wrong')
      }
    };
  
    const logOut=()=>{
      dispatch(logoutUser)
      removeUserLocalStorage()
      removeAuthTokenCookie()
      dispatch(setMyUser())
    }


  return (
    <SideBarContainer>
       <aside className={`${isOpen ? 'sidebar show-sidebar' : 'sidebar'}`} ref={sidebarRef}>
      <div className="sidebar-header">
        <p>Welcome <span>To manage your account</span></p>
        <button className="close-btn" type="submit" onClick={toggle}>
        <VscClose />
        </button>
        {/*sidebar login*/}
      </div>
      
      <div className='sidebar-login'>
      <div className='nav-links'>
          
          {links.map((link,)=>{
              const {id,text,url,icon,flags} =link;
               if(flags){
                const activeFlag = flags.find((flag) => flag.active);
                const activeImg = activeFlag ? activeFlag.img : null;
                return(
                  <>
                   {/* <div className='first' key={id}>
                   <div className='dropdown-main'><img src={activeImg} alt="flags"/><span><BsChevronDown /></span></div>
                   <div className='flag-div second'>
                   {flags.map((flag)=>{
                    const{id,img,text}= flag;
                    return(
                     <div className='dropdown' key={id}><button className='country' onClick={()=>console.log(text)}>
                      <img src={img} alt="flags"/><span className='country-name'>{text}</span></button></div>
                    )
                  })}
                  </div>
                   </div> */}
                  </>
                )
               }
               if(text ==="Register or sign in"){
                return (
                  <div key={id} className='second'>
                  {myUser ? <Link className='btn-login' onClick={logOut}><FaUserMinus/>Logout</Link> : <Link to='/user/login' onClick={loginToggle}className='btn-login'><FaUser />Login/sign up</Link>}
                  </div>
              )
                }
          })}
          </div>
      </div>

      {/*sidebar items*/}

       <Dropdown />
      {/*sidebar footer*/}
      <div className='down-sidebar'>
           {links.map((link,)=>{
              const {id,text,url,icon,flags} =link;
               if(text ==='Orders & rewards' || text ==='store location'){
                return (
                  <div key={id} className='second'>
                      <Link to={url} className='icon' onClick={closeModalClick}><span>{icon}</span><span className='text'>{text}</span></Link>
                  </div>
              )
               }
          })}
      </div>
     </aside>   
    </SideBarContainer>
  )
}

const SideBarContainer = styled.div`
text-align: center;
overflow: hidden;
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.5rem;
  background:var(--green-300);
  letter-spacing: 1px;
  padding-left:1rem;
}
.btns{
  font-family:inherit;

}
.sidebar-header {
  display:flex;
  justify-content:space-between;
  p{
    color:white;
    font-weight:bold;
    font-family:inherit;
    line-spacing:1px;
    text-align:left;
    margin:0.45rem 0 !important;
  }
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  transition: var(--transition);
  cursor: pointer;
  color: var(--clr-red-dark);
  
}
.sidebar-login{
  border-bottom:2px solid var(--green-500);
}
.close-btn:hover {
  color:white;
}
.btn-login{
  font-size:14.5px;
  background:transparent;
  border:none;
  color:var(--grey-500);
  padding-left:0;
  svg{
    padding-top:0.25rem;
  }
}
.btn-login:hover{
  color:var(--green-300);
  transition:color 0.3s;
}

.logo {
  justify-self: center;
  height: 45px;
}
.first-link{
  display:flex;
  justify-content:space-between;
}
.links {
  margin-bottom: 2rem;
}
.links a {
  display: block;
  text-align: left;
  font-size: 1rem;
  text-transform: capitalize;
  padding:1rem 0.5rem;
  color: var(--clr-grey-3);
  transition: var(--transition);
  letter-spacing: var(--spacing);
}

.links a:hover {
  padding-left:.5rem;
  padding-left: 2rem;
  background: var(--green-500);
  color: var(--clr-grey-2);
  .arrow{
    color:white;
    rotate: 90deg;
  }
}
.second-div li{
  width:80%;
}
.second-child{
  margin-left:1.5rem;
}
.second-child a{
  display:flex;
  justify-content:space-between;
}
.show{
  display:flex;

}
show-99{
  display:flex;
}
.sidebar {
  position:fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  box-shadow:var(--shadow-4);
  background-color: #FFF !important;
  background-color: rgba(0, 0, 0, 0.5);
  transition: var(--transition);
  transform: translate(-100%);
  z-index: -1;
  overflow-X:hidden;
}
.show-sidebar {
  transform: translate(0);
  z-index: 999;
}

.cart-btn-wrapper {
  margin: 2rem auto;
}
@media screen and (min-width: 992px) {
  .sidebar {
    display: none;
  }
}
@media screen and (max-width: 580px) {
  .sidebar {
    width:75%;
  }
  .sidebar-header p{
    font-size:14px;
  }
  .close-btn{
    padding-right:0;
    font-size:21px;
    margin-right:0;
  }
}

.first{
  
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 175px;
    margin-left: -15px;
  }
}
.nav-links {
  display:flex;
  justify-content:space-between;
  margin:0.5rem;

  position:relative;
}
.first{
  order:1;
}
.scroll-event{

}
.flag-div{
  max-width:9rem;
  flex-direction: row;
  position:absolute;
  z-index:1;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px grey;
  display:none;
  right:0rem;
}
a:hover{
  color:var(--green-300);
  transition: color 0.5s;
}
.dropdown-main:hover + .flag-div,
.flag-div:hover {
display: block;
z-index: 999;
}
.dropdown-main{
  display:flex;
  justify-content:center;
  align-items:center;
  gap:1.5rem;
}
.dropdown{
  width:10rem;
  background:white;
  display:flex;
  align-items:center;
  padding:0rem 0rem 0rem  0.25rem;
  
}
.dropdown:hover{
  background-color:var(--green-300);
  
}
a{
  color:var(--grey-700);
}
.dropdown:hover .country-name {
  font-weight: bold;
}
.flag-div button{
  width:100%;
  background:white;
  border:none;
  cursor:pointer;
}
.country{
  display:flex;
  padding-left:1rem;
  align-items:center;
  gap:1rem;
}
.country :hover{
  font-weight:1rem;
}
img{
  width:1.9rem;
}
.down-sidebar{
position: fixed;
bottom: 0;
text-align: center;
right: 0;
left:0;
width:100%;
display:flex;
justify-content:space-between;
border-top:2px solid var(--green-500);
padding:0.5rem;
z-index:999;

background:white;
padding-bottom:0.75rem;
a{
  color:var(--grey-500);
  font-size:14.5px;
}
}




`

export default SideBar