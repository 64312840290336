import { hover } from '@testing-library/user-event/dist/hover'
import React, { useState } from 'react'
import {FaStar} from 'react-icons/fa'
import styled from 'styled-components'

//input the star rating 
const StarRatingUser = ({ setUserRating }) => {
const [rating, setRating] = useState(null);
const [hover, setHover] = useState(null);

  return (
    <Wrapper>
      {[...Array(5)].map((start, index) => {
        const current = index + 1;
        return (
          <>
            <label>
              <input  type="radio" name="rating" value={current} onClick={() => {  setRating(current);  setUserRating(current);  }} />
              <FaStar color={current <= (hover || rating) ? "#ffc107" : "var(--grey-100"} className="star"  onMouseEnter={() => setHover(current)} onMouseLeave={() => setHover(null)} />
            </label>
          </>
        );
      })}
    </Wrapper>
  );
};


export default StarRatingUser



const Wrapper = styled.div`
 display:flex;
 gap:0.25rem;
 margin-top:0.75rem;
  input[type=radio]{
    display:none;
  }
  .star{
    cursor:pointer;
    font-size:30px;
  }

`