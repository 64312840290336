import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from 'axios';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

//banner 4
const FlashSaleBannerBottom = () => {
  const {banners,isBannerLoading} = useSelector((store)=>store.banner)
  const [activeIndex, setActiveIndex] = useState(0);
  const [images, setImages] = useState([]);

  const len = images.length - 1;

  useEffect(() => {
    const filteredBanners = banners.filter((b) => b.typeName === 'banner4');
    setImages(filteredBanners);
  }, [banners]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === len ? 0 : prevIndex + 1));
    }, 2000);
    return () => clearInterval(interval);
  }, [len]);

  if (images.length === 0) {
    // return <Loading />; 
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 800 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };

  return (
    <Wrapper>
       <Carousel responsive={responsive} autoPlay={true} swipeable={true} ssr={true}   arrows={false}  infinite draggable={true} showDots={false} className='gd-carousel' >
       {images && images.map((banner,idx)=>{
        if(banner.navigate){
          return(
            <img src={banner.photos[0].url} alt='offers' key={idx} style={{cursor:'pointer'}} draggable="false" onClick={() => {
              if (banner.navigate) {
                window.location.href = banner.navigate;
            }
            }}/>
          )
        }
        return(
          <img src={banner.photos[0].url} alt='offers' draggable="false" key={idx}/>
        )
       })}
        
        </Carousel>
    </Wrapper>
  )
}

export default FlashSaleBannerBottom

const Wrapper = styled.section`
width:95%;
margin:0 auto;
margin-top:3rem;

img{
    width:95%;
    height:auto;
    object-fit:cover;
}
.react-multi-carousel-item{
  margin:0 auto;
}
@media(max-width:800px){
  margin-top:1.5rem;
  margin-bottom:1.5rem;
  img{
    width:100%;
    height:auto;
}
}
@media(max-width:530px){
  margin-top:0rem;
}
`