import React, { useEffect, useState } from 'react'
import styled from 'styled-components';


import moment from 'moment-timezone';

const CountDownTimer = ({ startDate, startTime, timeInHours }) => {
    const [remainingTime, setRemainingTime] = useState('');
    useEffect(() => {
        const interval = setInterval(() => {
          
          const startDateMoment = moment.tz(startDate, 'YYYY-MM-DD', 'Asia/Dubai');
    
          const startTimeMoment = moment.tz(startTime, 'HH:mm', 'Asia/Dubai');
    
          const startDateTime = startDateMoment.set({
            hour: startTimeMoment.get('hour'),
            minute: startTimeMoment.get('minute'),
          });
    
          const endDateTime = startDateTime.clone().add(timeInHours, 'hours');
    
          const currentTimeDubai = moment().tz('Asia/Dubai');
          
          const timeDiff = endDateTime - currentTimeDubai;
    
          if (timeDiff > 0) {
            const hours = Math.floor(timeDiff / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
            setRemainingTime(`${hours}H ${minutes}M ${seconds}s`);
          } else {
            setRemainingTime('Expired');
            clearInterval(interval);
          }
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
      }, [startDate, startTime, timeInHours]);
    
      return (
        <Wrapper>Ends in<span>{remainingTime}</span></Wrapper>
      );
    }


export default CountDownTimer


const Wrapper =  styled.div`
    width:200px;
    background:#23398E;
    height:32px;
    color:#fff;
    margin-top:0 !important;
    font-weight:600 !important;
    text-align:center;
    padding:0.5rem 0;
    font-size:14px;
    display:flex;
    align-items:center;
    justify-content:center;
    span{
        height:100% !important;
        display:flex;
        align-items:center;
        justify-content:center;
        width:55%;
    }
    @media(max-width:580px){
      padding:0.25rem 0;
      height:28px;
      width:190px;
    }
`