import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Loading,
  Error,
  ProductImages,
  NewsletterForm,
  SingleFeaturedRowMobile
} from '../components'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProduct } from '../feature/plants/plantsSlice'
import { DeliveryLocation, SingleFeaturedRow, StarsReviews } from './support'
import ReviewForm from './ReviewForm'
import { addToCart, addTowishList, getCart, getWishList } from '../feature/user/userSlice'
import axios from 'axios'
import { toast } from 'react-toastify'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import moment from 'moment';
import { openCart } from '../feature/modal/modalSlice'
import AmountButtons from './support/AmountButtons'
import { MdOutlineFavoriteBorder, MdFavorite } from "react-icons/md";
import { FaShare } from 'react-icons/fa';
import CountDownTimer from './support/CountDownTimer'
import { Helmet } from 'react-helmet'
import idea from '../assets/images/idea.png'
import whatsApp from '../assets/images/whatsapp.png'
import RibbonTag from './RibbonTag'
import DeliveryLocationsingle from './support/DeliveryLocationsingle'
import AnimatedShareButton from './img/AnimatedSharebutton'



const SingleProductContainer = () => {
  const { singleProduct, isSingleProductLoading, isSingleProductError } = useSelector((store) => store.plants);
  const { user, wishListItems } = useSelector((store) => store.user)
  const { id } = useParams()
  const [newData, setNewData] = useState([])
  const [activeDimension, setActiveDimension] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [singleValue, setSingleValue] = useState(false);
  const [stockVisible, setStockVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const [isFeaturedView, setFeaturedView] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(getProduct(`${id}`));
      await dispatch(getCart());
      setTimeout(() => {
        setStockVisible(true);
        setFeaturedView(true)
      }, 2000);


    } catch (error) {
      // Handle error
    }

  }, [dispatch, id]);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [fetchData]);

  // useEffect(() => {
  //      window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    if (isSingleProductError) {
      setTimeout(() => {
        navigate(-1);
      }, 3000)
      setAmount(1);
    }

    // eslint-disable-next-line
  }, [isSingleProductError])

  const { title, price, stock, _id, SKU, photos, impdescription, dimensions, scienticName, description, selectedPlantCares, reviews, allLengths, flashSaleInfo
    , mainCategory, category, subCategory, Mname, Mdiscription, WhatsappMsg } = singleProduct;
  const [openCareIndex, setOpenCareIndex] = useState(-1);

  const handleCareItemClick = (index) => {
    setOpenCareIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  //cart count setting
  const [amount, setAmount] = useState(1);
  const [total, setTotal] = useState()

  const increase = () => {
    setAmount((oldAmount) => {
      let tempAmount = oldAmount + 1;
      if (tempAmount > stock) {
        tempAmount = stock;
      }
      return tempAmount;
    })
  }
  const decrease = () => {
    setAmount((oldAmount) => {
      let tempAmount = oldAmount - 1;
      if (tempAmount < 1) {
        tempAmount = 1;
      }
      return tempAmount;
    })
  }


  //pot selecting
  const [activePot, setActivePot] = useState(0);



  //add to cart
  const addCart = () => {

    dispatch(openCart('cart'))
    // Create the base object with common properties
    const dispatchObject = {
      title,
      productId: _id,
      mainCategory,
      subCategory,
      category,
      totalPrice: total,
      dimension: activeDimension,
      productQuantity: amount,
      image: activeImageName.url,
    };

    if (flashSaleInfo) {
      if (newPrice !== 0) {
        dispatchObject.flashSalePrice = newPrice;
      }
      if (flashSaleInfo.Status === "Unlimited") {

        if (flashSaleInfo.OfferPercentage) {
          dispatchObject.flashSaleDiscount = flashSaleInfo.OfferPercentage;
        }
        if (flashSaleInfo.Status) {
          dispatchObject.Status = flashSaleInfo.Status;
        }
      } else {
        if (flashSaleInfo.StartDate) {
          dispatchObject.flashSaleStartDate = flashSaleInfo.StartDate;
        }
        if (flashSaleInfo.StartTime) {
          dispatchObject.flashSaleStartTime = flashSaleInfo.StartTime;
        }
        if (flashSaleInfo.TimeInHours) {
          dispatchObject.flashSaleEndTime = flashSaleInfo.TimeInHours;
        }
        if (flashSaleInfo.OfferPercentage) {
          dispatchObject.flashSaleDiscount = flashSaleInfo.OfferPercentage;
        }
      }


    }

    dispatch(addToCart(dispatchObject));

    dispatch(getCart())
  }

  const addWishlist = () => {

    const dispatchObject = {
      title,
      productId: _id,
      mainCategory,
      subCategory,
      category,
      totalPrice: total,
      price,
      dimension: activeDimension,
      productQuantity: amount,
      image: activeImageName.url,
    };

    if (flashSaleInfo) {
      if (newPrice !== 0) {
        dispatchObject.flashSalePrice = newPrice;
      }
      if (flashSaleInfo.Status === "Unlimited") {

        if (flashSaleInfo.OfferPercentage) {
          dispatchObject.flashSaleDiscount = flashSaleInfo.OfferPercentage;
        }
        if (flashSaleInfo.Status) {
          dispatchObject.Status = flashSaleInfo.Status;
        }
      } else {
        if (flashSaleInfo.StartDate) {
          dispatchObject.flashSaleStartDate = flashSaleInfo.StartDate;
        }
        if (flashSaleInfo.StartTime) {
          dispatchObject.flashSaleStartTime = flashSaleInfo.StartTime;
        }
        if (flashSaleInfo.TimeInHours) {
          dispatchObject.flashSaleEndTime = flashSaleInfo.TimeInHours;
        }
        if (flashSaleInfo.OfferPercentage) {
          dispatchObject.flashSaleDiscount = flashSaleInfo.OfferPercentage;
        }
      }
    }

    dispatch(addTowishList(dispatchObject))
    dispatch(getWishList())
  }


  let stars = 0;
  let totalRating = 0;
  let reviewRate = 0;

  // Loop through the reviews array to calculate the total rating and number of ratings
  reviews && reviews.forEach((reviewData) => {
    if (reviewData.review && reviewData.review.rating) {
      const rating = parseInt(reviewData.review.rating);
      if (!isNaN(rating)) {
        totalRating += rating;
        reviewRate++;
      }
    }
  });

  // Calculate the average rating
  stars = reviewRate > 0 ? totalRating / reviewRate : 0;

  //show review
  const [show, setShow] = useState(true)
  //delete review
  const deleteReview = async (_id) => {
    try {
      const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}/reviews/${_id}`)
      toast.warning('Deleted successfully...')
      dispatch(getProduct(id))
    } catch (error) {
      toast.success(error)
    }
  }


  let imgName = photos && photos.length > 0 ? photos[0] : ''

  const [activeImageName, setActiveImageName] = useState(imgName.url);

  // Callback function to update active image name
  const updateActiveImageName = (imageName) => {
    setActiveImageName(imageName);
  };
  useEffect(() => {
    // Update activeImageName whenever it changes
    setActiveImageName(imgName);
  }, [imgName]);


  useEffect(() => {
    const sortedDimensions = Array.isArray(dimensions)
      ? [...dimensions].sort((a, b) => a.Price - b.Price)
      : [];

    if (sortedDimensions.length > 0) {
      const result = dimensions.filter(dim => dim.Value1 === sortedDimensions[0].Value1);
      setNewData(result);
    }
  }, [dimensions]);





  const newPrice = flashSaleInfo && (total - ((flashSaleInfo.OfferPercentage / 100) * total))

  //for date showing comments
  const formatDate = (inputDate) => {
    const originalDate = moment(inputDate);
    const formattedDate = originalDate.format('D MMMM YYYY');
    return formattedDate;
  }


  const isProductInWishlist = (Id) => {
    return (wishListItems || []).some(item => item.productId === Id);
  }
  const addedFav = isProductInWishlist(_id);

  const sortedDimensions = Array.isArray(dimensions)
    ? [...dimensions].sort((a, b) => a.Price - b.Price)
    : [];


  const [fieldData, setFieldData] = useState({});
  const [fieldData2, setFieldData2] = useState({})

  let result

  const handleClick = (correspondingDimension, value, field) => {

    if (value.toLowerCase() === 'ceramic' || value.toLowerCase().includes('ceramic')) {
      setActivePot(0)
    }
    if (value.toLowerCase() === 'default' || value.toLowerCase().includes('default')) {
      setActivePot(0)
    }
    if (value.toLowerCase() === 'mud' || value.toLowerCase().includes('mud')) {
      setActivePot(0)
    }

    result = (dimensions.filter(dim => dim.Value1 === value));
    if (field === 'Field1') {
      setNewData(result)
      if (result.length > 1) {
        setSingleValue(false)
      }
      else {
        setSingleValue(true)

      }
    }
    let result2 = (dimensions.filter(dim => dim.Value2 === value));
    if (field === 'Field2') {
      if (!singleValue) {
        setFieldData2(result2)
      }
    }


    // Update the state based on the current field
    setFieldData({
      ...fieldData,
      [field]: {
        Field: correspondingDimension[field],
        Value: value,
        correspondingDimension: correspondingDimension,
      },
    });


  };


  useEffect(() => {
    setFieldData2({})
    setAmount(1)
  }, [id])
  // Existing useEffect for setting field data
  useEffect(() => {
    if (sortedDimensions.length > 0) {
      const initialFieldData = {};
      Object.keys(sortedDimensions[0]).forEach((key) => {
        // Check if the key starts with 'Field'
        if (key.startsWith('Field')) {
          initialFieldData[key] = {
            Field: sortedDimensions[0][key],
            Value: sortedDimensions[0][`Value${key[key.length - 1]}`],
            correspondingDimension: sortedDimensions[0],
          };
        }
      });
      setFieldData(initialFieldData);
    }

  }, [dimensions]);


  useEffect(() => {
    let highestPrice = 0;
    let matchingDimensionWithHighestPrice = null;
    // Iterate over each field in fieldData
    Object.values(fieldData).forEach((field, index, fieldsArray) => {
      // Ensure there is a next field in fieldData
      const nextField = fieldsArray[index + 1];

      // If there is a next field, find the matching dimension in dimensions
      if (nextField) {
        let matchingDimension = dimensions.find((dim) => {
          return (
            dim.Value1 === field.Value &&
            dim.Value2 === nextField.Value
          );
        });

        if (!matchingDimension && field.correspondingDimension) {
          matchingDimension = field.correspondingDimension;
        }

        if (matchingDimension) {
          const price = parseFloat(matchingDimension.Price);
          if (!isNaN(price) && price > highestPrice) {
            highestPrice = price;
            matchingDimensionWithHighestPrice = matchingDimension;
          }
        }
      }
    });


    let totals = Number(highestPrice * amount)

    setTotal(totals);
    setActiveDimension(matchingDimensionWithHighestPrice)
    // Now you can use matchingDimensionWithHighestPrice for further operations if needed

  }, [fieldData, dimensions, amount]);

  const openWhatsapp = (prop) => {
    window.open(`https://wa.me/+971527349456?text=SKU:${prop.SKU},title:${prop.title}`, '_blank');
  }

  //loading icon
  if (isSingleProductLoading) {
    return (<Wrapper><Loading /></Wrapper>)
  }
  //error page
  if (isSingleProductError) {
    return <Error />
  }


  const copyToClipboard = (prop) => {
    const el = document.createElement('textarea');
    el.value = `https://myplantstore.me/products/${_id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    // Optionally, you can provide some UI feedback to the user that the link has been copied
    alert('Link copied to clipboard!');
  }

  return (
    <Wrapper>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{Mname ? Mname : title}</title>
        <meta name="description" content={Mdiscription} />
        <meta name='keywords' content={`${title},buy ${title},buy ${title} online,buy ${title} in uae,where to buy ${title} in uae,where to buy ${title} near me,best online shop for buying ${title},best shop for buying  ${title},
         best online shop for indoor and outdoor plants,cheap plants online,plant gifts,plants online,online shop for plants,buy plants in uae,plant plants in abu dhabi,buy plants in ajman, `} />
      </Helmet>
      <div className="page">
        {/* <Link to="" className="btn" onClick={() => navigate(-1)}>back to products</Link> */}
        <div className="product-center">
          <div className='image-container'><ProductImages images={photos} updateActiveImageName={updateActiveImageName} activePotIndex={activePot} /></div>
          <section className="content">
            <h5>{title}</h5>
            <StarsReviews stars={stars} reviewRate={reviewRate} />
            <p className="warning-info">{scienticName ? scienticName : ''}</p>
            <p className="warning-info">SKU : {SKU ? SKU : ''}</p>
            {flashSaleInfo && <div className='flash-sale'><p className='percentages'>{flashSaleInfo && flashSaleInfo.OfferPercentage}% <span>off</span></p>

              {(flashSaleInfo?.Status && flashSaleInfo?.Status === "Unlimited") ? null : <CountDownTimer startDate={flashSaleInfo.StartDate} startTime={flashSaleInfo.StartTime} timeInHours={flashSaleInfo.TimeInHours} />}
            </div>}
            {flashSaleInfo ? <div className='price-div'><div><h5 className="price">{parseFloat(newPrice?.toFixed(2))} AED</h5><span>Inc.Vat</span></div><p className='old-price'>{parseFloat(total?.toFixed(2))} AED</p></div>
              : <div className='price-div'><div><h5 className="price">{parseFloat(total?.toFixed(2))} AED</h5><span>Inc.Vat</span></div></div>}

            {(stock && stockVisible) && ((stock < 0) || (total <= 0)) && <span className="info">Out of stock</span>}

            <section className='actions'>
              {
                dimensions &&
                dimensions.map((dimension, index) =>
                  dimension[`Field${index + 1}`] && (
                    <div className='align-left' key={index}>
                      <span>{dimension[`Field${index + 1}`].toLowerCase()}</span>
                      {/* <div className={`field${index + 1}`}> */}
                      <div className={sortedDimensions.length === 1 ? 'button-column-div' : `field${index + 1}`}>

                        {Array.from(
                          new Set(sortedDimensions.map(dim => dim[`Value${index + 1}`]))
                        )
                          .map((value, idx) => {
                            const correspondingDimension = sortedDimensions.find(
                              dim => dim[`Value${index + 1}`] === value
                            );
                            // Check if the value exists in the newData array
                            let isEnabled = (`Value${index + 1}` !== 'Value1') ? newData.some(data => data[`Value${index + 1}`] === value) : true;
                            let isEnabledValue1
                            if (fieldData2.length > 0) {
                              isEnabledValue1 = (`Value${index + 1}` !== 'Value2')
                                ? (Array.isArray(fieldData2) && fieldData2.length > 0 && fieldData2.some(data => data[`Value${index + 1}`] === value))
                                : true
                            }
                            else {
                              isEnabledValue1 = true
                            }

                            const isActive =
                              correspondingDimension &&
                              correspondingDimension._id ===
                              fieldData[`Field${index + 1}`]?.correspondingDimension._id;
                            if (value) {
                              return (
                                <>
                                  <div key={idx}>
                                    <button

                                      className={`${(isEnabled && isEnabledValue1) ? (isActive ? 'active' : '') : 'disabled'} `}

                                      onClick={() =>
                                        handleClick(
                                          correspondingDimension,
                                          value,
                                          `Field${index + 1}`
                                        )
                                      }
                                      disabled={!isEnabled || !isEnabledValue1} >
                                      {value.toLowerCase()}
                                    </button>
                                  </div>
                                  {(sortedDimensions.length === 1 && sortedDimensions[0]?.Value2) &&
                                    <div className='single-dimension'>
                                      <span>{sortedDimensions[0]?.Field2.toLowerCase()}</span>
                                      <button className='active'>{sortedDimensions[0]?.Value2}</button>
                                    </div>
                                  }
                                </>
                              );
                            }
                          })}
                      </div>
                    </div>
                  )
                )
              }

              <div className="btn-container">
                <AmountButtons amount={amount} increase={increase} decrease={decrease} />
                <Link to="" className="btn-cart" onClick={addCart}>Add to Cart</Link>
                <button className='wishlist-btn' onClick={() => addWishlist()}> <span>{addedFav ? <MdFavorite /> : <MdOutlineFavoriteBorder />}</span></button>
                {/* <button className="share-btn" onClick={()=>copyToClipboard({_id})}><span><FaShare/></span></button> */}
                <AnimatedShareButton id={_id}/>
              </div>
              {(WhatsappMsg === 'true') && <button className='whatsapp-msg' onClick={() => openWhatsapp({ title, SKU })}>
                <span>For express delivery / customization please contact</span>
                <img src={whatsApp} alt="icon" /></button>}
              <DeliveryLocationsingle />
              <div className='cart-btn-container'>
              </div>
            </section>
            <div className='paragraph-plantCare'>Product Care:</div><br />
            <div className='plant-care'>
              {selectedPlantCares &&
                Array.from(new Set(selectedPlantCares.map(care => care.careName))) // Remove duplicates
                  .map((careName, idx) => {
                    const care = selectedPlantCares.find(c => c.careName === careName);
                    const { photo, caredes } = care;
                    const isOpen = idx === openCareIndex;
                    return (
                      <div className='care-item' key={idx}>
                        <div className={`show-div ${isOpen ? 'open' : ''}`} onClick={() => handleCareItemClick(idx)}>
                          {photo && <img src={photo} alt={careName} className='care-img' draggable="false" />}
                          <p>{careName}</p>
                          <div className='care-toggle-icon'>
                            {isOpen ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
                          </div>
                        </div>
                        <div className={`care-des ${isOpen ? 'open' : ''}`}>{caredes}</div>
                      </div>
                    );
                  })}
            </div>
          </section>
        </div>
      </div>
      {impdescription && <div className='impdescription'>
        <img src={idea} alt="idea" draggable="false" />
        {impdescription}
      </div>}

      {/* <div  className='description'><h5>Tag</h5><br/></div> */}
      {/* <div style={{paddingTop:'22px'}}>
        <RibbonTag sku={SKU} />
      </div> */}
      <div className='description'>
        <h5>Description</h5><br />
        <div className='p-element'>
          Categories: <RibbonTag sku={SKU} />
          {description && description?.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>

      {isFeaturedView && (isMobileView ? (
        <SingleFeaturedRowMobile category={''} mCategory={singleProduct?.maincategory} />
      ) : (
        <SingleFeaturedRow category={''} mCategory={singleProduct?.maincategory} />
      ))}
      <ReviewForm id={_id} />
      {singleProduct.reviews && singleProduct.reviews.map((item, id) => (
        <div key={id} className='show-rating'>
          <p className='rating-user'>{item.userName.split(' ')[0]}</p>
          <div className='review-show'>
            <p>{item.review.createdAt && formatDate(item.review.createdAt)}</p>
            <StarsReviews stars={item.review.rating} show={show} />
            <div className='review-content'>{item.review.content} </div>
            {user && (user.id && item.review.userId === user.id ? <button type='button' onClick={() => deleteReview(item.review._id)}>Delete</button> : null)}
          </div>
        </div>
      ))}
      <div className='news-letter'>
        <NewsletterForm />
      </div>
    </Wrapper>
  )
}

export default SingleProductContainer


const Wrapper = styled.main`
margin-top:1.75rem;
min-height:70vh;
.page{
  margin:0 auto;
  max-width:95%;
  
}
.product-center {
  display: flex;
  flex-wrap: wrap;
  gap: 3.5rem;
  justify-content:flex-start;
  width: 100%;
  align-items: flex-start;

}

.image-container {
  width:44vw;
  box-sizing: border-box;
  text-align: center;
  padding-left: 0.2rem; 
  margin-bottom:auto;
}
.flash-sale{
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  gap:1.5rem;
  margin:0.75rem 0;
}
.percentages{
  
  display:flex;
  justify-content:center;
  align-items:center;
  font-weight:600;
  text-transform:uppercase;
  background:yellow;
  width:100px !important;
  height:30px;
  text-align:center;
  padding:0.05rem;
  font-size:16px !important;
  font-weight:600;
  border-radius:5px;
  margin:0 !important;
  
  span{
   
      margin:0 !important;
      padding:0 !important;
      padding-left:0.25rem !important;
  }
}
input[type="text"] {
  font-family:  "Poppins";
}
select {
  font-family: "Poppins";
}
.paragraph-plantCare{
  float:left;
  padding-top: 1rem;
}
.disabled{
  color:var(--grey-100) !important;
  border:1.4px solid var(--grey-10) !important;
}
.align-left{
  display:flex;
  flex-direction:column;
  margin:0.75rem 0;
  max-width:400px;
  span{
    margin-right:auto;
    text-transform: capitalize;
  }
  button{
    text-transform: capitalize;
    background:transparent;
    border: 1.4px solid var(--grey-100);
    padding:0.35rem .75rem;
    font-weight:500;
    color:var(--grey-500);
    border-radius:5px;
  }
}

.impdescription{
  img{
    width:15px;
    height15px;
    margin-right:0.5rem;
  }
  
  width:95%;
  margin:0 auto;
  font-size: 14px;
  margin-top:1.5rem;
  margin-bottom:1rem;
  text-align: justify;
  background:#befad3;
  padding:0.5rem;
}
.field1{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  gap:0.5rem;
}
.button-column-div{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  gap:0.5rem;
  .single-dimension{
    display:flex;
    flex-direction:column;
  }
}
.field2{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  gap:0.5rem;
  
}
.active{
  color:var(--green-300) !important;
  border:1.4px solid var(--green-300) !important;
}
.timing{
  width:200px !important;
  background:#23398E;
  color:#fff;
  height:30px;
  font-weight:700;
  text-align:center !important;
  margin:0 !important;
  border-radius:5px;
  display:flex;
  justify-content:center !important;
  align-items:center !important;

  .ends-in{
    width:40%;
    text-align:right;
    margin-right:0.5rem;
  }
  .time{
    width:60% !important;
    text-align:left;
  }
}
.price{
  margin:0 !important;
}
.price-div{
  display:flex;
  flex-direction:row;
  gap:1rem;
  h5{
  font-weight:bold;
  padding: 0;
  margin: 0;
  display: inline-block !important;
  width: fit-content !important; 
  margin-top:0.5rem;
  }
  .old-price{
    padding: 0;
    margin: 0;
    display: inline-block !important;
    width: fit-content !important; 
    text-decoration: line-through;
  }
  span{
    font-weight:500;
    color:var(--grey-500);
    font-size:0.75rem;
  }
}

.content {
  max-width: calc(50% - 0.5rem);
  box-sizing: border-box;
  text-align: center;
  min-width:400px;
  padding-top:0;
  margin-bottom:auto;
  h5{
    margin-bottom:0.35rem;
  }
}
.show-rating{
  max-width:95%;
  display:flex;
  flex-direction:row;
  margin-top:1rem;
  margin:0 auto;
  gap:2rem;
  padding:1rem 1.25rem;
  font-size:14px;
  transition: box-shadow 0.3s ease-in-out;
  .rating-user{
    margin:0 !important;
    padding:0 !important;
    width:100px !important;
    height:100px !important;
    background-color: rgba(35, 57, 142, 0.2);
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    font-weight:600;
  }
 
  button{
    max-width:80px;
    margin-right: auto;
    background:transparent;
    color:red;
    border:0;
    margin-top:auto;
    border-radius:5px;
  }
  button:hover{
    background:#ffdfd5;

  }
}
.location-text,.location-btn{
  height:3rem;
  
}
.size{
  margin-top:0.5rem;
}
.show-rating:hover{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}
.review-show{
  display:flex;
  flex-direction:column;
  gap:0.25rem;
  width:100%;
  p{
    padding:0;
    margin:0;
  }
  .review-content{
  text-align: justify;
  width:100%;
  p{
    display: inline-block !important;
    width:10vw !important;
    text-align: justify;
  }
  }
  
}
.news-letter{
  margin-top:3.5rem;
  margin-bottom:2rem;
}
.whatsapp-msg {
  width:100%;
  display: flex;
  gap:0.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding:0.35rem .25rem;
  margin:1rem 0;
  border:1px solid var(--green-300);
  border-radius:5px;
  cursor: pointer;
  background:transparent;
  span {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: left;  
    font-size: 14px;
    color: var(--green-300);
    font-weight: 600;
    text-transform: capitalize;
  }

  img {
    width: 35px;
    height: 35px;
   
  }
  &:hover img  {
    animation: car 0.7s alternate infinite;
    animation-iteration-count: infinite;
  }
}

.show-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  // border-bottom: 1px solid #ccc;
  height: 30px;
  align-items: center;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  padding-bottom:0.5rem;
  p{
    margin:0 !important;
    padding:0;
    display:inline-block;
    text-align:left;
    padding-left:0.5rem;
  }
}

.show-div.open {
  height: auto;
}

.care-toggle-icon {
  display: flex;
  align-items: center;
}

.care-img {
  width: 21px;
  height: 21px;
}
.description{
 width:95%;
 margin:0 auto;
 font-size: 14px;
 margin-top:1rem;
 margin-bottom:1rem;
 text-align: justify;
 p{
  padding:0;
  margin:0;
  margin-bottom:0.5rem;
  width:100% !important;
 }
 h5{
  font-size: 16px;
  padding-bottom: 0.25rem; 
  border-bottom: 1px solid var(--green-500); 
  display: inline;
  
 }
 .p-element{
  margin-top:0.75rem;
 }
}
.plant-care {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 1rem;
  padding-top: 0.5rem;
  max-width:400px;
  width:100%;
}

.care-item {
  display: flex;
  flex-direction: column;
  text-transform:capitalize !important;
  p{
    text-transform:capitalize !important;
  }
}

.care-des {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  border-bottom:.4px solid var(--grey-100);
  font-size:14px;
  text-align: justify;

}

.care-des.open {
  max-height: 1000px;
}
.actions{
  .size{
    span{
      display:flex;
    }
  }
  .size-btn-container{
    position:relative;
    
  
  }
  .size-btn-container .select-box {
    width: 100%;
    padding: 0.75rem 2rem 0.75rem 0.75rem; 
    outline: none;
    border-radius: 5px;
    border: 1.4px solid var(--grey-100);
    position: relative; /* To position the arrow */
    -webkit-appearance: none;
    appearance: none;
  
    
  }
  .select-arrow{
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .btn-container{
    display:flex;
    margin-top:1rem;
    justify-content:space-between;
    gap:0.5rem;
  }
  .btn-cart{
    flex:1.5;
    background:var(--green-300);
    color:#fff;
    font-weight:600;
    text-align:center;
    padding:0.25rem 1.25rem;
    border-radius:5px;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .wishlist-btn{
    font-size:1.5rem;
    border:1.4px solid var(--green-300);
    border-radius:5px;
    background:transparent;
    color:var(--green-300);
    span{
      display:flex;
      justify-content:center;
  
    }
  }
  .share-btn {
    font-size:1.5rem;
    border:1.4px solid var(--green-300);
    border-radius:5px;
    background:transparent;
    color:var(--green-300);
    span{
      display:flex;
      justify-content:center;
  
    }
  }
  
 
  
  .location-check{
    margin:1rem 0;
    display:flex;
    justify-content:space-between;
    height:2.5rem;
    gap:0.5rem;
    input{
      border:1.4px solid var(--grey-100);
      padding-left:0.5rem;
      flex:1;
      border-radius:5px;
    }
  }
  .location-btn{
    border:none;
    flex:0.5;
    border-radius:5px;
    background:#23398E;
    color:#fff;
    font-weight:bold;
    font-size:16px;
  }
}



h4{
  font-weight:700;
  color:var(--green-300);
}
.paragraph {
  width: 100% !important;
}
.info-div{
  display:flex;
  justify-content:flex-start;
  flax-wrap:wrap;
  gap:0.75rem;
}
.options {
  flex: 0.85; 
  max-width: 330px;
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 1rem;
  align-self: flex-start;
  border: 2px solid var(--grey-100);
  border-radius: 0.45rem;
  margin-top: 1rem;
  padding-right:0.25rem;
  h6 {
    padding: 0.5rem 1rem;
  }
}
h5{
  text-align: left;
  margin:0;
  font-weight:600;
}
h6{
  margin:0;
  font-size:15px;
  color:var(--green-300);
  border-bottom:1px solid var(--grey-100);
  text-align: left;
}

  .desc {
    line-height: 2;
    max-width: 45em;
  }
  p{
    span{
      text-align: left;
    }
  }
  .warning-info{
    text-align:left;
    font-size:14px;
    margin-bottom:0.5rem;
    margin-top:0.75rem;
  }
  .info {
    text-transform: capitalize;
    display:flex;
    justify-content:center;
    align-items:center;
    background:#F34D4D;
    width:95px;
    padding:3px 0;
    font-size:0.8rem;
    text-align:center;
    color:#fff;
    font-weight:600;
    border-radius:5px;
    margin:0.5rem 0rem;
  }
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .tab-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
  }
  
  .tab-button.active {
    background-color: var(--green-500);
    color: #fff;
  }
  
  .content-container {
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
  
  .tab-content {
    display: none;
    padding: 20px;
    background-color: var(--grey-10);
    border-radius: 5px;
    
  }
  p {
    width: 100% !important;
}
  
  .tab-content.active {
    display: block;
  }
  @media(max-width:1150px){
    .product-center{
      flex-direction:column;
      max-width:500px;
      margin-left:3rem;
      gap:0;
    }
    .content{
      margin:0 auto;
      margin-left:0;
      margin-top:1rem;
    }
    .image-container {
      width:100%;
      margin: 0 auto;
    }
    .impdescription{
      margin-top:1rem;
    }
  }
  @media (max-width: 768px) {
    .product-center {
      flex-direction: column;
      align-items: center;
    }
  
    .image-container,
    .content {
      width: 100%;
      margin-top: 0;
    }
  }
  @media (max-width: 680px){
    .product-center{
      margin-left:1rem;
    }
  }
  @media(max-width:610px){
    .image-container {
      width:100%;
      margin: 0 auto;
      max-height:500px;
    }
  }
  @media (max-width: 580px){
    .product-center{
      margin-left:0rem;
    }
    .description{
      p{
        margin-bottom:0.5rem;
      }
    }
    .whatsapp-msg{
      span{
        font-size:13px;
      }
      img{
        width:30px;
        height:30px;
      }
    }
  }
  @media (max-width: 530px) {
    .image-container {
      max-height:415px;
    }
    .show-rating{
      padding:0;
      gap:0.25rem;
      text-overflow: ellipsis;
    }
    .review-content{
      font-size:13px;
    }
    .care-des,.impdescription,.description{
      font-size:12.5px;
    }
  }
  @media (max-width: 430px) {
    .image-container {
      max-height:350px;
      max-width:400px;
      padding:0;
      margin-right:auto;
    }
    .content {
      max-width:350px;
      min-width:200px !important;
    
      padding-top:1rem;
    }
    .page{
      margin:0 auto;
    }
    .paragraph-plantCare{
      font-size:14px;
    }
    .care-img{
      width:19px;
      height:19px;
    }
    .show-div{
      p{
        font-size:13.5px;
      }
    }
  }
  @media (min-width: 992px) {
    .product-center {
     
    }
    .price {
      font-size: 1.25rem;
    }
  }
  @media (max-width: 458px) {
    .btn-cart{
      font-size:12px;
    }
    .whatsapp-msg{
      span{
        font-size:12px;
      }
      img{
        width:20px;
        height:20px;
      }
    }
  }
  @media (max-width: 364px) {
    .btn-cart{
      font-size:11px;
    }
    .whatsapp-msg{
      span{
        font-size:12px;
      }
      img{
        width:20px;
        height:20px;
      }
    }
  }
`

